import {
  DiscordIcon,
  TwitchIcon,
  SteamIcon,
  InstagramIcon,
  TwitterIcon,
  TiktokIcon,
  YoutubeIcon,
  PlayStationIcon,
  FalseCheck16,
  LinkOutsideIcon,
  CorrectCheck15,
} from "../../";

type PlatformsFrameProps = {
  platform: string;
  url?: string;
  edit?: boolean;
  isVerified?: boolean;
  pseudo?: string;
  onClick?: () => void;
};

type PlatformIconComponents = {
  [key: string]: React.ElementType;
};

function getPlatformIconComponent(platform: string) {
  const platformIcons: PlatformIconComponents = {
    Discord: DiscordIcon,
    Twitch: TwitchIcon,
    Steam: SteamIcon,
    Twitter: TwitterIcon,
    Tiktok: TiktokIcon,
    Youtube: YoutubeIcon,
    Playstation: PlayStationIcon,
    Instagram: InstagramIcon,
  };

  return platformIcons[platform];
}

export const PlatformsFrame: React.FC<PlatformsFrameProps> = ({
  platform,
  url,
  edit,
  isVerified = false,
  pseudo,
  onClick,
}) => {
  const IconComponent = getPlatformIconComponent(platform);
  if (!IconComponent) {
    console.error(`Icon for platform ${platform} not found.`);
    return null;
  }
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex h-[44px] items-center justify-between self-stretch rounded-md border-[0.5px] border-solid border-white px-4 py-2"
      onClick={onClick}
    >
      <div className="flex items-center gap-x-4">
        <IconComponent />
        <div className="flex items-center gap-x-2">
          <p className="h4-mobile opacity-90">{pseudo}</p>
          {isVerified && <CorrectCheck15 />}
        </div>
      </div>
      {edit == false && <LinkOutsideIcon />}
      {edit && (
        <div className="relative">
          <div className="absolute bottom-3">
            <FalseCheck16 />
          </div>
        </div>
      )}
    </a>
  );
};
