import { useState, useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import {
  RoundedButtonMessagery,
  ChevronIcon,
  CommentIcon,
  PlayerDiscussionCard,
  GroupDiscussionCard,
} from "../..";

type PlayerData = {
  id: string;
  logo: string;
  username: string;
  messageCount: number;
  lastMessageDate: string;
  firstWord: string;
  type: "player";
};
type GroupData = {
  id: string;
  logo: string;
  groupname: string;
  participantCount: number;
  messageCount: number;
  lastMessageDate: string;
  type: "group";
};

type DiscussionData = PlayerData | GroupData;

type ListDiscussionsWidgetProps = {
  onDiscussionClick?: () => void;
  onNewMessageClick?: () => void;
};

export const ListDiscussionsWidget: React.FC<ListDiscussionsWidgetProps> = ({
  onDiscussionClick,
  onNewMessageClick,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (containerRef.current) {
        const aside = containerRef.current.querySelector("aside");
        if (aside) {
          const { scrollTop, scrollHeight, clientHeight } = aside;
          const atTop = scrollTop === 0;
          const atBottom = scrollTop + clientHeight === scrollHeight;

          if ((atTop && e.deltaY < 0) || (atBottom && e.deltaY > 0)) {
            e.preventDefault();
          }
        }
        e.stopPropagation();
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  // const fakePlayersData: PlayerData[] = [
  //   {
  //     id: "1",
  //     logo: Legolas,
  //     username: "Legolas",
  //     messageCount: 2,
  //     lastMessageDate: "2024-04-03T08:43:31.000+00:00",
  //     firstWord: "Hello",
  //     type: "player",
  //   },
  //   {
  //     id: "2",
  //     logo: Gimli,
  //     username: "Gimli",
  //     messageCount: 5,
  //     lastMessageDate: "2024-04-02T15:43:31.000+00:00",
  //     firstWord: "Hi",
  //     type: "player",
  //   },
  //   {
  //     id: "3",
  //     logo: Boromir,
  //     username: "Boromir",
  //     messageCount: 1,
  //     lastMessageDate: "2024-03-01T15:43:31.000+00:00",
  //     firstWord: "Hey",
  //     type: "player",
  //   },
  //   {
  //     id: "4",
  //     logo: Aragorn,
  //     username: "Aragorn",
  //     messageCount: 3,
  //     lastMessageDate: "2023-03-01T15:43:31.000+00:00",
  //     firstWord: "Hola",
  //     type: "player",
  //   },
  // ];

  const fakeGroupsData: GroupData[] = [
    {
      id: "1",
      logo: "https://via.placeholder.com/50",
      groupname: "Fellowship",
      participantCount: 9,
      messageCount: 10,
      lastMessageDate: "2024-04-03T08:43:31.000+00:00",
      type: "group",
    },
    {
      id: "2",
      logo: "https://via.placeholder.com/50",
      groupname: "The Shire",
      participantCount: 5,
      messageCount: 8,
      lastMessageDate: "2024-04-02T15:43:31.000+00:00",
      type: "group",
    },
  ];

  const combinedData: DiscussionData[] = [
    // ...fakePlayersData,
    ...fakeGroupsData,
  ].sort(
    (a, b) =>
      new Date(b.lastMessageDate).getTime() -
      new Date(a.lastMessageDate).getTime(),
  );

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div
      className="pointer-events-none fixed bottom-0 left-0 z-[200] flex w-full items-end justify-end pr-3 max-mobile:hidden"
      ref={containerRef}
    >
      <div
        className={`pointer-events-auto flex max-h-[calc(100vh-150px)] w-[400px] flex-col ${
          isVisible && "gap-y-[10px]"
        } rounded-lg px-6 shadow-[0_2px_34px_rgba(0,0,0,0.45)] backdrop-blur-[6.5px]`}
        style={{
          background:
            "linear-gradient(293deg, rgb(111 38 133 / 95%) 0.12%, rgb(94 36 111 / 95%) 117.69%)",
        }}
      >
        <header
          ref={containerRef}
          className={`${
            isVisible && "border-b-[2px] border-b-[rgba(248,239,251,0.37)]"
          } flex w-full items-center justify-between py-3`}
        >
          <div className="flex h-[30px] items-center gap-x-4">
            <CommentIcon isRtl={true} />
            <p className="h3-mobile">Discussions</p>
          </div>
          <div className="flex items-center gap-x-6">
            <RoundedButtonMessagery onClick={onNewMessageClick} />
            <ChevronIcon
              orientation={isVisible ? "up" : "down"}
              onClick={toggleVisibility}
              className="cursor-pointer"
            />
          </div>
        </header>
        <CSSTransition
          in={isVisible}
          timeout={300}
          classNames="slide"
          unmountOnExit
        >
          <aside className="styled-scrollbars flex max-h-[calc(100vh-150px)] flex-col gap-y-2 self-stretch overflow-y-auto pb-4">
            {combinedData.map((item, index) => {
              const nextItem = combinedData[index + 1];
              const noBorderBottom = !nextItem || nextItem.type === "group";
              return item.type === "player" ? (
                <PlayerDiscussionCard
                  key={`player-${index}`}
                  id={item.id}
                  logo={item.logo}
                  username={item.username}
                  messageCount={item.messageCount}
                  firstWord={item.firstWord}
                  lastMessageDate={item.lastMessageDate}
                  onClick={onDiscussionClick}
                  noBorderBottom={noBorderBottom}
                />
              ) : (
                <GroupDiscussionCard
                  key={`group-${index}`}
                  id={item.id}
                  logo={item.logo}
                  groupname={item.groupname}
                  participantCount={item.participantCount}
                  messageCount={item.messageCount}
                  lastMessageDate={item.lastMessageDate}
                  onClick={onDiscussionClick}
                  game={""}
                  gamelogo={""}
                />
              );
            })}
          </aside>
        </CSSTransition>
      </div>
    </div>
  );
};
