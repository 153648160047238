type CardStatProps = {
  direction?: "column" | "horizontal";
  children: React.ReactNode;
};

export const CardStat: React.FC<CardStatProps> = ({
  direction = "horizontal",
  children,
}) => {
  const isColumn = direction === "column";
  return (
    <div
      className={`flex ${
        isColumn ? "flex-col" : "flex-row"
      } items-center gap-x-2 gap-y-1 rounded-md bg-[rgba(245,235,255,0.10)] px-4 py-3 shadow-[1px_2px_1px_rgba(0,0,0,0.45)] backdrop-blur-[2px]`}
    >
      {children}
    </div>
  );
};
