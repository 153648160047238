import { useLocation, useNavigate } from "react-router-dom";
import { SubtitleNavigationButton } from "..";
import "../Search/SubtitleSearch.css";
import { useState, useRef, useEffect } from "react";

type NavigationBarProps = {
  buttons: { title: React.ReactNode; path?: string; hasNewEvents?: boolean }[];
  secondary?: boolean;
  padding?: string;
  overflowWidth?: string;
  onSelect?: (title: string) => void;
  activeButton?: string;
};

export const SubtitleNavigation: React.FC<NavigationBarProps> = ({
  buttons,
  secondary,
  padding,
  overflowWidth = "100%",
  onSelect,
  activeButton,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [containerWidth, setContainerWidth] = useState("100%");
  const containerRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (index: number) => {
    const path = buttons[index].path;
    if (path) {
      navigate(path);
    }
    if (onSelect) {
      onSelect(buttons[index].title as string);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const isOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setContainerWidth(isOverflowing ? overflowWidth : "100%");
    }
  }, [buttons, overflowWidth]);

  return (
    <div
      className={`scrollable-container flex w-full gap-x-2 overflow-x-auto whitespace-nowrap min-745:justify-center ${padding ? padding : "py-4"}`}
      ref={containerRef}
      style={{ width: containerWidth }}
    >
      {buttons.map((button, index) => (
        <SubtitleNavigationButton
          key={index}
          title={button.title}
          isActive={
            button.title === activeButton || location.pathname === button.path
          }
          onClick={() => handleButtonClick(index)}
          hasNewEvents={button.hasNewEvents}
          secondary={secondary}
        />
      ))}
    </div>
  );
};
