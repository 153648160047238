export const PrivateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      opacity="0.5"
      d="M33.75 17.5H31.875V11.875C31.875 5.32812 26.5469 0 20 0C13.4531 0 8.125 5.32812 8.125 11.875V17.5H6.25C4.17969 17.5 2.5 19.1797 2.5 21.25V36.25C2.5 38.3203 4.17969 40 6.25 40H33.75C35.8203 40 37.5 38.3203 37.5 36.25V21.25C37.5 19.1797 35.8203 17.5 33.75 17.5ZM25.625 17.5H14.375V11.875C14.375 8.77344 16.8984 6.25 20 6.25C23.1016 6.25 25.625 8.77344 25.625 11.875V17.5Z"
      fill="#F8EFFB"
    />
  </svg>
);
