import { QuotePost } from "../../../types";
import { AvatarIcon, GetFileType, PostImage } from "../..";
import { useNavigate } from "react-router-dom";

interface QuotePostCardProps {
  quotePost: QuotePost;
  cite?: boolean;
}
interface FileWithLink {
  link: string;
}

export const QuotePostCard: React.FC<QuotePostCardProps> = ({
  quotePost,
  cite,
}) => {
  const navigate = useNavigate();

  const filesWithTypes = GetFileType(quotePost?.files as FileWithLink[]);
  const navigateToPost = () => {
    navigate(`/post/${quotePost?.id}`);
  };
  return (
    <div
      className={`mt-1 flex cursor-pointer flex-col gap-y-1 self-stretch rounded-lg bg-[rgba(245,235,255,0.05)] px-4 py-3 outline outline-1 outline-[rgba(255,255,255,0.40)] hover:bg-[rgba(245,235,255,0.10)]`}
      onClick={navigateToPost}
    >
      {!cite && <p className="label-s-600 pb-1">Répond à</p>}
      {cite && <p className="label-s-600 pb-1">Message cité</p>}
      <div className="flex items-center gap-x-1 self-stretch">
        <AvatarIcon logo={quotePost?.logo} className="avatar-xxs" />
        <p className="label-s-600">
          {quotePost?.pseudo || quotePost?.username}
        </p>
      </div>
      <p className="label-s-400">{quotePost?.content}</p>
      {quotePost?.files && <PostImage files={filesWithTypes} />}
    </div>
  );
};
