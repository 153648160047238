import { useEffect, useState } from "react";
import {
  DiscussionsWidget,
  ListDiscussionsWidget,
  NewMessageWidget,
  Loading,
} from "../..";
import { useAuth } from "../../../providers/userAuth";
import { User } from "../../../types";

export const ManageWidgetDiscussions = () => {
  const auth = useAuth();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  const [activeWidget, setActiveWidget] = useState<
    "list" | "discussion" | "newMessage"
  >("list");

  const handleDiscussionClick = () => {
    setActiveWidget("discussion");
  };

  const handleBackButtonClick = () => {
    setActiveWidget("list");
  };

  const handleNewMessageClick = () => {
    setActiveWidget("newMessage");
  };

  if (!user) {
    return null;
  }

  return (
    <div>
      {activeWidget === "list" && (
        <ListDiscussionsWidget
          onDiscussionClick={handleDiscussionClick}
          onNewMessageClick={handleNewMessageClick}
        />
      )}
      {activeWidget === "discussion" && (
        <DiscussionsWidget onBackButtonClick={handleBackButtonClick} />
      )}
      {activeWidget === "newMessage" && (
        <NewMessageWidget onBackButtonClick={handleBackButtonClick} />
      )}
    </div>
  );
};
