import {
  PostCard,
  GameDescription,
  GameInfoStudio,
  GameplayCard,
} from "../../components";
import { GamePageType, StudioGames, Post, Gameplay } from "../../types";

interface GameContentProps {
  data: GamePageType;
  platformsWithUrls?: Array<{ name: string; url: string; pseudo: string }>;
  studioLogo: string;
  studioName: string;
  studioDescription: string;
  studioWebsite: string;
  studioGames: StudioGames[];
}

export const GameContent: React.FC<GameContentProps> = ({
  data,
  platformsWithUrls,
  studioLogo,
  studioName,
  studioDescription,
  studioWebsite,
  studioGames = [],
}) => {
  const posts = data.posts || [];
  const gameplays = data.gameplays || [];
  console.log(posts, gameplays);
  console.log(data.gameplays);
  const mergedContent = [...posts, ...gameplays].sort((a, b) => {
    const dateA = "created_at" in a ? new Date(a.created_at).getTime() : 0;
    const dateB = "created_at" in b ? new Date(b.created_at).getTime() : 0;
    return dateB - dateA;
  });
  return (
    <div className="self- stretch flex justify-center gap-x-8 pt-4 max-tablet:flex-col max-tablet:items-center max-tablet:px-5 max-tablet:pb-[150px] min-851:items-start min-851:pl-8 min-851:pr-5">
      <div className="flex w-full max-w-[610px] flex-col items-center justify-center gap-y-4 min-851:max-w-[480px]">
        <div className="flex w-full items-center justify-between">
          <p className="h2-mobile">Activité de la communauté</p>
        </div>
        {mergedContent.map((item) =>
          "comment" in item ? (
            <GameplayCard key={item.id} gameplay={item as Gameplay} />
          ) : (
            <PostCard key={item.id} post={item as Post} />
          ),
        )}
      </div>
      <div className="flex w-full max-w-[450px] flex-col items-start gap-y-4 pb-10 max-tablet:hidden">
        <GameDescription
          description={data.description}
          platformsWithUrls={platformsWithUrls}
        />
        {data.studios[0]?.name && (
          <GameInfoStudio
            studioLogo={studioLogo}
            studioName={studioName}
            studioDescription={studioDescription}
            studioWebsite={studioWebsite}
            studioGames={studioGames}
          />
        )}
      </div>
    </div>
  );
};
