export const CommunityFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M5.25732 10.1484C5.26083 11.1874 5.39096 12.2222 5.64482 13.2297H9.69482V7.30469H5.58857C5.37252 8.2373 5.26142 9.19114 5.25732 10.1484Z"
      fill="white"
    />
    <path
      d="M10.7578 7.30469V13.2297H14.8078C15.0722 12.2235 15.2128 11.1887 15.2266 10.1484C15.2245 9.19137 15.1155 8.23753 14.9016 7.30469H10.7578Z"
      fill="white"
    />
    <path
      d="M10.2266 0.147461C8.24875 0.147461 6.31535 0.73395 4.67086 1.83276C3.02637 2.93158 1.74465 4.49336 0.987771 6.32063C0.230895 8.14789 0.032862 10.1586 0.418714 12.0984C0.804566 14.0382 1.75697 15.82 3.1555 17.2185C4.55402 18.6171 6.33585 19.5695 8.27566 19.9553C10.2155 20.3412 12.2261 20.1431 14.0534 19.3863C15.8807 18.6294 17.4424 17.3477 18.5413 15.7032C19.6401 14.0587 20.2266 12.1253 20.2266 10.1475C20.2266 7.49529 19.173 4.95176 17.2976 3.07639C15.4223 1.20103 12.8787 0.147461 10.2266 0.147461ZM17.8641 14.3412H15.6016C15.1738 15.6805 14.5413 16.9454 13.7266 18.0912C13.1253 18.3532 12.4963 18.5461 11.8516 18.6662C13.0062 17.4115 13.8949 15.9361 14.4641 14.3287H10.7641V18.7975C10.5828 18.7975 10.4078 18.7975 10.2266 18.7975C10.0453 18.7975 9.88282 18.7975 9.70782 18.7975V14.3412H5.98907C6.55745 15.9508 7.44617 17.4284 8.60157 18.685C7.9701 18.5662 7.35381 18.3775 6.76407 18.1225C5.94646 16.9671 5.31387 15.6914 4.88907 14.3412H2.58907C2.3894 13.977 2.21605 13.5989 2.07032 13.21H4.57032C4.33606 12.2058 4.21448 11.1786 4.20782 10.1475C4.20816 9.19166 4.30871 8.23854 4.50782 7.30371H1.95157C2.083 6.91589 2.24173 6.53786 2.42657 6.17246H4.80782C5.25462 4.68436 5.94754 3.28162 6.85782 2.02246C7.43505 1.78328 8.03646 1.60726 8.65157 1.49746C7.40823 2.83187 6.46989 4.42064 5.90157 6.15371H9.70782V1.39746H10.2266C10.2266 1.39746 10.5891 1.39746 10.7641 1.39746V6.17246H14.5703C14.003 4.44217 13.0669 2.85564 11.8266 1.52246C12.4407 1.62433 13.0421 1.79196 13.6203 2.02246C14.5253 3.27252 15.216 4.66444 15.6641 6.14121H18.0203C18.2051 6.50662 18.3639 6.88465 18.4953 7.27246H15.9578C16.1612 8.21728 16.2639 9.181 16.2641 10.1475C16.2609 11.1846 16.1414 12.2182 15.9078 13.2287H18.4078C18.2532 13.612 18.0715 13.9837 17.8641 14.3412Z"
      fill="white"
    />
  </svg>
);

export const CommunityStrokeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M10.9883 20.9063C16.5111 20.9063 20.9883 16.4291 20.9883 10.9063C20.9883 5.3834 16.5111 0.90625 10.9883 0.90625C5.46544 0.90625 0.988281 5.3834 0.988281 10.9063C0.988281 16.4291 5.46544 20.9063 10.9883 20.9063Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.988281 10.9062H20.9883"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8344 10.9063C14.8344 15.7161 13.2985 18.0652 10.9882 20.9063C8.67797 18.0652 7.14209 15.7161 7.14209 10.9063C7.14209 5.8606 8.67797 3.74725 10.9882 0.90625C13.2985 3.74725 14.8344 5.80732 14.8344 10.9063Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
