import { useMediaQuery } from "react-responsive";
import {
  DotsIconFrame,
  SubtitleNavigation,
  HeaderProfileGame,
  SecondaryButton,
  DownloadFrame,
  GamesIcons,
  BackButton,
  ButtonSimple,
} from "../../";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Game } from "../../../types";

interface HeaderGameProps {
  name: string;
  banner: string;
  playerCount: number;
  hasGame: boolean;
  studioLogo?: string;
  logo: string;
  studioName?: string;
  iosLink?: string | null;
  androidLink?: string | null;
  pcLink?: string;
  platform: string[];
  follow: boolean;
}

const formatName = (name: string) => {
  return name.replace(/\s+/g, "_");
};

export const HeaderGame: React.FC<HeaderGameProps> = ({
  name,
  banner,
  playerCount,
  hasGame,
  studioLogo,
  logo,
  studioName,
  iosLink,
  androidLink,
  pcLink,
  platform,
  follow,
}) => {
  const { gameId } = useParams();
  const isDesktop = useMediaQuery({ minWidth: 850 });
  const formattedName = formatName(name);
  const navigate = useNavigate();
  const convertGameNameToUrl = (gameName: string) => {
    return gameName.replace(/\s+/g, "").toLowerCase();
  };

  const navigationButtons = [
    { title: "Communauté", path: `/game/${gameId}/${formattedName}` },

    ...(isDesktop
      ? []
      : [{ title: "Infos", path: `/game/${gameId}/${formattedName}/info` }]),
  ];
  const handleGameClick = () => {
    const gameUrl = convertGameNameToUrl(name);
    navigate(`/connectgames/${gameUrl}`, { state: { gameId: gameId } });
  };

  return (
    <>
      <style>
        {`
          .bg-mobile {
            background: linear-gradient(0deg, #280C31 7.83%, rgba(8, 0, 16, 0.39) 52.26%, rgba(0, 0, 0, 0.00) 66.65%), url(${banner}) lightgray 50% / cover no-repeat;
          }
          .bg-desktop {
            background: linear-gradient(273deg, rgba(0, 0, 0, 0.00) 88.15%, rgba(0, 0, 0, 0.20) 95.09%), url(${banner}) lightgray 50% / cover no-repeat;
          }
        `}
      </style>
      <div
        className={`relative flex w-full flex-col items-center justify-center px-5 ${isDesktop ? "bg-desktop" : "bg-mobile"} max-tablet:pt-[190px] min-851:h-[120px]`}
      >
        {/* <div className="absolute right-5 top-[60px] min-851:hidden">
          <DotsIconFrame />
        </div> */}
        <div className="flex w-full max-w-[610px] flex-col items-start justify-end gap-y-6 max-tablet:max-w-[610px] min-851:hidden">
          <HeaderProfileGame
            name={name}
            playerCount={playerCount}
            studioLogo={studioLogo}
          />

          <div className="flex w-full flex-col items-start justify-center pb-2">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-x-2">
                {!hasGame &&
                  studioName !== "Nintendo" &&
                  name !== "Squad Busters" && (
                    <ButtonSimple
                      label="Ajouter le !"
                      plusFonction={true}
                      onClick={handleGameClick}
                      size="h-[33px]"
                    />
                  )}
                <SecondaryButton
                  gameId={gameId ?? "0"}
                  initialFollow={follow}
                  actionType="followGame"
                />
              </div>
              <DownloadFrame
                iosLink={iosLink}
                androidLink={androidLink}
                pcLink={pcLink}
              />
            </div>
            <SubtitleNavigation
              buttons={navigationButtons}
              padding="pt-6 min-745:hidden"
              overflowWidth="0%"
            />
          </div>
        </div>
        <div className="absolute left-[20px] top-[40px] max-tablet:hidden">
          <BackButton />
        </div>
      </div>
      <div className="flex w-full flex-col items-center px-5 max-tablet:hidden">
        <div className="relative top-[-25px] flex w-full gap-x-4 pt-3">
          <GamesIcons logo={logo} className="game-thumbnail-xl cursor-auto" />
          <div className="flex w-full flex-col justify-end">
            <div className="flex items-center gap-x-8">
              <p className="h1-mobile">{name}</p>
              <div className="flex items-center gap-x-1">
                <p className="paragraph-m-400 flex h-[29px] items-center">
                  {studioName}
                </p>
              </div>
            </div>
            <div className="flex h-[33px] w-full items-center justify-end justify-between">
              <p className="label-m-400">Jeu {platform.join(", ")}</p>
              <div className="flex gap-x-4">
                <div className="flex gap-x-2">
                  {!hasGame &&
                    studioName !== "Nintendo" &&
                    name !== "Squad Busters" && (
                      <ButtonSimple
                        label="Ajouter le !"
                        plusFonction={true}
                        onClick={handleGameClick}
                        size="h-[33px]"
                      />
                    )}
                  <SecondaryButton
                    gameId={gameId ?? "0"}
                    initialFollow={follow}
                    actionType="followGame"
                  />
                </div>
                <div className="flex gap-x-2">
                  <DownloadFrame
                    iosLink={iosLink}
                    androidLink={androidLink}
                    pcLink={pcLink}
                  />
                  {/* <DotsIconFrame /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
