import { useState } from "react";
import {
  AvatarIcon,
  BurgerMenuIcon,
  LogoBeta,
  NotificationIcon,
  SecondaryButton,
  SidebarMobile,
} from "../..";
import { useAuth } from "../../../providers/userAuth";
import { useNavigate } from "react-router-dom";

interface MainHeaderMobileProps {
  label?: string;
}

export const MainHeaderMobile: React.FC<MainHeaderMobileProps> = ({
  label,
}) => {
  const { user: authUser } = useAuth();
  const auth = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateNotifications = () => {
    navigate(`/notifications`);
  };

  const navigateToSignIn = () => {
    navigate(`/signin`);
  };

  return (
    <>
      <header
        className={` ${auth.user ? "" : "max-500:px-2"} fixed left-0 top-0 z-[700] mx-auto flex w-[100vw] justify-center bg-[#140029] px-5`}
      >
        <nav className="mb-0 mt-0 flex h-[58px] w-full items-center justify-between self-stretch border-b border-b-[rgba(168,81,255,0.15)]">
          <div className={` ${auth.user ? "" : "pr-[74px] max-500:pr-[38px]"}`}>
            <BurgerMenuIcon onClick={toggleMenu} />
          </div>
          {label ? <p className="h1-mobile">{label}</p> : <LogoBeta />}
          {!auth.user ? (
            <div className="flex items-center gap-x-2">
              <SecondaryButton
                label="Connexion"
                transparent={true}
                onClick={navigateToSignIn}
                size="max-500:w-[70px]"
                mobileFontSize={true}
              />
            </div>
          ) : (
            <div className="flex items-center gap-x-2">
              <AvatarIcon
                logo={authUser?.logo} // assuming the correct prop is 'logo'
                className="avatar-xs"
                onClick={() => navigate(`/user/${authUser?.username}`)}
              />
              {/* <NotificationIcon onClick={navigateNotifications} /> */}
            </div>
          )}
        </nav>
      </header>
      <SidebarMobile isOpen={isMenuOpen} onClose={toggleMenu} />
    </>
  );
};
