import { useEffect, useRef, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { useMediaQuery } from "react-responsive";
import {
  AvatarIcon,
  BackButton,
  BottomBar,
  CloseFillIcon,
  GameCoverColumn,
  InputSearch,
  SubtitleSearch,
} from "../../components";
import { User, UserSearchResult, GameSearchResult, Game } from "../../types";
import { useAuth } from "../../providers/userAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { searchUsers } from "../../helpers/fetchUser";
import LogoTP from "../../assets/logoTP.png";
import { fetchAllGames } from "../../helpers/fetchGame";

export const SearchPage: React.FC = () => {
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const inputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<{
    friendsAndFollows: User[];
    users: User[];
    usersByPseudos: UserSearchResult[];
    games: GameSearchResult[];
  }>({ friendsAndFollows: [], users: [], usersByPseudos: [], games: [] });

  const [lastSearches, setLastSearches] = useState<string[]>([]);
  const [activeFilter, setActiveFilter] = useState<string>("Tout");
  const [games, setGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    setUser(auth.user);
    if (location.state?.fromHeader) {
      inputRef.current?.focus();
    }
    const savedSearches = localStorage.getItem("lastSearches");
    if (savedSearches) {
      setLastSearches(JSON.parse(savedSearches));
    }

    const fetchGames = async () => {
      try {
        const gamesList = await fetchAllGames();
        setGames(gamesList);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    };

    fetchGames();
    handleSearch("");
  }, [auth, location.state]);

  const handleSearch = async (query: string, gameId?: string) => {
    try {
      const results = await searchUsers(query, gameId);
      setSearchResults(results);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce((query: string, gameId?: string) => {
      handleSearch(query, gameId);
    }, 400),
    [],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (!isDropdownOpen) {
      debouncedHandleSearch(value, selectedGame ? selectedGame.id : undefined);
    }
  };

  const addSearch = (searchTerm: string) => {
    const updatedSearches = [
      searchTerm,
      ...lastSearches.filter((term) => term !== searchTerm),
    ].slice(0, 4);
    setLastSearches(updatedSearches);
    localStorage.setItem("lastSearches", JSON.stringify(updatedSearches));
  };

  const removeSearch = (index: number) => {
    const updatedSearches = lastSearches.filter((_, i) => i !== index);
    setLastSearches(updatedSearches);
    localStorage.setItem("lastSearches", JSON.stringify(updatedSearches));
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const handleResultClick = (username: string) => {
    addSearch(username);
    navigate(`/user/${encodeUsername(username)}`);
  };

  const handleGameSelect = (game: Game | null) => {
    setSelectedGame(game);
    setSearchQuery("");
    setSearchResults({
      friendsAndFollows: [],
      users: [],
      usersByPseudos: [],
      games: [],
    });
    handleSearch("", game ? game.id : undefined);
  };

  const handlePreviousSearchClick = (search: string) => {
    setSearchQuery(search);
    handleSearch(search, selectedGame ? selectedGame.id : undefined);
  };

  const navigationButtons = [
    { title: "Tout" },
    { title: "Jeux" },
    { title: "Utilisateurs" },
  ];

  const filterResults = () => {
    if (selectedGame) {
      return {
        friendsAndFollows: [],
        users: [],
        usersByPseudos: searchResults.usersByPseudos,
        games: [],
      };
    }
    switch (activeFilter) {
      case "Jeux":
        return {
          ...searchResults,
          friendsAndFollows: [],
          users: [],
          usersByPseudos: [],
        };
      case "Utilisateurs":
        return {
          friendsAndFollows: searchResults.friendsAndFollows.slice(0, 5),
          users: searchResults.users.slice(0, 5),
          usersByPseudos: searchResults.usersByPseudos.slice(0, 5),
          games: [],
        };
      default:
        return {
          friendsAndFollows: searchResults.friendsAndFollows,
          users: searchResults.users.slice(0, 4),
          usersByPseudos: searchResults.usersByPseudos.slice(0, 4),
          games: searchResults.games.slice(0, isSmallScreen ? 2 : 4),
        };
    }
  };

  const filteredResults = filterResults();

  return (
    <div className="flex min-h-[100vh] w-full max-w-[610px] flex-col items-center gap-y-6 pt-5 max-mobile:pt-[70px]">
      <div className="flex w-full flex-col gap-y-6 px-5 pb-5 max-mobile:pb-20">
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex h-12 w-full items-center">
            <InputSearch
              ref={inputRef}
              showLogoSection={true}
              placeholder="Rechercher sur TalkPlay"
              onChange={handleInputChange}
              selectedGame={selectedGame}
              games={games}
              onGameSelect={handleGameSelect}
              onDropdownChange={setIsDropdownOpen}
              searchQuery={searchQuery}
            />
          </div>
          {searchQuery && <p className="h2-mobile">Résultats</p>}
          {!selectedGame && (
            <SubtitleSearch
              buttons={navigationButtons}
              onClick={setActiveFilter}
            />
          )}
        </div>
        {!searchQuery && (
          <div className="flex flex-col items-start gap-y-1 self-stretch">
            <p className="h2-mobile">Recherches précédentes</p>
            {lastSearches.map((search, index) => (
              <div
                key={index}
                className="flex cursor-pointer items-center justify-between self-stretch rounded-lg px-2 py-2 hover:bg-[rgba(245,235,255,0.20)]"
                onClick={() => handlePreviousSearchClick(search)}
              >
                <p className="paragraph-s-500 cursor-pointer">{search}</p>
                <button onClick={(e) => e.stopPropagation()}>
                  <CloseFillIcon
                    onClick={() => removeSearch(index)}
                    hoverEffect={true}
                  />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col gap-y-4 self-stretch">
          {selectedGame ? (
            <>
              {filteredResults.usersByPseudos.length > 0 && (
                <div>
                  <p className="h3-mobile">Joueurs</p>
                  <div className="flex w-full flex-col gap-y-1 pt-2">
                    {filteredResults.usersByPseudos.map((user) => (
                      <div
                        key={user.id}
                        className="flex w-full cursor-pointer items-center rounded-lg px-2 py-2 hover:bg-[rgba(245,235,255,0.20)]"
                        onClick={() => handleResultClick(user.username)}
                      >
                        <div className="flex cursor-pointer items-center gap-x-4">
                          <div className="relative">
                            <AvatarIcon className="avatar-s" logo={user.logo} />
                            <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[30px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                              <div
                                className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                                style={{
                                  background: `url(${user.gameLogo}) 50% / cover no-repeat`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between self-stretch">
                            <div className="flex flex-col gap-y-1">
                              <p className="h4-mobile cursor-pointer self-stretch">
                                {user.name}
                              </p>
                              <p className="label-s-400 cursor-pointer self-stretch">
                                @{user.username}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {filteredResults.games.length > 0 && (
                <div>
                  <p className="h3-mobile">Jeux</p>
                  <div className="flex flex-wrap gap-x-1">
                    {filteredResults.games.map((game) => (
                      <div key={game.id} className="flex items-center">
                        <GameCoverColumn
                          gameId={game.id}
                          name={game.name}
                          banner={game.card}
                          size="h-[202.5px] w-[135px] transition-transform transform hover:scale-105 hover:shadow-lg"
                          isClickable={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {(filteredResults.users.length > 0 ||
                filteredResults.friendsAndFollows.length > 0) && (
                <div>
                  <p className="h3-mobile">Utilisateurs TP</p>
                  <div className="flex flex-col gap-y-1 pt-2">
                    {filteredResults.friendsAndFollows.map((user) => (
                      <div
                        key={user.id}
                        className="flex cursor-pointer items-center gap-x-3 self-stretch rounded-lg px-2 py-2 hover:bg-[rgba(245,235,255,0.20)]"
                        onClick={() => handleResultClick(user.username)}
                      >
                        <div className="flex cursor-pointer items-center gap-x-4">
                          <div className="relative">
                            <AvatarIcon className="avatar-s" logo={user.logo} />
                            <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[30px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                              <div
                                className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                                style={{
                                  background: `url(${LogoTP}) 50% / cover no-repeat`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between self-stretch">
                            <div className="flex items-center">
                              <p className="h4-mobile cursor-pointer self-stretch">
                                {user.username}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {filteredResults.users.map((user) => (
                      <div
                        key={user.id}
                        className="flex w-full cursor-pointer items-center gap-x-3 rounded-lg px-2 py-2 hover:bg-[rgba(245,235,255,0.20)]"
                        onClick={() => handleResultClick(user.username)}
                      >
                        <div className="flex cursor-pointer items-center gap-x-4">
                          <div className="relative">
                            <AvatarIcon className="avatar-s" logo={user.logo} />
                            <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[30px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                              <div
                                className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                                style={{
                                  background: `url(${LogoTP}) 50% / cover no-repeat`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between self-stretch">
                            <div className="flex items-center">
                              <p className="h4-mobile cursor-pointer self-stretch">
                                {user.username}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {filteredResults.usersByPseudos.length > 0 && (
                <div>
                  <p className="h3-mobile">Joueurs</p>
                  <div className="flex flex-col gap-y-1 pt-2">
                    {filteredResults.usersByPseudos.map((user) => (
                      <div
                        key={user.id}
                        className="flex w-full cursor-pointer items-center rounded-lg px-2 py-2 hover:bg-[rgba(245,235,255,0.20)]"
                        onClick={() => handleResultClick(user.username)}
                      >
                        <div className="flex cursor-pointer items-center gap-x-4">
                          <div className="relative">
                            <AvatarIcon className="avatar-s" logo={user.logo} />
                            <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[30px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                              <div
                                className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                                style={{
                                  background: `url(${user.gameLogo}) 50% / cover no-repeat`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between self-stretch">
                            <div className="flex flex-col gap-y-1">
                              <p className="h4-mobile cursor-pointer self-stretch">
                                {user.name}
                              </p>
                              <p className="label-s-400 cursor-pointer self-stretch">
                                @{user.username}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <BottomBar />
    </div>
  );
};
