import { ButtonSimple } from "..";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface InviteFriendsProps {
  onClick?: () => void;
  classname?: string;
}

export const InviteFriends = ({ onClick, classname }: InviteFriendsProps) => {
  const handleCopyLink = () => {
    const invitationLink = "https://talkplay.fr/";
    navigator.clipboard
      .writeText(invitationLink)
      .then(() => {
        toast.success("Lien d'invitation copié dans le presse-papiers !");
      })
      .catch((err) => {
        toast.error("Échec de la copie du lien. Veuillez réessayer.");
      });

    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`flex max-w-[320px] flex-col items-center justify-center gap-y-4 rounded-xl bg-[rgba(151,46,182,0.80)] ${classname} p-4 shadow-[inset_1.5px_1px_2px_rgba(248,239,251,0.60),_0_2px_4px_rgba(0,0,0,0.25)]`}
    >
      <div className="flex flex-col items-start justify-center self-stretch ">
        <p className="nav-menu-700 self-stretch text-center">
          Invite tes amis à rejoindre TalkPlay !
        </p>
        {/* <p className="label-m-400 self-stretch text-center">
          15 personnes déjà invitées !
        </p> */}
      </div>
      <ButtonSimple
        label="Copier le lien d'invitation"
        plusFonction={false}
        onClick={handleCopyLink}
      />
      <ToastContainer />
    </div>
  );
};
