import { useNavigate } from "react-router-dom";
import { SelectionIndicator } from "..";

type GameCoverColumnProps = {
  banner: string;
  name?: string;
  onToggle?: () => void;
  isSelected?: boolean;
  inscriptionChooseGames?: boolean;
  size?: string;
  gameId?: string;
  timePlayed?: string;
  isClickable?: boolean;
};

export const GameCoverColumn: React.FC<GameCoverColumnProps> = ({
  name,
  banner,
  onToggle,
  isSelected,
  inscriptionChooseGames,
  size,
  gameId,
  timePlayed,
  isClickable = true,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isClickable) return;
    if (onToggle) {
      onToggle();
    } else {
      navigate(`/game/${gameId}/${name?.replace(/\s+/g, "_") ?? ""}`);
    }
  };

  return (
    <div
      className={`${isSelected && "border-2 border-red"} ${isClickable && "cursor-pointer"} ${onToggle && "cursor-pointer"} ${inscriptionChooseGames && "min-w-[130px] max-500:w-[48%] min-501:w-[32%] min-950:!w-[170px] min-745:w-[48%]"} relative flex h-[200px] ${size ? size : "min-w-[170px]"} items-end p-1`}
      onClick={handleClick}
      style={{
        background: `url(${banner}) lightgray 50% / cover no-repeat`,
      }}
    >
      {name && (
        <div className="flex items-center rounded-lg border-[0.5px] border-[rgba(198,182,213,0.25)] bg-[rgba(0,0,0,0.25)] px-2 py-1 backdrop-blur-[8px]">
          <p className="label-s-600">{name}</p>
        </div>
      )}
      {timePlayed && (
        <div className="flex w-full flex-col rounded border-[1px] border-[rgba(198,182,213,0.25)] bg-[rgba(20,0,41,0.70)] px-2 py-1 backdrop-blur-[2px]">
          <p className="label-s-400">Temps de jeu</p>
          <p className="label-m-700">{timePlayed}</p>
        </div>
      )}
      {isSelected && (
        <div className="absolute right-[10px] top-[10px]">
          <SelectionIndicator isSelected={isSelected} />
        </div>
      )}
    </div>
  );
};
