import { useNavigate } from "react-router-dom";
import { BackButton, LogoBeta } from "../../components";

export const TermsOfService: React.FC = () => {
  const navigate = useNavigate();

  const navigateGuidelines = () => {
    navigate(`/legal/guidelines`);
  };

  const navigatePrivacy = () => {
    navigate(`/legal/privacy`);
  };

  return (
    <div className="flex max-h-[100svh] w-full flex-col gap-y-5 overflow-y-scroll bg-[#140029] px-5 pb-20 pt-10 min-745:px-10">
      <BackButton />
      <div className="relative flex w-full justify-center">
        <LogoBeta />
      </div>
      <h1 className="h1-mobile flex w-full justify-center">
        Termes du Service
      </h1>
      <p className="paragraph-m-400">Dernière mise à jour : 1er juin 2024</p>

      <p className="paragraph-m-400">
        Nous sommes ravis de t’accueillir sur Talkplay, le réseau social conçu
        pour les gamers passionnés. Notre objectif est de te permettre d'avoir
        un compte complet gamer, de te connecter avec d'autres joueurs et de
        suivre tes jeux préférés.
      </p>
      <p className="paragraph-m-400">
        Afin de garantir une utilisation agréable et sécurisée de notre
        plateforme, nous avons établi ces Termes du service (« Termes »),
        accompagnés de notre{" "}
        <span
          className="paragraph-m-700 cursor-pointer underline"
          onClick={navigatePrivacy}
        >
          Politique de confidentialité
        </span>{" "}
        et de nos{" "}
        <span
          className="paragraph-m-700 cursor-pointer underline"
          onClick={navigateGuidelines}
        >
          Normes Communautaires
        </span>{" "}
        (ensemble, « l’Accord utilisateur »). Cet Accord utilisateur précise les
        règles que tu dois suivre et les attentes que nous avons pour maintenir
        une communauté respectueuse et amusante.
      </p>
      <p className="paragraph-m-400">
        Si tu as des questions ou des préoccupations concernant cet Accord
        utilisateur, n'hésite pas à nous contacter. Notre objectif est de
        garantir que tu comprends parfaitement comment Talkplay fonctionne et ce
        que cela implique pour toi.
      </p>
      <p className="paragraph-m-400">
        En utilisant nos Services, tu acceptes d'être lié par cet Accord
        utilisateur. Si tu n'es pas d'accord avec l’un de ces termes, nous te
        prions de ne pas utiliser nos Services.
      </p>

      <h3 className="h3-mobile">1. Qui peut utiliser nos Services</h3>
      <p className="paragraph-m-400">
        Nos Services incluent toutes les fonctionnalités du site web Talkplay,
        ainsi que les logiciels et systèmes qui assurent leur fonctionnement.
      </p>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Âge minimum :</span> Avoir au moins
          13 ans. Si tu n’as pas atteint l’âge du consentement dans ton pays, un
          parent ou un tuteur légal doit donner son accord pour que tu puisses
          utiliser nos Services.
        </li>
        <li>
          <span className="paragraph-m-700">Autorité légale :</span> Si tu crées
          un compte pour une autre personne ou entité (entreprise, organisation,
          gouvernement), tu dois avoir l’autorité légale de le faire.
        </li>
      </ul>

      <h3 className="h3-mobile">2. Inscription et Compte Utilisateur</h3>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Informations nécessaires :</span>{" "}
          Pour créer un compte, tu dois fournir une adresse e-mail valide, un
          pseudo et un mot de passe.
        </li>
        <li>
          <span className="paragraph-m-700">
            Responsabilité de l'utilisateur :
          </span>{" "}
          Tu es responsable de la confidentialité de ton mot de passe et des
          activités effectuées sur ton compte. Ne partage pas tes informations
          de connexion.
        </li>
        <li>
          <span className="paragraph-m-700">Restrictions :</span> Tu ne peux pas
          créer plusieurs comptes ou usurper l'identité d'une autre personne (le
          pseudonyme en fait partie).
        </li>
      </ul>

      <h3 className="h3-mobile">
        3. Protection des données et Confidentialité
      </h3>
      <p className="paragraph-m-400">
        Notre Politique de confidentialité décrit les informations que tu
        partages avec nous, le moment où elles sont partagées et la manière dont
        nous les traitons lorsque tu utilises nos Services. En utilisant nos
        Services, tu comprends et acceptes que nous devons collecter, utiliser,
        stocker, traiter, partager et transférer ces informations dans les pays
        où nous opérons et avec nos partenaires qui nous aident à fournir nos
        Services.
      </p>

      <h3 className="h3-mobile">4. Connexion des Applications Tierces</h3>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Autorisation :</span> Tu peux
          connecter des applications tierces à Talkplay, telles que Steam ou
          Clash of Clans.
        </li>
        <li>
          <span className="paragraph-m-700">Données collectées :</span> Nous
          collectons des informations de ces applications pour enrichir ton
          expérience sur Talkplay. Cela inclut, mais sans s'y limiter, les
          informations de jeu et les statistiques.
        </li>
        <li>
          <span className="paragraph-m-700">Responsabilité :</span> Tu es
          responsable des informations fournies par ces applications tierces.
        </li>
      </ul>

      <h3 className="h3-mobile">5. Contenu Utilisateur</h3>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Publication de contenu :</span> Tu
          peux envoyer des messages, créer des posts, publier des vidéos et
          fournir des descriptions sur Talkplay.
        </li>
        <li>
          <span className="paragraph-m-700">Droits et responsabilités :</span>{" "}
          Tu possèdes les droits sur le contenu que tu publies, mais en le
          partageant sur Talkplay, tu nous accordes une licence mondiale, non
          exclusive et gratuite pour utiliser, modifier et afficher ce contenu.
        </li>
        <li>
          <span className="paragraph-m-700">Restrictions :</span> Tu ne dois pas
          publier de contenu illégal, offensant, diffamatoire ou qui viole les
          droits d'autrui (voir la section Comportement inapproprié).
        </li>
      </ul>

      <h3 className="h3-mobile">6. Comportement inapproprié</h3>
      <p className="paragraph-m-400">
        Nous ne tolérons pas le harcèlement, les propos politisés,
        l’intimidation, la diffamation, les menaces, le contenu haineux,
        l’exploitation sexuelle, les abus sexuels, la promotion du suicide ou de
        l’automutilation, le vol de propriété intellectuelle ou toute autre
        activité illégale. Pour de plus amples informations, consulte nos{" "}
        <span
          className="paragraph-m-700 cursor-pointer underline"
          onClick={navigateGuidelines}
        >
          Normes Communautaires
        </span>
        , qui font partie de l’Accord utilisateur.
      </p>

      <h3 className="h3-mobile">7. Signaler du contenu non conforme</h3>
      <p className="paragraph-m-400">
        Nous ne sommes pas tenus de vérifier le contenu que les utilisateurs
        partagent par l’intermédiaire de nos Services. Cependant, nous nous
        efforçons de maintenir un espace sécurisé. Si tu constates du contenu
        non conforme, tu peux nous le signaler via les outils de signalement de
        la plateforme.
      </p>

      <h3 className="h3-mobile">8. Suspension et résiliation des comptes</h3>
      <p className="paragraph-m-400">
        Nous pouvons suspendre temporairement ou définitivement ton compte et
        t’empêcher d’utiliser nos services si tu partages du contenu non
        conforme ou que tu utilises nos Services d’une manière qui enfreint les
        droits d’autrui ou qui ne respecte pas les lois, règles, règlements ou
        l’Accord utilisateur.
      </p>

      <h3 className="h3-mobile">
        9. Autorisation d’utilisation de nos Services
      </h3>
      <p className="paragraph-m-400">
        Nos Services sont protégés par des droits d’auteur, des marques
        déposées, des secrets commerciaux et d’autres lois. Aucune clause de
        l’Accord utilisateur ne te donne le droit d’utiliser le nom Talkplay ou
        toute autre marque déposée, logo, nom de domaine ou autre
        caractéristique distinctive de la marque.
      </p>

      <h3 className="h3-mobile">10. Limitation de responsabilité</h3>
      <p className="paragraph-m-400">
        En utilisant nos Services, tu acceptes que la responsabilité de
        Talkplay, de ses sociétés affiliées, de ses agents, de ses dirigeants,
        de ses employés et de ses représentants soit limitée dans toute la
        mesure permise par la loi de ton pays de résidence.
      </p>

      <h3 className="h3-mobile">
        11. Droit applicable et juridiction compétente
      </h3>
      <p className="paragraph-m-400">
        Dans la mesure où ces Conditions te permettent, à toi et à Talkplay,
        d’intenter une action en justice devant un tribunal, toi et Talkplay
        acceptez la compétence exclusive des tribunaux de Paris et du droit
        français.
      </p>

      <h3 className="h3-mobile">12. Général</h3>
      <p className="paragraph-m-400">
        Talkplay peut mettre à jour l’Accord utilisateur à tout moment. Si la
        mise à jour a une incidence sur les droits et les obligations des
        parties, nous t’en informerons avant que la mise à jour ne soit
        effective.
      </p>

      <h3 className="h3-mobile">13. Contact ou questions</h3>
      <p className="paragraph-m-400">
        Ton avis nous permet de nous améliorer. Si tu as des questions
        concernant une quelconque partie de l’Accord utilisateur, des
        commentaires ou des retours sur nos Services, nous souhaitons connaître
        ton avis. Pour cela, contacte-nous sur notre{" "}
        <span className="paragraph-m-700 cursor-pointer underline">
          serveur Discord
        </span>
        .
      </p>
      <h3 className="h3-mobile">14. Contact</h3>
      <p className="paragraph-m-400">
        TalkPlay SAS, sise au 14 rue Lénine 91170 Viry-Châtillon, FRANCE,
        enregistré sous le numéro 980 156 681 au Registre du commerce et des
        sociétés d'Évry.
      </p>
    </div>
  );
};
