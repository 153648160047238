import React, { useEffect, useRef, useState } from "react";
import { CloseFillIcon } from "../";

type GifOverlayProps = {
  imageUrl: string;
  altText: string;
  onClose: () => void;
};

export const GifOverlay: React.FC<GifOverlayProps> = ({
  imageUrl,
  altText,
  onClose,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isPortrait, setIsPortrait] = useState(true);

  const closeModal = () => {
    setIsVisible(false); // Ensure visibility state is managed properly
    onClose();
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const modalContent = document.getElementById("gif-overlay-content");
      if (modalContent && !e.composedPath().includes(modalContent)) {
        closeModal();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isVisible]);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsPortrait(img.height > img.width);
    };
  }, [imageUrl]);

  return isVisible ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-2">
      <div
        id="gif-overlay-content"
        ref={overlayRef}
        className="flex w-[60sw] flex-col items-center justify-center gap-y-5 rounded-3xl border-2 border-transparent p-4 shadow-[0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
        style={{
          background:
            "linear-gradient(293deg, #402C48 0.12%, #9552A9 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #402C48 40%) border-box",
        }}
      >
        <div className="flex items-center justify-between self-stretch">
          <div className="flex-1" />
          <button
            className="cursor-pointer"
            onClick={closeModal}
            aria-label="Close"
          >
            <CloseFillIcon size={12} />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-4">
          <img
            src={imageUrl}
            alt={altText}
            className={isPortrait ? "max-h-[500px]" : "max-h-[300px]"}
          />
        </div>
      </div>
    </div>
  ) : null;
};
