import { useEffect, useState } from "react";
import { Post, User } from "../../../types";
import { useAuth } from "../../../providers/userAuth";
import { useNavigate } from "react-router-dom";
import {
  DotsIconPost,
  RepostIcon,
  CommentIcon,
  LikeIcon,
  ShareIcon,
  AvatarIcon,
  PostImage,
  UnVerifiedIconFrame,
  GetFileType,
  LinkIcon,
  QuoteIcon,
} from "../..";
import {
  unLikePost,
  likePost,
  repostPost,
  unRepostPost,
} from "../../../helpers/fetchPost";
import React from "react";

interface FileWithLink {
  link: string;
}

interface CommentCardProps {
  post: Post;
}

export const CommentCard: React.FC<CommentCardProps> = ({ post }) => {
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(post.liked);
  const [isReposted, setIsReposted] = useState(post.reposted);
  const [likeCount, setLikeCount] = useState(post.likeCount);
  const [repostCount, setRepostCount] = useState(post.repostCount);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showShareOverlay, setShowShareOverlay] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const filesWithTypes = GetFileType(post.files as FileWithLink[]);
  const overlayRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        setShowOverlay(false);
        setShowShareOverlay(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef]);

  const handleLike = async () => {
    try {
      if (isLiked) {
        await unLikePost(post.id);
        setIsLiked(false);
        setLikeCount((prev) => Math.max(prev - 1, 0));
      } else {
        await likePost(post.id);
        setIsLiked(true);
        setLikeCount((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const handleRepost = async () => {
    try {
      if (isReposted) {
        await unRepostPost(post.id);
        setIsReposted(false);
        setRepostCount((prev) => Math.max(prev - 1, 0));
      } else {
        await repostPost(post.id);
        setIsReposted(true);
        setRepostCount((prev) => prev + 1);
      }
      setShowOverlay(false);
    } catch (error) {
      console.error("Error toggling repost:", error);
    }
  };

  const handleCopyLink = () => {
    const link = `${window.location.origin}/post/${post.id}`;
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000);
    });
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigatePlayerPage = () => {
    navigate(`/user/${encodeUsername(post.user.username)}`);
  };

  const navigateGamePage = () => {
    navigate(`/game/${post.game.id}/${post.game.name}`);
  };

  const showUserNameOrPseudo = post.game ? post.user.pseudo === null : false;

  return (
    <div className="flex w-full max-w-[600px] flex-col gap-2 border-b border-b-[rgba(245,235,255,0.10)] pb-3 pt-2">
      <div className="flex items-center justify-start gap-x-3 self-stretch">
        <div className="relative cursor-pointer" onClick={navigatePlayerPage}>
          <AvatarIcon logo={post.user.logo} className="avatar-xs" />
        </div>
        <div className="flex w-full flex-col items-start self-stretch">
          <div className="flex items-center justify-between self-stretch">
            <div className="flex items-center gap-x-2">
              {post.game ? (
                showUserNameOrPseudo ? (
                  <p
                    className="h4-mobile cursor-pointer self-stretch"
                    onClick={navigatePlayerPage}
                  >
                    <span className="paragraph-s-400">@</span>
                    {post.user.username}
                  </p>
                ) : (
                  <p
                    className="h4-mobile cursor-pointer self-stretch"
                    onClick={navigatePlayerPage}
                  >
                    {post.user.pseudo}
                  </p>
                )
              ) : (
                <p
                  className="h4-mobile cursor-pointer self-stretch"
                  onClick={navigatePlayerPage}
                >
                  {post.user.username}
                </p>
              )}
              {post.game &&
                post.user.isPseudoVerified == false &&
                post.user.pseudo && <UnVerifiedIconFrame />}
            </div>
            {/* <div className="flex w-[30px] cursor-pointer flex-col items-center justify-center px-1.5 py-1 opacity-50">
              <DotsIconPost />
            </div> */}
          </div>
          <div className="flex self-stretch">
            {post.game ? (
              <p
                className="label-s-400 self-stretch opacity-50"
                onClick={navigateGamePage}
              >
                {post.game.name}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <p className="paragraph-s-500 self-stretch text-sm">{post.content}</p>
      {post.files && <PostImage files={filesWithTypes} />}
      <div className="flex items-center justify-between self-stretch">
        {post.game ? (
          post.user.pseudo === null ? (
            ""
          ) : (
            <p
              className="label-s-400 cursor-pointer opacity-40"
              onClick={navigatePlayerPage}
            >
              @{post.user.username}
            </p>
          )
        ) : (
          <p
            className="label-s-400 cursor-pointer opacity-40"
            onClick={navigatePlayerPage}
          >
            @{post.user.username}
          </p>
        )}
        <p className="label-s-400 opacity-40">
          {new Date(post.created_at).toLocaleString()}
        </p>
      </div>

      <div className="flex items-center justify-between self-stretch pt-4 opacity-90">
        <div className="flex cursor-pointer items-center gap-2">
          <CommentIcon />
          <span className="label-s-400 text-xs">{post.childPostCount}</span>
        </div>
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={(e) => {
            e.stopPropagation();
            handleLike();
          }}
        >
          <LikeIcon onClick={handleLike} liked={isLiked} />
          <span
            className={`label-s-400 cursor-pointer text-xs ${
              isLiked ? "text-[rgba(249,24,128)]" : ""
            }`}
          >
            {likeCount}
          </span>
        </div>
        <div
          className="relative flex cursor-pointer items-center gap-2"
          onClick={(e) => {
            e.stopPropagation();
            setShowOverlay(!showOverlay);
          }}
        >
          <RepostIcon reposted={isReposted} />
          <span
            className={`label-s-400 cursor-pointer text-xs ${
              isReposted ? "text-[rgba(0,186,124)]" : ""
            }`}
          >
            {repostCount}
          </span>
          {showOverlay && (
            <div
              ref={overlayRef}
              className="absolute right-0 top-[-90px] z-[100] flex w-[200px] flex-col items-start gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-3 backdrop-blur-[6.5px]"
              style={{
                background:
                  "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="flex cursor-pointer items-center gap-x-[10px]"
                onClick={handleRepost}
              >
                <RepostIcon />
                <p className="nav-menu-500">
                  {isReposted ? "Annuler la republication" : "Republier"}
                </p>
              </div>
              <div
                className="flex cursor-pointer items-center gap-x-[10px]"
                onClick={() =>
                  navigate("/createpost", { state: { quotePost: post } })
                }
              >
                <QuoteIcon />
                <p className="nav-menu-500">Citer</p>
              </div>
            </div>
          )}
        </div>
        <div
          className="relative flex cursor-pointer items-center"
          onClick={(e) => {
            e.stopPropagation();
            setShowShareOverlay(!showShareOverlay);
          }}
        >
          <ShareIcon />
          {showShareOverlay && (
            <div
              ref={overlayRef}
              className="absolute right-0 top-[-50px] z-[100] flex w-[200px] flex-col items-start gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-3 backdrop-blur-[6.5px]"
              style={{
                background:
                  "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="flex cursor-pointer items-center gap-x-[10px]"
                onClick={handleCopyLink}
              >
                <LinkIcon />
                <p className="nav-menu-500">
                  {linkCopied ? "Lien copié" : "Copier le lien"}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
