interface FormatTimeDifferenceProps {
  date: Date;
}

export const FormatTimeDifference: React.FC<FormatTimeDifferenceProps> = ({
  date,
}) => {
  const timeDifference = Math.floor(
    (new Date().getTime() - date.getTime()) / 1000,
  );

  if (timeDifference < 60) {
    return <span>il y a quelques secondes</span>;
  }

  const minutes = Math.floor(timeDifference / 60);
  if (minutes < 60) {
    return (
      <span>
        {minutes === 1
          ? "Dernier il y a 1 minute"
          : `Dernier il y a ${minutes} minutes`}
      </span>
    );
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return (
      <span>
        {hours === 1
          ? "Dernier il y a 1 heure"
          : `Dernier il y a ${hours} heures`}
      </span>
    );
  }

  const days = Math.floor(hours / 24);
  if (days < 7) {
    return (
      <span>
        {days === 1 ? "Dernier il y a 1 jour" : `Dernier il y a ${days} jours`}
      </span>
    );
  }

  const weeks = Math.floor(days / 7);
  if (weeks < 4) {
    return (
      <span>
        {weeks === 1
          ? "Dernier il y a 1 semaine"
          : `Dernier il y a ${weeks} semaines`}
      </span>
    );
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return (
      <span>
        {months === 1
          ? "Dernier il y a 1 mois"
          : `Dernier il y a ${months} mois`}
      </span>
    );
  }

  const years = Math.floor(days / 365);
  return (
    <span>
      {years === 1 ? "Dernier il y a 1 an" : `Dernier il y a ${years} ans`}
    </span>
  );
};
