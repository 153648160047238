import {
  HeaderGroupDiscussion,
  InputSearch,
  ParticipantGroup,
  Loading,
} from "../../../components";
import { Key, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import DefaultAvatar from "../../../assets/DefaultAvatar.png";

export const GroupParticipant = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const optionsRef = useRef(null);
  const location = useLocation();
  const isWideScreen = useMediaQuery({ minWidth: 745 });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !(optionsRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const participants = location.state?.participants || [];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="h1-mobile">{error}</div>;
  }

  return (
    <div
      className={`z-20 flex w-full flex-col max-mobile:min-h-[100svh] ${isWideScreen ? "" : "absolute inset-0 min-h-screen"}`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <HeaderGroupDiscussion
        groupName={location.state?.groupName || "Nom du groupe"}
        banner={location.state?.banner}
        showParticipants={false}
        showImagesIcon={false}
      />
      <div className="flex w-full flex-col gap-y-6 px-5 pt-6">
        <InputSearch
          showLogoSection={false}
          placeholder="Rechercher un participant"
        />
        <div className="styled-scrollbars flex h-[calc(100vh-350px)] w-full flex-col items-start gap-y-3 overflow-auto pr-2">
          {participants.map(
            (
              participant: { username: string; logo: string },
              index: Key | null | undefined,
            ) => (
              <div
                key={index}
                className="flex w-full flex-col items-start border-b-[0.8px] border-b-[rgba(248,239,251,0.27)]"
              >
                <ParticipantGroup
                  name={participant.username}
                  image={participant.logo || DefaultAvatar}
                  showOptions={true}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
