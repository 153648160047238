import {
  BackButton,
  SimpleInput,
  ButtonSimple,
  ChevronIcon,
} from "../../../../components";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/userAuth";
import { User } from "../../../../types";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const EmailChange = () => {
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const username = authUser?.username;
  const [userData, setUserData] = useState<User>();
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [isCurrentEmailValid, setIsCurrentEmailValid] = useState<
    boolean | null
  >(null);
  const [isNewEmailValid, setIsNewEmailValid] = useState<boolean | null>(null);

  useEffect(() => {
    setUserData(authUser);
    if (authUser?.email) {
      setCurrentEmail(authUser.email);
      setIsCurrentEmailValid(true);
    }
  }, [authUser]);

  const validateEmail = (email: string) => {
    return emailRegex.test(email);
  };

  const handleCurrentEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setCurrentEmail(email);
    setIsCurrentEmailValid(validateEmail(email));
  };

  const handleNewEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setNewEmail(email);
    setIsNewEmailValid(validateEmail(email));
  };

  const handleSave = () => {
    if (isCurrentEmailValid && isNewEmailValid) {
      // Logic to save the new email
      console.log("Email updated successfully");
    } else {
      console.log("Invalid email format");
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`flex min-h-[calc(100vh-58px)] w-full flex-col gap-y-6 border-l border-l-[rgba(198,182,213,0.25)] px-5 pb-[60px] pt-10 max-mobile:pt-[40px] ${isWideScreen ? "" : "absolute inset-0 min-h-screen"}`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="min-745:hidden">
        <BackButton />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex items-center gap-x-10 self-stretch">
          <ChevronIcon
            orientation="left"
            onClick={navigateBack}
            className="cursor-pointer max-mobile:hidden"
          />
          <p className="h1-mobile">Modifier votre mail</p>
        </div>
        <div className="flex flex-col gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Mail actuel</p>
            <SimpleInput
              placeholder="Ton mail actuel"
              iconType="check"
              value={currentEmail}
              onChange={handleCurrentEmailChange}
              isCorrect={isCurrentEmailValid}
              name="currentEmail"
            />
          </div>
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Nouveau mail</p>
            <SimpleInput
              placeholder="Ton nouveau mail"
              iconType="check"
              value={newEmail}
              onChange={handleNewEmailChange}
              isCorrect={isNewEmailValid}
              name="newEmail"
            />
          </div>
          <div className="flex items-end self-stretch">
            <ButtonSimple
              label="Enregistrer"
              onClick={handleSave}
              showChevron={true}
              chevronDirection="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
