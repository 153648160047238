import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  FriendCard,
  InputSearch,
  InviteFriends,
  Loading,
} from "../../components";
import { fetchFriends } from "../../helpers/fetchUser";
import { useAuth } from "../../providers/userAuth";
import { Friend as FriendType } from "../../types";

export const FriendsList = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [friendsData, setFriendsData] = useState<FriendType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const hasFetchedFriends = useRef(false);

  useEffect(() => {
    const getFriends = async () => {
      if (user?.username && !hasFetchedFriends.current) {
        try {
          const friends = await fetchFriends(user.username)();
          setFriendsData(friends);
          hasFetchedFriends.current = true;
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      } else if (!user?.username) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    const delayFetchFriends = setTimeout(() => {
      getFriends();
    }, 300);

    return () => clearTimeout(delayFetchFriends);
  }, [user]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error loading friends.</p>;
  }

  const requestFriendsCount = friendsData.length;

  return (
    <div className="relative flex min-h-[100svh] w-full max-w-[610px] flex-col gap-y-6 px-5 pt-10">
      <div className="absolute left-5 top-[50px] cursor-pointer">
        <BackButton />
      </div>

      <p className="h1-mobile self-stretch text-center">
        Amis ({requestFriendsCount})
      </p>
      <InputSearch placeholder="Rechercher un ami" showLogoSection={false} />
      <div className="flex flex-col items-start gap-y-2 self-stretch">
        {isLoading && <Loading />}
        {isError && <div className="h1-mobile">{isError}</div>}
        {friendsData.length > 0 ? (
          friendsData.map((friend) => (
            <FriendCard
              key={friend.id}
              logo={friend.logo}
              username={friend.username}
              userId={friend.id}
              friendDemand={false}
              isClickable={true}
            />
          ))
        ) : (
          <div className="flex flex-col items-center gap-y-4">
            <p className="paragraph-m-400 text-center">
              Vous n'avez aucun ami pour le moment. Invitez des amis pour
              commencer à discuter!
            </p>
            <InviteFriends />
          </div>
        )}
      </div>
    </div>
  );
};
