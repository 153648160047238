import React, {
  createContext,
  useRef,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";

// Définition de l'interface pour le contexte
interface SidebarWidthContextProps {
  sidebarWidth: number;
  sidebarRef: React.MutableRefObject<HTMLDivElement | null>;
}

// Initialisation du contexte avec des valeurs par défaut
const defaultValues: SidebarWidthContextProps = {
  sidebarWidth: 0,
  sidebarRef: { current: null },
};
const SidebarWidthContext =
  createContext<SidebarWidthContextProps>(defaultValues);

// Provider
interface SidebarWidthProviderProps {
  children: ReactNode;
}

export const SidebarWidthProvider = ({
  children,
}: SidebarWidthProviderProps) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [sidebarWidth, setSidebarWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (sidebarRef.current) {
        setSidebarWidth(sidebarRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SidebarWidthContext.Provider value={{ sidebarWidth, sidebarRef }}>
      {children}
    </SidebarWidthContext.Provider>
  );
};

export const useSidebarWidth = () => useContext(SidebarWidthContext);
