import { AvatarIcon, LastMessageDate, PastilleDiscussion } from "..";

type PlayerDiscussionCardProps = {
  id: string;
  logo: string;
  username: string;
  messageCount: number;
  firstWord: string;
  lastMessageDate: string;
  isActive?: boolean;
  onClick?: () => void;
  noBorderBottom?: boolean;
};

export const PlayerDiscussionCard: React.FC<PlayerDiscussionCardProps> = ({
  id,
  logo,
  username,
  messageCount,
  firstWord,
  lastMessageDate,
  isActive = false,
  onClick,
  noBorderBottom = false,
}) => {
  return (
    <div
      className={`flex w-full cursor-pointer items-center justify-between gap-x-3 px-3 py-2 ${
        !noBorderBottom
          ? isActive
            ? "rounded-lg bg-[rgba(245,235,255,0.15)] outline outline-[1px] outline-primary-500"
            : ""
          : ""
      } py-2 hover:rounded-lg hover:bg-[rgba(245,235,255,0.15)]`}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-3">
        <AvatarIcon logo={logo} className="avatar-m text-center" />
        <div className="flex flex-col gap-y-0.5">
          <p className="h4-mobile">{username}</p>
          <p className="paragraph-s-500 opacity-60">{firstWord}</p>
        </div>
      </div>
      <div className="flex w-[35px] flex-col items-center gap-y-[10px] self-stretch pt-1">
        {!isActive && messageCount > 0 ? (
          <PastilleDiscussion messageCount={messageCount} />
        ) : null}
        <p className="paragraph-s-500 whitespace-nowrap text-right opacity-60">
          <LastMessageDate timestamp={lastMessageDate} />
        </p>
      </div>
    </div>
  );
};
