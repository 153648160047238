import { useNavigate } from "react-router-dom";

type GamesIconsProps = {
  logo: string;
  className?: string;
  gameId?: string;
  name?: string;
  isClickable?: boolean;
};

export const GamesIcons: React.FC<GamesIconsProps> = ({
  logo,
  className,
  gameId,
  name,
  isClickable,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (isClickable) {
      navigate(`/game/${gameId}/${name?.replace(/\s+/g, "_") ?? ""}`);
    }
  };

  return (
    <div
      className={`relative mb-1 mr-1 cursor-pointer overflow-hidden ${className}`}
      onClick={handleCardClick}
    >
      <div
        className={`z-1 absolute left-0 top-0 box-border h-full w-full overflow-hidden bg-cover bg-center`}
        style={{
          backgroundImage: `url(${logo})`,
        }}
      ></div>
      <div className="pt-[100%]"></div>
    </div>
  );
};

export const SupercellIDIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="29" fill="none">
    <path
      d="M24.843 29H7.13C3.21 29 0 25.573 0 21.384V7.616C0 3.427 3.208 0 7.13 0h17.74C28.793 0 32 3.427 32 7.616v13.768C31.973 25.573 28.764 29 24.843 29z"
      fill="#fff"
    />
    <path
      d="M9.234 7.675h2.692v13.767H9.234V7.675zm14.582 3.603v6.561c0 .616-.056 1.026-.45 1.612-.336.468-1.009 1.2-1.457 1.523-.561.41-.954.468-1.543.468h-6.59V7.675h6.59c.59 0 .982.058 1.543.468.448.352 1.15 1.055 1.458 1.524.392.586.449.996.449 1.61zm-2.664-.059c0-.029-.14-.205-.365-.468-.252-.264-.42-.381-.449-.381h-3.87v8.407h3.87c.028 0 .197-.147.45-.381.252-.264.364-.44.364-.469V11.22z"
      fill="#000"
    />
  </svg>
);
