import { BackButton, ButtonSimple } from "../../../../components";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/userAuth";
import { User } from "../../../../types";
import { useMediaQuery } from "react-responsive";
import axios, { AxiosResponse } from "axios";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { updateUserUrl } from "../../../../constants/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import find from "magic-bytes.js";

export const EditAccount = () => {
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const { user: authUser } = useAuth();
  const [userData, setUserData] = useState<User>();
  const [formData, setFormData] = useState({
    about: "",
    logo: "",
    banner: "",
  });
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [charactersLeft, setCharactersLeft] = useState(255);

  useEffect(() => {
    const storedAbout = localStorage.getItem("about");
    if (authUser) {
      setFormData({
        about: storedAbout || authUser.about || "",
        logo: authUser.logo || "",
        banner: authUser.banner || "",
      });
      setUserData(authUser);
      setLoading(false);
      setCharactersLeft(
        255 - (storedAbout?.length || authUser.about?.length || 0),
      );
    }
  }, [authUser]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "about") {
      setCharactersLeft(255 - value.length);
    }
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "logo" | "banner",
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 25 * 1024 * 1024) {
        toast.error("Chaque fichier doit être inférieur à 25 Mo", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "!z-[1000]",
        });
        return;
      }

      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const result = find(uint8Array);
      const mimeType = result.length > 0 ? result[0].mime : null;

      if (type === "banner" && mimeType === "image/gif") {
        toast.error("Les fichiers GIF ne sont pas autorisés pour la bannière", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "!z-[1000]",
        });
        return;
      }

      const url = URL.createObjectURL(file);
      setFormData({ ...formData, [type]: url });
      if (type === "logo") {
        setLogoFile(file);
      } else {
        setBannerFile(file);
      }
    }
  };

  const handleSubmit = async () => {
    if (charactersLeft < 0) {
      toast.error("La description doit faire moins de 255 caractères", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "!z-[1000]",
      });
      return;
    }

    const authToken = localStorage.getItem("token");
    if (!authToken) {
      setError("Auth token not found");
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("about", formData.about);
    if (logoFile) {
      formDataToSubmit.append("logo", logoFile);
    }
    if (bannerFile) {
      formDataToSubmit.append("banner", bannerFile);
    }

    try {
      const response: AxiosResponse<User> = await axios.patch(
        updateUserUrl,
        formDataToSubmit,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      setUserData(response.data);
      localStorage.setItem("about", formData.about);
      toast.success("Profil mis à jour avec succès !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "!z-[1000]",
      });
    } catch (error) {
      setError("Erreur lors de la mise à jour du profil.");
      console.error("Error updating profile:", error);
      toast.error("Erreur lors de la mise à jour du profil.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "!z-[1000]",
      });
    }
  };

  if (loading) {
    return (
      <div className="h1-mobile flex h-screen items-center justify-center">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="h1-mobile flex h-screen items-center justify-center">
        {error}
      </div>
    );
  }

  return (
    <div
      className={`flex h-full w-full flex-col gap-y-6 border-l border-l-[rgba(198,182,213,0.25)] px-5 pb-[60px] pt-10 max-mobile:pt-[40px] ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="z-[1000]">
        <ToastContainer />
      </div>
      <div className="min-745:hidden">
        <BackButton />
      </div>
      <div className="flex w-full flex-col gap-y-6">
        <div className="flex w-full items-center gap-x-10">
          <p className="h1-mobile">Modifier ton profil</p>
        </div>
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex w-full flex-col gap-y-2">
            <p className="nav-menu-700">Description</p>
            <TextareaAutosize
              minRows={3}
              placeholder="Description"
              name="about"
              value={formData.about}
              onChange={handleInputChange}
              maxRows={10}
              className="paragraph-m-400 mb-[1px] flex w-full grow select-text resize-none self-stretch break-words rounded-lg bg-[rgba(245,235,255,0.10)] px-2 pb-2 pt-2 opacity-50 focus:opacity-100 focus:outline-none"
              style={{ minHeight: "30px", maxHeight: "200px" }}
            />
            <div className="relative flex items-center gap-x-2 px-4">
              <p
                className={`label-s-400 ${charactersLeft < 0 ? "text-red-600" : "opacity-70"}`}
              >
                {charactersLeft} caractères restants
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <p className="nav-menu-700">Avatar</p>
            <label className="cursor-pointer rounded-full bg-[#573B73] px-6 py-2 text-white hover:bg-[#573B63]">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, "logo")}
                className="hidden"
              />
              Choisir un fichier
            </label>
            {formData.logo && (
              <img
                src={formData.logo}
                alt="Logo"
                className="mt-2 h-20 w-20 object-contain"
              />
            )}
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <p className="nav-menu-700">Bannière</p>
            <label className="cursor-pointer rounded-full bg-[#573B73] px-6 py-2 text-white hover:bg-[#573B63]">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, "banner")}
                className="hidden"
              />
              Choisir un fichier
            </label>
            {formData.banner && (
              <img
                src={formData.banner}
                alt="Banner"
                className="mt-2 h-40 w-full object-cover"
              />
            )}
          </div>
          <ButtonSimple
            label="Mettre à jour"
            onClick={handleSubmit}
            size="w-full py-3"
          />
        </div>
      </div>
    </div>
  );
};
