export const AddFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <g filter="url(#filter0_i_1186_6886)">
      <path
        d="M12.948 3.27457C12.948 1.74222 14.1902 0.5 15.7225 0.5H16.4624C17.9948 0.5 19.237 1.74222 19.237 3.27457V29.7254C19.237 31.2578 17.9948 32.5 16.4624 32.5H15.7225C14.1902 32.5 12.948 31.2578 12.948 29.7254V3.27457Z"
        fill="white"
      />
      <path
        d="M29.2254 13.263C30.7578 13.263 32 14.5052 32 16.0376V16.7775C32 18.3098 30.7578 19.552 29.2254 19.552L2.77457 19.552C1.24222 19.552 -6.69812e-08 18.3098 0 16.7775L3.23419e-08 16.0376C9.93231e-08 14.5052 1.24222 13.263 2.77457 13.263L29.2254 13.263Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1186_6886"
        x="0"
        y="0.5"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.960784 0 0 0 0 0.921569 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1186_6886"
        />
      </filter>
    </defs>
  </svg>
);
export const AddStrokeIcon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_573_3404)">
      <path
        d="M24.0925 24.4769H24.5925V23.9769V17.7341C24.5925 17.0525 25.145 16.5 25.8266 16.5H26.289C26.9706 16.5 27.5231 17.0525 27.5231 17.7341V23.9769V24.4769H28.0231L34.2659 24.4769C34.9475 24.4769 35.5 25.0294 35.5 25.711V26.1734C35.5 26.855 34.9475 27.4075 34.2659 27.4075L28.0231 27.4075H27.5231V27.9075V34.2659C27.5231 34.9475 26.9706 35.5 26.289 35.5H25.8266C25.145 35.5 24.5925 34.9475 24.5925 34.2659V27.9075V27.4075H24.0925H17.7341C17.0525 27.4075 16.5 26.855 16.5 26.1734V25.711C16.5 25.0294 17.0525 24.4769 17.7341 24.4769H24.0925Z"
        stroke="#F8EFFB"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_573_3404"
        x="-4"
        y="-4"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_573_3404"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_573_3404"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6.17341 0H5.89595C5.32132 0 4.85549 0.465831 4.85549 1.04046V4.78613H1.04046C0.465831 4.78613 0 5.25196 0 5.82659V6.10405C0 6.67868 0.465831 7.14451 1.04046 7.14451H4.85549V10.9595C4.85549 11.5342 5.32132 12 5.89595 12H6.17341C6.74804 12 7.21387 11.5342 7.21387 10.9595V7.14451L10.9595 7.14451C11.5342 7.14451 12 6.67868 12 6.10405V5.82659C12 5.25196 11.5342 4.78613 10.9595 4.78613L7.21387 4.78613V1.04046C7.21387 0.465831 6.74804 0 6.17341 0Z"
      fill="#F8EFFB"
    />
  </svg>
);

interface NegativeIconProps {
  onClick?: () => void;
}

export const NegativeIcon: React.FC<NegativeIconProps> = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    onClick={onClick}
    style={{ cursor: onClick ? "pointer" : "default" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99984 18.0003C14.142 18.0003 17.4998 14.6425 17.4998 10.5003C17.4998 6.3582 14.142 3.00033 9.99984 3.00033C5.85771 3.00033 2.49984 6.3582 2.49984 10.5003C2.49984 14.6425 5.85771 18.0003 9.99984 18.0003ZM9.99984 18.8337C14.6022 18.8337 18.3332 15.1027 18.3332 10.5003C18.3332 5.89795 14.6022 2.16699 9.99984 2.16699C5.39746 2.16699 1.6665 5.89795 1.6665 10.5003C1.6665 15.1027 5.39746 18.8337 9.99984 18.8337Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4165 10.5007C5.4165 10.2705 5.60305 10.084 5.83317 10.084H14.1665C14.3966 10.084 14.5832 10.2705 14.5832 10.5007C14.5832 10.7308 14.3966 10.9173 14.1665 10.9173H5.83317C5.60305 10.9173 5.4165 10.7308 5.4165 10.5007Z"
      fill="white"
    />
  </svg>
);
