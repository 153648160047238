export const GroupFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6 10C7.93438 10 9.5 8.43438 9.5 6.5C9.5 4.56562 7.93438 3 6 3C4.06562 3 2.5 4.56562 2.5 6.5C2.5 8.43438 4.06562 10 6 10ZM8.4 11H8.14062C7.49063 11.3125 6.76875 11.5 6 11.5C5.23125 11.5 4.5125 11.3125 3.85938 11H3.6C1.6125 11 0 12.6125 0 14.6V15.5C0 16.3281 0.671875 17 1.5 17H10.5C11.3281 17 12 16.3281 12 15.5V14.6C12 12.6125 10.3875 11 8.4 11ZM15 10C16.6562 10 18 8.65625 18 7C18 5.34375 16.6562 4 15 4C13.3438 4 12 5.34375 12 7C12 8.65625 13.3438 10 15 10ZM16.5 11H16.3813C15.9469 11.15 15.4875 11.25 15 11.25C14.5125 11.25 14.0531 11.15 13.6187 11H13.5C12.8625 11 12.275 11.1844 11.7594 11.4812C12.5219 12.3031 13 13.3938 13 14.6V15.8C13 15.8688 12.9844 15.9344 12.9812 16H18.5C19.3281 16 20 15.3281 20 14.5C20 12.5656 18.4344 11 16.5 11Z"
      fill="#F8EFFB"
    />
  </svg>
);

export const GroupStrokeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6.5C9 8.15823 7.65823 9.5 6 9.5C4.34177 9.5 3 8.15823 3 6.5C3 4.84177 4.34177 3.5 6 3.5C7.65823 3.5 9 4.84177 9 6.5ZM6 12C6.80966 12 7.56869 11.812 8.25193 11.5H8.4C10.1114 11.5 11.5 12.8886 11.5 14.6V15.5C11.5 16.052 11.052 16.5 10.5 16.5H1.5C0.948017 16.5 0.5 16.052 0.5 15.5V14.6C0.5 12.8886 1.88864 11.5 3.6 11.5H3.74849C4.43343 11.8117 5.18964 12 6 12ZM17.5 7C17.5 8.38011 16.3801 9.5 15 9.5C13.6199 9.5 12.5 8.38011 12.5 7C12.5 5.61989 13.6199 4.5 15 4.5C16.3801 4.5 17.5 5.61989 17.5 7ZM15 11.75C15.525 11.75 16.0134 11.6483 16.4633 11.5H16.5C18.1582 11.5 19.5 12.8418 19.5 14.5C19.5 15.052 19.052 15.5 18.5 15.5H13.5V14.6C13.5 13.5015 13.1432 12.4917 12.5522 11.6639C12.8521 11.5586 13.1692 11.5 13.5 11.5H13.5367C13.9866 11.6483 14.475 11.75 15 11.75Z"
      stroke="#F8EFFB"
    />
  </svg>
);
