import {
  Chat,
  HeaderGroupDiscussion,
  TypingMessage,
  MediaThumbnail,
} from "../../../components";
import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../../providers/userAuth";
import { GroupInPage, GroupMessage } from "../../../types/Group";
import {
  fetchGroupMessages,
  markGroupAsSeen,
  sendMessageWithVideo,
} from "../../../helpers/Group";
import socket from "../../../socket";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertToBase64, convertToWebP } from "../../../utils/File";

export const GroupDiscussion = () => {
  const { id } = useParams<{ id: string }>();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const auth = useAuth();
  const [group, setGroup] = useState<GroupInPage | null>(null);
  const [messages, setMessages] = useState<GroupMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [content, setContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const markAsSeen = async () => {
    try {
      await markGroupAsSeen(id!);
    } catch (err) {
      console.error("Error marking group as seen:", err);
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const data = await fetchGroupMessages(id!);
        setMessages(data.messages);
        setGroup(data);
        markAsSeen();
      } catch (err) {
        console.error("Error fetching messages:", err);
        setError("Failed to fetch messages.");
      } finally {
        setLoading(false);
        setTimeout(() => {
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "auto" });
          }
        }, 100);
      }
    };

    if (auth.token) {
      fetchMessages();
    }
  }, [auth.token, id]);

  useEffect(() => {
    socket.emit("joinGroup", id);

    socket.on("newGroupMessage", (message: GroupMessage) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (message.user.id != auth.user?.id) {
        markAsSeen();
      }
    });

    return () => {
      socket.off("newGroupMessage");
    };
  }, [id]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages]);

  useEffect(() => {
    return () => {
      if (auth.token) {
        markAsSeen();
      }
    };
  }, [auth.token, id]);

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && selectedFiles.length + files.length > 1) {
      toast.error("Veuillez mettre au maximum 1 média", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };

  const handleDeleteClick = (index: number) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const sendMessage = async () => {
    if (content.trim() || selectedFiles.length) {
      const messageData: any = {
        groupId: id,
        content,
        userId: auth.user?.id,
        files: [],
      };

      for (const file of selectedFiles) {
        if (file.type.startsWith("image")) {
          const webPFile = await convertToWebP(file);
          const base64 = await convertToBase64(webPFile);
          messageData.files.push({
            name: webPFile.name,
            type: webPFile.type,
            data: base64,
          });
        } else {
          await sendMessageWithVideo(id || "", content, file);
        }
      }

      socket.emit("groupMessage", messageData);
      setContent("");
      setSelectedFiles([]);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!group) {
    return <div>Group not found</div>;
  }

  return (
    <div
      className={`z-20 flex max-h-[calc(100vh-58px)] flex-col border-l border-l-[rgba(198,182,213,0.25)] ${isWideScreen ? "" : "absolute inset-0 min-h-screen"}`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <HeaderGroupDiscussion
        groupName={group.name}
        banner={group.logo}
        showParticipants={true}
        gameName={group.game?.name}
        participants={group.participants}
      />
      <div className="styled-scrollbars h-[calc(100vh-125px)] overflow-auto min-745:bg-[#3D2347]">
        <div
          ref={messagesContainerRef}
          className="flex h-full flex-col gap-y-[12px] px-5 pt-1"
        >
          {messages.map((msg, index) => (
            <Chat
              key={index}
              isUserMessage={msg.user.id === auth.user?.id}
              username={msg.user.username}
              pseudo={msg.participants?.pseudo}
              text={msg.content}
              time={new Date(msg.createdAt).toLocaleTimeString()}
              logo={msg.user.logo}
              isGroupMessage={true}
              files={msg.files}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      {selectedFiles.length > 0 && (
        <div className="flex gap-x-2 px-5 py-1">
          {selectedFiles.slice(0, 4).map((file, index) => (
            <MediaThumbnail
              isVideo={file.type.includes("video")}
              key={index}
              banner={URL.createObjectURL(file)}
              size="m"
              showSelectionIndicator={true}
              onClick={() => handleDeleteClick(index)}
            />
          ))}
        </div>
      )}
      <input
        type="file"
        accept="image/*,video/*"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        multiple
        style={{ display: "none" }}
      />
      <TypingMessage
        content={content}
        onContentChange={setContent}
        onSendMessage={sendMessage}
        onFileInputClick={handleFileInputClick}
      />
      <ToastContainer />
    </div>
  );
};
