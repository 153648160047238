type LastMessageDateProps = {
  timestamp: string;
};

export const LastMessageDate: React.FC<LastMessageDateProps> = ({
  timestamp,
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const today = new Date(now.toDateString());
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const currentYear = now.getFullYear();
    const messageYear = date.getFullYear();

    if (date.toDateString() === now.toDateString()) {
      return date.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Hier";
    } else if (currentYear === messageYear) {
      return date.toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
      });
    } else {
      return messageYear.toString();
    }
  };

  return <>{formatDate(timestamp)}</>;
};
