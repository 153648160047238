import { SetStateAction, useState } from "react";
import { ChevronIcon } from "../";

type ListButtonProps = {
  items: { label: string; onClick: () => void; className?: string }[];
  overlay?: boolean;
  className?: string;
  size?: string;
};

export const ListButton: React.FC<ListButtonProps> = ({
  items,
  overlay = false,
  className,
  size,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLabel, setActiveLabel] = useState(items[0].label);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick = (item: {
    label: SetStateAction<string>;
    onClick: () => void;
  }) => {
    setActiveLabel(item.label);
    item.onClick();
    setIsOpen(false);
  };

  return (
    <div className={`${className} relative`}>
      <div
        className={`${size} ${isOpen ? "rounded-t-[20px] border-t-[0.4px]" : "rounded-[20px] border-[0.4px]"} flex cursor-pointer items-center justify-between gap-x-2 self-stretch border-solid border-[rgba(198,182,213,0.25)] bg-[rgba(245,235,255,0.10)] px-3 py-[5px] backdrop-blur-[4px]`}
        onClick={toggleDropdown}
      >
        <p className="label-m-400 text-center text-primary-100">
          {activeLabel}
        </p>
        <ChevronIcon orientation={isOpen ? "up" : "down"} />
      </div>

      {isOpen && (
        <ul
          className={`z-20 ${overlay ? "top-full" : ""} w-full rounded-b-md border-[0.4px]  border-solid border-[rgba(198,182,213,0.25)] shadow-lg ${overlay ? "absolute bg-[#2B183F]" : "static bg-[#f8ebff11]"}`}
        >
          {items.map((item, index) => (
            <li
              key={index}
              className={`label-m-400 cursor-pointer px-4 py-3 hover:bg-[#f8ebff] hover:text-black ${item.className || ""}`}
              onClick={() => handleItemClick(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
