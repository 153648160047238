import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import {
  ChevronIcon,
  BackButton,
  AvatarIcon,
  DotsIconFrame,
  Chat,
  TypingMessage,
} from "../..";

const fakeMessages = [
  {
    isUserMessage: false,
    text: "Allons-y !",
    time: "14:00",
  },
  {
    isUserMessage: false,
    text: "Suivez mon plan.",
    time: "14:05",
  },
  {
    isUserMessage: true,
    text: "Entendu.",
    time: "14:10",
  },
  {
    isUserMessage: false,
    text: "Allons-y !",
    time: "14:00",
  },
  {
    isUserMessage: false,
    text: "Suivez mon plan.",
    time: "14:05",
  },
  {
    isUserMessage: false,
    text: "Allons-y !",
    time: "14:00",
  },
  {
    isUserMessage: false,
    text: "Suivez mon plan.",
    time: "14:05",
  },
  {
    isUserMessage: true,
    text: "Entendu.",
    time: "14:10",
  },
  {
    isUserMessage: false,
    text: "Allons-y !",
    time: "14:00",
  },
  {
    isUserMessage: false,
    text: "Suivez mon plan.",
    time: "14:05",
  },
];

type DiscussionsWidgetProps = {
  onBackButtonClick: () => void;
};

export const DiscussionsWidget: React.FC<DiscussionsWidgetProps> = ({
  onBackButtonClick,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="pointer-events-none fixed bottom-0 left-0 z-[200] flex w-full items-end justify-end pr-3 max-mobile:hidden">
      <div
        className="pointer-events-auto flex max-h-[calc(100vh-150px)] w-[400px] flex-col gap-y-[10px] rounded-lg shadow-[0_2px_34px_rgba(0,0,0,0.45)] backdrop-blur-[6.5px]"
        style={{
          background:
            "linear-gradient(293deg, rgba(40, 12, 49, 0.95) 0.12%, rgba(151, 46, 182, 0.95) 117.69%)",
        }}
      >
        <header className="flex w-full flex-col gap-y-[10px] px-5 py-3">
          <div className="flex w-full items-center justify-between">
            <BackButton onClick={onBackButtonClick} shadow={false} />
            <ChevronIcon
              orientation={isVisible ? "up" : "down"}
              onClick={toggleVisibility}
              className="cursor-pointer"
            />
          </div>
        </header>
        <CSSTransition
          in={isVisible}
          timeout={300}
          classNames="slide"
          unmountOnExit
        >
          <div>
            <div className="flex items-center gap-x-3 rounded-lg px-5 py-2">
              {/* <AvatarIcon logo={Legolas} className="avatar-m" /> */}
              <div className="flex flex-1 flex-col gap-y-[2px]">
                <p className="h4-mobile">TalkPlay_name</p>
              </div>
              <div className="flex items-center gap-x-4 self-stretch">
                <DotsIconFrame shadow={false} />
              </div>
            </div>

            <aside className="styled-scrollbars flex max-h-[calc(100vh-150px)] flex-grow flex-col gap-y-3 self-stretch overflow-y-auto border-t-[2px] border-t-[rgba(248,239,251,0.37)] px-5 pb-60 pt-4">
              {fakeMessages.map((msg, index) => (
                <Chat
                  key={index}
                  isUserMessage={msg.isUserMessage}
                  text={msg.text}
                  time={msg.time}
                  isGroupMessage={false}
                />
              ))}
              <div ref={messagesEndRef} />
            </aside>
            <TypingMessage />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
