import axios, { AxiosResponse } from "axios";
import {
  User,
  UserPageType,
  UserPost,
  UserExist,
  Friend,
  UserId,
  Post,
  SearchResults,
  Gameplay,
} from "../types";
import {
  addFriendUrl,
  fetchSearchUrl,
  fetchTimeLeftUpdateUsernameUrl,
  fetchUserByTokenUrl,
  fetchUserByUsernameUrl,
  fetchUserGameplaysUrl,
  fetchUsernameExistUrl,
  followUserUrl,
  getFriendsRequestsUrl,
  getFriendsUrl,
  getUserPostsUrl,
  hideUserUrl,
  removeFriendUrl,
  unFollowUserUrl,
  unHideUserUrl,
  updateUsernameUrl,
} from "../constants/api";

export const fetchUserByUsername =
  (username: string) => async (): Promise<User> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const encodedUsername = encodeURIComponent(
        username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      );

      const response: AxiosResponse<User> = await axios.get(
        fetchUserByUsernameUrl + encodedUsername,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const userData: User = response.data;
      return userData;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

export const fetchUsernameExist =
  (username: string) => async (): Promise<UserExist> => {
    try {
      const encodedUsername = encodeURIComponent(
        username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      );

      const response: AxiosResponse<UserExist> = await axios.get(
        fetchUsernameExistUrl + encodedUsername,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

export const fetchUserByToken = () => async (): Promise<User> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<User> = await axios.get(fetchUserByTokenUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    const userData: User = response.data;
    return userData;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const fetchUserPage =
  (username: string) => async (): Promise<UserPageType> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const encodedUsername = encodeURIComponent(
        username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      );

      const response: AxiosResponse<UserPageType> = await axios.get(
        fetchUserByUsernameUrl + encodedUsername,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const userPageData: UserPageType = response.data;
      return userPageData;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

export const fetchUserPost =
  (username: string) => async (): Promise<UserPost> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const encodedUsername = encodeURIComponent(
        username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      );

      const response: AxiosResponse<UserPost> = await axios.get(
        fetchUserByUsernameUrl + encodedUsername,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const userPostData: UserPost = response.data;
      return userPostData;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

export const fetchUserGameplay =
  (username: string) => async (): Promise<Gameplay[]> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const encodedUsername = encodeURIComponent(
        username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      );

      const response: AxiosResponse<Gameplay[]> = await axios.get(
        fetchUserGameplaysUrl + encodedUsername,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching user gameplays:", error);
      throw error;
    }
  };

export const fetchFriends =
  (username: string) => async (): Promise<Friend[]> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const encodedUsername = encodeURIComponent(
        username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      );

      const response: AxiosResponse<Friend[]> = await axios.get(
        getFriendsUrl + encodedUsername,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const userFriendsData: Friend[] = response.data;
      return userFriendsData;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

export const fetchFriendsRequest = async (): Promise<{
  friendsRequests: Friend[];
}> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<{ friendsRequests: Friend[] }> =
      await axios.get(getFriendsRequestsUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

    return response.data;
  } catch (error) {
    console.error("Error fetching friend requests:", error);
    throw error;
  }
};

export const followUser = async (userId: string): Promise<UserId> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  const response = await axios.post<UserId>(
    followUserUrl,
    { userId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unFollowUser = async (userId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(unFollowUserUrl, {
    data: { userId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const addFriend = async (userId: string): Promise<UserId> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  const response = await axios.post<UserId>(
    addFriendUrl,
    { userId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const removeFriend = async (userId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(removeFriendUrl, {
    data: { userId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const hideUser = async (userId: string): Promise<UserId> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  const response = await axios.post<UserId>(
    hideUserUrl,
    { userId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unHideUser = async (userId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(unHideUserUrl, {
    data: { userId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const fetchUserPosts = async (username: string) => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const encodedUsername = encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );

    const response: AxiosResponse<{
      posts: Post[];
      likedPosts: Post[];
      repostedPosts: Post[];
    }> = await axios.get(getUserPostsUrl + encodedUsername, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    const { posts, likedPosts, repostedPosts } = response.data;
    return { posts, likedPosts, repostedPosts };
  } catch (error) {
    console.error("Error fetching user posts:", error);
    throw error;
  }
};

export const searchUsers = async (
  query: string,
  gameId?: string,
): Promise<SearchResults> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const requestBody: any = { query };
    if (gameId !== undefined) {
      requestBody.gameId = gameId;
    }

    const response: AxiosResponse<SearchResults> = await axios.post(
      fetchSearchUrl,
      requestBody,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error searching users:", error);
    throw error;
  }
};

export const fetchTimeLeftUpdateUsername = async (): Promise<{
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<{
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    }> = await axios.get(fetchTimeLeftUpdateUsernameUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching time left to update username:", error);
    throw error;
  }
};

export const updateUsername = async (newUsername: string): Promise<User> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    throw new Error("Auth token not found");
  }

  try {
    const formData = new FormData();
    formData.append("username", newUsername);

    const response: AxiosResponse<User> = await axios.patch(
      updateUsernameUrl,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error updating username:", error);
    throw error;
  }
};
