import axios, { AxiosResponse } from "axios";
import { apiUrl, createGroupUrl, deleteDiscussionUrl } from "../constants/api";
import { User } from "../types/User";
import { CreateDiscussion, DiscussionList, Message } from "../types/Discussion";

export const fetchDiscussions = async (): Promise<DiscussionList[]> => {
  const authToken = localStorage.getItem("token");
  try {
    const response: AxiosResponse<DiscussionList[]> = await axios.get(
      `${apiUrl}discussions`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching discussions:", error);
    throw error;
  }
};

export const markDiscussionAsSeen = async (
  discussionId: string,
): Promise<void> => {
  const authToken = localStorage.getItem("token");
  try {
    await axios.post(
      `${apiUrl}discussion/markAsSeen/${discussionId}`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (error) {
    console.error("Error marking discussion as seen:", error);
    throw error;
  }
};

export const fetchDiscussionMessages = async (
  discussionId: string,
): Promise<{ messages: Message[]; user: User; user2: User }> => {
  const authToken = localStorage.getItem("token");
  try {
    const response: AxiosResponse<{
      messages: Message[];
      user: User;
      user2: User;
    }> = await axios.get(`${apiUrl}discussion/${discussionId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching discussion messages:", error);
    throw error;
  }
};

export const sendMessageWithVideo = async (
  discussionId: string,
  message: string,
  video: File,
): Promise<void> => {
  const authToken = localStorage.getItem("token");
  try {
    await axios.post(
      `${apiUrl}discussion/message/file`,
      { discussionId, content: message, files: video },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const createGroup = async (
  name: string,
  userIds: string[],
  description?: string,
  logo?: string,
  gameId?: string,
): Promise<CreateDiscussion> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  const response = await axios.post<CreateDiscussion>(
    createGroupUrl,
    { name, description, logo, userIds, gameId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const deleteDiscussion = async (discussionId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  try {
    await axios.delete(deleteDiscussionUrl + discussionId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error("Error deleting discussion:", error);
    throw error;
  }
};
