import { useLocation } from "react-router-dom";
import { InputSearch, NewDiscussionFrame } from "../..";
import { useState, useRef } from "react";

export const HeaderMessageList = () => {
  const location = useLocation();
  const [isShrink, setIsShrink] = useState(false);
  const isShrinkRef = useRef(isShrink);
  isShrinkRef.current = isShrink;

  return (
    <div className="relative z-[5] flex w-full flex-col gap-y-4 px-3 max-mobile:pt-4">
      <div className="flex w-full items-center justify-between pt-8 max-mobile:hidden">
        <p className="h1-mobile">Messages</p>
        <NewDiscussionFrame />
      </div>
      <div className="relative">
        <InputSearch
          showLogoSection={false}
          placeholder="Rechercher une discussion"
        />
      </div>
    </div>
  );
};
