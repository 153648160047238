import React, { createContext, useContext, useState } from "react";

interface RouteOriginContextType {
  fromRegistration: boolean;
  setFromRegistration: (from: boolean) => void;
}

const RouteOriginContext = createContext<RouteOriginContextType>({
  fromRegistration: false,
  setFromRegistration: () => {
    /* noop */
  }, // No operation function
});

export const RouteOriginProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [fromRegistration, setFromRegistration] = useState(false);
  return (
    <RouteOriginContext.Provider
      value={{ fromRegistration, setFromRegistration }}
    >
      {children}
    </RouteOriginContext.Provider>
  );
};

export const useRouteOrigin = () => useContext(RouteOriginContext);
