import {
  ParticipantGroup,
  SimpleInput,
  BackAndButtonNext,
  BackButton,
  DisplayedUsers,
  Loading,
} from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Friend } from "../../../types";
import { useAuth } from "../../../providers/userAuth";
import { useMediaQuery } from "react-responsive";
import { fetchFriends } from "../../../helpers/fetchUser";
import DefaultAvatar from "../../../assets/DefaultAvatar.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Player = {
  id: string;
  name: string;
  image: string;
};

export const CreateGroupChooseParticipant: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const username = user?.username;
  const [friends, setFriends] = useState<Friend[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>(
    location.state?.selectedPlayers ||
      JSON.parse(localStorage.getItem("participants") || "[]"),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const hasFetchedFriends = useRef(false);
  const fetchTimeoutRef = useRef<number | null>(null);
  const isWideScreen = useMediaQuery({ minWidth: 745 });

  useEffect(() => {
    const getFriends = async () => {
      if (username && !hasFetchedFriends.current) {
        try {
          const friendsData = await fetchFriends(username)();
          setFriends(friendsData);
          hasFetchedFriends.current = true;
          console.log("Fetched friends data: ", friendsData);
        } catch (error) {
          console.error("Error fetching friends:", error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      } else if (!username) {
        console.error("Username not found");
        setIsError(true);
        setIsLoading(false);
      }
    };

    if (user && !hasFetchedFriends.current) {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      fetchTimeoutRef.current = window.setTimeout(getFriends, 300);
    } else {
      setIsLoading(false);
    }
  }, [user, username]);

  useEffect(() => {
    localStorage.setItem("participants", JSON.stringify(selectedPlayers));
  }, [selectedPlayers]);

  const navigateToCreatePage = () => {
    const coverImage = localStorage.getItem("coverImage") || undefined;
    const groupName = localStorage.getItem("groupName") || "";
    const description = localStorage.getItem("description") || "";
    const selectedGame = JSON.parse(
      localStorage.getItem("selectedGame") || "null",
    );

    navigate(`/messages/creategroup/`, {
      state: {
        selectedPlayers,
        coverImage,
        groupName,
        description,
        selectedGame,
      },
    });
    toast.success("Groupe créé avec succès !", { position: "top-center" });

    if (selectedPlayers.length > 0) {
      toast.success("Participants sélectionnés avec succès !");
    } else {
      toast.error("Aucun participant sélectionné.");
    }
  };

  const togglePlayerSelection = (player: Player) => {
    setSelectedPlayers((currentSelected) => {
      if (currentSelected.some((p) => p.id === player.id)) {
        return currentSelected.filter((p) => p.id !== player.id);
      } else {
        return [...currentSelected, player];
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="h1-mobile">Error loading friends.</div>;
  }

  const background = isWideScreen
    ? "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)"
    : "";

  return (
    <div className="flex w-full flex-col max-mobile:min-h-[100svh]">
      <ToastContainer position="top-center" />
      <div className="flex flex-grow flex-col gap-y-6 self-stretch overflow-auto max-mobile:px-5 max-mobile:pb-40 max-mobile:pt-[60px]">
        <div className="flex w-full flex-col gap-y-6 min-745:hidden">
          <BackButton />
          <p className="h1-mobile">Nouveau groupe</p>
          <SimpleInput iconType="none" />
        </div>
        <div
          className="flex flex-col gap-y-4 self-stretch min-745:h-[calc(100vh-250px)] min-745:rounded-xl min-745:p-4"
          style={{
            background: background,
          }}
        >
          <div className="flex w-full flex-col gap-y-2 max-mobile:hidden">
            <div className="flex items-center justify-between">
              <p className="nav-menu-700 whitespace-nowrap">Participants</p>
              <div className="flex items-center gap-x-2">
                <DisplayedUsers
                  participants={selectedPlayers.map((player) => ({
                    id: player.id,
                    username: player.name,
                    logo: player.image || DefaultAvatar,
                  }))}
                  showParticipants={true}
                  displayType="participants"
                />
                <p className="label-m-400">
                  {selectedPlayers.length} joueurs sélectionnés
                </p>
              </div>
            </div>
            <SimpleInput iconType="none" />
          </div>
          <div className="styled-scrollbars flex h-[calc(100vh-350px)] w-full flex-col gap-y-4 overflow-auto p-1 pr-2">
            {friends.length === 0 ? (
              <p className="h3-mobile w-full text-center">Aucun ami trouvé</p>
            ) : (
              friends.map((friend) => (
                <ParticipantGroup
                  key={friend.id}
                  name={friend.username}
                  image={friend.logo || DefaultAvatar}
                  isSelected={selectedPlayers.some((p) => p.id === friend.id)}
                  onToggle={() =>
                    togglePlayerSelection({
                      id: friend.id,
                      name: friend.username,
                      image: friend.logo || DefaultAvatar,
                    })
                  }
                  showOptions={false}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <BackAndButtonNext
        selectedPlayersCount={selectedPlayers.length}
        navigateTo={navigateToCreatePage}
        label="Créer le groupe"
        isPlayer={true}
      />
    </div>
  );
};
