import { useRef, useState, useEffect } from "react";
import {
  BackButton,
  ButtonSimple,
  CRLevelIcon,
  CardStat,
  ImageCard,
  SecondaryButton,
  SimpleInput,
} from "../../../components";
import { SpinnerLoading } from "../../../components/Icons/Spinner/SpinnerLoading";
import {
  addSupercellGame,
  fetchPlayerAccountClashRoyale,
} from "../../../helpers/fetchGame";
import { ClashRoyaleProps } from "../../../types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagRoyale from "../../../assets/TagRoyale.gif";
import { useLocation, useNavigate } from "react-router-dom";
import CRTrophy from "../../../assets/CRtrophy.png";

export const ClashRoyaleAuth = () => {
  const location = useLocation();
  const { gameId } = location.state || {};
  const [tag, setTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountConnected, setIsAccountConnected] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [playerStats, setPlayerStats] = useState<ClashRoyaleProps | null>(null);
  const [error, setError] = useState<string | null>(null);
  const tagInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const preloadImages = (imageUrls: string[]) => {
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };

    preloadImages([TagRoyale]);
  }, []);

  const cleanTagInput = (input: string) =>
    `${input.replace("#", "").toUpperCase().substring(0, 9)}`;

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTag(cleanTagInput(e.target.value));
  };

  const handleConnectAccount = async () => {
    setIsLoading(true);
    try {
      const response = await addSupercellGame(gameId, tag);
      setIsAccountConnected(true);
      setAccountName(response.name);
      navigate("/connectgames");
    } catch (error) {
      console.error("Failed to connect account:", error);
      toast.error("Échec de la connexion du compte.");
      setIsAccountConnected(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyTag = async () => {
    setIsLoading(true);
    try {
      const playerData = await fetchPlayerAccountClashRoyale(gameId, tag)();
      setPlayerStats(playerData);
      setError(null);
    } catch (error) {
      console.error("Error fetching player account:", error);
      setError("Failed to fetch player account. Please try again.");
      setPlayerStats(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-start gap-y-6 self-stretch px-8 pb-10 pt-6 max-mobile:pt-20 ">
      <BackButton />
      <div
        className={`${playerStats ? "" : "items-center"} flex flex-col gap-y-4 self-stretch max-1157:items-center min-745:gap-y-8 min-745:pt-[10vh]`}
      >
        {error && <div className="h1-mobile text-red">{error}</div>}
        <div className="flex gap-x-2">
          <p className="h1-mobile">Connexion Clash Royale</p>
        </div>
        <p className="paragraph-m-400 max-1157:max-w-[400px] ">
          Renseigne ton tag in-game pour lier ton compte à TalkPlay
        </p>
        <div
          className={`${playerStats ? "" : "items-center"} flex w-full max-1157:flex-col max-1157:gap-y-4 min-851:gap-x-16`}
        >
          <div
            className={`flex w-full flex-col ${playerStats ? "" : "items-center"} gap-y-4 self-stretch min-745:gap-y-8`}
          >
            <div className="flex w-full max-w-[400px] flex-col items-start gap-y-4 rounded-lg border border-[rgba(198,182,213,0.15)] bg-[rgba(151,46,182,0.29)] p-4">
              <p className="nav-menu-700">Tag du compte</p>
              <SimpleInput
                placeholder="AAAAAAAA"
                iconType="cross"
                helper={true}
                helperText="<- Comment trouver son tag ?"
                helperImageUrl={TagRoyale}
                value={tag}
                onChange={handleTagChange}
              />
              <SecondaryButton label="Vérifier Tag" onClick={handleVerifyTag} />
            </div>
            <div className="w-full max-w-[400px]">
              <ButtonSimple
                label="Connecter ce compte"
                onClick={handleConnectAccount}
              />
            </div>
          </div>
          {playerStats && (
            <div className="flex w-full flex-col items-center justify-center gap-y-4 self-stretch px-5">
              <div
                className="flex w-full max-w-[370px] flex-col items-start justify-center gap-y-6 rounded-xl p-5"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
                }}
              >
                {isLoading && (
                  <div className="flex w-full flex-col items-center gap-y-4">
                    <p className="h4-mobile">Recherche du compte en cours</p>
                    <SpinnerLoading />
                  </div>
                )}
                <div className="flex flex-col items-center justify-center gap-y-3 self-stretch">
                  <CardStat direction="horizontal">
                    <p className="h2-mobile">{playerStats.name}</p>
                    <CRLevelIcon level={playerStats.expLevel} size={32} />
                  </CardStat>
                  <div className="flex items-center justify-center gap-x-3">
                    <CardStat direction="column">
                      <div className="flex items-center justify-center gap-x-2">
                        <p className="paragraph-s-700">
                          {playerStats.trophies}
                        </p>
                        <ImageCard imageUrl={CRTrophy} size={18} />
                      </div>
                      <p className="label-s-400 text-center opacity-60">
                        Trophées actuels
                      </p>
                    </CardStat>
                    <CardStat direction="column">
                      <div className="flex items-center justify-center gap-x-2">
                        <p className="paragraph-s-700">
                          {playerStats.bestTrophies}
                        </p>
                        <ImageCard imageUrl={CRTrophy} size={18} />
                      </div>
                      <p className="label-s-400 text-center  opacity-60">
                        Record trophées
                      </p>
                    </CardStat>
                  </div>
                  <CardStat direction="horizontal">
                    <p className="h3-mobile">Clan : {playerStats.clan.name}</p>
                  </CardStat>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
