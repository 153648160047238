import React from "react";
import { Gameplay, PlayerStats, Post } from "../../../types";
import { UserPostGameStatDesktop, UserPostGameStatMobile } from "../..";

interface UserPostGameStatProps {
  post: Post | Gameplay;
  playerStats: PlayerStats | undefined;
  hasGameAccount: boolean;
}

export const UserPostGameStat: React.FC<UserPostGameStatProps> = ({
  post,
  playerStats,
  hasGameAccount,
}) => {
  if (!post.game || !playerStats) return null;

  return (
    <>
      <div className="w-full min-851:hidden">
        <UserPostGameStatMobile
          post={post}
          playerStats={playerStats}
          hasGameAccount={hasGameAccount}
        />
      </div>
      <div className="max-tablet:hidden">
        {playerStats && (
          <UserPostGameStatDesktop
            post={post}
            playerStats={playerStats}
            hasGameAccount={hasGameAccount}
          />
        )}
      </div>
    </>
  );
};
