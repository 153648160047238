type InfoIconProps = {
  size?: number;
  color?: string;
  onMouseEnter?: () => void;
};

export const InfoIcon: React.FC<InfoIconProps> = ({
  size = 24,
  color = "bg-primary-100",
  onMouseEnter,
}) => (
  <div
    className={`flex flex-shrink-0 items-center justify-center gap-[2px] rounded-full ${color} ${size == 18 ? "" : "p-[3px]"} shadow-[0_0_4px_#140029]`}
    style={{
      height: `${size}px`,
      width: `${size}px`,
    }}
    onMouseEnter={onMouseEnter}
  >
    <p
      className={`button-label  ${color == "red" ? "text-white" : "text-primary-900"}`}
    >
      i
    </p>
  </div>
);
