import React from "react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  AccountSettings,
  ChangeAccountPassword,
  EditAccount,
  EmailChange,
  PseudoChange,
  SettingPrivacyUser,
  Settings,
} from "../..";

export const PagesContainerSettings: React.FC = () => {
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const location = useLocation();

  const isInSettingsPage = location.pathname.includes("/settings/");

  return (
    <div
      className={`flex min-h-screen w-full items-center min-745:min-h-[calc(100vh-58px)] ${isWideScreen ? "flex-row" : "flex-col"}`}
    >
      <Settings>
        {isWideScreen && (
          <div className="flex min-h-[calc(100vh-58px)]">
            <div className="max-w-[670px] flex-1">
              <Routes>
                <Route path="/account" element={<AccountSettings />} />
                <Route path="/account/pseudo" element={<PseudoChange />} />
                <Route path="/account/mail" element={<EmailChange />} />
                <Route
                  path="/account/password"
                  element={<ChangeAccountPassword />}
                />
                <Route path="/privacy" element={<SettingPrivacyUser />} />
                <Route path="/editprofil" element={<EditAccount />} />
              </Routes>
            </div>
          </div>
        )}
      </Settings>
      {!isWideScreen && (
        <Routes>
          <Route path="/account" element={<AccountSettings />} />
          <Route path="/account/pseudo" element={<PseudoChange />} />
          <Route path="/account/mail" element={<EmailChange />} />
          <Route path="/account/password" element={<ChangeAccountPassword />} />
          <Route path="/privacy" element={<SettingPrivacyUser />} />
          <Route path="/editprofil" element={<EditAccount />} />
        </Routes>
      )}
    </div>
  );
};
