import React from "react";
import { CardStat } from "../../..";
import { PlayerStats } from "../../../../types";

interface SteamStatsProps {
  playerStats: PlayerStats;
  hasGameAccount: boolean;
}

const convertMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  return `${hours} heures`;
};

export const SteamStats: React.FC<SteamStatsProps> = ({
  playerStats,
  hasGameAccount,
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      {hasGameAccount && playerStats.playtimeForever && (
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex gap-x-3">
            <CardStat direction="column">
              <div className="flex items-center justify-center gap-x-2">
                <p className="paragraph-s-700">
                  {convertMinutesToHours(playerStats.playtimeForever)}
                </p>
              </div>
            </CardStat>
          </div>
        </div>
      )}
      {hasGameAccount && playerStats.nbAchievedAchievements && (
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex gap-x-3">
            <CardStat direction="column">
              <div className="flex items-center justify-center gap-x-2">
                <p className="paragraph-s-700">
                  Succès obtenus : {playerStats.nbAchievedAchievements}/
                  {playerStats.nbTotalAchievements}
                </p>
              </div>
            </CardStat>
          </div>
        </div>
      )}
    </div>
  );
};
