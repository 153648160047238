import { ChevronIcon, NewMessage20Icon, PlusIcon } from "../..";

interface ButtonSimpleProps {
  label?: string;
  plusFonction?: boolean;
  onClick: () => void;
  showChevron?: boolean;
  chevronDirection?: "left" | "right";
  disabled?: boolean;
  inactive?: boolean;
  size?: string;
  newMessage?: boolean;
  classname?: string;
}

export const ButtonSimple: React.FC<ButtonSimpleProps> = ({
  label,
  plusFonction,
  onClick,
  showChevron = false,
  chevronDirection = "right",
  disabled = false,
  inactive = false,
  size = "h-12",
  newMessage = false,
  classname,
}) => {
  const justifyContent = showChevron ? "justify-between" : "justify-center";
  const isButtonActive = !disabled && !inactive;

  return (
    <>
      <style>
        {`
          .button-simple {
            background: linear-gradient(290deg, #32123d, #972eb6) padding-box,
                        linear-gradient(to bottom right, rgba(198, 182, 213, 0.8), #280c3100 40%) border-box;
            cursor: pointer;
          }
          .button-simple:hover {
            background: linear-gradient(292.86deg, rgba(47, 47, 144, 0.4) 0.12%, rgba(9, 65, 166, 0.4) 0.13%, rgba(205, 25, 131, 0.4) 117.69%) padding-box,
                        linear-gradient(to bottom right, rgba(198, 182, 213, 0.8), #573B73 40%) border-box;
          }
          .button-simple:active {
            background: rgba(248, 239, 251, 0.11);
          }
          .button-inactive {
            background: linear-gradient(290deg, #1a0a1f, #4a1576) padding-box,
                        linear-gradient(to bottom right, rgba(100, 80, 100, 0.4), #14061600 40%) border-box;
            cursor: not-allowed;
          }
        `}
      </style>
      <div
        className={`flex ${size} ${classname} items-center gap-x-2.5 self-stretch whitespace-nowrap rounded-[12px] border border-transparent px-4 py-2.5 ${justifyContent} backdrop-blur-sm ${
          isButtonActive ? "button-simple" : "button-inactive"
        } ${inactive ? "button-inactive cursor-not-allowed" : ""}`}
        onClick={() => !disabled && onClick()}
      >
        {newMessage && <NewMessage20Icon />}
        {plusFonction && (
          <div
            className={`flex ${size === "h-[33px]" ? "h-6 w-6" : "h-8 w-8"} items-center justify-center rounded-full border border-[rgba(198,182,213,0.25)] bg-[rgba(20,0,41,0.50)] shadow-[0_1px_0_0_rgba(0,0,0,0.25)]`}
          >
            <PlusIcon />
          </div>
        )}
        {showChevron && chevronDirection === "left" && (
          <ChevronIcon orientation="left" />
        )}
        {label && <p className="button-label">{label}</p>}
        {showChevron && chevronDirection === "right" && (
          <ChevronIcon orientation="right" />
        )}
      </div>
    </>
  );
};
