import { useMediaQuery } from "react-responsive";
import { LinkIcon, GameSocialLinks, GamesIcons, GameCoverColumn } from "../";

interface InfoGamePageProps {
  logo: string;
  gameName: string;
  studioName: string;
  gameType: string;
  description: string;
  officialWebsite: string;
  studioGames: Array<{ id: string; name: string; card: string }>;
}

export const InfoGamePage: React.FC<InfoGamePageProps> = ({
  logo,
  gameName,
  studioName,
  gameType,
  description,
  officialWebsite,
  studioGames,
}) => {
  const isBelow450px = useMediaQuery({ query: "(max-width: 450px)" });
  const gamesToShow = isBelow450px
    ? studioGames.slice(0, 2)
    : studioGames.slice(0, 3);

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="flex w-full flex-col items-start justify-end gap-y-6 self-stretch pb-8 pt-5">
        <div className="flex items-start gap-x-4">
          <GamesIcons logo={logo} className="game-thumbnail-l" />
          <div className="flex max-w-[200px] flex-col items-start gap-y-[9px]">
            <div className="flex flex-col">
              <p className="h2-mobile mt-[-4px] h-7 truncate">{gameName}</p>
              <p className="label-m-400 truncate">{studioName}</p>
            </div>
            <p className="label-m-400">Jeu {gameType}</p>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col items-start gap-y-4">
            <p className="h2-mobile">Description</p>
            <p className="paragraph-m-400">{description}</p>
          </div>
          {studioGames.length > 0 && (
            <div className="flex flex-col gap-y-2 pb-20">
              <p className="h2-mobile">Jeux de l'éditeur</p>
              <div
                className={`flex flex-wrap content-start items-start gap-x-4 self-stretch ${
                  studioGames.length === 0 ? "hidden" : ""
                }`}
              >
                {gamesToShow.map((game) => (
                  <GameCoverColumn
                    key={game.id}
                    banner={game.card}
                    name={game.name}
                    gameId={game.id.toString()}
                    isClickable={true}
                    size="w-[125px] h-[187px]"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
