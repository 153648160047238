import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { fetchUsernameExist } from "../../../helpers/fetchUser";
import {
  ButtonSimple,
  SimpleInput,
  StepCard,
  CorrectCheck,
  FalseCheck,
  InfoIcon,
  CustomCheckbox,
  Loading,
} from "../../../components";
import { UserExist } from "../../../types";
import { useRouteOrigin } from "../../../contexts/RouteOriginContext";
import { useAuth } from "../../../providers/userAuth";

export const UserInformation = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [charactersLeft, setCharactersLeft] = useState(32);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<
    boolean | null
  >(null);
  const [isValidLength, setIsValidLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [emailInUseError, setEmailInUseError] = useState<boolean>(false);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { setFromRegistration } = useRouteOrigin();

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = e.target.value.replace(/\s/g, "_");
    setUsername(newUsername);
    setCharactersLeft(32 - newUsername.length);

    if (newUsername.length < 3) {
      setIsUsernameAvailable(false);
    } else {
      debouncedCheckUsername(newUsername);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
    setShowEmailError(false);
    setEmailInUseError(false);
  };

  const handleConsentChange = () => {
    setIsConsentGiven(!isConsentGiven);
  };

  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const upperCaseValid = /[A-Z]/.test(password);
    const lowerCaseValid = /[a-z]/.test(password);
    const specialCharValid = /[\^$*.[\]{}()?"!@#%&/,><':;|_~`]/.test(password);

    setIsValidLength(lengthValid);
    setHasUpperCase(upperCaseValid);
    setHasLowerCase(lowerCaseValid);
    setHasSpecialChar(specialCharValid);
  };

  const validateUsernameLength = (username: string) => {
    return (
      username.length >= 3 && username.length <= 32 && !/\s/.test(username)
    );
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const debouncedCheckUsername = useCallback(
    debounce(async (username: string) => {
      if (!validateUsernameLength(username)) {
        setIsUsernameAvailable(null);
        return;
      }

      try {
        const userExistData: UserExist = await fetchUsernameExist(username)();
        setIsUsernameAvailable(!userExistData.exists);
      } catch (error) {
        console.error("Erreur lors de la vérification du pseudo", error);
        setIsUsernameAvailable(false);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    if (username && username.length >= 3) {
      debouncedCheckUsername(username);
    }
    return () => debouncedCheckUsername.cancel();
  }, [username, debouncedCheckUsername]);

  const handleRegister = async () => {
    setIsLoading(true);
    setError(null);

    if (!isEmailValid) {
      setShowEmailError(true);
      setIsLoading(false);
      return;
    }

    if (isUsernameAvailable === false) {
      setIsLoading(false);
      return;
    }

    if (!isValidLength || !hasUpperCase || !hasLowerCase || !hasSpecialChar) {
      setIsLoading(false);
      return;
    }

    if (!isConsentGiven) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await auth.registerAction(username, email, password);
      console.log("Réponse de l'inscription : ", response);
      if (response == true) {
        setFromRegistration(true);
        navigate("/connectgames");
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription", error);
      setEmailInUseError(true);
      setError("Erreur lors de l'inscription. Veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };

  const openInNewWindow = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="flex min-h-screen flex-col items-start gap-y-8 self-stretch px-5 pt-[60px] min-745:max-w-[480px]">
      {isLoading && <Loading />}
      {error && <div className="h1-mobile">{error}</div>}
      <div className="flex flex-col items-center justify-center gap-y-[10px] self-stretch">
        <h1 className="h1-mobile">Inscription</h1>
        <p className="paragraph-m-400 self-stretch text-center">
          Accède en exclusivité à TalkPlay pour interagir avec toute la
          communauté gamer
        </p>
      </div>
      <StepCard currentStep={1} />
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex flex-col items-start gap-y-2 self-stretch">
          <p className="nav-menu-700">Email</p>
          <SimpleInput
            placeholder="Ton email"
            iconType="check"
            value={email}
            onChange={handleEmailChange}
            name="email"
            isCorrect={isEmailValid}
            hasError={showEmailError}
            errorText="Format d'email incorrect"
          />
          {emailInUseError && (
            <p className="label-s-400 flex items-center gap-x-2 pl-4 text-red opacity-90">
              <InfoIcon size={18} />
              L'adresse mail est déjà utilisée,{" "}
              <span
                className="cursor-pointer underline opacity-100"
                onClick={() => navigate("/signin")}
              >
                connectez-vous !
              </span>
            </p>
          )}
        </div>
        <div className="flex flex-col items-start gap-y-2 self-stretch">
          <p className="nav-menu-700">Pseudo unique</p>
          <SimpleInput
            placeholder="Ton pseudo"
            iconType="check"
            value={username}
            onChange={handleUsernameChange}
            isCorrect={isUsernameAvailable}
            name="username"
            maxLength={32}
            helper={true}
            helperText={`${charactersLeft} caractères restants`}
          />
        </div>
        <div className="flex flex-col items-start gap-y-2 self-stretch">
          <p className="nav-menu-700">Mot de passe</p>
          <SimpleInput
            placeholder="Ton mot de passe"
            iconType="eyes"
            value={password}
            onChange={handlePasswordChange}
            name="password"
          />
          <div className="flex items-center gap-x-2">
            {isValidLength ? <CorrectCheck /> : <FalseCheck />}
            <p className="label-s-400">8 caractères minimum</p>
          </div>
          <div className="flex items-center gap-x-2">
            {hasUpperCase ? <CorrectCheck /> : <FalseCheck />}
            <p className="label-s-400">1 majuscule</p>
          </div>
          <div className="flex items-center gap-x-2">
            {hasLowerCase ? <CorrectCheck /> : <FalseCheck />}
            <p className="label-s-400">1 minuscule</p>
          </div>
          <div className="flex items-center gap-x-2">
            {hasSpecialChar ? <CorrectCheck /> : <FalseCheck />}
            <p className="label-s-400">1 caractère spécial</p>
          </div>
        </div>
        <div className="flex items-start gap-x-2">
          <CustomCheckbox
            checked={isConsentGiven}
            onChange={handleConsentChange}
          />
          <p className="label-s-400 mt-[-3px] flex-1">
            J’accepte les{" "}
            <span
              className="cursor-pointer underline"
              onClick={() => openInNewWindow("/legal/terms")}
            >
              conditions générales d’utilisation
            </span>{" "}
            de TalkPlay et l’utilisation de mes données comme décrit dans la{" "}
            <span
              className="cursor-pointer underline"
              onClick={() => openInNewWindow("/legal/privacy")}
            >
              politique de confidentialité.
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col items-end self-stretch">
        <div className="w-1/2">
          <ButtonSimple
            label="Suivant"
            plusFonction={false}
            onClick={handleRegister}
            showChevron={true}
            chevronDirection="right"
          />
        </div>
      </div>
    </div>
  );
};
