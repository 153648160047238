import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  PagePrincipale,
  DiscussionUser,
  SettingDiscussion,
  GroupDiscussion,
  SettingsGroup,
  CreateDiscussion,
  CreateGroup,
  CreateGroupChooseParticipant,
  GroupParticipant,
  Silence,
} from "..";
import { BackButton, ButtonSimple } from "../../components";
import { handleGroupCreation } from "../../utils/createGroupUtil";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useRef } from "react";

export const PagesContainer: React.FC = () => {
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const location = useLocation();
  const isCreateGroupParticipantsPath =
    location.pathname === "/messages/creategroup/participants";
  const hasRedirected = useRef(false);

  const clearLocalStorage = () => {
    localStorage.removeItem("coverImage");
    localStorage.removeItem("groupName");
    localStorage.removeItem("description");
    localStorage.removeItem("participants");
    localStorage.removeItem("selectedGame");
  };

  const handleCreateGroupClick = async () => {
    const groupName = localStorage.getItem("groupName") || "";
    const participants = JSON.parse(
      localStorage.getItem("participants") || "[]",
    );
    const description = localStorage.getItem("description") || "";
    const coverImage = localStorage.getItem("coverImage") || undefined;
    const selectedGame = JSON.parse(
      localStorage.getItem("selectedGame") || "null",
    );

    await handleGroupCreation(
      groupName,
      participants,
      description,
      coverImage,
      selectedGame,
      clearLocalStorage,
      navigate,
    );
  };

  useEffect(() => {
    if (location.pathname === "/messages/" && !hasRedirected.current) {
      const firstDiscussionId = "1";
      const firstGroupId = "1";
      if (firstDiscussionId) {
        navigate(`/messages/user/${firstDiscussionId}`);
      } else if (firstGroupId) {
        navigate(`/messages/group/${firstGroupId}`);
      }
      hasRedirected.current = true;
    }
  }, [location.pathname, navigate]);

  return (
    <div
      className={`flex w-full ${
        isWideScreen
          ? "min-h-[calc(100vh-58px)] flex-row"
          : "min-h-screen flex-col"
      } overflow-hidden`}
    >
      <ToastContainer position="top-center" />
      {!isCreateGroupParticipantsPath && (
        <PagePrincipale>
          {isWideScreen && (
            <div className="flex min-h-[calc(100vh-58px)] w-full overflow-hidden">
              <div className="w-full overflow-y-auto">
                <Routes>
                  <Route path="/user/:id" element={<DiscussionUser />} />
                  <Route path="/group/:id" element={<GroupDiscussion />} />
                  <Route
                    path="/user/:id/settings"
                    element={<SettingDiscussion />}
                  />
                  <Route
                    path="/group/:id/settings"
                    element={<SettingsGroup />}
                  />
                  <Route
                    path="/group/:id/modifygroup"
                    element={<CreateGroup edit={true} />}
                  />
                  <Route
                    path="/group/:id/participants"
                    element={<GroupParticipant />}
                  />
                  <Route path="/newmessage" element={<CreateDiscussion />} />
                  <Route path="/user/:id/silent" element={<Silence />} />
                  <Route path="/group/:id/silent" element={<Silence />} />
                </Routes>
              </div>
            </div>
          )}
        </PagePrincipale>
      )}
      {isCreateGroupParticipantsPath && isWideScreen && (
        <div className="flex w-full flex-col gap-y-6 pb-8 pl-8 pt-6">
          <BackButton />
          <div className="flex w-full flex-col gap-y-8">
            <div className="flex items-center justify-between">
              <p className="h1-mobile">Nouveau groupe</p>
              <ButtonSimple
                label="Créer le groupe"
                onClick={handleCreateGroupClick}
                showChevron={true}
                chevronDirection="right"
              />
            </div>
            <div className="flex max-h-[calc(100vh-200px)] w-full flex-row overflow-auto">
              <div className="flex flex-1">
                <div className="max-w-[480px] flex-1">
                  <CreateGroup edit={false} />
                </div>
                <div className="min-w-[460px] flex-1">
                  <CreateGroupChooseParticipant />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isWideScreen && (
        <Routes>
          <Route path="/user/:id" element={<DiscussionUser />} />
          <Route path="/group/:id" element={<GroupDiscussion />} />
          <Route path="/user/:id/settings" element={<SettingDiscussion />} />
          <Route path="/group/:id/settings" element={<SettingsGroup />} />
          <Route
            path="/group/:id/modifygroup"
            element={<CreateGroup edit={true} />}
          />
          <Route
            path="/group/:id/participants"
            element={<GroupParticipant />}
          />
          <Route path="/newmessage" element={<CreateDiscussion />} />
          <Route
            path="/creategroup/participants"
            element={<CreateGroupChooseParticipant />}
          />
          <Route path="/creategroup" element={<CreateGroup edit={false} />} />
          <Route path="/user/:id/silent" element={<Silence />} />
          <Route path="/group/:id/silent" element={<Silence />} />
        </Routes>
      )}
    </div>
  );
};
