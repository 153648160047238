import { useState } from "react";
import {
  ChevronIcon,
  BackButton,
  BackAndButtonNext,
  SimpleInput,
  ParticipantGroup,
} from "../..";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";

type NewMessageWidgetProps = {
  onBackButtonClick: () => void;
};

type Player = {
  id: number;
  name: string;
  image: string;
};

// const fakePlayers: Player[] = [
//   { id: 1, name: "Legolas", image: Legolas },
//   { id: 2, name: "Gandalf", image: Gimli },
//   { id: 3, name: "Frodo", image: Boromir },
//   { id: 4, name: "Aragorn", image: Aragorn },
// ];
export const NewMessageWidget: React.FC<NewMessageWidgetProps> = ({
  onBackButtonClick,
}) => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState<number | null>(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const navigateToCreateMessage = () => {
    navigate(`/message/user/`);
  };

  const togglePlayerSelection = (playerId: number) => {
    setSelectedPlayer(playerId === selectedPlayer ? null : playerId);
  };

  return (
    <div className="pointer-events-none fixed bottom-0 left-0 z-[200] flex w-full items-end justify-end pr-3 max-mobile:hidden">
      <div
        className="pointer-events-auto relative flex max-h-[calc(100vh-150px)] w-[400px] flex-col gap-y-[10px] rounded-lg shadow-[0_2px_34px_rgba(0,0,0,0.45)] backdrop-blur-[6.5px]"
        style={{
          background:
            "linear-gradient(293deg, rgba(40, 12, 49, 0.95) 0.12%, rgba(151, 46, 182, 0.95) 117.69%)",
        }}
      >
        <header className="flex w-full flex-col gap-y-[10px] px-5 pt-3">
          <div className="flex w-full items-center justify-between">
            <BackButton onClick={onBackButtonClick} shadow={false} />
            <ChevronIcon
              orientation={isVisible ? "up" : "down"}
              onClick={toggleVisibility}
              className="cursor-pointer"
            />
          </div>
        </header>
        <CSSTransition
          in={isVisible}
          timeout={300}
          classNames="slide"
          unmountOnExit
        >
          <div>
            <div className="flex w-full flex-col gap-y-[10px] px-5 pb-3">
              <p className="h1-mobile">Nouveau message</p>
              <SimpleInput iconType="none" />
            </div>
            <aside className="styled-scrollbars flex max-h-[calc(100vh-400px)] flex-grow flex-col gap-y-2 self-stretch overflow-auto border-t-[2px] border-t-[rgba(248,239,251,0.37)] px-5 pb-20 pt-4">
              {/* {fakePlayers.map((player) => (
                <ParticipantGroup
                  key={player.name}
                  name={player.name}
                  image={player.image}
                  isSelected={selectedPlayer === player.id}
                  onToggle={() => togglePlayerSelection(player.id)}
                  showOptions={false}
                />
              ))}
              {fakePlayers.map((player) => (
                <ParticipantGroup
                  key={player.name}
                  name={player.name}
                  image={player.image}
                  isSelected={selectedPlayer === player.id}
                  onToggle={() => togglePlayerSelection(player.id)}
                  showOptions={false}
                />
              ))} */}
            </aside>
            <BackAndButtonNext
              widget={true}
              back={true}
              navigateTo={navigateToCreateMessage}
              label="Lancer la discussion"
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
