import { useNavigate } from "react-router-dom";
import { GamesIcons } from "..";

type RecentlyPlayedCardProps = {
  logo: string;
  game: string;
  pseudo: string;
  playtime: string;
  lastPlayDate: string;
  id?: string;
  isClickable?: boolean; // Ajoutez cette ligne
};

export const RecentlyPlayedCard: React.FC<RecentlyPlayedCardProps> = ({
  game,
  pseudo,
  playtime,
  lastPlayDate,
  logo,
  id,
  isClickable = true, // Valeur par défaut
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isClickable) return;
    if (id !== undefined) {
      navigate(`/game/${id}/${game.replace(/\s+/g, "_")}`);
    }
  };

  return (
    <div
      className={`flex cursor-pointer items-center justify-center gap-x-4 self-stretch rounded-lg p-2 ${isClickable ? "cursor-pointer" : ""}`}
      style={{
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(99deg, #280C31 -3.96%, rgba(98, 79, 117, 0.00) 104.82%)",
      }}
      onClick={handleClick}
    >
      <GamesIcons logo={logo} className="game-thumbnail-l" />
      <div className="flex flex-1 flex-shrink-0 flex-col items-center">
        <div className="flex flex-col self-stretch">
          <p className="paragraph-s-700-space">{game}</p>
          {pseudo && <p className="label-m-400">{pseudo}</p>}
        </div>
        {playtime && (
          <div className="flex flex-col items-end justify-end self-stretch">
            <p className="label-s-400">{playtime}</p>
            <p className="label-s-400">{lastPlayDate}</p>
          </div>
        )}
      </div>
    </div>
  );
};
