import { useEffect, useRef, useState } from "react";
import { CloseFillIcon, SettingFrame } from "../";
import { useNavigate } from "react-router-dom";

type OptionsParticipantProps = {
  username: string;
  onClick?: () => void;
};

export const OptionsParticipant: React.FC<OptionsParticipantProps> = ({
  username,
  onClick,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  const closeModal = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const modalContent = document.getElementById("modal-content");
      if (modalContent && !e.composedPath().includes(modalContent)) {
        closeModal();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isVisible]);

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigatePlayerPage = () => {
    navigate(`/user/${encodeUsername(username)}`);
  };

  const navigateReportPage = () => {
    navigate(`/user/${encodeUsername(username)}/report`);
  };

  return isVisible ? (
    <div
      id="modal-content"
      ref={overlayRef}
      className="z-[100] flex w-[60svw] flex-col justify-center gap-y-4 rounded-lg border-2 border-transparent px-6 py-8 shadow-[0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px] min-745:w-[300px] min-745:pt-4"
      style={{
        background:
          "linear-gradient(293deg, #402C48 0.12%, #9552A9 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #402C48 40%) border-box",
      }}
    >
      <div className="flex items-center justify-between gap-x-2 self-stretch border-b border-b-[rgba(248,239,251,0.50)] pb-3">
        <p className="h2-mobile self-stretch">{username}</p>
        <CloseFillIcon size={12} onClick={closeModal} />
      </div>
      <div className="flex flex-col gap-y-6">
        <div
          className="flex cursor-pointer justify-between self-stretch"
          onClick={navigatePlayerPage}
        >
          <div className="flex gap-x-2 self-stretch">
            <SettingFrame settingIconName="User" />
            <p className="nav-menu-500">Profil</p>
          </div>
        </div>
        {/* <div className="flex cursor-pointer items-center justify-between self-stretch">
          <div className="flex gap-x-2 self-stretch">
            <SettingFrame settingIconName="NewMessage" />
            <p className="nav-menu-500">Ecrire un message au joueur</p>
          </div>
        </div>
        <div className="flex cursor-pointer items-center justify-between self-stretch">
          <div className="flex gap-x-2 self-stretch">
            <SettingFrame settingIconName="Remove" />
            <p className="nav-menu-500">Retirer le joueur du groupe</p>
          </div>
        </div> */}
        <div
          className="flex cursor-pointer items-center justify-between self-stretch"
          onClick={navigateReportPage}
        >
          <div className="flex gap-x-2 self-stretch">
            <SettingFrame settingIconName="Report" />
            <p className="nav-menu-500">Signaler le joueur</p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
