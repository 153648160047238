import React, { useRef, useEffect } from "react";
import { ChevronIcon, useOverlay } from "../..";

export const ImageOverlay: React.FC = () => {
  const {
    showModal,
    currentImage,
    closeModal,
    nextImage,
    prevImage,
    images,
    currentImageId,
    setCurrentImage,
    setCurrentImageId,
  } = useOverlay();
  const thumbnailRefs = useRef<(HTMLImageElement | null)[]>([]);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showModal && thumbnailContainerRef.current) {
      const container = thumbnailContainerRef.current;
      const thumbnailIndex = images.findIndex(
        (image: { id: any }) => image.id === currentImageId,
      );
      const thumbnail = thumbnailRefs.current[thumbnailIndex];
      if (thumbnail) {
        const offset =
          thumbnail.offsetLeft -
          container.offsetWidth / 2 +
          thumbnail.clientWidth / 2;
        container.scrollLeft = offset;
      }
    }
  }, [showModal, currentImageId, images]);

  if (!showModal) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex h-screen w-screen flex-col items-center justify-between bg-black bg-opacity-75"
      onClick={closeModal}
    >
      <div
        className="mt-[30vh] flex w-full items-center justify-between px-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="flex h-full items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            prevImage();
          }}
        >
          <ChevronIcon
            orientation="left"
            className="mr-2 h-8 w-8 cursor-pointer"
          />
        </div>
        <div className="flex flex-grow items-center justify-center">
          <img
            src={currentImage}
            alt="Enlarged"
            className="max-h-full max-w-full border border-white"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div
          className="flex h-full items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            nextImage();
          }}
        >
          <ChevronIcon
            orientation="right"
            className="ml-2 h-8 w-8 cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full bg-black bg-opacity-75 p-2">
        <div
          ref={thumbnailContainerRef}
          className="styled-scrollbars flex overflow-x-auto"
        >
          {images.map((image, index) => (
            <img
              key={image.id}
              ref={(el) => (thumbnailRefs.current[index] = el)}
              src={image.src}
              alt="Thumbnail"
              className={`mx-2 h-20 w-20 cursor-pointer ${
                image.id === currentImageId ? "border-2 border-white" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentImage(image.src);
                setCurrentImageId(image.id);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
