import {
  BackAndButtonNext,
  AddedGameGroup,
  ChevronIcon,
  CloseFillIcon,
  AddFillIcon,
  SecondaryButton,
  Loading,
} from "../../../components";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Game } from "../../../types";
import { handleGroupCreation } from "../../../utils/createGroupUtil";
import { createBrowserHistory } from "history";
import axios, { AxiosResponse } from "axios";
import { createGroupUrl } from "../../../constants/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Player = {
  id: string;
  name: string;
  image: string;
};

type CreateGroupProps = {
  edit?: boolean;
};

export const CreateGroup: React.FC<CreateGroupProps> = ({ edit }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupId } = useParams();
  const [avatarImage, setAvatarImage] = useState<string | undefined>(
    () => localStorage.getItem("avatarImage") || undefined,
  );
  const [coverImage, setCoverImage] = useState<string | undefined>(
    () => localStorage.getItem("coverImage") || undefined,
  );
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [groupName, setGroupName] = useState(
    () => localStorage.getItem("groupName") || "",
  );
  const [description, setDescription] = useState(
    () => localStorage.getItem("description") || "",
  );
  const [participants, setParticipants] = useState<Player[]>(() => {
    const storedParticipants = localStorage.getItem("participants");
    return storedParticipants
      ? JSON.parse(storedParticipants)
      : location.state?.selectedPlayers || [];
  });
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(
    location.state?.selectedGame ||
      JSON.parse(localStorage.getItem("selectedGame") || "null"),
  );
  const [avatarError, setAvatarError] = useState<string | null>(null);
  const [coverError, setCoverError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isWideScreen = useMediaQuery({ minWidth: 745 });

  useEffect(() => {
    localStorage.setItem("avatarImage", avatarImage || "");
    localStorage.setItem("coverImage", coverImage || "");
    localStorage.setItem("groupName", groupName);
    localStorage.setItem("description", description);
    localStorage.setItem("participants", JSON.stringify(participants));
    localStorage.setItem("selectedGame", JSON.stringify(selectedGame));
  }, [
    avatarImage,
    coverImage,
    groupName,
    description,
    participants,
    selectedGame,
  ]);

  useEffect(() => {
    const history = createBrowserHistory();

    const unlisten = history.listen(({ location }) => {
      if (
        ![
          "/messages/creategroup",
          "/messages/creategroup/participants",
          "/choosegame",
        ].includes(location.pathname)
      ) {
        clearLocalStorage();
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const navigateToParticipantsPage = () => {
    if (edit) {
      navigate(`/message/group/${groupId}/participants`, {
        state: { selectedPlayers: participants },
      });
    } else {
      navigate(`/messages/creategroup/participants`, {
        state: {
          selectedPlayers: participants,
          avatarImage,
          coverImage,
          groupName,
          description,
          selectedGame,
        },
      });
    }
  };

  const navigateToChooseGamePage = () => {
    navigate("/choosegame", {
      state: {
        avatarImage,
        coverImage,
        groupName,
        description,
        selectedGame,
        participants,
      },
    });
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("avatarImage");
    localStorage.removeItem("coverImage");
    localStorage.removeItem("groupName");
    localStorage.removeItem("description");
    localStorage.removeItem("participants");
    localStorage.removeItem("selectedGame");
  };

  const navigateToGroupeDiscussion = async () => {
    await handleGroupCreation(
      groupName,
      participants.map((p) => ({ ...p, id: p.id })),
      description,
      coverImage,
      selectedGame,
      clearLocalStorage,
      navigate,
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "avatar" | "cover",
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      if (type === "avatar") {
        setAvatarImage(url);
        setAvatarFile(file);
      } else {
        setCoverImage(url);
        setCoverFile(file);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      setAvatarError("Auth token not found");
      toast.error("Auth token not found");
      setIsLoading(false);
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("groupName", groupName);
    formDataToSubmit.append("description", description);
    if (avatarFile) {
      formDataToSubmit.append("avatar", avatarFile);
    }
    if (coverFile) {
      formDataToSubmit.append("cover", coverFile);
    }

    try {
      const response: AxiosResponse<any> = await axios.post(
        createGroupUrl,
        formDataToSubmit,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      clearLocalStorage();
      navigateToGroupeDiscussion();
      toast.success("Groupe créé avec succès !");
    } catch (error) {
      setAvatarError("Erreur lors de la création du groupe.");
      toast.error("Erreur lors de la création du groupe.");
      console.error("Error creating group:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const background = isWideScreen
    ? ""
    : "linear-gradient(0deg, #280C31 61.19%, #140029 100%)";

  return (
    <div
      className={`z-20 flex h-[calc(100vh-250px)] w-full flex-col overflow-hidden max-mobile:min-h-[100svh] max-mobile:pb-20 ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: background,
      }}
    >
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="flex flex-grow flex-col gap-y-10 overflow-auto px-5 pb-10 max-mobile:pt-[60px]">
        {edit ? (
          <div className="flex h-[36px] items-center justify-between self-stretch min-745:hidden">
            <ChevronIcon
              orientation="left"
              className="h-6 w-6 cursor-pointer"
              onClick={handleBack}
            />
            <p className="h3-mobile">Modifier le groupe</p>
            <div className="h-6 w-6">
              <CloseFillIcon onClick={navigateToGroupeDiscussion} />
            </div>
          </div>
        ) : (
          <p className="h1-mobile min-745:hidden">Nouveau groupe</p>
        )}
        <div className="flex flex-col items-start gap-y-8 self-stretch">
          <div className="flex flex-col items-start gap-y-4 self-stretch">
            <div className="flex items-center justify-between self-stretch">
              <p className="nav-menu-700">Jeu associé</p>
              <p className="label-s-400 opacity-70">FACULTATIF</p>
            </div>
            {selectedGame ? (
              <div onClick={navigateToChooseGamePage}>
                <AddedGameGroup
                  logo={selectedGame.logo}
                  gamename={selectedGame.name}
                />
              </div>
            ) : (
              <div className="w-auto">
                <SecondaryButton
                  label="Sélectionner un jeu"
                  onClick={navigateToChooseGamePage}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-4 self-stretch">
            <p className="nav-menu-700">Nom du groupe</p>
            <div className="flex h-12 items-center gap-x-4 rounded-full bg-[rgba(245,235,255,0.10)] px-6 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Saisi le nom de ton groupe"
                className="label-m-400 border-none bg-transparent opacity-50 focus:opacity-100 focus:outline-none"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-y-4 self-stretch">
            <div className="flex w-full items-center justify-between">
              <p className="nav-menu-700">Avatar du groupe </p>
              <p className="label-s-400 opacity-70">FACULTATIF</p>
            </div>
            <label className="block h-[122px] w-[122px] cursor-pointer rounded-full border-[2.5px] border-dashed border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] p-4 text-center shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
              <div className="flex h-full flex-col items-center justify-center gap-y-1">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, "avatar")}
                />
                {avatarImage ? (
                  <img
                    src={avatarImage}
                    alt="Avatar"
                    className="max-h-full max-w-full rounded-full"
                  />
                ) : (
                  <>
                    <AddFillIcon />
                    <p className="paragraph-s-500 text-center">
                      Ajouter une image
                    </p>
                  </>
                )}
              </div>
            </label>
            {avatarError && (
              <p className="label-s-400 mt-2 text-red">{avatarError}</p>
            )}
          </div>
          <div className="flex flex-col items-start gap-y-4 self-stretch">
            <div className="flex w-full items-center justify-between">
              <p className="nav-menu-700">Image de couverture </p>
              <p className="label-s-400 opacity-70">FACULTATIF</p>
            </div>
            <label className="block h-32 w-full cursor-pointer rounded-[20px] border-[2.5px] border-dashed border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] p-4 text-center shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
              <div className="flex h-full flex-col items-center justify-center gap-y-1">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, "cover")}
                />
                {coverImage ? (
                  <img
                    src={coverImage}
                    alt="Couverture"
                    className="max-h-full max-w-full rounded-lg"
                  />
                ) : (
                  <>
                    <AddFillIcon />
                    <p className="paragraph-s-500">Ajouter une image</p>
                  </>
                )}
              </div>
            </label>
            {coverError && (
              <p className="label-s-400 mt-2 text-red">{coverError}</p>
            )}
          </div>
          <div className="flex flex-col items-start gap-y-4 self-stretch min-745:hidden">
            <p className="nav-menu-700">Participants</p>
            <div className="flex items-center justify-between self-stretch">
              <div
                className="flex items-center pl-[15px]"
                onClick={navigateToParticipantsPage}
              >
                {participants.slice(0, 4).map((participant, index) => (
                  <div key={index} className="avatar-s -ml-[15px]">
                    <img
                      key={index}
                      src={participant.image}
                      alt={participant.name}
                      className="image-avatar border border-[#C6B6D5]"
                    />
                  </div>
                ))}
                {participants.length > 4 && (
                  <div className="label-s-600 avatar-s -ml-[15px] flex h-[38px] w-[38px] items-center justify-center border border-[#C6B6D5] bg-primary-500">
                    +{participants.length - 4}
                  </div>
                )}
              </div>
              <button className="link-s" onClick={navigateToParticipantsPage}>
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
      <BackAndButtonNext
        navigateTo={handleSubmit}
        label="Créer le groupe"
        back={true}
        showSelectedCountText={false}
      />
    </div>
  );
};
