type PastilleDiscussionProps = {
  messageCount: number;
};

export const PastilleDiscussion: React.FC<PastilleDiscussionProps> = ({
  messageCount,
}) => {
  return (
    <div
      className={`flex h-[25px] w-[25px] flex-col items-center justify-center rounded-full bg-red shadow-[inset_-1px_1px_2px_rgba(0,0,0,0.25),_0_1px_4px_rgba(0,0,0,0.45)]`}
    >
      <p className="label-s-600">{messageCount}</p>
    </div>
  );
};
