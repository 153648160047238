import platform from "platform";

export const LinkIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.93079 5.56929C10.5645 7.20469 10.5421 9.82665 8.94064 11.437C8.93763 11.4403 8.93407 11.4438 8.93079 11.4471L7.0933 13.2846C5.47264 14.9052 2.83591 14.905 1.2155 13.2846C-0.405165 11.6642 -0.405165 9.02717 1.2155 7.40679L2.23011 6.39217C2.49917 6.12311 2.96254 6.30194 2.97643 6.68218C2.99415 7.16676 3.08105 7.65362 3.24139 8.12376C3.2957 8.28296 3.25689 8.45905 3.13795 8.578L2.7801 8.93584C2.01377 9.70218 1.98973 10.95 2.74852 11.7238C3.5148 12.5053 4.77431 12.5099 5.54646 11.7378L7.38396 9.90053C8.15481 9.12968 8.15158 7.88374 7.38396 7.11612C7.28276 7.01511 7.18082 6.93664 7.1012 6.88181C7.04487 6.84313 6.99836 6.79182 6.96538 6.73198C6.9324 6.67213 6.91386 6.6054 6.91124 6.53712C6.90041 6.24818 7.00279 5.95043 7.23111 5.72211L7.8068 5.14639C7.95777 4.99543 8.19459 4.97689 8.36965 5.09906C8.57012 5.23905 8.75789 5.3964 8.93079 5.56929ZM12.7845 1.71541C11.1641 0.0949715 8.52734 0.0947527 6.90668 1.71541L5.06918 3.55291C5.0659 3.55619 5.06234 3.55975 5.05934 3.56303C3.45792 5.17335 3.43548 7.79531 5.06918 9.43071C5.24207 9.6036 5.42983 9.76094 5.6303 9.90091C5.80535 10.0231 6.04221 10.0045 6.19314 9.85358L6.76884 9.27786C6.99716 9.04954 7.09953 8.75179 7.0887 8.46285C7.08609 8.39457 7.06755 8.32785 7.03457 8.268C7.00159 8.20815 6.95508 8.15684 6.89875 8.11816C6.81912 8.06333 6.71718 7.98486 6.61599 7.88385C5.84837 7.11623 5.84514 5.87029 6.61599 5.09944L8.45348 3.26222C9.22564 2.49006 10.4851 2.49471 11.2514 3.27616C12.0102 4.04999 11.9862 5.29779 11.2198 6.06413L10.862 6.42198C10.7431 6.54092 10.7043 6.71701 10.7586 6.87621C10.9189 7.34636 11.0058 7.83321 11.0235 8.31779C11.0374 8.69804 11.5008 8.87686 11.7698 8.6078L12.7844 7.59319C14.4051 5.97283 14.4051 3.3358 12.7845 1.71541Z"
      fill="#F8EFFB"
    />
  </svg>
);

export const LinkOutsideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M12.6562 9.375H11.7188C11.5944 9.375 11.4752 9.42439 11.3873 9.51229C11.2994 9.6002 11.25 9.71943 11.25 9.84375V13.125H1.875V3.75H6.09375C6.21807 3.75 6.3373 3.70061 6.42521 3.61271C6.51311 3.5248 6.5625 3.40557 6.5625 3.28125V2.34375C6.5625 2.21943 6.51311 2.1002 6.42521 2.01229C6.3373 1.92439 6.21807 1.875 6.09375 1.875H1.40625C1.03329 1.875 0.675604 2.02316 0.411881 2.28688C0.148158 2.5506 0 2.90829 0 3.28125L0 13.5938C0 13.9667 0.148158 14.3244 0.411881 14.5881C0.675604 14.8518 1.03329 15 1.40625 15H11.7188C12.0917 15 12.4494 14.8518 12.7131 14.5881C12.9768 14.3244 13.125 13.9667 13.125 13.5938V9.84375C13.125 9.71943 13.0756 9.6002 12.9877 9.51229C12.8998 9.42439 12.7806 9.375 12.6562 9.375ZM14.2969 0H10.5469C9.9208 0 9.60791 0.759082 10.0488 1.20117L11.0956 2.24795L3.95508 9.38584C3.88953 9.45116 3.83752 9.52878 3.80203 9.61425C3.76654 9.69971 3.74827 9.79135 3.74827 9.88389C3.74827 9.97643 3.76654 10.0681 3.80203 10.1535C3.83752 10.239 3.88953 10.3166 3.95508 10.3819L4.61924 11.0449C4.68456 11.1105 4.76218 11.1625 4.84765 11.198C4.93311 11.2335 5.02474 11.2517 5.11729 11.2517C5.20983 11.2517 5.30146 11.2335 5.38692 11.198C5.47239 11.1625 5.55001 11.1105 5.61533 11.0449L12.7523 3.90586L13.7988 4.95117C14.2383 5.39062 15 5.08301 15 4.45312V0.703125C15 0.516645 14.9259 0.337802 14.7941 0.205941C14.6622 0.074079 14.4834 0 14.2969 0Z"
      fill="white"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g clipPath="url(#clip0_1071_4905)">
      <path
        d="M7.25 0.5H9.75C10.1656 0.5 10.5 0.834375 10.5 1.25V6.5H13.2406C13.7969 6.5 14.075 7.17188 13.6812 7.56563L8.92813 12.3219C8.69375 12.5562 8.30937 12.5562 8.075 12.3219L3.31562 7.56563C2.92187 7.17188 3.2 6.5 3.75625 6.5H6.5V1.25C6.5 0.834375 6.83437 0.5 7.25 0.5ZM16.5 12.25V15.75C16.5 16.1656 16.1656 16.5 15.75 16.5H1.25C0.834375 16.5 0.5 16.1656 0.5 15.75V12.25C0.5 11.8344 0.834375 11.5 1.25 11.5H5.83437L7.36562 13.0312C7.99375 13.6594 9.00625 13.6594 9.63437 13.0312L11.1656 11.5H15.75C16.1656 11.5 16.5 11.8344 16.5 12.25ZM12.625 15C12.625 14.6562 12.3438 14.375 12 14.375C11.6562 14.375 11.375 14.6562 11.375 15C11.375 15.3438 11.6562 15.625 12 15.625C12.3438 15.625 12.625 15.3438 12.625 15ZM14.625 15C14.625 14.6562 14.3438 14.375 14 14.375C13.6562 14.375 13.375 14.6562 13.375 15C13.375 15.3438 13.6562 15.625 14 15.625C14.3438 15.625 14.625 15.3438 14.625 15Z"
        fill="#F8EFFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_4905">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

type DownloadFrameProps = {
  iosLink?: string | null;
  androidLink?: string | null;
  pcLink?: string;
};

export const DownloadFrame: React.FC<DownloadFrameProps> = ({
  iosLink,
  androidLink,
  pcLink,
}) => {
  const getDownloadLink = () => {
    const os = platform.os?.family;
    if (os === "iOS" && iosLink) return iosLink;
    if (os === "Android" && androidLink) return androidLink;
    if (os === "Windows" && "Linux" && "Ubuntu" && pcLink) return pcLink;
    return pcLink;
  };

  const downloadLink = getDownloadLink();

  return (
    <a
      href={downloadLink}
      className="flex h-[33px] w-[33px] cursor-pointer items-center justify-center rounded-lg border-[0.3px] border-solid border-white bg-[rgba(255,255,255,0.25)] px-[6px] backdrop-blur-[2px]"
      target="_blank"
      rel="noopener noreferrer"
    >
      <DownloadIcon />
    </a>
  );
};
