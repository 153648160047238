import React, { ReactNode } from "react";

interface SubtitleNavigationButtonProps {
  isActive: boolean;
  title: ReactNode;
  onClick: () => void;
  hasNewEvents?: boolean;
  secondary?: boolean;
}

export const SubtitleNavigationButton: React.FC<
  SubtitleNavigationButtonProps
> = ({ isActive, title, onClick, hasNewEvents, secondary }) => {
  const buttonClassName = `relative inline-flex cursor-pointer items-center justify-center border px-4 py-2 ${
    secondary
      ? "border-[0.5px] border-[rgba(248,239,251,0.60)] h-[33px] rounded-full"
      : "border-transparent rounded-xl"
  } opacity-${isActive ? 100 : 70} ${
    isActive
      ? secondary
        ? "secondaryButton-isActive"
        : "button-isActive"
      : secondary
        ? "secondaryButton-notActive"
        : "button-notActive"
  }`;

  return (
    <>
      <style>
        {`.button-isActive {
            background: linear-gradient(135deg, #190430ed, rgba(95, 69, 122, 0.3)) padding-box,
                        linear-gradient(to right, #5f457a, #280c31) border-box;
        } 
        .button-notActive {
            background: linear-gradient(90deg, #190430ed, #190430ed) padding-box,
                        linear-gradient(to top right, #280c31, #5f457a) border-box;
        }        
        .button-notActive:hover {
            background: rgba(245,235,255,0.15);
            opacity: 100;
        }
        .secondaryButton-notActive:hover {
            background: rgba(245,235,255,0.15);
            opacity: 100;
        }
        .secondaryButton-isActive { 
            background: linear-gradient(112deg, #493557 0.46%, #574368 100%);
        } 
        .secondaryButton-notActive {
            background: linear-gradient(90deg, #190430ed, #190430ed) padding-box,
                        linear-gradient(to top right, #280c31, #5f457a) border-box;
        }
        `}
      </style>
      <div className={buttonClassName} onClick={onClick}>
        {hasNewEvents && (
          <div className="absolute right-[-5px] top-0 z-10 h-4 w-4 -translate-y-1/2 transform rounded-full bg-[#FF0000]"></div>
        )}
        <span className={`${isActive ? "nav-menu-700" : "nav-menu-500"}`}>
          {title}
        </span>
      </div>
    </>
  );
};
