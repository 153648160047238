import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useVideoContext } from "../../../contexts/VideoContext";
import { Gameplay } from "../../../types";
import { AvatarIcon } from "../..";
import { formatDate } from "../../../utils/dateUtils";

interface GameplayPreviewCardProps {
  gameplay: Gameplay;
  bottomBorder?: boolean;
  autoPlay?: boolean;
}

const formatDateMonthDay = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };
  return new Intl.DateTimeFormat("fr-FR", options).format(date);
};

export const GameplayPreviewCard: React.FC<GameplayPreviewCardProps> = ({
  gameplay,
  bottomBorder = true,
  autoPlay = true,
}) => {
  const navigate = useNavigate();
  const { activeVideoId, setActiveVideoId } = useVideoContext();
  const [videoDuration, setVideoDuration] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView && autoPlay) {
      setActiveVideoId(gameplay.id);
    }
  }, [inView, gameplay.id, setActiveVideoId, autoPlay]);

  useEffect(() => {
    if (activeVideoId === gameplay.id && autoPlay) {
      const playPromise = videoRef.current?.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Failed to play video:", error);
          });
      }
    } else if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [activeVideoId, gameplay.id, autoPlay]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        const duration = videoRef.current.duration;
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        setVideoDuration(`${minutes}:${seconds.toString().padStart(2, "0")}`);
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata,
        );
      }
    };
  }, []);

  const navigateToGameplay = () => {
    navigate(`/gameplay/${gameplay.id}`);
  };

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to play video on hover:", error);
      });
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div
      className={`flex w-full max-w-[300px] flex-col gap-y-1 rounded-2xl${
        bottomBorder && "border-b border-b-[rgba(245,235,255,0.10)]"
      } cursor-pointer rounded`}
      onClick={navigateToGameplay}
      ref={ref}
    >
      <div
        className="relative flex w-full justify-center rounded"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <video
          src={`${gameplay.file}#t=0.001`}
          className="w-full transform cursor-pointer rounded shadow-[4px_4px_4px_rgba(0,0,0,0.30)] transition-transform hover:scale-[102%]"
          ref={videoRef}
          playsInline
          muted
        />
        {videoDuration && (
          <div
            className="label-s-400 absolute bottom-2 right-2 rounded bg-[#581E69] bg-opacity-80 px-1 py-0.5"
            style={{
              textShadow: "2px 2px 10px rgba(0, 0, 0)",
            }}
          >
            {videoDuration}
          </div>
        )}
        <div
          className="label-s-400 absolute bottom-2 left-2 inline-flex items-center justify-center rounded bg-[#581E69] bg-opacity-80 px-1 py-0.5 shadow-[0_4px_4px_rgba(0,0,0,0.25)] backdrop-blur-[2px]"
          style={{
            textShadow: "2px 2px 10px rgba(0, 0, 0)",
          }}
        >
          {formatDateMonthDay(gameplay.created_at)}
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="relative cursor-pointer">
          <AvatarIcon
            logo={gameplay.game.logo}
            className="game-thumbnail-s border-none"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/game/${gameplay.game.id}/${gameplay.game.name}`);
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <p className="truncate-one-line h4-mobile">{gameplay.name}</p>
          {gameplay.user.pseudo ? (
            <p
              className="truncate-one-line label-s-600 whitespace-nowrap opacity-90 hover:text-[#F78888]"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/user/${gameplay.user.username}`);
              }}
            >
              {gameplay.user.pseudo}
            </p>
          ) : (
            <p
              className="truncate-one-line label-s-600 opacity-90 hover:text-[#F78888]"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/user/${gameplay.user.username}`);
              }}
            >
              @{gameplay.user.username}
            </p>
          )}

          <div className={`flex gap-x-2 self-stretch`}>
            {gameplay.game ? (
              <p
                className="truncate-one-line label-s-400 cursor-pointer self-stretch opacity-50 hover:underline"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/game/${gameplay.game.id}/${gameplay.game.name}`);
                }}
              >
                {gameplay.game.name}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
