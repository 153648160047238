type BadgeStudioProps = {
  logo: string;
  size?: string;
};

export const BadgeStudio: React.FC<BadgeStudioProps> = ({ logo, size }) => {
  return (
    <div
      className={`flex ${size ? { size } : "h-[38px]"}  items-center justify-center rounded-[24px] border border-[#000] bg-[#FFF] px-4 py-[6px]`}
    >
      <img src={logo} className="max-h-full max-w-full" />
    </div>
  );
};
