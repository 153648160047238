import { BackButton, BadgeStudio } from "../..";

interface HeaderProfileGameProps {
  name: string;
  playerCount: number;
  studioLogo?: string;
}

export const HeaderProfileGame: React.FC<HeaderProfileGameProps> = ({
  name,
  playerCount,
  studioLogo,
}) => {
  return (
    <div className="flex flex-col items-start self-stretch">
      <h1 className="h1-mobile self-stretch">{name}</h1>
      <div className="flex w-full items-center justify-between">
        <h1 className="label-m-400 h-full content-center self-stretch">
          {playerCount} joueurs sur TalkPlay
        </h1>
        {studioLogo && <BadgeStudio logo={studioLogo} />}
      </div>
      <div className="absolute left-[20px] top-[40px]">
        <BackButton />
      </div>
    </div>
  );
};
