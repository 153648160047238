import React from "react";
import { ImageTile, VideoTile } from "../..";
import { useVideoContext } from "../../../contexts/VideoContext";

interface FileProps {
  link: string;
  type: "image" | "video";
}

interface PostImageProps {
  files: FileProps[];
}

export const PostImage: React.FC<PostImageProps> = ({ files }) => {
  const { activeVideoId, setActiveVideoId } = useVideoContext();

  if (files.length === 0) return null;

  const renderMediaTile = (
    file: FileProps,
    className: string,
    isSingle = false,
    autoPlay = false,
  ) => {
    if (file.type === "video" || file.link.endsWith(".webm")) {
      return (
        <VideoTile
          link={file.link}
          className={className}
          isSingle={isSingle}
          videoId={file.link}
          activeVideoId={activeVideoId}
          setActiveVideoId={setActiveVideoId}
        />
      );
    } else {
      return (
        <ImageTile link={file.link} className={className} isSingle={isSingle} />
      );
    }
  };

  if (files.length === 1) {
    return renderMediaTile(files[0], "w-full", true, true);
  }

  if (files.length === 2) {
    return (
      <div className="mt-4 flex w-auto flex-row gap-[2px]">
        {renderMediaTile(
          files[0],
          "w-[50%] rounded-l-lg rounded-r-none",
          false,
          true,
        )}
        {renderMediaTile(files[1], "w-[50%] rounded-r-lg rounded-l-none")}
      </div>
    );
  }

  if (files.length === 3) {
    return (
      <div className="mt-4 flex max-h-[400px] w-auto flex-row gap-[2px]">
        {renderMediaTile(
          files[0],
          "w-[50%] rounded-l-lg rounded-r-none",
          false,
          true,
        )}
        <div className="flex w-[50%] flex-col gap-1">
          {renderMediaTile(
            files[1],
            "w-full rounded-tr-lg rounded-l-none rounded-br-none",
          )}
          {renderMediaTile(
            files[2],
            "w-full rounded-br-lg rounded-l-none rounded-tr-none",
          )}
        </div>
      </div>
    );
  }

  if (files.length === 4) {
    return (
      <div className="mt-4 flex w-auto flex-wrap gap-[2px]">
        {renderMediaTile(
          files[0],
          "w-[49.25%] rounded-tl-lg rounded-r-none rounded-bl-none",
          false,
          true,
        )}
        {renderMediaTile(
          files[1],
          "w-[49.25%] rounded-tr-lg rounded-l-none rounded-br-none",
        )}
        {renderMediaTile(
          files[2],
          "w-[49.25%] rounded-bl-lg rounded-r-none rounded-tl-none",
        )}
        {renderMediaTile(
          files[3],
          "w-[49.25%] rounded-br-lg rounded-l-none rounded-tr-none",
        )}
      </div>
    );
  }

  return null;
};
