import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  followUser,
  unFollowUser,
  addFriend,
  removeFriend,
} from "../../../helpers/fetchUser";
import { followGame, unFollowGame } from "../../../helpers/fetchGame";

interface SecondaryButtonProps {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: string;
  transparent?: boolean;
  userId?: string;
  gameId?: string;
  actionType?: "follow" | "friendRequest" | "followGame";
  initialFollow?: boolean;
  initialFriendStatus?: string;
  mobileFontSize?: boolean;
  onFollowChange?: (newFollowStatus: boolean) => void;
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  label,
  onClick,
  disabled = false,
  size = "h-[33px]",
  transparent,
  userId,
  gameId,
  actionType,
  initialFollow = false,
  initialFriendStatus = "false",
  mobileFontSize = false,
  onFollowChange,
}) => {
  const [isFollowing, setIsFollowing] = useState(initialFollow);
  const [friendStatus, setFriendStatus] = useState(initialFriendStatus);

  const isMobile = useMediaQuery({ maxWidth: 745 });

  useEffect(() => {
    setIsFollowing(initialFollow);
  }, [initialFollow]);

  useEffect(() => {
    setFriendStatus(initialFriendStatus);
  }, [initialFriendStatus]);

  const handleFollowUser = async () => {
    if (!userId) return;
    try {
      if (isFollowing) {
        await unFollowUser(userId);
      } else {
        await followUser(userId);
      }
      setIsFollowing(!isFollowing);
      if (onFollowChange) onFollowChange(!isFollowing);
    } catch (error) {
      console.error("Failed to follow/unfollow user:", error);
    }
  };

  const handleFriendRequest = async () => {
    if (!userId) return;
    try {
      if (friendStatus === "false") {
        await addFriend(userId);
        setFriendStatus("pending");
      } else if (friendStatus === "pending") {
        await removeFriend(userId);
        setFriendStatus("false");
      } else if (friendStatus === "toCheck") {
        await addFriend(userId);
        setFriendStatus("Ami");
      }
    } catch (error) {
      console.error("Failed to add/remove friend:", error);
    }
  };

  const handleFollowGame = async () => {
    if (!gameId) return;
    try {
      if (isFollowing) {
        await unFollowGame(gameId);
        setIsFollowing(false);
      } else {
        await followGame(gameId, 1);
        setIsFollowing(true);
      }
    } catch (error) {
      console.error("Failed to follow/unfollow game", error);
    }
  };

  const handleClick = () => {
    if (disabled) return;
    if (actionType === "follow") {
      handleFollowUser();
    } else if (actionType === "friendRequest") {
      handleFriendRequest();
    } else if (actionType === "followGame") {
      handleFollowGame();
    } else if (onClick) {
      onClick();
    }
  };

  const isTransparent =
    transparent ||
    ((actionType === "follow" || actionType === "followGame") && isFollowing);

  const buttonLabelStyle = {
    fontSize: mobileFontSize && isMobile ? "10px" : "inherit",
  };

  return (
    <>
      <style>
        {`
          .secondary-button {
            background: #972EB6;
          }
          .secondary-button:active {
            background: linear-gradient(0deg, #573B73 0%, #573B73 100%), #972EB6;
          }
          .secondary-button-transparent:hover::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid #F8EFFB;
            border-radius: inherit;
            pointer-events: none;
          }
          .secondary-button-transparent {
            position: relative;
            overflow: hidden;
          }
          .secondary-button:not(.secondary-button-disabled):hover {
            background: #B14FCB;
          }
          .secondary-button-disabled {
            background: #972EB6;
            opacity: 0.4;
            pointer-events: none;
          }
        `}
      </style>
      <div
        className={`flex ${size} ${
          isTransparent
            ? "secondary-button-transparent border-[0.4px] border-primary-100 bg-[rgba(245,235,255,0.20)] backdrop-blur-[4px] hover:bg-[rgba(245,235,255,0.35)]"
            : "secondary-button"
        } items-center justify-center self-stretch whitespace-nowrap rounded-[20px] px-6 py-2 ${
          disabled ? "secondary-button-disabled" : "cursor-pointer"
        }`}
        onClick={handleClick}
      >
        <p className="button-label" style={buttonLabelStyle}>
          {label && label}
          {actionType === "follow" && (isFollowing ? "Suivi" : "Suivre")}
          {actionType === "friendRequest" &&
            (friendStatus === "false"
              ? "Demander en ami"
              : friendStatus === "pending"
                ? "Demande envoyée"
                : friendStatus === "toCheck"
                  ? "Accepter la demande"
                  : "Ami")}
          {actionType === "followGame" && (isFollowing ? "Suivi" : "Suivre")}
        </p>
      </div>
    </>
  );
};
