import { useState } from "react";
import { CloseFillIcon, NotificationCard } from "..";

const initialNotifications = [
  {
    id: 1,
    friendDemand: true,
    newFollower: false,
    event: false,
    connexion: false,
    name: "John Doe",
    gamename: "",
    isRead: false,
    eventname: "",
  },
  {
    id: 2,
    friendDemand: false,
    newFollower: true,
    event: false,
    connexion: false,
    name: "Jane Smith",
    gamename: "",
    isRead: true,
    eventname: "",
  },
  {
    id: 3,
    friendDemand: false,
    newFollower: false,
    event: true,
    connexion: false,
    name: "",
    gamename: "",
    isRead: false,
    eventname: "Epic Event",
  },
  {
    id: 4,
    friendDemand: false,
    newFollower: false,
    event: false,
    connexion: true,
    name: "",
    gamename: "Awesome Game",
    isRead: true,
    eventname: "",
  },
];

interface OverlayNotificationProps {
  onClose: () => void;
}

export const OverlayNotification: React.FC<OverlayNotificationProps> = ({
  onClose,
}) => {
  const [notifications, setNotifications] = useState(initialNotifications);

  const handleRemove = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id),
    );
  };

  return (
    <div className="flex max-h-[70vh] w-[450px] flex-col gap-y-4 rounded-lg border-[2px] border-[rgba(198,182,213,0.5)] bg-[#3D2347] pb-8 pl-6 pr-4 pt-4 shadow-[0_2px_34px_rgba(0,0,0,0.45)] backdrop-blur-[6.5px]">
      <div className="flex w-full items-center justify-between">
        <p className="h2-mobile">Notifications</p>
        <div className="flex items-center gap-x-4 pr-2">
          {/* <SettingNotifIcon /> */}
          <CloseFillIcon onClick={onClose} />
        </div>
      </div>
      <div className="styled-scrollbars flex max-h-[calc(70vh-94px)] flex-col gap-y-4 overflow-y-auto pr-2">
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            friendDemand={notification.friendDemand}
            newFollower={notification.newFollower}
            event={notification.event}
            connexion={notification.connexion}
            name={notification.name}
            gamename={notification.gamename}
            isRead={notification.isRead}
            eventname={notification.eventname}
            onRemove={() => handleRemove(notification.id)}
          />
        ))}
      </div>
    </div>
  );
};
