type BadgePlatformProps = {
  label: string;
};

export const BadgePlatform: React.FC<BadgePlatformProps> = ({ label }) => {
  return (
    <div
      className="inline-flex h-[22px] items-center rounded bg-[rgba(255,255,255,0.1)] px-1 py-[2px]"
      style={{ width: "auto" }}
    >
      <span className="label-m-400 self-stretch whitespace-nowrap  opacity-80">
        {label}
      </span>
    </div>
  );
};
