import { CloseFillIcon } from "..";

interface SendMessageProps {
  onClick?: () => void;
}

export const AddFileChat: React.FC<SendMessageProps> = ({ onClick }) => {
  return (
    <div
      className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border-[0.5px] border-primary-100 bg-[rgba(255,255,255,0.15)] px-[5px]"
      onClick={onClick}
    >
      <CloseFillIcon size={10} />
    </div>
  );
};

export const SendMessage: React.FC<SendMessageProps> = ({ onClick }) => {
  return (
    <div
      className="label-s-400 flex h-auto cursor-pointer items-center justify-center whitespace-nowrap rounded-full border-[0.5px] border-primary-100 bg-blue px-2 py-[2px] hover:border-[rgba(59,130,246,0.8)] hover:bg-[rgba(59,130,246,0.8)]"
      onClick={onClick}
    >
      Publier
    </div>
  );
};
