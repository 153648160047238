import { useEffect, useState } from "react";
import { InfoGamePage, GameContent } from "../../components";
import { useAuth } from "../../providers/userAuth";
import { User } from "../../types";
import { GameLayout } from "./GameLayout";

export const GameInfoPage = () => {
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const platformsWithUrls = [
    { name: "Steam", url: "https://steamcommunity.com/", pseudo: "Clashix" },
    {
      name: "Discord",
      url: "https://discord.com/",
      pseudo: "ClashixDiscord",
    },
    { name: "Twitch", url: "https://www.twitch.tv/", pseudo: "ClashixOff" },
  ];

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  return (
    <GameLayout>
      {(data, studioGames) => (
        <>
          <div className="flex w-full max-tablet:hidden">
            <GameContent
              data={data}
              platformsWithUrls={platformsWithUrls}
              studioLogo={data.studios[0].logo}
              studioName={data.studios[0].name}
              studioDescription={data.studios[0].description}
              studioWebsite={data.studios[0].websiteUrl}
              studioGames={studioGames}
            />
          </div>
          <div className="flex w-full flex-col items-center px-5 min-850:hidden">
            <div className="flex w-full max-w-[610px]">
              <InfoGamePage
                logo={data.logo}
                gameName={data.name}
                studioName={data.studios[0].name}
                gameType={data.platforms[0].name}
                description={data.description}
                officialWebsite={data.downloadLink.pc}
                studioGames={studioGames}
              />
            </div>
          </div>
        </>
      )}
    </GameLayout>
  );
};
