interface FileProps {
  link: string;
  type: "image" | "video";
}

interface FileWithLink {
  link: string;
}

export const GetFileType = (files: FileWithLink[]): FileProps[] => {
  const imageExtensions = new Set([".png", ".jpg", ".jpeg", ".gif", ".webp"]);
  const videoExtensions = new Set([".mp4", ".mov", ".avi"]);

  const getFileType = (fileName: string): "image" | "video" => {
    const cleanedFileName = fileName.split(/[?#]/)[0];
    const lastDotIndex = cleanedFileName.lastIndexOf(".");
    if (lastDotIndex === -1) {
      console.error(`Unsupported file type: ${cleanedFileName}`);
      return "image";
    }

    let potentialExtension = cleanedFileName.slice(lastDotIndex).toLowerCase();

    if (imageExtensions.has(potentialExtension)) {
      return "image";
    } else if (videoExtensions.has(potentialExtension)) {
      return "video";
    } else {
      potentialExtension = potentialExtension.split("/")[0];
      if (imageExtensions.has(potentialExtension)) {
        return "image";
      } else if (videoExtensions.has(potentialExtension)) {
        return "video";
      } else {
        console.error(`Unsupported file type: ${potentialExtension}`);
        return "image";
      }
    }
  };

  return files.map((file) => ({
    link: file.link,
    type: getFileType(file.link),
  }));
};
