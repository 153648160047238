type ChevronIconProps = {
  className?: string;
  orientation?: "up" | "down" | "left" | "right";
  onClick?: () => void;
};

export const ChevronIcon: React.FC<ChevronIconProps> = ({
  onClick,
  className = "",
  orientation = "down",
}) => {
  const getRotation = (orientation: string) => {
    switch (orientation) {
      case "up":
        return "rotate-180";
      case "left":
        return "rotate-90";
      case "right":
        return "-rotate-90";
      case "down":
      default:
        return "rotate-0";
    }
  };

  return (
    <svg
      className={`${className} ${getRotation(orientation)}`}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${getRotation(orientation)})` }}
      onClick={onClick}
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="#F8EFFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronBackButtonIcon: React.FC<ChevronIconProps> = ({
  onClick,
  className = "",
  orientation = "down",
}) => {
  const getRotation = (orientation: string) => {
    switch (orientation) {
      case "right":
        return "rotate-180";
      case "up":
        return "rotate-90";
      case "down":
        return "-rotate-90";
      case "left":
      default:
        return "rotate-0";
    }
  };

  return (
    <svg
      className={`${className} ${getRotation(orientation)}`}
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${getRotation(orientation)})`,
        textShadow: "0px 4px 5px rgba(0, 0, 0)",
      }}
      onClick={onClick}
    >
      <g filter="url(#filter0_d_599_8487)">
        <path
          d="M12 4L6 10L12 16"
          stroke="#F8EFFB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_599_8487"
          x="0"
          y="0"
          width="18"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_599_8487"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_599_8487"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
