import React from "react";
import { Post, PlayerStats, Gameplay } from "../../../types";
import {
  CocStats,
  BsStats,
  CrStats,
  SteamStats,
  GamesIcons,
  CocLevelIcon,
  CRLevelIcon,
} from "../..";

interface UserPostGameStatDesktopProps {
  post: Post | Gameplay;
  playerStats: PlayerStats;
  hasGameAccount: boolean;
}

export const UserPostGameStatDesktop: React.FC<
  UserPostGameStatDesktopProps
> = ({ post, playerStats, hasGameAccount }) => {
  if (!post.game) return null;

  const renderGameStats = () => {
    switch (post.game.name) {
      case "Clash of Clans":
        return (
          <CocStats playerStats={playerStats} hasGameAccount={hasGameAccount} />
        );
      case "Brawl Stars":
        return (
          <BsStats playerStats={playerStats} hasGameAccount={hasGameAccount} />
        );
      case "Clash Royale":
        return (
          <CrStats playerStats={playerStats} hasGameAccount={hasGameAccount} />
        );
      default:
        return (
          <SteamStats
            playerStats={playerStats}
            hasGameAccount={hasGameAccount}
          />
        );
    }
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center justify-between gap-x-2 rounded-md bg-[rgba(245,235,255,0.10)] px-4 py-3 shadow-[1px_2px_1px_rgba(0,0,0,0.45)] backdrop-blur-[2px]">
        <div className="flex items-center gap-x-2">
          <GamesIcons
            logo={post.game.logo}
            name={post.game.name}
            gameId={post.game.id}
            isClickable={true}
            className="game-thumbnail-s"
          />
          <div className="flex flex-col">
            <p className="label-s-400 whitespace-nowrap opacity-60">
              Pseudo {post.game.name}
            </p>
            <p className="paragraph-s-700 whitespace-nowrap">
              {post.user.pseudo}
            </p>
          </div>
        </div>
        {playerStats?.expLevel && post.game.name == "Clash of Clans" && (
          <CocLevelIcon level={playerStats?.expLevel ?? 0} size={30} />
        )}
        {playerStats?.expLevel && post.game.name == "Clash Royale" && (
          <CRLevelIcon level={playerStats?.expLevel ?? 0} size={30} />
        )}
      </div>
      {renderGameStats()}
    </div>
  );
};
