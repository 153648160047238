import React, { createContext, useContext, useState } from "react";

interface VideoContextProps {
  activeVideoId: string | null;
  setActiveVideoId: (id: string | null) => void;
}

const VideoContext = createContext<VideoContextProps | undefined>(undefined);

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error("useVideoContext must be used within a VideoProvider");
  }
  return context;
};

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeVideoId, setActiveVideoId] = useState<string | null>(null);

  return (
    <VideoContext.Provider value={{ activeVideoId, setActiveVideoId }}>
      {children}
    </VideoContext.Provider>
  );
};
