import React, { useState } from "react";
import Select, { components } from "react-select";
import { Game } from "../../types";
import { IoMdClose } from "react-icons/io";

type SubtitleNavigationListProps = {
  items: Game[];
  onSelect?: (item: Game | undefined) => void;
  selectedItem?: Game;
  disabled?: boolean;
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <IoMdClose style={{ color: "#ffffff" }} />
    </components.ClearIndicator>
  );
};

export const SelectGame: React.FC<SubtitleNavigationListProps> = ({
  items,
  onSelect,
  selectedItem,
  disabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const options = items.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleSelectChange = (selectedOption: any) => {
    const selectedItem = items.find(
      (item) => item.id === selectedOption?.value,
    );
    if (selectedOption?.value === "none") {
      setSearchTerm("");
      if (onSelect) {
        onSelect(undefined);
      }
    } else {
      setSearchTerm(selectedItem?.name || "");
      if (onSelect) {
        onSelect(selectedItem);
      }
    }
  };

  return (
    <div className="relative z-[10] w-full">
      <Select
        options={options}
        onChange={handleSelectChange}
        value={options.find((option) => option.label === searchTerm)}
        placeholder={selectedItem?.name || "Recherche un jeu"}
        isDisabled={disabled}
        className="label-m-400 w-full outline-none"
        isClearable
        components={{ ClearIndicator }}
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: "#3a2144", // Color of the select box
            border: "1px solid #ced4da", // Border color of the select box
            boxShadow: "none",
            borderRadius: "0.375rem", // Rounded corners
            color: "#ffffff", // Text color inside the select box
            zIndex: 1000, // Ensures the select box has a high z-index
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: "#3a2144", // Background color of the dropdown menu
            borderRadius: "4px", // Rounded corners for the dropdown menu
            zIndex: 1100, // Ensures the dropdown menu has a higher z-index than the select box
            marginTop: 0,
            opacity: 1,
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#f5ebff" : "#3a2144", // Highlight color when an option is focused
            color: state.isFocused ? "#000000" : "#ffffff", // Text color of the options
            padding: "8px 12px",
            borderBottomLeftRadius:
              state.data.value === options[options.length - 1].value
                ? "0.375rem"
                : 0,
            borderBottomRightRadius:
              state.data.value === options[options.length - 1].value
                ? "0.375rem"
                : 0,
          }),
          singleValue: (base) => ({
            ...base,
            color: "#ffffff", // Text color of the selected value
          }),
          placeholder: (base) => ({
            ...base,
            color: "#6c757d", // Text color of the placeholder
          }),
          clearIndicator: (base) => ({
            ...base,
            color: "#ffffff", // Color of the clear indicator
            cursor: "pointer",
          }),
          input: (base) => ({
            ...base,
            color: "#ffffff", // Text color while searching
          }),
        }}
      />
    </div>
  );
};
