import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchUserPage, fetchUserPosts } from "../../helpers/fetchUser";
import { UserActivity, RecentlyPlayed, ButtonSimple } from "../../components";
import { Post } from "../../types";
import { useAuth } from "../../providers/userAuth";
import { UserLayout } from "./UserLayout";
import { useMediaQuery } from "react-responsive";

export const UserPage = () => {
  const { username } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<Post[]>([]);
  const { data } = useQuery(["user", username], fetchUserPage(username ?? ""));
  const isMobile = useMediaQuery({ maxWidth: 745 });
  useEffect(() => {
    const fetchPosts = async () => {
      if (username) {
        try {
          const userPosts = await fetchUserPosts(username);
          setPosts([
            ...userPosts.posts,
            ...userPosts.likedPosts,
            ...userPosts.repostedPosts,
          ]);
        } catch (error) {
          console.error("Erreur lors de la récupération des posts", error);
        }
      }
    };

    fetchPosts();
  }, [username]);

  const hasGames = Number(data?.gamesCount ?? "") > 0;
  const hasRecentGames = data?.recentGames && data.recentGames.length > 0;
  const isOwnProfile = auth.user?.username === username;

  const userPage = () => {
    navigate(`/user/${username}/games`);
  };

  const navigateConnectGames = () => {
    navigate(`/connectgames/`);
  };

  return (
    <UserLayout>
      <div
        className="flex self-stretch pt-4 max-tablet:flex-col max-tablet:items-center max-tablet:px-5 max-tablet:pb-[150px] min-851:items-start min-851:gap-x-8 min-851:pl-8 min-851:pr-5"
        style={{
          background: isMobile
            ? "linear-gradient(0deg, #140029 61.19%, #1A0920 100%)"
            : "none",
        }}
      >
        <div className="flex w-full flex-col gap-y-4 pb-8 max-mobile:pt-4 max-tablet:items-center max-tablet:gap-y-8 min-851:items-start">
          <div className="flex w-full flex-col items-center justify-center gap-y-4 max-tablet:max-w-[610px] min-851:max-w-[480px]">
            <div className="flex w-full items-center justify-between gap-x-2">
              <p className="h2-mobile">
                {hasGames
                  ? `${data?.gamesCount ?? ""} jeux possédés`
                  : isOwnProfile
                    ? "Vous ne possédez aucun jeu, ajoutez-en !"
                    : "Cet utilisateur ne possède aucun jeu"}
              </p>
              {isOwnProfile && !hasGames && (
                <ButtonSimple
                  label="Ajouter des jeux"
                  plusFonction={true}
                  onClick={navigateConnectGames}
                />
              )}
              {hasGames && (
                <p className="link-s cursor-pointer" onClick={userPage}>
                  Voir plus
                </p>
              )}
            </div>
            {hasGames && hasRecentGames && (
              <RecentlyPlayed games={data.recentGames} />
            )}
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-y-2 max-tablet:max-w-[610px] min-851:hidden">
            {data && data.posts && (
              <UserActivity posts={data.posts} title="Dernières activités" />
            )}
          </div>
        </div>
        <div className="flex w-full max-w-[450px] flex-col items-start gap-y-4 max-tablet:hidden">
          <div className="flex w-full max-w-[450px] flex-col items-center justify-center gap-y-2 max-tablet:hidden">
            {data && data.posts && (
              <UserActivity posts={data.posts} title="Dernières activités" />
            )}
          </div>
        </div>
      </div>
    </UserLayout>
  );
};
