type SwitchButtonProps = {
  isSelected: boolean;
  onClick: () => void;
};

export const SwitchButton: React.FC<SwitchButtonProps> = ({
  isSelected,
  onClick,
}) => {
  return (
    <div
      className={`flex h-[28px] w-[50px] ${isSelected ? "justify-end" : "items-start"} items-center rounded-lg bg-[#2F1A40] p-[3px] outline-none focus:outline-none`}
      onClick={onClick}
    >
      {isSelected ? (
        <div className="bg-green h-[22px] w-[22px] rounded-md border-[1.2px] border-[rgba(255,255,255,0.25)] shadow-[0_0_4.8px_rgba(0,0,0,0.25),_inset_0_0_1.2px_#FFF]"></div>
      ) : (
        <div className="h-[22px] w-[22px] rounded-md border-[1.2px] border-[rgba(255,255,255,0.25)] bg-red shadow-[0_0_4.8px_rgba(0,0,0,0.25),_inset_0_0_1.2px_#FFF]"></div>
      )}
    </div>
  );
};
