import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  BottomBar,
  GroupDiscussionCard,
  HeaderMessageList,
  PlayerDiscussionCard,
  InviteFriends,
  ButtonSimple,
  Loading,
} from "../../components";
import { useAuth } from "../../providers/userAuth";
import { fetchDiscussions } from "../../helpers/Discussion";
import { fetchGroups } from "../../helpers/Group";
import socket from "../../socket";
import { Message } from "../../types/Discussion";
import { GroupMessage } from "../../types/Group";
import DefaultAvatarGroup from "../../assets/DefaultAvatarGroup.png";

interface PagePrincipaleProps {
  children: React.ReactNode;
}

type CombinedDiscussion = {
  type: "user" | "group";
  id: string;
  logo: string;
  name: string;
  unreadMessages: number;
  lastMessageDate: string;
  additionalInfo?: any;
  game?: string;
  gamelogo?: string;
};

export const PagePrincipale: React.FC<PagePrincipaleProps> = ({ children }) => {
  const auth = useAuth();
  const [combinedDiscussions, setCombinedDiscussions] = useState<
    CombinedDiscussion[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const location = useLocation();
  const navigate = useNavigate();
  const isInMessagePage = location.pathname.includes("/messages/");
  const currentDiscussionId = location.pathname.split("/").pop();

  const updateUnreadMessages = (message: Message) => {
    setCombinedDiscussions((prevDiscussions) => {
      return prevDiscussions.map((discussion) => {
        if (
          discussion.type === "user" &&
          discussion.id === String(message.discussionId) &&
          String(message.userId) !== auth.user?.id
        ) {
          return {
            ...discussion,
            unreadMessages: (discussion.unreadMessages || 0) + 1,
            lastMessageDate: message.createdAt,
            additionalInfo: message.content,
          };
        }
        return discussion;
      });
    });
  };

  const updateUnreadGroupMessages = (message: GroupMessage) => {
    setCombinedDiscussions((prevDiscussions) => {
      return prevDiscussions.map((discussion) => {
        if (
          discussion.type === "group" &&
          discussion.id === String(message.groupId) &&
          String(message.userId) !== auth.user?.id
        ) {
          return {
            ...discussion,
            unreadMessages: (discussion.unreadMessages || 0) + 1,
            lastMessageDate: message.createdAt,
            additionalInfo: message.content,
          };
        }
        return discussion;
      });
    });
  };

  const resetUnreadMessages = (
    discussionId: string,
    type: "user" | "group",
  ) => {
    setCombinedDiscussions((prevDiscussions) => {
      return prevDiscussions.map((discussion) => {
        if (discussion.id === discussionId && discussion.type === type) {
          return {
            ...discussion,
            unreadMessages: 0,
          };
        }
        return discussion;
      });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userDiscussions, groupDiscussions] = await Promise.all([
          fetchDiscussions(),
          fetchGroups(),
        ]);

        const formattedUserDiscussions: CombinedDiscussion[] =
          userDiscussions.map((discussion) => ({
            type: "user",
            id: String(discussion.id),
            logo: discussion.otherUser.logo,
            name: discussion.otherUser.username || "",
            unreadMessages: discussion.unreadMessages || 0,
            lastMessageDate: discussion.lastMessageDate || discussion.createdAt,
            additionalInfo: discussion.firstWord || "",
          }));

        const formattedGroupDiscussions: CombinedDiscussion[] =
          groupDiscussions.map((group) => ({
            type: "group",
            id: String(group.id),
            logo: group.logo || DefaultAvatarGroup,
            name: group.name || "",
            unreadMessages: group.unreadMessages || 0,
            lastMessageDate: group.lastMessageDate || group.createdAt,
            additionalInfo: group.participantsCount || "",
            game: group.game?.name || "",
            gamelogo: group.game?.logo || "",
          }));

        const combinedData = [
          ...formattedUserDiscussions,
          ...formattedGroupDiscussions,
        ];
        combinedData.sort(
          (a, b) =>
            new Date(b.lastMessageDate).getTime() -
            new Date(a.lastMessageDate).getTime(),
        );

        setCombinedDiscussions(combinedData);
      } catch (err) {
        console.error("Error fetching discussions and groups:", err);
        setError("Failed to fetch discussions and groups.");
      } finally {
        setLoading(false);
      }
    };

    if (auth.token) {
      fetchData();
    }

    socket.on("updateUnreadMessages", ({ message }) => {
      updateUnreadMessages(message);
      if (String(message.discussionId) === currentDiscussionId) {
        resetUnreadMessages(String(message.discussionId), "user");
      }
    });

    socket.on("updateUnreadMessagesGroup", ({ message }) => {
      updateUnreadGroupMessages(message);
      if (String(message.groupId) === currentDiscussionId) {
        resetUnreadMessages(String(message.groupId), "group");
      }
    });

    return () => {
      socket.off("updateUnreadMessages");
      socket.off("updateUnreadMessagesGroup");
    };
  }, [auth.token, currentDiscussionId]);

  const handleCreateNewDiscussion = () => {
    navigate("/messages/newmessage");
  };

  return (
    <div
      className={`flex h-full w-full min-745:max-h-[calc(100vh-58px)] ${
        isWideScreen ? "flex-row" : "flex-col pt-[60px]"
      } `}
    >
      <div
        className={`w-full flex-col max-mobile:max-w-[580px] min-745:min-w-[350px] ${
          !isInMessagePage ? "min-745:min-w-[370px]" : "flex-1"
        } styled-scrollbars overflow-y-auto px-2`}
      >
        <HeaderMessageList />
        <div className="flex h-auto flex-col gap-y-2 p-2">
          {loading && <Loading />}
          {error && <div className="h1-mobile">{error}</div>}
          {combinedDiscussions.length > 0 ? (
            combinedDiscussions.map((discussion) =>
              discussion.type === "user" ? (
                <PlayerDiscussionCard
                  key={`user-${discussion.id}`}
                  onClick={() => {
                    resetUnreadMessages(discussion.id, "user");
                    navigate(`/messages/user/${discussion.id}`);
                  }}
                  id={discussion.id}
                  logo={discussion.logo}
                  username={discussion.name}
                  messageCount={discussion.unreadMessages}
                  firstWord={discussion.additionalInfo}
                  lastMessageDate={discussion.lastMessageDate}
                  isActive={location.pathname.includes(
                    `/messages/user/${discussion.id}`,
                  )}
                />
              ) : (
                <GroupDiscussionCard
                  key={`group-${discussion.id}`}
                  onClick={() => {
                    resetUnreadMessages(discussion.id, "group");
                    navigate(`/messages/group/${discussion.id}`);
                  }}
                  id={discussion.id}
                  logo={discussion.logo}
                  groupname={discussion.name}
                  participantCount={discussion.additionalInfo}
                  messageCount={discussion.unreadMessages}
                  lastMessageDate={discussion.lastMessageDate}
                  isActive={location.pathname.includes(
                    `/messages/group/${discussion.id}`,
                  )}
                  game={discussion.game || ""}
                  gamelogo={discussion.gamelogo || ""}
                />
              ),
            )
          ) : (
            <div className="flex flex-col items-center gap-y-4">
              <p className="paragraph-m-400 text-center">
                Vous n'avez aucune discussion pour le moment. Invitez des amis
                ou créez une nouvelle discussion pour commencer à discuter!
              </p>
              <InviteFriends />
              <ButtonSimple
                label="Créer une nouvelle discussion"
                onClick={handleCreateNewDiscussion}
              />
            </div>
          )}
        </div>
      </div>
      <BottomBar isMessagery={true} hidden={isInMessagePage} />
      {isWideScreen && (
        <div className="relative z-[5] h-full w-full max-w-[610px] overflow-y-auto">
          {children}
        </div>
      )}
    </div>
  );
};
