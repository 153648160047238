import { FirstStep, SecondStep, ThirdStep } from "../";

type StepCardProps = {
  currentStep: number;
};

export const StepCard: React.FC<StepCardProps> = ({ currentStep }) => {
  const stepTitles = [
    "Information du gamer",
    "Mes jeux et plateformes",
    "Connexions",
  ];

  return (
    <div
      className="flex w-full items-center gap-x-3 self-stretch rounded-md border border-transparent px-5 py-2 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[2px]"
      style={{
        background: "linear-gradient(290deg, #3D0F53, #3D0F53)",
      }}
    >
      <div className="relative flex h-[53px] w-[53px] items-center justify-center">
        {currentStep === 1 && <FirstStep />}
        {currentStep === 2 && <ThirdStep />}
        {currentStep === 3 && <ThirdStep />}
        <div className="h2-mobile absolute left-[49%] top-[53%] -translate-x-1/2 -translate-y-1/2 transform">
          {currentStep}
        </div>
      </div>
      <div className="flex flex-col items-start">
        <p className="label-m-400 opacity-60">Étape {currentStep} sur 2</p>
        <p className="h2-mobile">{stepTitles[currentStep - 1]}</p>
      </div>
    </div>
  );
};
