import { useRef, useState, useEffect } from "react";
import { EditIcon, GroupFillIcon, CloseFillIcon } from "..";
import { useNavigate } from "react-router-dom";

type TooltipNewMessageProps = {
  onClick?: () => void;
  desktop?: boolean;
};

export const TooltipNewMessage: React.FC<TooltipNewMessageProps> = ({
  onClick,
  desktop,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  const navigateToCreatePage = () => {
    clearLocalStorage();
    navigate(`/messages/creategroup/participants`);
  };
  const navigateNewMessage = () => {
    clearLocalStorage();
    navigate(`/messages/newmessage`);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("coverImage");
    localStorage.removeItem("groupName");
    localStorage.removeItem("description");
    localStorage.removeItem("participants");
    localStorage.removeItem("selectedGame");
  };

  const closeModal = () => {
    if (onClick) {
      onClick();
    }
    setIsVisible(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const modalContent = document.getElementById("modal-content");
      if (modalContent && !e.composedPath().includes(modalContent)) {
        closeModal();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isVisible]);

  return isVisible ? (
    <div
      id="modal-content"
      ref={overlayRef}
      className={`backdrop-blur-[6.5px relative z-[100] inline-flex w-[90svw] flex-col items-center justify-end gap-y-1 rounded-lg border-2 border-transparent opacity-100 shadow-[0_2px_4px_rgba(0,0,0,0.25)] min-745:w-[300px]`}
      style={{
        background:
          "linear-gradient(293deg, #3D2345 0.12%, #A042BC 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #3D2345 50%) border-box",
      }}
    >
      <div className="flex items-center justify-between self-stretch px-6 py-3">
        <div className="flex flex-col items-start justify-center self-stretch">
          <p className="button-label self-stretch">Discussions</p>
        </div>
        <CloseFillIcon rotated={false} onClick={closeModal} />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch px-6 pb-5 pt-2">
        {/* <div
          className="flex cursor-pointer items-center gap-x-[10px] self-stretch"
          onClick={navigateToCreatePage}
        >
          <div className="flex items-center justify-center">
            <GroupFillIcon />
          </div>
          <p className="nav-menu-500">Créer un groupe</p>
        </div> */}
        <div
          className="flex cursor-pointer items-center gap-x-[10px] self-stretch"
          onClick={navigateNewMessage}
        >
          <div className="flex h-5 w-5 items-center justify-center">
            <EditIcon />
          </div>
          <p className="nav-menu-500">Nouvelle discussion</p>
        </div>
      </div>
    </div>
  ) : null;
};
