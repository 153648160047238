import React, { createContext, useState, useContext, ReactNode } from "react";
import { ImageOverlay } from "./ImageOverlay";
import { ContentOverlay } from "./ContentOverlay";

type OverlayContextType = {
  showModal: boolean;
  currentImage: string;
  currentImageId: string;
  images: Array<{ id: string; src: string }>;
  modalType: "image" | "content";
  content: ReactNode;
  openImageModal: (
    imageId: string,
    imageSrc: string,
    images: Array<{ id: string; src: string }>,
  ) => void;
  openContentModal: (content: ReactNode) => void;
  closeModal: () => void;
  nextImage: () => void;
  prevImage: () => void;
  setCurrentImage: (src: string) => void;
  setCurrentImageId: (id: string) => void;
};

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

export const OverlayProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentImageId, setCurrentImageId] = useState("");
  const [images, setImages] = useState<Array<{ id: string; src: string }>>([]);
  const [modalType, setModalType] = useState<"image" | "content">("image");
  const [content, setContent] = useState<ReactNode>(null);

  const openImageModal = (
    imageId: string,
    imageSrc: string,
    images: Array<{ id: string; src: string }>,
  ) => {
    setCurrentImage(imageSrc);
    setCurrentImageId(imageId);
    setImages(images);
    setModalType("image");
    setShowModal(true);
  };

  const openContentModal = (content: ReactNode) => {
    setContent(content);
    setModalType("content");
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const nextImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === currentImageId,
    );
    if (currentIndex < images.length - 1) {
      const nextImage = images[currentIndex + 1];
      setCurrentImage(nextImage.src);
      setCurrentImageId(nextImage.id);
    }
  };

  const prevImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === currentImageId,
    );
    if (currentIndex > 0) {
      const prevImage = images[currentIndex - 1];
      setCurrentImage(prevImage.src);
      setCurrentImageId(prevImage.id);
    }
  };

  return (
    <OverlayContext.Provider
      value={{
        showModal,
        currentImage,
        currentImageId,
        images,
        modalType,
        content,
        openImageModal,
        openContentModal,
        closeModal,
        nextImage,
        prevImage,
        setCurrentImage,
        setCurrentImageId,
      }}
    >
      {children}
      {showModal && modalType === "content" && (
        <ContentOverlay>{content}</ContentOverlay>
      )}
      {showModal && modalType === "image" && <ImageOverlay />}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error("useOverlay must be used within an OverlayProvider");
  }
  return context;
};
