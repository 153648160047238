export const FirstStep = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
  >
    <g opacity="0.6" filter="url(#filter0_i_434_8005)">
      <path
        d="M0 26.5C0 41.1355 11.8645 53 26.5 53C41.1355 53 53 41.1355 53 26.5C53 11.8645 41.1355 0 26.5 0C11.8645 0 0 11.8645 0 26.5ZM47.7 26.5C47.7 38.2084 38.2084 47.7 26.5 47.7C14.7916 47.7 5.3 38.2084 5.3 26.5C5.3 14.7916 14.7916 5.3 26.5 5.3C38.2084 5.3 47.7 14.7916 47.7 26.5Z"
        fill="#F8EFFB"
      />
    </g>
    <g filter="url(#filter1_i_434_8005)">
      <path
        d="M25.0772 2.69248C24.9899 1.23153 26.1056 -0.0374587 27.568 0.0215229C30.8463 0.153749 34.0796 0.89404 37.1008 2.21268C40.9336 3.88559 44.316 6.44186 46.9716 9.67249C49.6271 12.9031 51.4804 16.7164 52.3798 20.8005C53.0888 24.0197 53.1893 27.3352 52.6845 30.5771C52.4593 32.0233 50.9984 32.8722 49.582 32.5038V32.5038C48.1655 32.1354 47.3336 30.6883 47.5226 29.237C47.8384 26.8113 47.7328 24.3424 47.2039 21.9404C46.4843 18.6731 45.0017 15.6225 42.8773 13.038C40.7528 10.4535 38.0468 8.40848 34.9806 7.07015C32.7264 6.08626 30.3246 5.50485 27.8836 5.34519C26.4232 5.24967 25.1645 4.15342 25.0772 2.69248V2.69248Z"
        fill="#FE0045"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_434_8005"
        x="0"
        y="0"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.0470588 0 0 0 0 0.192157 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_434_8005"
        />
      </filter>
      <filter
        id="filter1_i_434_8005"
        x="25.0725"
        y="0.0195312"
        width="27.9275"
        height="32.5701"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_434_8005"
        />
      </filter>
    </defs>
  </svg>
);

export const SecondStep = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
  >
    <g opacity="0.6" filter="url(#filter0_i_434_7897)">
      <path
        d="M0 26.5C0 41.1355 11.8645 53 26.5 53C41.1355 53 53 41.1355 53 26.5C53 11.8645 41.1355 0 26.5 0C11.8645 0 0 11.8645 0 26.5ZM47.7 26.5C47.7 38.2084 38.2084 47.7 26.5 47.7C14.7916 47.7 5.3 38.2084 5.3 26.5C5.3 14.7916 14.7916 5.3 26.5 5.3C38.2084 5.3 47.7 14.7916 47.7 26.5Z"
        fill="#F8EFFB"
      />
    </g>
    <g filter="url(#filter1_i_434_7897)">
      <path
        d="M25.0772 2.69248C24.9899 1.23153 26.1056 -0.0374635 27.568 0.0215161C31.5432 0.181845 35.4435 1.23593 38.9736 3.11928C43.2856 5.4197 46.8774 8.86772 49.3519 13.0821C51.8265 17.2965 53.0874 22.1132 52.9953 26.9995C52.9032 31.8858 51.4616 36.6515 48.83 40.7696C46.1983 44.8878 42.4791 48.198 38.0836 50.3342C33.688 52.4705 28.7873 53.3496 23.9233 52.8744C19.0592 52.3992 14.4213 50.5882 10.5224 47.6416C7.3303 45.2292 4.73528 42.1325 2.91984 38.5924C2.25199 37.2901 2.9349 35.7446 4.29736 35.2101V35.2101C5.65982 34.6756 7.1834 35.3573 7.88359 36.6425C9.32029 39.2795 11.3061 41.5905 13.7179 43.4132C16.8371 45.7706 20.5474 47.2194 24.4386 47.5995C28.3298 47.9797 32.2504 47.2764 35.7669 45.5674C39.2833 43.8584 42.2587 41.2102 44.364 37.9157C46.4693 34.6212 47.6225 30.8086 47.6962 26.8996C47.7699 22.9905 46.7612 19.1372 44.7815 15.7657C42.8019 12.3942 39.9285 9.63576 36.4789 7.79542C33.8116 6.37242 30.8802 5.54119 27.8836 5.34519C26.4232 5.24967 25.1645 4.15342 25.0772 2.69248V2.69248Z"
        fill="#EEB728"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_434_7897"
        x="0"
        y="0"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.0470588 0 0 0 0 0.192157 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_434_7897"
        />
      </filter>
      <filter
        id="filter1_i_434_7897"
        x="2.66101"
        y="0.0195312"
        width="50.339"
        height="52.9805"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_434_7897"
        />
      </filter>
    </defs>
  </svg>
);

export const ThirdStep = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
  >
    <g opacity="0.6" filter="url(#filter0_i_434_7944)">
      <path
        d="M0 26.5C0 41.1355 11.8645 53 26.5 53C41.1355 53 53 41.1355 53 26.5C53 11.8645 41.1355 0 26.5 0C11.8645 0 0 11.8645 0 26.5ZM47.7 26.5C47.7 38.2084 38.2084 47.7 26.5 47.7C14.7916 47.7 5.3 38.2084 5.3 26.5C5.3 14.7916 14.7916 5.3 26.5 5.3C38.2084 5.3 47.7 14.7916 47.7 26.5Z"
        fill="#F8EFFB"
      />
    </g>
    <g filter="url(#filter1_i_434_7944)">
      <path
        d="M25.0772 2.69248C24.9899 1.23153 26.1057 -0.0374797 27.5681 0.021484C32.9446 0.23827 38.1514 2.08893 42.4776 5.35844C47.5115 9.16277 51.0228 14.6384 52.3798 20.8005C53.7369 26.9625 52.8507 33.4066 49.8807 38.9736C46.9107 44.5407 42.0518 48.8653 36.1779 51.1696C30.3039 53.4739 23.8006 53.6067 17.8375 51.5442C11.8744 49.4816 6.84291 45.359 3.64807 39.9179C0.453235 34.4768 -0.695303 28.0742 0.409044 21.8619C1.35814 16.5229 3.91769 11.6255 7.71267 7.81073C8.74486 6.77316 10.4259 6.94498 11.3552 8.07559V8.07559C12.2846 9.20621 12.107 10.8659 11.1011 11.929C8.26813 14.9228 6.35533 18.6937 5.62723 22.7895C4.74376 27.7594 5.66259 32.8814 8.21846 37.2343C10.7743 41.5872 14.7995 44.8853 19.57 46.5353C24.3405 48.1854 29.5432 48.0791 34.2423 46.2357C38.9414 44.3922 42.8286 40.9325 45.2046 36.4789C47.5806 32.0253 48.2895 26.87 47.2039 21.9404C46.1182 17.0107 43.3092 12.6302 39.2821 9.58675C35.9633 7.07857 31.9967 5.61415 27.8837 5.34516C26.4233 5.24965 25.1645 4.15342 25.0772 2.69248V2.69248Z"
        fill="#00DD23"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_434_7944"
        x="0"
        y="0"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.156863 0 0 0 0 0.0470588 0 0 0 0 0.192157 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_434_7944"
        />
      </filter>
      <filter
        id="filter1_i_434_7944"
        x="-6.10352e-05"
        y="0.0195312"
        width="53.0001"
        height="52.9805"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_434_7944"
        />
      </filter>
    </defs>
  </svg>
);
