import { io } from "socket.io-client";
import { server, serverPort, socketUrl } from "./constants/api";

const socket = io(server + serverPort, {
  withCredentials: true,
  extraHeaders: {
    "Access-Control-Allow-Origin": socketUrl,
  },
});

export default socket;
