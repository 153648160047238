import "./Spinner.css";

export const SpinnerLoading = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="spinner">
        <div className="dot bg-violet-1"></div>
        <div className="dot bg-violet-2"></div>
        <div className="dot bg-violet-3"></div>
        <div className="dot bg-violet-4"></div>
        <div className="dot bg-violet-5"></div>
        <div className="dot bg-violet-6"></div>
        <div className="dot bg-violet-7"></div>
      </div>
    </div>
  );
};
