import React, { useState } from "react";
import Modal from "react-modal";

type ChatProps = {
  isUserMessage: boolean;
  username?: string;
  pseudo?: string;
  text?: string;
  time: string;
  files?: {
    id: string;
    link: string;
    type: string;
  }[];
  logo?: string;
  isGroupMessage: boolean;
};

export const Chat: React.FC<ChatProps> = ({
  isUserMessage,
  username,
  pseudo,
  text,
  time,
  files,
  logo,
  isGroupMessage,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage("");
  };

  return (
    <div
      className={`flex flex-col ${isUserMessage ? "items-end" : "items-start"} gap-y-1`}
      style={{ position: "relative", zIndex: 1 }}
    >
      {!isUserMessage && isGroupMessage && (
        <div className="flex items-center gap-x-2">
          <div className="avatar-s">
            <img
              src={logo}
              alt={username}
              className="image-avatar border border-[#C6B6D5]"
            />
          </div>
          <div className="flex flex-col">
            <p className="h4-mobile">{username}</p>
            {pseudo && <p className="label-s-400 opacity-90">{pseudo}</p>}
          </div>
        </div>
      )}
      <div
        className={`flex max-w-[50%] flex-col gap-y-1 ${text && "!w-auto"} items-center justify-center ${isUserMessage ? "rounded-bl-[16px]" : "rounded-bl-none"}  ${isUserMessage ? "rounded-br-none" : "rounded-br-[16px]"} rounded-tl-[16px] rounded-tr-[16px] ${isUserMessage ? "bg-primary-500" : "bg-[rgba(245,235,255,0.30)]"} p-[10px]`}
        style={{ position: "relative", zIndex: 2 }}
      >
        {text && (
          <p className="paragraph-s-500 self-stretch opacity-90">{text}</p>
        )}

        {files?.map((file) => (
          <div
            key={file.id}
            className="mt-2 flex h-auto max-h-[200px] w-[90%] justify-end"
          >
            {file.type.startsWith("image") ? (
              <img
                src={file.link}
                alt="file"
                className="h-auto max-w-full cursor-pointer"
                onClick={() => openModal(file.link)}
              />
            ) : (
              <video controls className="h-auto max-w-full">
                <source src={file.link} />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>
      <p className="label-s-400 opacity-70">{time}</p>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 9998,
          },
        }}
      >
        <img
          src={modalImage}
          alt="modal"
          className="h-auto w-[50vw] max-mobile:w-[70vw]"
        />
      </Modal>
    </div>
  );
};
