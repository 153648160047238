import { useEffect, useRef } from "react";
import { CloseFillIcon, DeleteIcon } from "../";
import { removeFriend } from "../../helpers/fetchUser";

type FriendOptionsProps = {
  userId: string;
  username: string;
  onClose: () => void;
  onRemoveFriend: () => void;
};

export const FriendOptions: React.FC<FriendOptionsProps> = ({
  userId,
  username,
  onClose,
  onRemoveFriend,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef, onClose]);

  const handleRemoveFriend = async () => {
    try {
      await removeFriend(userId);
      onRemoveFriend();
      onClose();
    } catch (error) {
      console.error("Error removing friend:", error);
    }
  };

  return (
    <div
      ref={overlayRef}
      className="absolute right-0 top-0 z-[100] flex min-w-[300px] flex-col gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-1 backdrop-blur-[6.5px]"
      style={{
        background:
          "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
      }}
    >
      <div className="flex items-center justify-between self-stretch py-3 pl-2">
        <p className="button-label items-start self-stretch">Options d'ami</p>
        <CloseFillIcon onClick={onClose} />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch pb-2">
        <div
          className="flex cursor-pointer flex-wrap content-center items-center gap-x-[10px] self-stretch whitespace-nowrap px-1"
          onClick={handleRemoveFriend}
        >
          <DeleteIcon />
          <p className="nav-menu-500">
            Supprimer <span className="font-bold">{username}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
