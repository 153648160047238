import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchUserPage, fetchUserPosts } from "../../helpers/fetchUser";
import {
  GameDetailCard,
  GameCoverColumn,
  ChevronIcon,
  ButtonSimple,
} from "../../components";
import { Post, Game } from "../../types";
import { useAuth } from "../../providers/userAuth";
import { fetchUserGames } from "../../helpers/fetchGame";
import { UserLayout } from "./UserLayout";
import { useMediaQuery } from "react-responsive";

export const UserGamesPage = () => {
  const { username } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<Post[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 745 });

  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {},
  );

  const { data: userData } = useQuery(
    ["user", username],
    fetchUserPage(username ?? ""),
  );

  const { data: gamesData } = useQuery(["games", username], () =>
    fetchUserGames(username ?? ""),
  );

  useEffect(() => {
    const fetchPosts = async () => {
      if (username) {
        try {
          const userPosts = await fetchUserPosts(username);
          setPosts([
            ...userPosts.posts,
            ...userPosts.likedPosts,
            ...userPosts.repostedPosts,
          ]);
        } catch (error) {
          console.error("Erreur lors de la récupération des posts", error);
        }
      }
    };

    fetchPosts();
  }, [username]);

  const navigateConnectGames = () => {
    navigate(`/connectgames/`);
  };

  const isOwnProfile = auth.user?.username === username;

  const gamesByPlatform: { [key: string]: Game[] } =
    gamesData?.reduce(
      (acc, game) => {
        game.platforms.forEach((platform) => {
          if (!acc[platform.name]) {
            acc[platform.name] = [];
          }
          acc[platform.name].push(game);
        });
        return acc;
      },
      {} as { [key: string]: Game[] },
    ) || {};

  useEffect(() => {
    if (gamesData) {
      const initialOpenSections = Object.keys(gamesByPlatform).reduce(
        (acc, platform) => {
          acc[platform] = true;
          return acc;
        },
        {} as { [key: string]: boolean },
      );
      setOpenSections(initialOpenSections);
    }
  }, [gamesData]);

  const toggleSection = (platform: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [platform]: !prev[platform],
    }));
  };

  const recentlyPlayedGames = gamesData?.slice(0, 4) || [];

  return (
    <UserLayout>
      <div
        className="flex h-full w-full justify-center px-5"
        style={{
          background: isMobile
            ? "linear-gradient(0deg, #140029 61.19%, #1A0920 100%)"
            : "none",
        }}
      >
        <div className="flex w-full max-w-[610px] flex-col gap-y-6 pt-4 min-745:min-w-[500px]">
          <div className="flex w-full flex-col gap-y-4">
            <div className="flex items-center justify-between gap-x-2">
              <p className="h2-mobile">Récemment joués</p>
              {isOwnProfile && (
                <ButtonSimple
                  label="Ajouter des jeux"
                  plusFonction={true}
                  onClick={navigateConnectGames}
                />
              )}
            </div>
            <div className="styled-scrollbars flex w-full flex-row gap-x-4 pb-[2px] pl-[2px] max-mobile:max-w-[610px] max-mobile:overflow-auto min-745:overflow-hidden">
              {recentlyPlayedGames.map((game) => (
                <div
                  key={game.id}
                  className="flex flex-col items-center gap-y-1"
                >
                  <p className="paragraph-s-500 whitespace-nowrap opacity-60">
                    {game.name}
                  </p>
                  <GameCoverColumn
                    banner={game.card}
                    timePlayed=""
                    size="min-w-[140px] h-[210px] rounded-lg hover:scale-[102%]"
                    isClickable={true}
                    gameId={game.id}
                    name={game.name}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full flex-col gap-y-4 pb-20">
            <p className="h2-mobile">Jeux de {userData?.username}</p>
            {Object.entries(gamesByPlatform).map(([platform, games]) => (
              <div
                key={platform}
                className="flex w-full flex-col gap-y-2 border-t border-t-[rgba(248,239,251,0.40)] py-3"
              >
                <div
                  className="flex w-full cursor-pointer items-center justify-between"
                  onClick={() => toggleSection(platform)}
                >
                  <p className="h3-mobile">
                    Jeux {platform} ({games.length})
                  </p>
                  <ChevronIcon
                    orientation={openSections[platform] ? "down" : "up"}
                  />
                </div>
                {openSections[platform] && (
                  <div className="flex flex-col gap-y-2">
                    {games.map((game) => (
                      <div className="rounded-lg px-2 py-1 hover:bg-[rgba(245,235,255,0.20)]">
                        <GameDetailCard
                          key={game.id}
                          gameId={game.id}
                          card={game.card}
                          name={game.name}
                          studioName={game.studios[0]?.name}
                          platforms={game.platforms.map((p) => p.name)}
                          showStatsLabel={game.pseudo}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </UserLayout>
  );
};
