import { GreenDotIcon, RedDotIcon } from "..";

export const BadgeConnected = () => {
  return (
    <div className="inline-flex items-center justify-center gap-x-1 rounded bg-[rgba(255,255,255,0.20)] px-1.5 py-0.5 backdrop-blur-sm">
      <GreenDotIcon />
      <p className="label-m-400 opacity-80">Connecté</p>
    </div>
  );
};

export const BadgeDisconnected = () => {
  return (
    <div className="inline-flex items-center justify-center gap-x-1 rounded bg-[rgba(255,255,255,0.20)] px-1.5 py-0.5 backdrop-blur-sm">
      <RedDotIcon />
      <p className="label-m-400 opacity-80">Non connecté</p>
    </div>
  );
};

export const BadgeHide = () => {
  return (
    <div className="inline-flex items-center justify-center gap-x-1 rounded bg-[rgba(255,255,255,0.10)] px-1.5 py-0.5">
      <p className="label-m-400 opacity-80">Masqué</p>
    </div>
  );
};
