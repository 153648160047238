import React from "react";

type SearchFillIconProps = {
  isFocused?: boolean;
};

export const SearchFillIcon: React.FC<SearchFillIconProps> = ({
  isFocused,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M14.7011 13.4771H13.7977L13.4776 13.1683C14.5982 11.8647 15.2729 10.1723 15.2729 8.33126C15.2729 4.22605 11.9452 0.898438 7.84005 0.898438C3.73484 0.898438 0.407227 4.22605 0.407227 8.33126C0.407227 12.4365 3.73484 15.7641 7.84005 15.7641C9.6811 15.7641 11.3735 15.0894 12.6771 13.9688L12.9858 14.2889V15.1923L18.7034 20.8984L20.4072 19.1946L14.7011 13.4771ZM7.84005 13.4771C4.9927 13.4771 2.69425 11.1786 2.69425 8.33126C2.69425 5.48391 4.9927 3.18546 7.84005 3.18546C10.6874 3.18546 12.9858 5.48391 12.9858 8.33126C12.9858 11.1786 10.6874 13.4771 7.84005 13.4771Z"
      fill={isFocused ? "rgb(59,130,246)" : "#F8EFFB"}
    />
  </svg>
);

export const SearchStrokeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1384 12.8157L13.5591 13.5566L12.818 14.1359C11.524 15.1475 9.89722 15.7484 8.12559 15.7484C3.91305 15.7484 0.5 12.3358 0.5 8.12421C0.5 3.91259 3.91305 0.5 8.12559 0.5C12.3381 0.5 15.7512 3.91259 15.7512 8.12421C15.7512 9.89546 15.1502 11.522 14.1384 12.8157ZM13.626 14.5298V13.6237H14.5323H15.1691C15.2883 13.6237 15.3989 13.67 15.4797 13.7507L19.3726 17.643C19.3728 17.6432 19.3731 17.6435 19.3733 17.6437C19.5428 17.8158 19.5425 18.0938 19.3706 18.2657L18.265 19.371L18.2631 19.3729C18.0966 19.5413 17.8208 19.544 17.6478 19.3711L13.7529 15.4769C13.6722 15.3962 13.626 15.2857 13.626 15.1665V14.5298ZM2.62523 8.12421C2.62523 11.166 5.08787 13.6237 8.12559 13.6237C11.1677 13.6237 13.626 11.1616 13.626 8.12421C13.626 5.08237 11.1633 2.62469 8.12559 2.62469C5.08345 2.62469 2.62523 5.08679 2.62523 8.12421Z"
      stroke="#F8EFFB"
    />
  </svg>
);
