import { useNavigate } from "react-router-dom";
import { BackButton, LogoBeta } from "../../components";

export const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();

  const navigateTerms = () => {
    navigate(`/legal/terms`);
  };

  const navigateGuidelines = () => {
    navigate(`/legal/guidelines`);
  };

  return (
    <div className="flex max-h-[100svh] w-full flex-col gap-y-5 overflow-y-scroll bg-[#140029] px-5 pb-20 pt-10 min-745:px-10">
      <BackButton />
      <div className="relative flex w-full justify-center">
        <LogoBeta />
      </div>
      <h1 className="h1-mobile flex w-full justify-center">
        Politique de Confidentialité
      </h1>
      <p className="paragraph-m-400">Dernière mise à jour : 1er juin 2024</p>

      <p className="paragraph-m-400">
        Les moments de jeu entre amis sont souvent précieux et intimes. Chez
        TalkPlay, nous valorisons cette intimité et nous nous engageons à
        protéger vos données personnelles. Cette Politique de Confidentialité
        fait partie de notre Accord Utilisateur, qui inclut également nos{" "}
        <span
          className="paragraph-m-700 cursor-pointer underline"
          onClick={navigateTerms}
        >
          Termes du Service
        </span>{" "}
        (« Termes ») et nos{" "}
        <span
          className="paragraph-m-700 cursor-pointer underline"
          onClick={navigateGuidelines}
        >
          Normes Communautaires
        </span>
        . Elle vous informe sur les informations que nous recueillons, comment
        nous les utilisons, quand nous les partageons et les contrôles que nous
        mettons en place pour traiter les informations que vous partagez avec
        nous.
      </p>

      <h3 className="h3-mobile">1. Les informations que nous recueillons</h3>

      <p className="paragraph-m-400">
        Le terme <span className="paragraph-m-700">« Informations »</span>{" "}
        désigne des données qui peuvent inclure, sans s’y limiter, des données à
        caractère personnel. TalkPlay collecte des informations que vous nous
        partagez directement et des informations collectées dans le cadre de
        votre utilisation de nos services.
      </p>

      <h4 className="paragraph-m-700">1.1 Données que vous nous partagez</h4>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">
            Données de création de compte :
          </span>{" "}
          nom d'utilisateur, biographie, adresse e-mail, mot de passe, photo de
          profil.
        </li>
        <li>
          <span className="paragraph-m-700">Données de contenu :</span> photos,
          vidéos, commentaires, messages, géolocalisations, données des comptes
          de jeux connectés (ex. Steam, Clash of Clans).
        </li>
      </ul>

      <h4 className="paragraph-m-700">
        1.2 Données que nous collectons lors de l'utilisation de nos Services
      </h4>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">
            Données techniques et de connexion :
          </span>{" "}
          adresse IP, date de création du compte, dernière connexion, équipement
          et point de connexion.
        </li>
        <li>
          <span className="paragraph-m-700">Données d'interaction :</span> amis,
          invitations d'amis, réactions, commentaires, interactions avec des
          jeux.
        </li>
        <li>
          <span className="paragraph-m-700">Données d'utilisation :</span>{" "}
          actions réalisées, publications, contenus visualisés, interactions
          avec le contenu, suppressions, blocages, masquages.
        </li>
        <li>
          <span className="paragraph-m-700">Données de l'appareil :</span> type
          d'appareil, version du système d'exploitation, langue de préférence.
        </li>
        <li>
          <span className="paragraph-m-700">Données de géolocalisation :</span>{" "}
          position précise ou approximative partagée via nos Services.
        </li>
      </ul>

      <h3 className="h3-mobile">2. Traitement des informations</h3>
      <p className="paragraph-m-400">
        Nous utilisons vos informations de manière réfléchie et sécurisée pour
        vous proposer nos Services :
      </p>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">
            Fonctionnement et amélioration des Services :
          </span>{" "}
          création et gestion de compte, réception et affichage de contenu,
          recommandation de comptes et de contenus.
        </li>
        <li>
          <span className="paragraph-m-700">Sécurité et sûreté :</span>{" "}
          protection des utilisateurs et des données, conformité avec les lois.
        </li>
        <li>
          <span className="paragraph-m-700">Communications :</span>{" "}
          notifications et messages concernant les fonctionnalités ou les
          questions de service.
        </li>
      </ul>

      <h3 className="h3-mobile">3. Partage des informations</h3>
      <p className="paragraph-m-400">
        Nous partageons vos informations seulement lorsque nécessaire pour
        fournir nos Services ou lorsque la loi l'exige :
      </p>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Employés de TalkPlay :</span> accès
          aux informations pour fournir et assister les Services.
        </li>
        <li>
          <span className="paragraph-m-700">Autres utilisateurs :</span> partage
          de contenu avec des amis proches ou tous les utilisateurs selon vos
          paramètres de partage.
        </li>
        <li>
          <span className="paragraph-m-700">Prestataires de services :</span>{" "}
          partenaires pour l'hébergement, la maintenance et d'autres services
          essentiels à la fonctionnalité de TalkPlay.
        </li>
        <li>
          <span className="paragraph-m-700">Obligations légales :</span>{" "}
          réponses aux demandes légales, prévention des fraudes et sécurité.
        </li>
        <li>
          <span className="paragraph-m-700">Changement de propriété :</span>{" "}
          partage des informations en cas de fusion, acquisition, réorganisation
          ou vente d'actifs.
        </li>
      </ul>

      <h3 className="h3-mobile">4. Durée de conservation des informations</h3>
      <p className="paragraph-m-400">
        Nous conservons vos informations uniquement pendant la période
        nécessaire à la prestation de nos services :
      </p>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">
            Données de création de compte :
          </span>{" "}
          conservées tant que votre compte est actif.
        </li>
        <li>
          <span className="paragraph-m-700">Données de contenu :</span>{" "}
          conservées tant que votre compte est actif ou jusqu'à suppression.
        </li>
        <li>
          <span className="paragraph-m-700">
            Communications avec TalkPlay :
          </span>{" "}
          conservées pour une durée maximale de 24 mois.
        </li>
        <li>
          <span className="paragraph-m-700">
            Données techniques et de connexion :
          </span>{" "}
          conservées pour une durée maximale de 24 mois.
        </li>
        <li>
          <span className="paragraph-m-700">Données d'interaction :</span>{" "}
          conservées tant que votre compte est actif ou jusqu'à suppression.
        </li>
        <li>
          <span className="paragraph-m-700">Données d'utilisation :</span>{" "}
          conservées pour une durée maximale de 24 mois.
        </li>
        <li>
          <span className="paragraph-m-700">Données de l'appareil :</span>{" "}
          conservées pour une durée maximale de 24 mois.
        </li>
      </ul>

      <h3 className="h3-mobile">5. Contrôle de vos informations</h3>
      <p className="paragraph-m-400">
        Vous pouvez contrôler et gérer vos informations via les réglages de
        l'application :
      </p>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Notifications :</span> choix des
          notifications reçues.
        </li>
        <li>
          <span className="paragraph-m-700">Jeux associés :</span> choix de
          l’affichage des jeux possédés.
        </li>
        <li>
          <span className="paragraph-m-700">
            Blocage ou masquage d'utilisateurs :
          </span>{" "}
          possibilité de bloquer ou masquer des utilisateurs.
        </li>
        <li>
          <span className="paragraph-m-700">Suppression de compte :</span>{" "}
          option de suppression de compte avec délai de 15 jours pour annuler la
          demande.
        </li>
      </ul>

      <h3 className="h3-mobile">6. Vos droits sur vos informations</h3>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Droit d'accès :</span> vous pouvez
          demander une copie des informations que nous détenons sur vous.
        </li>
        <li>
          <span className="paragraph-m-700">Droit de rectification :</span> vous
          pouvez corriger toute information inexacte ou incomplète.
        </li>
        <li>
          <span className="paragraph-m-700">Droit à l'effacement :</span> vous
          pouvez demander la suppression de vos données personnelles.
        </li>
        <li>
          <span className="paragraph-m-700">Droit d'opposition :</span> vous
          pouvez vous opposer au traitement de vos données.
        </li>
        <li>
          <span className="paragraph-m-700">Droit à la portabilité :</span> vous
          pouvez demander que vos données soient transférées à un autre
          fournisseur de services.
        </li>
        <li>
          <span className="paragraph-m-700">Droit de limitation :</span> vous
          pouvez demander la limitation du traitement de vos données.
        </li>
      </ul>

      <h3 className="h3-mobile">7. Cookies et Technologies Similaires</h3>
      <p className="paragraph-m-400">
        Nous utilisons des cookies pour améliorer l'expérience utilisateur. Les
        types de cookies utilisés incluent :
      </p>
      <ul className="paragraph-m-400 list-inside list-disc">
        <li>
          <span className="paragraph-m-700">Cookies de session :</span> utilisés
          pour maintenir la session utilisateur.
        </li>
        <li>
          <span className="paragraph-m-700">Cookies de préférence :</span> pour
          mémoriser vos préférences de site.
        </li>
        <li>
          <span className="paragraph-m-700">Cookies de sécurité :</span> pour
          des raisons de sécurité.
        </li>
      </ul>

      <h3 className="h3-mobile">8. Transferts Internationaux de Données</h3>
      <p className="paragraph-m-400">
        Nous pouvons transférer vos données hors de l'Union Européenne. Dans ce
        cas, nous mettons en place des garanties pour protéger vos données
        conformément aux lois en vigueur.
      </p>

      <h3 className="h3-mobile">9. Liens vers des Sites Externes</h3>
      <p className="paragraph-m-400">
        TalkPlay peut contenir des liens vers des sites externes. Nous ne sommes
        pas responsables des pratiques de confidentialité de ces sites.
      </p>

      <h3 className="h3-mobile">
        10. Modifications de la Politique de Confidentialité
      </h3>
      <p className="paragraph-m-400">
        TalkPlay se réserve le droit de modifier cette politique de
        confidentialité à tout moment. Nous vous notifierons des modifications
        via notre site web ou par d'autres moyens appropriés.
      </p>

      <h3 className="h3-mobile">11. Contact</h3>
      <p className="paragraph-m-400">
        Le responsable du traitement des données pour tous les utilisateurs est
        TalkPlay SAS, sise au 14 rue Lénine 91170 Viry-Châtillon, FRANCE,
        enregistré sous le numéro 980 156 681 au Registre du commerce et des
        sociétés d'Évry.
      </p>
    </div>
  );
};
