import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  GameCoverColumn,
  BadgePlatform,
  OverlayLauncherGames,
  InfoIcon,
  BadgeConnected,
} from "../../components";
import { Game, GameSearchResult } from "../../types";

interface GameDetailCardProps {
  card: string;
  gameId?: string;
  name: string;
  studioName?: string;
  platforms: string[];
  tpPlayers?: number;
  showStatsLabel?: string;
  listGame?: number | string;
  games?: GameSearchResult[];
  onConnect?: () => void;
  isConnected?: boolean;
  userGames?: Game[];
  isClickable?: boolean;
}

export const GameDetailCard: React.FC<GameDetailCardProps> = ({
  card,
  name,
  gameId,
  studioName,
  platforms,
  tpPlayers,
  showStatsLabel,
  listGame,
  games,
  onConnect,
  isConnected,
  userGames = [],
  isClickable = true,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggleOverlay = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  const handleCardClick = () => {
    if (isClickable) {
      navigate(`/game/${gameId}/${name?.replace(/\s+/g, "_") ?? ""}`);
    }
  };

  // Filter out games that the user already owns
  const availableGames = games?.filter(
    (game) =>
      !userGames.some(
        (userGame) => userGame.id === game.id && userGame.hasGame,
      ),
  );

  const renderConnectButton = () => {
    if (onConnect && !isConnected) {
      return (
        <>
          {name !== "test" && name !== "test2" && (
            <p
              className="link-s cursor-pointer no-underline hover:underline"
              onClick={onConnect}
            >
              Se connecter
            </p>
          )}
          {/* {name !== "Brawl Stars" && name !== "Clash Royale" && (
            <p className="link-s cursor-pointer" onClick={onConnect}>
              Se connecter
            </p>
          )}
          {(name === "Brawl Stars" || name === "Clash Royale") && (
            <div className="flex flex-1 flex-col items-end">
              <p className="link-s cursor-pointer text-end" onClick={onConnect}>
                Connecter sans Supercell ID
              </p>
              <p className="label-s-400 text-end">
                Pas de vérification possible sans Supercell ID
              </p>
            </div>
          )} */}
        </>
      );
    } else if (isConnected) {
      return <BadgeConnected />;
    }
    return null;
  };

  const renderListGame = () => {
    if (listGame !== undefined) {
      const listGameNumber =
        typeof listGame === "number" ? listGame : parseInt(listGame, 10);
      if (!isNaN(listGameNumber) && listGameNumber > 0) {
        return (
          <p className="paragraph-s-500 flex items-center gap-x-2">
            {`${listGameNumber} ${listGameNumber === 1 ? "jeu disponible" : "jeux disponibles"}`}{" "}
            <span
              className="paragraph-s-500 cursor-pointer"
              onClick={handleToggleOverlay}
            >
              <InfoIcon size={17} />
            </span>
          </p>
        );
      } else {
        return (
          <p className="paragraph-s-500">
            {typeof listGame === "number"
              ? `${listGame} ${listGame === 1 ? "jeu disponible" : "jeux disponibles"}`
              : listGame}
          </p>
        );
      }
    }
    return null;
  };

  return (
    <>
      <div
        className={`relative flex items-center justify-between ${
          isClickable && "cursor-pointer"
        }`}
        onClick={handleCardClick}
      >
        <div className="flex items-center gap-x-4 self-stretch py-2">
          <GameCoverColumn
            banner={card}
            size="min-w-[66px] !h-[100px]"
            isClickable={false}
          />
          <div className="flex flex-col items-start justify-between self-stretch">
            <div className="flex flex-col items-start gap-y-1 self-stretch">
              <div className="flex flex-col items-start self-stretch">
                <p className="nav-menu-700">{name}</p>
                <p className="paragraph-s-500">{studioName}</p>
              </div>
              <div className="flex flex-wrap gap-1">
                {platforms.map((platform) => (
                  <BadgePlatform key={platform} label={platform} />
                ))}
              </div>
            </div>
            {tpPlayers && (
              <p className="label-s-400">Joueurs TP : {tpPlayers}</p>
            )}
            {showStatsLabel && <p className="label-s-400">{showStatsLabel}</p>}
            {renderListGame()}
          </div>
        </div>
        {renderConnectButton()}
        {isOverlayOpen && availableGames && (
          <OverlayLauncherGames
            launcherName={name}
            games={availableGames}
            onClose={handleToggleOverlay}
          />
        )}
      </div>
    </>
  );
};
