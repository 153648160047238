import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonSimple,
  ChevronIcon,
  InfoIcon,
  ListButton,
} from "../../../components";
import { TextareaAutosize } from "@mui/base";

export const ReportPost = () => {
  const navigate = useNavigate();
  const { postId } = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  const listItems = [
    {
      label: "Choisir un motif",
      onClick: () => console.log("Choisir un motif"),
      className: "opacity-50",
    },
    {
      label: "Contenu inapproprié",
      onClick: () => console.log("Contenu inapproprié signalé"),
    },
    {
      label: "Comportement inapproprié",
      onClick: () => console.log("Comportement inapproprié signalé"),
    },
  ];

  return (
    <div className="flex flex-col items-start gap-y-6 self-stretch px-5 pb-[60px] pt-[80px] max-mobile:min-h-[100svh]">
      <div className="flex h-[36px] items-center justify-between self-stretch">
        <ChevronIcon
          orientation="left"
          className="h-6 w-6"
          onClick={handleBack}
        />
        <p className="h3-mobile">Signaler le post</p>
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex flex-col items-start gap-y-4 self-stretch">
          <p className="h4-mobile self-stretch">Motif du signalement</p>
          <ListButton
            items={listItems}
            className="self-stretch"
            overlay={false}
            size="h-[50px]"
          />
        </div>
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex flex-col items-start gap-y-4 self-stretch">
          <div className="flex items-start justify-between gap-x-1 self-stretch">
            <p className="h4-mobile self-stretch">
              Ajoute un commentaire au signalement (facultatif)
            </p>
            <p className="label-s-400">FACULTATIF</p>
          </div>
          <TextareaAutosize
            placeholder="Tom commentaire"
            maxRows={4}
            className="label-m-400 mb-[1px] flex grow select-text resize-none self-stretch break-words rounded-[20px] border-[0.5px] border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] px-6 pb-[5px] pt-3 opacity-50 focus:opacity-100"
            style={{ minHeight: "80px", maxHeight: "150px" }}
          />
          <div className="flex items-center gap-x-2 self-stretch px-4 opacity-70">
            <InfoIcon size={18} />
            <p className="label-s-400">
              Sois le plus précis possible pour que nous puissions comprendre la
              situation
            </p>
          </div>
        </div>
      </div>
      <ButtonSimple
        label="Envoyer"
        plusFonction={false}
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    </div>
  );
};
