type CustomCheckboxProps = {
  checked: boolean;
  onChange: () => void;
};

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
}) => {
  return (
    <div
      className={`relative flex h-4 w-4 cursor-pointer rounded-[2px] shadow-[inset_0_0_2px_#FFF] backdrop-blur-[2px] ${
        checked
          ? "border border-[rgba(175,171,179)] bg-red"
          : "bg-[rgba(47,26,64,0.60)]"
      }`}
      onClick={onChange}
    ></div>
  );
};
