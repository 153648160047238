type BadgeSettingProps = {
  content: string | number;
};

export const BadgeSetting: React.FC<BadgeSettingProps> = ({ content }) => {
  return (
    <div className="flex items-center justify-center rounded bg-[rgba(255,255,255,0.10)] px-3 py-[2px]">
      <p className="label-s-400">{content}</p>
    </div>
  );
};
