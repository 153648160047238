import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchUserGameplay } from "../../helpers/fetchUser";
import {
  ButtonSimple,
  GameplayPreviewCard,
  SubtitleNavigation,
} from "../../components";
import { Gameplay } from "../../types";
import { useAuth } from "../../providers/userAuth";
import { UserLayout } from "./UserLayout";
import { useMediaQuery } from "react-responsive";

const postButtons = [
  { title: "Populaire", path: "" },
  { title: "Jeux", path: "jeux" },
  { title: "Date", path: "date" },
];

export const UserGameplayPage = () => {
  const { username } = useParams();
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [gameplays, setGameplays] = useState<Gameplay[]>([]);
  const [filteredGameplays, setFilteredGameplays] = useState<Gameplay[]>([]);
  const [games, setGames] = useState<string[]>(["Tous"]);
  const isMobile = useMediaQuery({ maxWidth: 745 });

  useEffect(() => {
    const fetchGameplays = async () => {
      if (username) {
        try {
          const userGameplays = await fetchUserGameplay(username)();
          setGameplays(userGameplays);
          setFilteredGameplays(userGameplays);
          const gameNames = [
            "Tous",
            ...Array.from(new Set(userGameplays.map((g) => g.game.name))),
          ];
          setGames(gameNames);
        } catch (error) {
          console.error("Erreur lors de la récupération des gameplays", error);
        }
      }
    };

    fetchGameplays();
  }, [username]);

  const isOwner = auth.user?.username === username;
  const handleDeleteGameplay = (gameplayId: string) => {
    setGameplays((prevGameplays) =>
      prevGameplays.filter((gameplay) => gameplay.id !== gameplayId),
    );
    setFilteredGameplays((prevFilteredGameplays) =>
      prevFilteredGameplays.filter((gameplay) => gameplay.id !== gameplayId),
    );
  };

  const handleNavigation = (path: string) => {
    navigate(`/user/${username}/gameplay${path ? `/${path}` : ""}`);
  };

  return (
    <UserLayout>
      <div
        className="flex h-full w-full flex-col items-center gap-y-4 px-5"
        style={{
          background: isMobile
            ? "linear-gradient(0deg, #140029 61.19%, #1A0920 100%)"
            : "none",
        }}
      >
        <div className="flex w-full flex-col items-center pb-8 max-mobile:pt-4 max-tablet:gap-y-8">
          <div className="flex w-full flex-col items-center gap-y-4 max-tablet:max-w-[610px]">
            <div className="flex w-full flex-wrap items-center justify-center gap-3">
              {filteredGameplays.length > 0 ? (
                filteredGameplays.map((gameplay) => (
                  <GameplayPreviewCard
                    key={gameplay.id}
                    gameplay={gameplay}
                    autoPlay={false}
                  />
                ))
              ) : (
                <div className="flex flex-col items-center gap-y-4">
                  <p className="paragraph-m-400">
                    {isOwner
                      ? "Vous n'avez encore rien publié. Commencez par partager un gameplay!"
                      : "Cet utilisateur n'a encore rien publié."}
                  </p>
                  {isOwner && (
                    <ButtonSimple
                      label="Partager un gameplay"
                      onClick={() => navigate("/creategameplay")}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};
