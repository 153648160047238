import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { format, isToday, isYesterday, parseISO, isThisYear } from "date-fns";
import {
  Chat,
  HeaderUserDiscussion,
  TypingMessage,
  MediaThumbnail,
  Loading,
} from "../../../components";
import { useAuth } from "../../../providers/userAuth";
import { Message } from "../../../types/Discussion";
import { User } from "../../../types/User";
import {
  fetchDiscussionMessages,
  markDiscussionAsSeen,
  sendMessageWithVideo,
} from "../../../helpers/Discussion";
import socket from "../../../socket";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertToBase64, convertToWebP } from "../../../utils/File";

const formatDate = (date: Date) => {
  const currentYear = new Date().getFullYear();
  if (isThisYear(date)) {
    return format(date, "eeee, d MMMM");
  }
  return format(date, "eeee, d MMMM yyyy");
};

export const DiscussionUser = () => {
  const { id } = useParams<{ id: string }>();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const auth = useAuth();
  const [discussionUser, setDiscussionUser] = useState<User>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [content, setContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const markAsSeen = async () => {
    try {
      await markDiscussionAsSeen(id!);
    } catch (err) {
      console.error("Error marking discussion as seen:", err);
    }
  };

  useEffect(() => {
    if (id !== null) {
      const fetchMessages = async () => {
        try {
          const data = await fetchDiscussionMessages(id!);
          const sortedMessages = data.messages.sort(
            (a: Message, b: Message) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          );
          setMessages(sortedMessages);
          setDiscussionUser(
            data.user.id == auth.user?.id ? data.user2 : data.user,
          );
        } catch (err) {
          console.error("Error fetching messages:", err);
          setError("Failed to fetch messages.");
        } finally {
          setLoading(false);
          setTimeout(() => {
            if (messagesEndRef.current) {
              messagesEndRef.current.scrollIntoView({ behavior: "auto" });
            }
          });
        }
      };

      if (auth.token) {
        fetchMessages();
        markAsSeen();
      }
    }
  }, [auth, id]);

  useEffect(() => {
    socket.emit("joinDiscussion", id);

    socket.on("newMessage", (message: any) => {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, message];
        return newMessages.sort(
          (a: Message, b: Message) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
      });
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (message.userId !== auth.user?.id) {
        markAsSeen();
      }
    });

    return () => {
      socket.off("newMessage");
    };
  }, [id]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages]);

  useEffect(() => {
    return () => {
      if (auth.token) {
        markAsSeen();
      }
    };
  }, [auth.token, id]);

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && selectedFiles.length + files.length > 1) {
      toast.error("Veuillez mettre au maximum 1 média", {
        position: "top-center",
        autoClose: 3000,
      });
    } else if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };

  const handleDeleteClick = (index: number) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const sendMessage = async () => {
    if (content.trim() || selectedFiles.length) {
      const messageData: any = {
        discussionId: id,
        content,
        userId: auth.user?.id,
        files: [],
      };

      for (const file of selectedFiles) {
        if (file.type.startsWith("image")) {
          const webPFile = await convertToWebP(file);
          const base64 = await convertToBase64(webPFile);
          messageData.files.push({
            name: webPFile.name,
            type: webPFile.type,
            data: base64,
          });
        } else {
          await sendMessageWithVideo(id || "", content, file);
        }
      }

      socket.emit("discussionMessage", messageData);
      setContent("");
      setSelectedFiles([]);
    }
  };

  const renderMessages = () => {
    const messagesWithDates: any[] = [];
    let lastDate: Date | null = null;

    messages.forEach((msg) => {
      const messageDate = parseISO(msg.createdAt);
      if (!lastDate || lastDate.toDateString() !== messageDate.toDateString()) {
        messagesWithDates.push({
          type: "date",
          date: messageDate,
        });
        lastDate = messageDate;
      }
      messagesWithDates.push({
        type: "message",
        ...msg,
      });
    });

    return messagesWithDates.map((item, index) => {
      if (item.type === "date") {
        return (
          <div key={index} className="flex w-full justify-center">
            <p className="label-s-400 my-2 rounded-full px-2 py-1">
              {isToday(item.date)
                ? "Aujourd'hui"
                : isYesterday(item.date)
                  ? "Hier"
                  : formatDate(item.date)}
            </p>
          </div>
        );
      }

      return (
        <Chat
          key={item.id}
          isUserMessage={item.userId === auth.user?.id}
          text={item.content}
          time={format(parseISO(item.createdAt), "HH:mm")}
          files={item.files}
          isGroupMessage={false}
        />
      );
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="h1-mobile">{error}</div>;
  }

  return (
    <div
      className={`z-20 flex max-h-[calc(100vh-58px)] flex-col border-l border-l-[rgba(198,182,213,0.25)] ${isWideScreen ? "" : "absolute inset-0 min-h-screen"}`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <HeaderUserDiscussion
        user={discussionUser?.username || ""}
        banner={discussionUser?.banner || ""}
        logo={discussionUser?.logo || ""}
        discussionUser={discussionUser?.username || ""}
      />
      <div className="styled-scrollbars overflow-auto max-mobile:h-[calc(100vh-80px)] min-745:h-[calc(100vh-125px)] min-745:bg-[#3D2347]">
        <div
          ref={messagesContainerRef}
          className="flex flex-col gap-y-[12px] px-5 max-mobile:pt-[200px] min-745:pt-1"
        >
          {renderMessages()}
          <div ref={messagesEndRef} />
        </div>
      </div>
      {selectedFiles.length > 0 && (
        <div className="flex gap-x-2 px-5 py-1">
          {selectedFiles.slice(0, 4).map((file, index) => (
            <MediaThumbnail
              isVideo={file.type.includes("video")}
              key={index}
              banner={URL.createObjectURL(file)}
              size="m"
              showSelectionIndicator={true}
              onClick={() => handleDeleteClick(index)}
            />
          ))}
        </div>
      )}
      <input
        type="file"
        accept="image/*,video/*"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        multiple
        style={{ display: "none" }}
      />
      <TypingMessage
        content={content}
        onContentChange={setContent}
        onSendMessage={sendMessage}
        onFileInputClick={handleFileInputClick}
      />
      <ToastContainer />
    </div>
  );
};
