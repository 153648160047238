import { useNavigate } from "react-router-dom";
import { AvatarIcon, GreenDotIcon } from "../../";

type FriendProps = {
  username: string;
  logo: string;
  size?: "normal" | "small";
  classname?: string;
};

export const Friend: React.FC<FriendProps> = ({
  username,
  logo,
  size = "normal",
  classname,
}) => {
  const isSmall = size === "small";
  const navigate = useNavigate();

  const navigateFriend = () => {
    navigate(`/user/${username}`);
  };
  return (
    <div
      className={`flex ${isSmall && "justify-center"} ${classname} cursor-pointer items-center gap-x-2 self-stretch pl-5 pr-2`}
      onClick={navigateFriend}
    >
      <AvatarIcon logo={logo} className={isSmall ? "avatar-xs" : "avatar-s"} />
      {/* <GreenDotIcon /> */}
      {!isSmall && <p className="label-m-400">{username}</p>}
    </div>
  );
};
