import React from "react";
import BsTrophy from "../../../../assets/BStrophy.png";
import { ImageCard, CardStat } from "../../..";
import { PlayerStats } from "../../../../types";

interface BsStatsProps {
  playerStats: PlayerStats;
  hasGameAccount: boolean;
}

export const BsStats: React.FC<BsStatsProps> = ({
  playerStats,
  hasGameAccount,
}) => {
  return (
    <div className="flex flex-col gap-y-6">
      {hasGameAccount && playerStats?.club?.name && (
        <div className="flex items-center justify-center rounded-md bg-[rgba(245,235,255,0.10)] px-4 py-3 shadow-[1px_2px_1px_rgba(0,0,0,0.45)] backdrop-blur-[2px]">
          <p className="h3-mobile">{playerStats?.club.name}</p>
        </div>
      )}
      {hasGameAccount && playerStats?.trophies && (
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex gap-x-3">
            <CardStat direction="column">
              <div className="flex items-center justify-center gap-x-2">
                <p className="paragraph-s-700">{playerStats.trophies}</p>
                <ImageCard imageUrl={BsTrophy} size={18} />
              </div>
              <p className="label-s-400 text-center opacity-60">
                Trophées actuels
              </p>
            </CardStat>
            <CardStat direction="column">
              <div className="flex items-center justify-center gap-x-2">
                <p className="paragraph-s-700">{playerStats.highestTrophies}</p>
                <ImageCard imageUrl={BsTrophy} size={18} />
              </div>
              <p className="label-s-400 text-center opacity-60">
                Record trophées
              </p>
            </CardStat>
          </div>
        </div>
      )}
    </div>
  );
};
