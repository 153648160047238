import {
  SettingIcon,
  ViewHideSettingIcon,
  RingIcon,
  DisconnectIcon,
  EditIcon,
  SearchSettingIcon,
  ImagesSettingIcon,
  SilentSettingIcon,
  UserSettingIcon,
  BlockSettingIcon,
  ReportSettingIcon,
  NewMessageSettingIcon,
  RemoveSettingIcon,
  DownloadSettingIcon,
  LegalSettingIcon,
  MessageSettingIcon,
  HelpSettingIcon,
  MailSettingIcon,
  PasswordSettingIcon,
  CommunityFillIcon,
} from "../../index";

type SettingFrameProps = {
  settingIconName: string;
  noBackground?: boolean;
};

type settingIconNameComponents = {
  [key: string]: React.ElementType;
};

function getsettingIconNameComponent(platform: string) {
  const settingIconName: settingIconNameComponents = {
    Cogwheel: SettingIcon,
    ViewHide: ViewHideSettingIcon,
    Ring: RingIcon,
    Disconnect: DisconnectIcon,
    Edit: EditIcon,
    Search: SearchSettingIcon,
    Images: ImagesSettingIcon,
    Silent: SilentSettingIcon,
    User: UserSettingIcon,
    Block: BlockSettingIcon,
    Report: ReportSettingIcon,
    NewMessage: NewMessageSettingIcon,
    Remove: RemoveSettingIcon,
    Download: DownloadSettingIcon,
    Legal: LegalSettingIcon,
    Message: MessageSettingIcon,
    Help: HelpSettingIcon,
    Mail: MailSettingIcon,
    Password: PasswordSettingIcon,
    Community: CommunityFillIcon,
  };

  return settingIconName[platform];
}

export const SettingFrame: React.FC<SettingFrameProps> = ({
  settingIconName,
  noBackground = false,
}) => {
  const IconComponent = getsettingIconNameComponent(settingIconName);

  return (
    <div
      className={`flex h-[24px] w-[24px] items-center justify-center rounded-[5px] p-[5px] ${noBackground ? "" : "bg-[rgb(117,34,143,0.8)]"}`}
    >
      <IconComponent />
    </div>
  );
};
