interface BurgerMenuIconProps {
  onClick: () => void;
}

export const BurgerMenuIcon: React.FC<BurgerMenuIconProps> = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="22"
    viewBox="0 0 32 22"
    fill="none"
    onClick={onClick}
    style={{ cursor: "pointer" }}
  >
    <path d="M1 1.00012H31ZM1 11.0002H31ZM1 21.0002H31Z" fill="white" />
    <path
      d="M1 1.00012H31M1 11.0002H31M1 21.0002H31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
