import { ButtonSimple, TransparentSecondaryButton, CloseFillIcon } from "../";

type NotificationCardProps = {
  friendDemand?: boolean;
  newFollower?: boolean;
  event?: boolean;
  connexion?: boolean;
  gamename?: string;
  name?: string;
  isRead?: boolean;
  eventname?: string;
  onRemove: () => void;
};

export const NotificationCard: React.FC<NotificationCardProps> = ({
  friendDemand,
  newFollower,
  event,
  connexion,
  gamename,
  name,
  isRead,
  eventname,
  onRemove,
}) => {
  let headerName = "";

  if (friendDemand) headerName = "DEMANDE D’AMIS";
  if (newFollower) headerName = "NOUVEAU FOLLOWERS";
  if (event) headerName = "EVENEMENT";
  if (connexion) headerName = "CONNEXION";

  return (
    <div
      className={`flex flex-col items-start gap-y-2 self-stretch rounded-lg ${isRead ? "border-2 border-primary-500 bg-[rgba(40,12,49,0.20)] shadow-[0_0_8px_rgba(0,0,0,0.35),_0_0_6px_#972EB6]" : "bg-[rgba(245,235,255,0.10)]"} px-[14px] py-[10px] `}
    >
      <div className="flex items-center justify-between self-stretch">
        <p className="label-s-400">{headerName}</p>
        <CloseFillIcon onClick={onRemove} />
      </div>
      <div className="paragraph-m-400 self-stretch">
        {friendDemand && (
          <p>
            <span className="paragraph-m-700">{name}</span> souhaite t'ajouter
            en tant qu'ami.
          </p>
        )}
        {newFollower && (
          <p>
            <span className="paragraph-m-700">{name}</span> a commencé à vous
            suivre.
          </p>
        )}
        {connexion && (
          <p>
            Votre connexion à{" "}
            <span className="paragraph-m-700">{gamename}</span> a expirée.
            Reconnecte toi pour charger tes données.
          </p>
        )}
        {event && (
          <p>
            L’évènement <span className="paragraph-m-700">{eventname}</span> a
            commencé.
          </p>
        )}
      </div>
      {friendDemand && (
        <div className="flex items-start justify-between gap-x-3 self-stretch py-[6px]">
          <TransparentSecondaryButton label="Refuser" size="h-[33px] w-[45%]" />
          <ButtonSimple
            label="Accepter"
            plusFonction={false}
            size="h-[33px] w-[45%]"
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      )}
      {connexion && (
        <div className="flex items-end justify-end self-stretch py-[6px]">
          <ButtonSimple
            label="Connexion"
            plusFonction={false}
            size="h-[33px] w-[45%]"
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      )}
    </div>
  );
};
