import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchGamePage } from "../../helpers/fetchGame";
import { BottomBar, HeaderGame, Loading } from "../../components";
import { GamePageType, StudioGames } from "../../types";
import { fetchStudioGames } from "../../helpers/fetchStudio";

interface GameLayoutProps {
  children: (data: GamePageType, studioGames: StudioGames[]) => ReactNode;
}

export const GameLayout = ({ children }: GameLayoutProps) => {
  const { gameId } = useParams();
  const [studioGames, setStudioGames] = useState<StudioGames[]>([]);
  const { data, isLoading, isError } = useQuery<GamePageType, Error>(
    ["gamePage", gameId],
    fetchGamePage(gameId ? gameId : ""),
  );

  useEffect(() => {
    const fetchGames = async (studioId: string) => {
      try {
        const games = await fetchStudioGames(studioId);
        setStudioGames(games);
      } catch (error) {
        console.error("Error fetching studio games:", error);
      }
    };

    if (data && data.studios.length > 0) {
      fetchGames(data.studios[0].id);
    }
  }, [data]);

  return (
    <div className="flex min-h-[100svh] w-full flex-col">
      <div className="w-full">
        {data && (
          <HeaderGame
            name={data.name}
            banner={data.banner}
            playerCount={data.playerCount}
            follow={data.follow}
            hasGame={data.hasGame || false}
            studioLogo={data.studios[0]?.logo}
            logo={data.logo}
            studioName={data.studios[0]?.name}
            iosLink={data.downloadLink?.ios}
            androidLink={data.downloadLink?.android}
            pcLink={data.downloadLink?.pc}
            platform={data.platforms.map((platform) => platform.name)}
          />
        )}
      </div>
      {isLoading && <Loading />}
      {isError && <div className="h1-mobile">{isError}</div>}
      {data && children(data, studioGames)}
      <BottomBar />
    </div>
  );
};
