import { useState, useEffect } from "react";
import { SwitchButton, BackButton, ChevronIcon } from "../../../../components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../providers/userAuth";
import axios, { AxiosResponse } from "axios";
import { updateUserUrl } from "../../../../constants/api";

export const SettingPrivacyUser = () => {
  const { user: authUser } = useAuth();
  const [privateProfil, setPrivateProfil] = useState<boolean>(false);
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      setPrivateProfil(Boolean(authUser.private));
    }
  }, [authUser]);

  const togglePrivateProfil = async () => {
    const newPrivateStatus = !privateProfil;
    setPrivateProfil(newPrivateStatus);

    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      return;
    }

    try {
      const response: AxiosResponse = await axios.patch(
        updateUserUrl,
        { private: newPrivateStatus ? 1 : 0 },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      // Optionnel : Mettre à jour l'utilisateur localement si nécessaire
      // Par exemple : authUser.private = newPrivateStatus ? 1 : 0;
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil.", error);
      setPrivateProfil(!newPrivateStatus); // Revenir à l'état précédent en cas d'erreur
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`flex min-h-[calc(100vh-58px)] w-full flex-col gap-y-6 border-l border-l-[rgba(198,182,213,0.25)] px-5 pb-[60px] pt-10 max-mobile:pt-[40px] ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="min-745:hidden">
        <BackButton />
      </div>
      <div className="flex flex-col items-start gap-y-4 self-stretch border-b border-b-[rgba(248,239,251,0.50)] pb-6">
        <div className="flex items-center gap-x-10 self-stretch">
          <ChevronIcon
            orientation="left"
            onClick={navigateBack}
            className="cursor-pointer max-mobile:hidden"
          />
          <p className="h1-mobile">Confidentialité</p>
        </div>
        <div className="flex items-center justify-between self-stretch">
          <p className="nav-menu-700">Profil privé</p>
          <SwitchButton
            isSelected={privateProfil}
            onClick={togglePrivateProfil}
          />
        </div>
        <p className="paragraph-s-500 self-stretch">
          Ton profil est actuellement {privateProfil ? "privé" : "public"}.{" "}
          {privateProfil
            ? "Seules les personnes autorisées peuvent voir tes informations."
            : "Toutes tes informations sont visibles par les autres utilisateurs de TalkPlay."}
        </p>
      </div>
    </div>
  );
};
