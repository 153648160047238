export const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M9.78542 2.56098L11.9778 4.96723C12.0701 5.0686 12.0701 5.23399 11.9778 5.33537L7.77812 10.0529L5.52257 10.3277C5.22118 10.365 4.96597 10.0849 5 9.75412L5.25035 7.27851L9.45 2.56098C9.54236 2.4596 9.69306 2.4596 9.78542 2.56098ZM13.7229 1.95008L12.5368 0.648247C12.1674 0.242759 11.567 0.242759 11.1951 0.648247L10.3347 1.59261C10.2424 1.69398 10.2424 1.85938 10.3347 1.96075L12.5271 4.367C12.6194 4.46837 12.7701 4.46837 12.8625 4.367L13.7229 3.42264C14.0924 3.01448 14.0924 2.35556 13.7229 1.95008ZM12.1333 9V12.2927H1.55556V1.59261H7.14097C7.21875 1.59261 7.41076 1.59527 7.46667 1.53659L8.31979 0.512195C8.50451 0.309451 8.11319 0 8.11319 0H1.16667C0.522569 0 0 0.885671 0 1.59261V12.7195C0 13.4264 0.52257 14 1.16667 14H12.1333C12.7774 14 14 13.4264 14 12.7195V7.5C14 7.21456 13.7181 7.29992 13.5333 7.5L12.5 8.38719C12.5 8.38719 12.1333 8.91463 12.1333 9Z"
      fill="#F8EFFB"
    />
  </svg>
);

export const EditRoundedIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="16" height="16" rx="8" fill="#F8EFFB" />
    <path
      d="M8.2362 6.43986L10.0413 8.22533C10.1174 8.30055 10.1174 8.42328 10.0413 8.4985L6.58343 11.999L4.72625 12.2029C4.47809 12.2306 4.26795 12.0227 4.29597 11.7773L4.5021 9.94034L7.96002 6.43986C8.03607 6.36464 8.16015 6.36464 8.2362 6.43986ZM11.4783 5.98656L10.5016 5.02058C10.1974 4.7197 9.70313 4.7197 9.39694 5.02058L8.68848 5.72131C8.61243 5.79653 8.61243 5.91926 8.68848 5.99448L10.4936 7.77995C10.5697 7.85517 10.6938 7.85517 10.7698 7.77995L11.4783 7.07922C11.7825 6.77636 11.7825 6.28744 11.4783 5.98656Z"
      fill="#0842A4"
    />
  </svg>
);

export const EditRounded25Icon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="25" height="25" rx="12.5" fill="#F8EFFB" />
    <path
      opacity="0.8"
      d="M12.8827 9.1637L15.8058 12.055C15.929 12.1768 15.929 12.3755 15.8058 12.4973L10.2063 18.1658L7.19888 18.496C6.79703 18.5408 6.45675 18.2043 6.50212 17.8068L6.83591 14.8322L12.4355 9.1637C12.5586 9.0419 12.7595 9.0419 12.8827 9.1637ZM18.1327 8.42966L16.5512 6.86542C16.0586 6.37819 15.2581 6.37819 14.7623 6.86542L13.6151 8.00014C13.4919 8.12194 13.4919 8.32068 13.6151 8.44248L16.5382 11.3338C16.6614 11.4556 16.8623 11.4556 16.9855 11.3338L18.1327 10.1991C18.6253 9.70863 18.6253 8.91689 18.1327 8.42966Z"
      fill="#0842A4"
    />
  </svg>
);

export const EditIconFrame = () => (
  <div
    className="flex h-[34px] w-[34px] items-center justify-center rounded-[7px] border-[0.3px] border-solid border-white px-[4px] "
    style={{ backgroundColor: "rgba(255, 255, 255, 0.25)" }}
  >
    <EditIcon />
  </div>
);
