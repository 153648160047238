import { useRef, useEffect, useState, forwardRef } from "react";
import { ButtonSimple, CloseFillIcon, PlatformsFrame, SimpleInput } from "../";

type Platform = {
  name: string;
  url?: string;
  edit?: boolean;
  configured?: boolean;
};

type OverlayAddPlatformsProps = {
  existingPlatforms?: Platform[];
  availablePlatforms?: Platform[];
  onClick?: () => void;
};

export const OverlayAddPlatforms = forwardRef<
  HTMLDivElement,
  OverlayAddPlatformsProps
>(({ existingPlatforms, availablePlatforms, onClick }, ref) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(
    null,
  );
  const [inputUrl, setInputUrl] = useState("");
  const [isAddedSuccessfully, setIsAddedSuccessfully] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const closeModal = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        ref &&
        "current" in ref &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [ref]);

  const getFullUrl = (input: string) => {
    if (!selectedPlatform) return "";
    const baseUrls: { [key: string]: string } = {
      Twitch: `https://www.twitch.tv/${input}`,
      Facebook: `https://www.facebook.com/${input}`,
      Twitter: `https://twitter.com/${input}`,
      Instagram: `https://www.instagram.com/${input}`,
      YouTube: `https://www.youtube.com/@${input}`,
      TikTok: `https://www.tiktok.com/@${input}`,
    };
    return baseUrls[selectedPlatform.name] || input;
  };

  const validateUrlOrUsername = (input: string) => {
    if (!selectedPlatform) return false;

    const regexPatterns: { [key: string]: RegExp } = {
      Twitch: /^https:\/\/www\.twitch\.tv\/[a-zA-Z0-9_]+$/,
      Facebook: /^https:\/\/www\.facebook\.com\/[a-zA-Z0-9_.]+$/,
      Twitter: /^https:\/\/twitter\.com\/[a-zA-Z0-9_]+$/,
      Instagram: /^https:\/\/www\.instagram\.com\/[a-zA-Z0-9_.]+$/,
      YouTube: /^https:\/\/www\.youtube\.com\/@[a-zA-Z0-9_]+$/,
      TikTok: /^https:\/\/www\.tiktok\.com\/@[a-zA-Z0-9_.]+$/,
    };
    const regex = regexPatterns[selectedPlatform.name];

    return regex.test(input) || regex.test(getFullUrl(input));
  };

  const handleAddPlatform = () => {
    const urlToValidate = inputUrl.startsWith("http")
      ? inputUrl
      : getFullUrl(inputUrl);
    if (validateUrlOrUsername(urlToValidate)) {
      console.log("URL is correct and ready to send to server:", urlToValidate);
      setIsAddedSuccessfully(true);
    } else {
      console.log("URL is not valid.");
    }
  };

  useEffect(() => {
    if (selectedPlatform && selectedPlatform.url) {
      setInputUrl(selectedPlatform.url);
    }
  }, [selectedPlatform]);

  const combinedPlatforms = [
    ...(availablePlatforms ?? []),
    ...(existingPlatforms ?? []),
  ];

  return isVisible ? (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-90 p-5">
      <div
        id="modal-content"
        ref={ref}
        className="flex flex-col items-center justify-center gap-y-5 rounded-3xl border-2 border-transparent px-5 pb-8 pt-6 opacity-90 shadow-[0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
        style={{
          background:
            "linear-gradient(293deg, #402C48 0.12%, #9552A9 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #402C48 40%) border-box",
        }}
      >
        <div className="flex items-center justify-between gap-x-2 self-stretch">
          <p className="h2-mobile">Ajouter une plateforme</p>
          <div className="cursor-pointer" onClick={onClick}>
            <CloseFillIcon rotated={false} />
          </div>
        </div>
        <div className="flex flex-wrap items-start gap-x-4 self-stretch">
          {combinedPlatforms.map((platform) => (
            <PlatformsFrame
              key={platform.name}
              platform={platform.name}
              onClick={() => setSelectedPlatform(platform)}
              url={platform.url}
              isVerified={platform.configured}
            />
          ))}
        </div>
        {selectedPlatform && (
          <>
            <div className="flex items-center gap-x-2 self-stretch">
              <div className="w-[44px]">
                <PlatformsFrame platform={selectedPlatform.name} />
              </div>
              <div className="w-full">
                <SimpleInput
                  placeholder={`URL ou ton pseudo ${selectedPlatform.name}`}
                  iconType="check"
                  isCorrect={validateUrlOrUsername(inputUrl)}
                  value={inputUrl}
                  onChange={(e) => setInputUrl(e.target.value)}
                />
              </div>
            </div>
            <ButtonSimple
              label={isAddedSuccessfully ? "Ajout avec succès !" : "Ajouter"}
              plusFonction={false}
              onClick={handleAddPlatform}
              disabled={!validateUrlOrUsername(inputUrl)}
            />
          </>
        )}
      </div>
    </div>
  ) : null;
});
