import { DotsIconFrame, ImagesIcon, BackButton } from "../..";
import { useNavigate, useParams } from "react-router-dom";

type HeaderUserDiscussionProps = {
  banner?: string;
  logo?: string;
  user: string;
  discussionUser: string;
};

export const HeaderUserDiscussion: React.FC<HeaderUserDiscussionProps> = ({
  banner,
  user,
  logo,
  discussionUser,
}) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const handleSettingsDiscussion = () => {
    navigate(`/messages/user/${id}/settings`, {
      state: { discussionUser, logo },
    });
  };

  const navigateMediaPage = () => {
    navigate(`/message/user/${id}/medias`);
  };

  const defaultBackground =
    "linear-gradient(0deg, #280C31 7.83%, rgba(8, 0, 16, 0.1) 52.26%, rgba(0, 0, 0, 0.00) 66.65%), linear-gradient(285deg, rgba(14, 65, 168) 10.65%, rgba(132, 35, 129) 26.88%, rgba(250, 5, 90) 60.48%, rgba(211, 19, 132) 82.82%, rgba(152, 40, 197) 103.6%)";

  return (
    <div className="left-0 right-0 top-0 z-10 opacity-100 max-mobile:fixed min-745:sticky">
      <div
        className="flex w-full flex-col items-center justify-end pt-[109px]"
        style={{
          background: banner
            ? `linear-gradient(0deg, #280C31 10.91%, rgba(27, 8, 33, 0.06) 41.77%, rgba(9, 3, 12, 0.10) 77.2%, rgba(0, 0, 0, 0.11) 81.91%), url(${banner}) lightgray 50% / cover no-repeat`
            : defaultBackground,
        }}
      >
        <div className="flex w-[90%] items-end justify-between pb-4">
          <div className="flex flex-col items-start gap-y-2">
            <p
              className="h1-mobile"
              style={{ textShadow: "0px 2px 5px rgba(0, 0, 0, 0.45)" }}
            >
              {user}
            </p>
          </div>
          <div className="flex items-start items-center justify-between gap-x-4 self-stretch">
            <DotsIconFrame onClick={handleSettingsDiscussion} />
          </div>
        </div>
        <div className="absolute left-[20px] top-[40px] min-745:hidden">
          <BackButton path="/messages" />
        </div>
        <span
          className="h-[1px] w-full"
          style={{
            background:
              "linear-gradient(90deg, rgba(248, 239, 251, 0.00) 0%, #F8EFFB 47.5%, rgba(248, 239, 251, 0.00) 100%)",
          }}
        />
      </div>
    </div>
  );
};
