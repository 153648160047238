import {
  BadgeConnected,
  BadgeDisconnected,
  ChevronIcon,
  DotsIcon,
  GamesIcons,
} from "..";

type AddedGameProps = {
  logo: string;
  gamename: string;
  pseudo: string;
  level: number;
  isConnected: boolean;
};

type AddedGameGroupProps = {
  logo: string;
  gamename: string;
};

export const AddedGame: React.FC<AddedGameProps> = ({
  logo,
  gamename,
  pseudo,
  level,
  isConnected,
}) => {
  return (
    <div className="flex items-center gap-x-3 self-stretch rounded-lg border border-transparent bg-[#f5ebff1a] px-4 pb-2.5 pt-2">
      <div className="flex items-start">
        <GamesIcons logo={logo} className="game-thumbnail-l" />
      </div>
      <div className="flex flex-1 flex-shrink-0 flex-col items-start gap-y-2 self-stretch">
        <div className="flex flex-col items-start gap-y-[2px] self-stretch">
          <div className="flex items-center justify-between self-stretch">
            <p className="h4-mobile">{gamename}</p>
            <div className="cursor-pointer opacity-50">
              <DotsIcon />
            </div>
          </div>
          {isConnected ? <BadgeConnected /> : <BadgeDisconnected />}
        </div>
        <div className="flex items-start justify-between self-stretch">
          <div className="flex flex-col items-start">
            <p className="label-s-400">Pseudo</p>
            <p className="label-s-600">{pseudo}</p>
          </div>
          <div className="flex flex-col items-start">
            <p className="label-s-400">Level</p>
            <div className="flex items-center gap-y-1">
              <p className="label-s-600">{level}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddedGameGroup: React.FC<AddedGameGroupProps> = ({
  logo,
  gamename,
}) => {
  return (
    <div className="flex cursor-pointer items-center gap-x-3 self-stretch rounded-lg border border-transparent bg-[#f5ebff1a] px-4 pb-[10px] pt-2">
      <div className="flex items-start">
        <GamesIcons logo={logo} className="game-thumbnail-l" />
      </div>
      <div className="flex w-full items-center justify-between gap-x-1">
        <p className="h3-mobile self-stretch">{gamename}</p>
        <ChevronIcon orientation="right" />
      </div>
    </div>
  );
};
