import { ChevronBackButtonIcon } from "..";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  path?: string;
  cancel?: boolean;
  onClick?: () => void;
  shadow?: boolean;
  label?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({
  path,
  cancel,
  onClick,
  shadow = true,
  label,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className="flex cursor-pointer gap-x-1 whitespace-nowrap"
      onClick={onClick ? onClick : handleClick}
    >
      <ChevronBackButtonIcon orientation="left" />
      <span
        className="button-label"
        style={{ textShadow: shadow ? "0px 4px 5px rgba(0, 0, 0)" : "none" }}
      >
        {label ? label : cancel ? "Annuler" : "Retour"}{" "}
      </span>
    </div>
  );
};
