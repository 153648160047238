import { useEffect, useState } from "react";
import { EyesHideWhiteIcon, WarningIcon, CloseFillIcon } from "../";
import React from "react";
import { useNavigate } from "react-router-dom";
import { hideUser } from "../../helpers/fetchUser";

type UserOptionsProps = {
  userId: string;
  name: string;
  onClose: () => void;
};

export const UserOptions: React.FC<UserOptionsProps> = ({
  userId,
  name,
  onClose,
}) => {
  const [isUserHidden, setIsUserHidden] = useState(false);
  const overlayRef = React.createRef<HTMLDivElement>();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef, onClose]);

  const navigateReportUser = () => {
    navigate(`/user/${name}/report`, { state: { userId, name } });
  };

  const handleHideUser = async () => {
    try {
      await hideUser(userId);
      setIsUserHidden(true);
      onClose();
    } catch (error) {
      console.error("Error hiding user:", error);
    }
  };

  return (
    <div
      ref={overlayRef}
      className="absolute right-0 top-0 z-[100] flex w-[400px] flex-col gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-1 backdrop-blur-[6.5px]"
      style={{
        background:
          "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
      }}
    >
      <div className="flex items-center justify-between self-stretch py-3 pl-2">
        <p className="button-label items-start self-stretch">
          Options utilisateur
        </p>
        <CloseFillIcon onClick={onClose} />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch pb-1">
        <div
          className="flex cursor-pointer flex-wrap content-center items-center gap-x-[10px] self-stretch px-1"
          onClick={navigateReportUser}
        >
          <WarningIcon />
          <p className="nav-menu-500">Signaler utilisateur</p>
        </div>
        <div
          className="flex cursor-pointer flex-wrap content-center items-center gap-x-[8px] self-stretch pl-[2px] pr-1"
          onClick={handleHideUser}
        >
          <EyesHideWhiteIcon />
          <p className="nav-menu-500">
            {isUserHidden ? (
              "Utilisateur masqué"
            ) : (
              <>
                Masquer <span className="nav-menu-700">{name}</span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
