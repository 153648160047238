import { useMediaQuery } from "react-responsive";
import { PostCard } from "../";
import { Post } from "../../types";
import { useNavigate, useParams } from "react-router-dom";

interface UserActivityProps {
  posts: Post[];
  title: "Dernières activités" | "Activités de jeu";
}

export const UserActivity: React.FC<UserActivityProps> = ({ posts, title }) => {
  const { username } = useParams();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({ query: "(min-width: 851px)" });

  const encodeUsername = (username: string | undefined) => {
    return encodeURIComponent(
      (username ?? "").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const userPage = () => {
    navigate(`/user/${encodeUsername(username)}/posts`);
  };
  return (
    <div
      className={`flex h-auto flex-col gap-y-4 self-stretch rounded ${
        title === "Activités de jeu" && "p-4"
      }`}
      style={{
        background:
          title === "Activités de jeu" && isLargeScreen
            ? "linear-gradient(99deg, rgb(69 45 77 / 68%) -3.96%, rgba(98, 79, 117, 0.50) 104.82%)"
            : "none",
      }}
    >
      <div className="flex items-center justify-between self-stretch">
        <div className="h2-mobile">{title}</div>
        {posts.length > 0 && (
          <div className="link-s cursor-pointer" onClick={userPage}>
            Voir plus
          </div>
        )}
      </div>
      <div
        className={`flex flex-col gap-y-1 gap-y-2 self-stretch rounded ${posts.length === 0 ? "" : "pb-4"}  ${
          title === "Dernières activités" && ""
        }`}
      >
        {posts.length === 0 ? (
          <div className="paragraph-s-500 w-full py-2 text-center">
            L'utilisateur n'a rien posté pour le moment.
          </div>
        ) : (
          posts.map((post) => (
            <PostCard key={post.id} post={post} showInteractions={false} />
          ))
        )}
      </div>
    </div>
  );
};
