import { useState } from "react";
import {
  ChevronIcon,
  CloseFillIcon,
  CorrectCheck,
  FalseCheck,
} from "../../../components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export const Silence = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMuted, setIsMuted] = useState(false);
  const [muteOption, setMuteOption] = useState("");
  const params = useParams<{ id: string }>();
  const { id } = params;
  const isWideScreen = useMediaQuery({ minWidth: 745 });

  const handleDiscussion = () => {
    if (location.pathname.includes("group")) {
      navigate(`/messages/group/${id}`);
    } else if (location.pathname.includes("user")) {
      navigate(`/messages/user/${id}`);
    }
  };

  const muteOptionsMap = {
    "1h": "1 heure",
    "8h": "8 heures",
    "1d": "1 jour",
    always: "toujours",
  };

  const handleMuteClick = (option: keyof typeof muteOptionsMap) => {
    setIsMuted(true);
    setMuteOption(muteOptionsMap[option]);
    console.log(`Notifications muted for ${option}`);
  };

  const handleReactivateClick = () => {
    setIsMuted(false);
    setMuteOption("");
    console.log("Notifications reactivated");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`z-20 flex flex-col items-center gap-y-8 px-5 pb-[60px] pt-[35px] max-mobile:pt-[60px] ${isWideScreen ? "" : "absolute inset-0 min-h-screen"}`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="flex h-[36px] items-center justify-between self-stretch">
        <ChevronIcon
          orientation="left"
          className="h-6 w-6 cursor-pointer"
          onClick={handleBack}
        />
        <p className="h3-mobile">Silence</p>
        <div className="h-6 w-6">
          <CloseFillIcon onClick={handleDiscussion} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-y-4 self-stretch">
        <div className="flex items-center justify-between self-stretch bg-[#f5ebff33] px-4 py-3 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]">
          <p className="nav-menu-500">Notifications activées</p>
          {isMuted ? <FalseCheck /> : <CorrectCheck />}
        </div>
        <div
          className="flex cursor-pointer items-center self-stretch bg-[#f5ebff33] px-4 py-3 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
          onClick={handleReactivateClick}
        >
          <p className="nav-menu-500">Réactiver</p>
        </div>
        <p className="paragraph-s-500">
          Tu peux désactiver les notifications pendant un certain temps ou
          manuellement.
        </p>
        <div
          className="flex cursor-pointer items-center self-stretch bg-[#f5ebff33] px-4 py-3 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
          onClick={() => handleMuteClick("1h")}
        >
          <p className="nav-menu-500">1 heure</p>
        </div>
        <div
          className="flex cursor-pointer items-center self-stretch bg-[#f5ebff33] px-4 py-3 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
          onClick={() => handleMuteClick("8h")}
        >
          <p className="nav-menu-500">8 heures</p>
        </div>
        <div
          className="flex cursor-pointer items-center self-stretch bg-[#f5ebff33] px-4 py-3 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
          onClick={() => handleMuteClick("1d")}
        >
          <p className="nav-menu-500">1 jour</p>
        </div>
        <div
          className="flex cursor-pointer items-center self-stretch bg-[#f5ebff33] px-4 py-3 shadow-[inset_0.5px_0.5px_1px_rgba(248,239,251,0.50),_0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
          onClick={() => handleMuteClick("always")}
        >
          <p className="nav-menu-500">Toujours</p>
        </div>
      </div>
      {isMuted && (
        <p className="button-label self-stretch text-center">
          Les notifications sont désactivées pour {muteOption}
        </p>
      )}
    </div>
  );
};
