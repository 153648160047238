import axios, { AxiosResponse } from "axios";
import {
  createPostUrl,
  deletePostUrl,
  fetchOnePostUrl,
  getTimelineUrl,
  likePostUrl,
  repostPostUrl,
  unLikePostUrl,
  unRepostPostUrl,
} from "../constants/api";
import {
  Post,
  PostPanelAdmin,
  PostResponse,
  PostTimeline,
} from "../types/Post";
import { convertToWebP } from "../utils/File";

export const fetchOnePost = (postId: string) => async (): Promise<Post> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<Post> = await axios.get(
      fetchOnePostUrl + postId,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    const postData: Post = response.data;
    return postData;
  } catch (error) {
    console.error("Error fetching post:", error);
    throw error;
  }
};

export const fetchTimeline = async (): Promise<PostTimeline[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response = await axios.get<PostTimeline[]>(getTimelineUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching post:", error);
    throw error;
  }
};

export const fetchPostPanelAdmin =
  (postId: string) => async (): Promise<PostPanelAdmin> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<PostPanelAdmin> = await axios.get(
        fetchOnePostUrl + postId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const postPanelData: PostPanelAdmin = response.data;
      return postPanelData;
    } catch (error) {
      console.error("Error fetching post:", error);
      throw error;
    }
  };

export const createPost = async (
  content: string,
  gameId: string,
  files: File[],
  parentId?: string,
  quoteId?: string,
): Promise<PostResponse> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("content", content);
  if (gameId !== "") {
    formData.append("gameId", gameId);
  }
  if (parentId) {
    formData.append("parentId", parentId);
  }
  if (quoteId) {
    formData.append("quoteId", quoteId);
  }

  for (const file of files) {
    if (file.type.startsWith("image/")) {
      const webPFile = await convertToWebP(file);
      formData.append("files", webPFile);
    } else {
      formData.append("files", file);
    }
  }

  const response = await axios.post<PostResponse>(createPostUrl, formData, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const likePost = async (postId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.post<void>(
    likePostUrl,
    { postId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unLikePost = async (postId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(unLikePostUrl, {
    data: { postId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const repostPost = async (postId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.post<void>(
    repostPostUrl,
    { postId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unRepostPost = async (postId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(unRepostPostUrl, {
    data: { postId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const deletePost = async (postId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(deletePostUrl + postId, {
    data: { postId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};
