import { useEffect, useState } from "react";
import { GameContent } from "../../components";
import { useAuth } from "../../providers/userAuth";
import { User } from "../../types";
import { GameLayout } from "./GameLayout";

export const GamePage = () => {
  const auth = useAuth();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  return (
    <GameLayout>
      {(data, studioGames) => (
        <GameContent
          data={data}
          studioLogo={data.studios[0]?.logo}
          studioName={data.studios[0]?.name}
          studioDescription={data.studios[0]?.description}
          studioWebsite={data.studios[0]?.websiteUrl}
          studioGames={studioGames}
        />
      )}
    </GameLayout>
  );
};
