type SelectionIndicatorProps = {
  isSelected: boolean;
};

export const SelectionIndicator: React.FC<SelectionIndicatorProps> = ({
  isSelected,
}) => {
  return (
    <div
      className={`${isSelected ? "shadow-[0px_0px_1px_0px_#F8EFFB_inset]" : "bg-[rgba(245,235,255,0.25)] shadow-[inset_0_0_1px_rgba(255,255,255,0.55)]"} relative h-6 w-6 rounded-full outline-none focus:outline-none`}
    >
      {isSelected && (
        <span className="absolute inset-0 flex items-center justify-center">
          <span className="block h-4 w-4 rounded-full bg-red"></span>
        </span>
      )}
    </div>
  );
};
