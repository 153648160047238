import {
  AvatarIcon,
  BadgeSetting,
  ChevronIcon,
  CloseFillIcon,
  ImagesSettingIcon,
  SilentSettingIcon,
  SearchSettingIcon,
  BlockSettingIcon,
  ReportSettingIcon,
  TrashSettingIcon,
  BackButton,
  useOverlay,
  ConfirmBlockUser,
  ConfirmationModal,
} from "../../../components";
import { useState, useEffect } from "react";
import { useAuth } from "../../../providers/userAuth";
import { User } from "../../../types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { deleteDiscussion } from "../../../helpers/Discussion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultAvatar from "../../../assets/DefaultAvatar.png";

export const SettingDiscussion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user: authUser } = useAuth();
  const username = authUser?.username;
  const [userData, setUserData] = useState<User>();
  const { id } = useParams<{ id: string }>();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const { openContentModal, closeModal } = useOverlay();

  const discussionUser = location.state?.discussionUser || "Utilisateur";
  const logoUser = location.state?.logo || DefaultAvatar;

  const numberMedia = 31;
  const notificationActive = "Notifications activées";

  useEffect(() => {
    setUserData(authUser);
  }, [authUser]);

  const navigateHomePage = () => {
    navigate(-1);
  };

  const navigateSilentPage = () => {
    navigate(`/messages/user/${id}/silent`);
  };

  const navigateSearchPage = () => {
    navigate(`/messages/user/${id}/search`);
  };

  const navigateMediaPage = () => {
    navigate(`/messages/user/${id}/medias`);
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigateProfilePage = () => {
    navigate(`/user/${encodeUsername(discussionUser)}`);
  };

  const navigateReportPage = () => {
    navigate(`/user/${encodeUsername(discussionUser)}/report`);
  };

  const handleBlockUser = () => {
    openContentModal(<ConfirmBlockUser onClick={closeModal} />);
  };

  const handleDeleteDiscussion = async () => {
    if (!id) {
      console.error("Discussion ID is undefined");
      toast.error("Erreur : ID de la discussion non défini.");
      return;
    }
    try {
      await deleteDiscussion(id);
      navigate("/messages");
      toast.success("Discussion supprimée avec succès.");
    } catch (error) {
      console.error("Error deleting discussion:", error);
      toast.error("Erreur lors de la suppression de la discussion.");
    }
  };

  const confirmDeleteDiscussion = () => {
    openContentModal(
      <ConfirmationModal
        title="Confirmer la suppression de la discussion"
        message="Êtes-vous sûr de vouloir supprimer cette discussion ? Cette action est irréversible."
        onConfirm={() => {
          handleDeleteDiscussion();
          closeModal();
        }}
        onCancel={closeModal}
      />,
    );
  };

  if (!authUser) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p className="h1-mobile">
          Veuillez vous connecter pour accéder aux options de la discussion.
        </p>
      </div>
    );
  }

  return (
    <div
      className={`z-20 flex w-full flex-col items-center justify-between gap-y-8 px-5 pb-[60px] max-mobile:pt-[80px] ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <ToastContainer />
      <div className="flex flex-col items-start gap-y-6 self-stretch pt-8">
        <div className="flex items-center justify-between gap-x-2 self-stretch">
          <div className="cursor-pointer pt-1 max-mobile:hidden">
            <BackButton onClick={navigateHomePage} />
          </div>
          <p className="h1-mobile">Options de la discussion</p>
          <div className="cursor-pointer min-745:hidden">
            <CloseFillIcon onClick={navigateHomePage} />
          </div>
        </div>
        <div
          className="flex w-full items-center gap-x-2 rounded-xl border border-[rgba(198,182,213,0.3)] p-2 shadow-[0_2px_4px_rgba(0,0,0,0.25)]"
          style={{
            background:
              "linear-gradient(0deg, rgba(245, 235, 255, 0.10) 0%, rgba(245, 235, 255, 0.10) 100%), rgba(151, 46, 182, 0.10)",
          }}
        >
          <AvatarIcon logo={logoUser} className="avatar-xs" />
          <div className="flex flex-col justify-center">
            <p className="h4-mobile">{discussionUser}</p>
            <p className="link-s cursor-pointer" onClick={navigateProfilePage}>
              Voir profil
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-6 self-stretch">
          <div className="flex flex-col items-start gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
            <p className="h2-mobile">Discussion</p>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateMediaPage}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <ImagesSettingIcon />
                <p className="nav-menu-500">Médias de la discusion</p>
              </div>
              <div className="flex items-center gap-x-2">
                <BadgeSetting content={numberMedia} />
                <ChevronIcon orientation="right" />
              </div>
            </div>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateSilentPage}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <SilentSettingIcon />
                <p className="nav-menu-500">Silence</p>
              </div>
              <div className="flex items-center gap-x-2">
                <BadgeSetting content={notificationActive} />
                <ChevronIcon orientation="right" />
              </div>
            </div>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateSearchPage}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <SearchSettingIcon />
                <p className="nav-menu-500">Rechercher</p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
          </div>
          <div className="flex flex-col items-start gap-y-4 self-stretch pb-6">
            <p className="h2-mobile">Paramètres</p>

            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={confirmDeleteDiscussion}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <TrashSettingIcon />
                <p className="nav-menu-500">Supprimer la discussion</p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={handleBlockUser}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <BlockSettingIcon />
                <p className="nav-menu-500">Bloquer l'utilisateur</p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateReportPage}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <ReportSettingIcon />
                <p className="nav-menu-500">Signaler l'utilisateur</p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
