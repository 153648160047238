import {
  ParticipantGroup,
  SimpleInput,
  BackAndButtonNext,
  ButtonSimple,
  Loading,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { fetchFriends } from "../../../helpers/fetchUser";
import { useAuth } from "../../../providers/userAuth";
import { Friend } from "../../../types";
import axios from "axios";
import { createDiscussionUrl } from "../../../constants/api";

export const CreateDiscussion = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const [friendsData, setFriendsData] = useState<Friend[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const hasFetchedFriends = useRef(false);

  useEffect(() => {
    const getFriends = async () => {
      if (user?.username && !hasFetchedFriends.current) {
        try {
          const friends = await fetchFriends(user.username)();
          setFriendsData(friends.slice(0, 6));
          hasFetchedFriends.current = true;
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      } else if (!user?.username) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    const delayFetchFriends = setTimeout(() => {
      getFriends();
    }, 300);

    return () => clearTimeout(delayFetchFriends);
  }, [user]);

  const navigateToCreateMessage = async () => {
    if (selectedPlayer === null) {
      setErrorMessage(
        "Veuillez sélectionner un utilisateur avant de lancer un nouveau message.",
      );
      return;
    }

    try {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        setErrorMessage("Auth token not found");
        return;
      }

      const response = await axios.post(
        createDiscussionUrl,
        { userId: selectedPlayer },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      if (response.data.discussionId) {
        navigate(`/messages/user/${response.data.discussionId}`);
      } else {
        const discussionId = response.data.id;
        navigate(`/messages/user/${discussionId}`);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data.message ===
          "Discussion already exists between these users."
      ) {
        navigate(`/messages/user/${error.response.data.discussionId}`);
      } else {
        setErrorMessage("Erreur lors de la création de la discussion.");
        console.error("Error creating discussion:", error);
      }
    }
  };

  const togglePlayerSelection = (playerId: string) => {
    setSelectedPlayer(playerId === selectedPlayer ? null : playerId);
    setErrorMessage(null);
  };

  if (isLoading) return <Loading />;
  if (isError) return <div className="h1-mobile">Error loading friends.</div>;

  return (
    <div
      className={`z-20 flex w-full flex-col ${
        isWideScreen ? "h-[calc(100vh-60px)]" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="flex flex-grow flex-col items-start gap-y-6 self-stretch overflow-auto px-5 pt-8 max-mobile:pt-[60px]">
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex w-full items-center justify-between gap-x-1">
            <p className="h1-mobile">Nouveau message</p>
            {friendsData.length > 0 && (
              <div className="max-mobile:hidden">
                <ButtonSimple
                  newMessage={true}
                  onClick={navigateToCreateMessage}
                  showChevron={true}
                  chevronDirection="right"
                  size="h-[34px]"
                />
              </div>
            )}
          </div>
          <SimpleInput placeholder="Rechercher un ami" iconType="none" />
        </div>
        {errorMessage && (
          <div className="h4-mobile text-red">{errorMessage}</div>
        )}
        <div className="styled-scrollbars flex flex-col items-start gap-y-4 self-stretch overflow-auto p-1 pr-2">
          {friendsData.length > 0 ? (
            friendsData.map((friend) => (
              <ParticipantGroup
                key={friend.id}
                name={friend.username}
                image={friend.logo}
                isSelected={selectedPlayer === friend.id}
                onToggle={() => togglePlayerSelection(friend.id)}
                showOptions={false}
              />
            ))
          ) : (
            <p className="h3-mobile w-full text-center">
              Vous n'avez pas encore d'amis, ajoutez-les ou invitez-les !
            </p>
          )}
        </div>
      </div>
      <BackAndButtonNext
        back={true}
        navigateTo={navigateToCreateMessage}
        label="Lancer la discussion"
      />
    </div>
  );
};
