import { useContext, createContext, useState, ReactNode } from "react";
import { login, register } from "../helpers/Auth";
import { fetchUserByToken } from "../helpers/fetchUser";
import { User } from "../types";

const AuthContext = createContext<any>("");

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  const getUserByToken = async () => {
    try {
      const response = await fetchUserByToken()();
      if (response) {
        setUser(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const registerAction = async (
    username: string,
    email: string,
    password: string,
  ) => {
    try {
      const response = await register(username, email, password)();
      if (response) {
        setUser(response.user);
        setToken(response.token);
        localStorage.setItem("token", response.token.token);
        return true;
      }
      throw new Error(response.message);
    } catch (err) {
      return err;
    }
  };

  const loginAction = async (inputs: any) => {
    try {
      const response = await login(inputs.login, inputs.password)();
      if (response) {
        setUser(response.user);
        setToken(response.token);
        localStorage.setItem("token", response.token.token);
        return true;
      }
      throw new Error(response.message);
    } catch (err) {
      return err;
    }
  };

  const logoutAction = () => {
    setUser(undefined);
    setToken("");
    localStorage.removeItem("token");
  };

  if (user == null) {
    getUserByToken();
  }

  return (
    <AuthContext.Provider
      value={{ token, user, loginAction, logoutAction, registerAction }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = (): {
  token: string;
  user: User;
  loginAction: any;
  logoutAction: any;
  registerAction: any;
} => {
  return useContext(AuthContext);
};
