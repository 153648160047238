import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { CrossAddWhiteIcon, OverlayAddPlatforms, PlatformsFrame } from "..";

type UserPlatformsProps = {
  platforms?: { name: string; url: string; edit?: boolean; pseudo: string }[];
  socialNetworks?: boolean;
  edit?: boolean;
};

export const UserPlatforms: React.FC<UserPlatformsProps> = ({
  platforms,
  edit,
  socialNetworks,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleOverlay = () => setShowOverlay(!showOverlay);
  const overlayRef = useRef(null);

  const allPlatforms = [
    { name: "YouTube", url: "" },
    { name: "Discord", url: "" },
    { name: "TikTok", url: "" },
    { name: "Instagram", url: "" },
  ];

  const unownedPlatforms = allPlatforms.filter(
    (ap) => !platforms?.some((p) => p.name === ap.name),
  );

  const overlayPlatforms = unownedPlatforms.map((platform) => ({
    ...platform,
    configured: false,
  }));

  const existingPlatforms = platforms?.map((platform) => ({
    ...platform,
    configured: true,
  }));

  return (
    <div className="flex flex-col gap-y-4 self-stretch">
      <p className="h2-mobile">
        {socialNetworks ? "Réseaux sociaux" : "Plateformes"}
      </p>
      <div className="flex flex-col gap-y-4 self-stretch">
        {edit && (
          <div
            className="flex items-center gap-x-4 self-stretch"
            onClick={toggleOverlay}
          >
            <div className="h-[26px] w-[26px] cursor-pointer">
              <CrossAddWhiteIcon />
            </div>
            <p className="link-s cursor-pointer">
              Ajoute des réseaux où les autres joueurs peuvent aussi te suivre
            </p>
          </div>
        )}
        <div className="flex w-full flex-col gap-y-4 self-stretch">
          {platforms?.map((platform) => (
            <PlatformsFrame
              key={platform.name}
              platform={platform.name}
              url={platform.url}
              edit={platform.edit}
              pseudo={platform.pseudo}
            />
          ))}
        </div>
      </div>
      <CSSTransition
        in={showOverlay}
        timeout={600}
        classNames="overlay"
        unmountOnExit
        nodeRef={overlayRef}
      >
        <OverlayAddPlatforms
          existingPlatforms={existingPlatforms}
          availablePlatforms={overlayPlatforms}
          onClick={toggleOverlay}
        />
      </CSSTransition>
    </div>
  );
};
