import { LinkIcon, UserPlatforms } from "../../components";

interface GameDescriptionProps {
  description: string;
  platformsWithUrls?: Array<{ name: string; url: string; pseudo: string }>;
}

export const GameDescription: React.FC<GameDescriptionProps> = ({
  description,
  platformsWithUrls,
}) => {
  return (
    <div
      className="flex flex-col items-start justify-center gap-y-6 rounded-xl p-5"
      style={{
        background:
          "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
      }}
    >
      <div className="flex flex-col items-start gap-y-4 self-stretch">
        <p className="h2-mobile">Description</p>
        <p className="paragraph-m-400">{description}</p>
        {/* <div className="flex cursor-pointer items-center gap-x-3">
          <LinkIcon />
          <p className="link-s">Site officiel</p>
        </div> */}
      </div>
      {platformsWithUrls && platformsWithUrls.length > 0 && (
        <UserPlatforms socialNetworks={true} platforms={platformsWithUrls} />
      )}
    </div>
  );
};
