import {
  OverlayUnauthenticated,
  InputSearch,
  GameCoverColumn,
  BackAndButtonNext,
  ButtonSimple,
  Loading,
} from "../../../components";
import { useEffect, useState } from "react";
import { useAuth } from "../../../providers/userAuth";
import { User, Game } from "../../../types";
import { fetchAllGames } from "../../../helpers/fetchGame";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export const ChooseGame = () => {
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [games, setGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery({ minWidth: 745 });

  useEffect(() => {
    setUser(auth.user);

    const fetchGames = async () => {
      try {
        const fetchedGames = await fetchAllGames();
        setGames(fetchedGames);
      } catch (error) {
        setError("Erreur lors de la récupération des jeux.");
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [auth]);

  const handleGameSelect = (game: Game) => {
    setSelectedGame(selectedGame?.id === game.id ? null : game);
  };

  const navigateToCreateGroup = () => {
    if (selectedGame) {
      const targetPath = isWideScreen
        ? "/messages/creategroup/participants"
        : "/messages/creategroup";
      navigate(targetPath, {
        state: { selectedGame },
      });
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col max-mobile:pt-[58px] min-745:min-h-[calc(100svh-58px)] min-851:gap-y-6 min-851:pl-8 min-851:pr-5 min-851:pt-8">
      {loading && <Loading />}
      {error && <div className="h1-mobile text-red">{error}</div>}
      <div className="flex items-start gap-x-8 max-tablet:px-5">
        <div className="flex w-full flex-col gap-y-4 pb-10 max-tablet:max-w-[610px] min-851:max-w-[560px]">
          <div className="flex items-center justify-between">
            <p className="h1-mobile">Sélectionner un jeu</p>
            <div className="flex items-center gap-x-6">
              {selectedGame ? (
                <p className="label-m-400">{selectedGame.name} sélectionné</p>
              ) : (
                <p className="label-m-400">Aucun jeu sélectionné</p>
              )}
              <ButtonSimple label="Ajouter" onClick={navigateToCreateGroup} />
            </div>
          </div>
          <InputSearch
            placeholder="Rechercher un jeu"
            showLogoSection={false}
          />
          <div className="flex w-full flex-col items-start gap-y-4 self-stretch ">
            <div className="styled-scrollbars flex w-[100%] flex-grow flex-wrap items-start gap-x-6">
              {games.map((game) => (
                <GameCoverColumn
                  key={game.id}
                  banner={game.logo}
                  name={game.name}
                  gameId={game.id}
                  size="min-w-[140px] cursor-pointer"
                  isSelected={selectedGame?.id === game.id}
                  onToggle={() => handleGameSelect(game)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {!user && <OverlayUnauthenticated />}
      <BackAndButtonNext
        selectedPlayersCount={selectedGame ? 1 : 0}
        navigateTo={navigateToCreateGroup}
        label="Ajouter"
        isPlayer={false}
        infoChoice={true}
        showSelectedCountText={true}
      />
    </div>
  );
};
