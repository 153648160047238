import { useState } from "react";
import { BackButton, ButtonSimple, SimpleInput } from "../../../components";
import { forgotPassword } from "../../../helpers/Auth";

export const LostPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(isValidEmail(newEmail));
    setSuccess("");
  };
  // function forgotPassword(email: string): Promise<boolean> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (email === "test@example.com") {
  //         resolve(true);
  //       } else {
  //         reject(new Error("Function not implemented."));
  //       }
  //     }, 1000);
  //   });
  // }

  const handleLostPassword = async () => {
    if (email === "") {
      setError("Veuillez renseigner votre email");
      return;
    }

    if (!isValidEmail(email)) {
      setError("Veuillez renseigner un email valide");
      return;
    }

    try {
      const result = await forgotPassword(email)();
      if (result) {
        setSuccess("Un email vous a été envoyé !");
        setError("");
      } else {
        setError("Aucun utilisateur trouvé avec cet email");
      }
    } catch (err) {
      setError("Erreur lors de l'envoi de l'email");
    }
  };

  return (
    <div className="relative flex min-h-screen max-w-[550px] flex-col items-start gap-y-4 self-stretch pt-[96px]">
      <div className="flex flex-col items-start gap-y-4 self-stretch px-5 min-745:gap-y-10">
        <div className="flex flex-col items-center justify-center gap-y-1 self-stretch self-stretch min-745:gap-y-6">
          <h1 className="h1-mobile">Mot de passe oublié</h1>
          <p className="paragraph-m-400 self-stretch">
            Saisis l'email avec lequel tu t'es inscrit pour recevoir le lien de
            réinitialisation du mot de passe.
          </p>
        </div>
        <div className="flex flex-col items-start gap-y-6 self-stretch">
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Adresse mail</p>
            <SimpleInput
              iconType="check"
              placeholder="Ton adresse mail"
              value={email}
              onChange={handleEmailChange}
              isCorrect={isEmailValid}
              hasError={Boolean(error)}
              errorText={error}
            />
          </div>
          <ButtonSimple
            label="Envoyer"
            plusFonction={false}
            onClick={handleLostPassword}
          />
          {success && (
            <div className="mt-4 w-full">
              <p className="nav-menu-700 text-center text-green">{success}</p>
            </div>
          )}
        </div>
      </div>

      <div className="absolute left-[20px] top-[60px]">
        <BackButton />
      </div>
    </div>
  );
};
