import {
  AvatarIcon,
  DotsIcon,
  StrokeCorrectCheck35,
  StrokeFalseCheck35,
  FriendOptions,
} from "..";
import { useState } from "react";
import { useAuth } from "../../providers/userAuth";
import { addFriend, removeFriend } from "../../helpers/fetchUser";
import { useNavigate } from "react-router-dom";

type FriendCardProps = {
  logo: string;
  username: string;
  isConnected?: boolean;
  friendDemand?: boolean;
  userId: string;
  isClickable?: boolean;
};

export const FriendCard: React.FC<FriendCardProps> = ({
  logo,
  username,
  isConnected,
  friendDemand = false,
  userId,
  isClickable = false,
}) => {
  const [status, setStatus] = useState<string>(
    friendDemand ? "pending" : "false",
  );
  const [showOptions, setShowOptions] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleAccept = async () => {
    try {
      await addFriend(userId);
      setStatus("accepted");
    } catch (error) {
      console.error("Failed to accept friend request:", error);
    }
  };

  const handleReject = async () => {
    try {
      await removeFriend(userId);
      setStatus("rejected");
    } catch (error) {
      console.error("Failed to reject friend request:", error);
    }
  };

  const toggleOptions = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowOptions(!showOptions);
  };

  const handleRemoveFriend = () => {
    setStatus("removed");
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const handleCardClick = () => {
    if (isClickable) {
      navigate(`/user/${encodeUsername(username)}`);
    }
  };

  return (
    <div
      className="relative flex cursor-pointer items-center justify-between gap-x-3 self-stretch border-b-[0.5px] border-b-[rgba(248,239,251,0.37)] px-1 py-2 hover:bg-[rgba(245,235,255,0.20)]"
      onClick={handleCardClick}
    >
      <div className="flex items-start items-center gap-x-3">
        <AvatarIcon logo={logo} className="avatar-m text-center" />
        <div className="flex flex-col gap-y-0.5">
          <p className="h4-mobile">{username}</p>
        </div>
      </div>
      {friendDemand && status === "pending" ? (
        <div
          className="flex items-start items-center gap-x-3"
          onClick={(e) => e.stopPropagation()}
        >
          <div onClick={handleAccept}>
            <StrokeCorrectCheck35 />
          </div>
          <div onClick={handleReject}>
            <StrokeFalseCheck35 />
          </div>
        </div>
      ) : status === "accepted" ? (
        <p className="h4-mobile">Accepté</p>
      ) : status === "rejected" ? (
        <p className="h4-mobile">Rejeté</p>
      ) : status === "removed" ? (
        <p className="h4-mobile">Enlevé</p>
      ) : (
        <div
          className="flex h-full items-center gap-x-3"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="cursor-pointer" onClick={toggleOptions}>
            <DotsIcon />
          </div>
          {showOptions && (
            <FriendOptions
              userId={userId}
              username={username}
              onClose={() => setShowOptions(false)}
              onRemoveFriend={handleRemoveFriend}
            />
          )}
        </div>
      )}
    </div>
  );
};
