import { SpinnerLoading } from "../";

interface LoadingProps {
  text?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  text = "Chargement en cours",
}) => {
  return (
    <div className="z-[100] flex h-full w-full flex-col items-center justify-center gap-y-1">
      <div className="h2-mobile text-center">{text}</div>
      <SpinnerLoading />
    </div>
  );
};
