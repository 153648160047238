import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  ChevronIcon,
  CloseFillIcon,
  ConfirmLeaveGroup,
  DisconnectIcon,
  DisplayedUsers,
  EditIcon,
  GamesIcons,
  ReportSettingIcon,
  ConfirmationModal,
} from "../../../components";
import { useState, useEffect } from "react";
import { useAuth } from "../../../providers/userAuth";
import { User } from "../../../types";
import { CSSTransition } from "react-transition-group";
import { useMediaQuery } from "react-responsive";
import { deleteGroup } from "../../../helpers/Group";

export const SettingsGroup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user: authUser } = useAuth();
  const [userData, setUserData] = useState<User>();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleOverlay = () => setShowOverlay(!showOverlay);
  const { id } = useParams<{ id: string }>();
  const isWideScreen = useMediaQuery({ minWidth: 745 });

  const numberMedia = 31;

  useEffect(() => {
    setUserData(authUser);
  }, [authUser]);

  const navigateHomePage = () => {
    navigate(`/messages/group/${id}`);
  };

  const handleParticipantsClick = () => {
    navigate(`/messages/group/${id}/participants`, {
      state: { groupName, banner, participants },
    });
  };

  const navigateModifyGroupPage = () => {
    navigate(`/messages/group/${id}/modifygroup`);
  };
  const navigateSilentPage = () => {
    navigate(`/messages/group/${id}/silent`);
  };

  const navigateSearchPage = () => {
    navigate(`/messages/group/${id}/search`);
  };

  const navigateMediaPage = () => {
    navigate(`/messages/group/${id}/medias`);
  };

  const navigateReportPage = () => {
    navigate(`/messages/group/${id}/report`);
  };

  const handleDeleteGroup = async () => {
    if (!id) {
      console.error("Group ID is undefined");
      return;
    }
    try {
      await deleteGroup(id);
      navigate("/messages");
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  const confirmDeleteGroup = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const groupName = location.state?.groupName || "Nom du groupe";
  const banner = location.state?.banner || "";
  const participants = location.state?.participants || "";

  return (
    <div
      className={`z-20 flex w-full flex-col items-center justify-between gap-y-8 px-5 pb-[60px] max-mobile:pt-[40px] ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="flex flex-col items-start gap-y-6 self-stretch min-745:pt-8">
        <div className="flex items-center justify-between gap-x-10 self-stretch">
          <div className="cursor-pointer pt-1 max-mobile:hidden">
            <BackButton onClick={navigateHomePage} />
          </div>
          <p className="h1-mobile">Options du groupe</p>
          <div className="cursor-pointer min-745:hidden">
            <CloseFillIcon onClick={navigateHomePage} />
          </div>
        </div>
        <div
          className="flex w-full items-center gap-x-2 rounded-xl border border-[rgba(198,182,213,0.3)] p-2 shadow-[0_2px_4px_rgba(0,0,0,0.25)]"
          style={{
            background:
              "linear-gradient(0deg, rgba(245, 235, 255, 0.10) 0%, rgba(245, 235, 255, 0.10) 100%), rgba(151, 46, 182, 0.10)",
          }}
        >
          <GamesIcons logo={banner} className="game-thumbnail-s !mb-0 !mr-0" />
          <p className="h4-mobile">{groupName}</p>
        </div>
        <div className="flex flex-col items-start gap-y-6 self-stretch">
          <div className="flex flex-col items-start gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
            <p className="h2-mobile">Paramètres</p>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateModifyGroupPage}
            >
              <div className="flex items-center gap-x-2 self-stretch">
                <EditIcon />
                <p className="nav-menu-500">Modifier le groupe</p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
          </div>
          <div className="flex flex-col items-start gap-y-4 self-stretch">
            <div className="flex items-center justify-between self-stretch ">
              <p className="h2-mobile">Participants</p>
              <ChevronIcon orientation="right" />
            </div>
            <div
              className="flex items-center"
              onClick={handleParticipantsClick}
            >
              <DisplayedUsers
                participants={participants}
                displayType="participants"
                showParticipants={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div
            className="flex cursor-pointer items-center gap-x-2"
            onClick={confirmDeleteGroup}
          >
            <DisconnectIcon />
            <p className="nav-menu-500">Supprimer le groupe</p>
          </div>
          {/* <div
            className="flex items-center gap-x-2 self-stretch"
            onClick={navigateReportPage}
          >
            <ReportSettingIcon />
            <p className="nav-menu-500">Signaler le groupe</p>
          </div> */}
        </div>
      </div>
      <CSSTransition
        in={showOverlay}
        timeout={600}
        classNames="overlay"
        unmountOnExit
      >
        <ConfirmLeaveGroup onClick={toggleOverlay} />
      </CSSTransition>
      {showModal && (
        <ConfirmationModal
          title="Confirmer la suppression du groupe"
          message="Êtes-vous sûr de vouloir supprimer ce groupe ? Cette action est irréversible."
          onConfirm={() => {
            handleDeleteGroup();
            closeModal();
          }}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};
