import React from "react";
import Trophy from "../../../assets/trophy.webp";
import TrophyBuilder from "../../../assets/trophies_builder.webp";
import { useNavigate } from "react-router-dom";
import { ImageCard } from "../..";
import { Gameplay, PlayerStats, Post } from "../../../types";

interface UserPostGameStatMobileProps {
  post: Post | Gameplay;
  playerStats: PlayerStats;
  hasGameAccount: boolean;
}

const convertMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  return `${hours} heures`;
};

const encodeUsername = (username: string) => {
  return encodeURIComponent(
    username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  );
};

export const UserPostGameStatMobile: React.FC<UserPostGameStatMobileProps> = ({
  post,
  playerStats,
  hasGameAccount,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-center justify-between gap-y-1">
      <div className="flex w-full max-w-[610px] items-center justify-between">
        <p className="h4-mobile">{post.user.pseudo || post.user.username}</p>
        <p
          className="link-s cursor-pointer"
          onClick={() =>
            navigate(`/user/${encodeUsername(post.user.username)}`)
          }
        >
          Voir le profil
        </p>
      </div>
      <div
        className="flex items-center gap-x-4 rounded px-3 py-2"
        style={{
          background:
            "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
        }}
      >
        {playerStats.trophies && (
          <div className="flex items-center gap-x-2">
            <p className="paragraph-s-700">{playerStats.trophies}</p>
            <ImageCard imageUrl={Trophy} size={18} />
          </div>
        )}
        {playerStats.builderBaseTrophies && (
          <div className="flex items-center gap-x-2">
            <p className="paragraph-s-700">{playerStats.builderBaseTrophies}</p>
            <ImageCard imageUrl={TrophyBuilder} size={20} />
          </div>
        )}
        {playerStats.playtimeForever && (
          <div className="flex items-center gap-x-2">
            <p className="paragraph-s-700">
              {convertMinutesToHours(playerStats.playtimeForever)}
            </p>
          </div>
        )}
        {hasGameAccount && playerStats.nbAchievedAchievements && (
          <div className="flex items-center gap-x-2">
            <p className="paragraph-s-700">
              Succès obtenus : {playerStats.nbAchievedAchievements}/
              {playerStats.nbTotalAchievements}
            </p>
          </div>
        )}
        {hasGameAccount && playerStats.summonerLevel && (
          <div className="flex items-center gap-x-2">
            <p className="paragraph-s-700">Level {playerStats.summonerLevel}</p>
          </div>
        )}
      </div>
    </div>
  );
};
