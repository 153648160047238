import axios, { AxiosResponse } from "axios";
import {
  addSupercellGameUrl,
  apiUrl,
  fetchAllGamesUrl,
  fetchGameByIdUrl,
  followGameUrl,
  getPlayerAccountUrl,
  getPlayerStatsUrl,
  getUserGamesUrl,
  linkLauncherUrl,
  unFollowGameUrl,
  updateGameUrl,
} from "../constants/api";
import {
  AddSupercellGame,
  BrawlStarsProps,
  ClashOfClansProps,
  ClashRoyaleProps,
  FollowGame,
  Game,
  GamePageType,
  GamePanelAdmin,
  PlayerStats,
  AddRiotGame,
} from "../types";

export const fetchOneGame = (gameId: string) => async (): Promise<Game> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<Game> = await axios.get(
      fetchGameByIdUrl + gameId,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    const gameData: Game = response.data;
    return gameData;
  } catch (error) {
    console.error("Error fetching game:", error);
    throw error;
  }
};

export const fetchGamePage =
  (gameId: string) => async (): Promise<GamePageType> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<GamePageType> = await axios.get(
        fetchGameByIdUrl + gameId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const gamePageData: GamePageType = response.data;
      return gamePageData;
    } catch (error) {
      console.error("Error fetching game:", error);
      throw error;
    }
  };

export const fetchGamePanelAdmin =
  (gameId: string) => async (): Promise<GamePanelAdmin> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<GamePanelAdmin> = await axios.get(
        fetchGameByIdUrl + gameId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const gamePanelData: GamePanelAdmin = response.data;
      return gamePanelData;
    } catch (error) {
      console.error("Error fetching game:", error);
      throw error;
    }
  };

export const fetchAllGames = async (): Promise<Game[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.get<Game[]>(fetchAllGamesUrl, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const fetchOwnedGames = async (username: string): Promise<Game[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.get<Game[]>(
    `${apiUrl}games/getGames/${username}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const addSupercellGame = async (
  gameId: string,
  name: string,
  token?: string,
): Promise<AddSupercellGame> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const payload: { gameId: string; name: string; token?: string } = {
    gameId,
    name,
  };
  if (token) {
    payload.token = token;
  }

  const response = await axios.post<AddSupercellGame>(
    addSupercellGameUrl,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );

  return response.data;
};

export const fetchPlayerAccountClashOfClans =
  (gameId: string, id: string) => async (): Promise<ClashOfClansProps> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<ClashOfClansProps> = await axios.get(
        getPlayerAccountUrl + gameId + "/" + id,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const cocAccountData: ClashOfClansProps = response.data;
      return cocAccountData;
    } catch (error) {
      console.error("Error fetching game:", error);
      throw error;
    }
  };

export const fetchPlayerAccountClashRoyale =
  (gameId: string, id: string) => async (): Promise<ClashRoyaleProps> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<ClashRoyaleProps> = await axios.get(
        `${getPlayerAccountUrl}${gameId}/${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const crAccountData: ClashRoyaleProps = response.data;
      return crAccountData;
    } catch (error) {
      console.error("Error fetching game:", error);
      throw error;
    }
  };

export const fetchPlayerAccountBrawlStars =
  (gameId: string, id: string) => async (): Promise<BrawlStarsProps> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<BrawlStarsProps> = await axios.get(
        `${getPlayerAccountUrl}${gameId}/${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const bsAccountData: BrawlStarsProps = response.data;
      return bsAccountData;
    } catch (error) {
      console.error("Error fetching game:", error);
      throw error;
    }
  };

export const addRiotGame = async (
  launcherId: string,
  name: string,
  tag: string,
): Promise<AddRiotGame> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.post<AddRiotGame>(
    linkLauncherUrl,
    { launcherId, name, tag },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );

  return response.data;
};

export const followGame = async (
  gameId: string,
  postCommunity = 1,
): Promise<FollowGame> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  const response = await axios.post<FollowGame>(
    followGameUrl,
    { gameId, postCommunity },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unFollowGame = async (gameId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.delete<void>(unFollowGameUrl, {
    data: { gameId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const fetchUserGames = async (username: string): Promise<Game[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  try {
    const response = await axios.get<Game[]>(getUserGamesUrl + username, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    const userGamesData: Game[] = response.data;
    return userGamesData;
  } catch (error) {
    console.error("Error fetching game:", error);
    throw error;
  }
};

export const fetchPlayerStats =
  (gameId: string, userId: string) => async (): Promise<PlayerStats> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<PlayerStats> = await axios.get(
        getPlayerStatsUrl + gameId + "/" + userId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const cocAccountData: PlayerStats = response.data;
      return cocAccountData;
    } catch (error) {
      console.error("Error fetching game:", error);
      throw error;
    }
  };

export const updateGame = async (
  gameId: string,
  updatedData: any,
  files: { logo?: File; banner?: File; card?: File },
): Promise<Game> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("name", updatedData.name);
  formData.append("single", updatedData.single);
  formData.append("date_release", updatedData.date_release);
  formData.append("description", updatedData.description);
  formData.append("platformIds", JSON.stringify(updatedData.platformIds));
  formData.append("studioIds", JSON.stringify(updatedData.studioIds));
  formData.append("launcherIds", JSON.stringify(updatedData.launcherIds));
  formData.append("ios", updatedData.ios);
  formData.append("android", updatedData.android);
  formData.append("pc", updatedData.pc);

  if (files.logo) {
    const webPLogo = await convertToWebP(files.logo);
    formData.append("logo", webPLogo);
  }

  if (files.banner) {
    const webPBanner = await convertToWebP(files.banner);
    formData.append("banner", webPBanner);
  }

  if (files.card) {
    const webPCard = await convertToWebP(files.card);
    formData.append("card", webPCard);
  }

  try {
    const response: AxiosResponse<Game> = await axios.patch(
      updateGameUrl + gameId,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    const updatedGame: Game = response.data;
    return updatedGame;
  } catch (error) {
    console.error("Error updating game:", error);
    throw error;
  }
};

import { convertToWebP } from "../utils/File";

export const createGame = async (
  newGameData: any,
  files: { logo?: File; banner?: File; card?: File },
): Promise<Game> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("name", newGameData.name);
  formData.append("single", newGameData.single);
  formData.append("date_release", newGameData.date_release);
  formData.append("description", newGameData.description);
  formData.append("platformIds", JSON.stringify(newGameData.platformIds));
  formData.append("studioIds", JSON.stringify(newGameData.studioIds));
  formData.append("launcherIds", JSON.stringify(newGameData.launcherIds));
  formData.append("ios", newGameData.ios);
  formData.append("android", newGameData.android);
  formData.append("pc", newGameData.pc);

  if (files.logo) {
    const webPLogo = await convertToWebP(files.logo);
    formData.append("logo", webPLogo);
  }

  if (files.banner) {
    const webPBanner = await convertToWebP(files.banner);
    formData.append("banner", webPBanner);
  }

  if (files.card) {
    const webPCard = await convertToWebP(files.card);
    formData.append("card", webPCard);
  }

  try {
    const response: AxiosResponse<Game> = await axios.post(
      updateGameUrl,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    const createdGame: Game = response.data;
    return createdGame;
  } catch (error) {
    console.error("Error creating game:", error);
    throw error;
  }
};
