import { useState } from "react";
import { EditRoundedIcon } from "../..";

type DescriptionUserProps = { edit: boolean; about: string };

export const DescriptionUser: React.FC<DescriptionUserProps> = ({
  edit,
  about,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleOverlay = () => setShowOverlay(!showOverlay);
  return (
    <div className="flex w-full">
      {edit == true && about === "" && (
        <div
          className="mt-1 flex cursor-pointer items-center gap-x-2 self-stretch"
          onClick={toggleOverlay}
        >
          <EditRoundedIcon />
          <p className="link-s">Ajoute une cover à ton profil</p>
        </div>
      )}
      {edit == true && about && (
        <div className="relative flex w-full rounded-[20px] border-[0.5px] border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] px-4 py-2 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
          <p className="label-s-400 inline-block max-w-[90%] overflow-hidden align-middle">
            <span className="label-s-400">{about}</span>
          </p>
          <div
            className="absolute right-[0px] top-0 cursor-pointer"
            onClick={toggleOverlay}
          >
            <EditRoundedIcon />
          </div>
        </div>
      )}
      {edit == false && (
        <div className="relative w-full">
          <p className="label-s-400 inline-block overflow-hidden align-middle">
            <span className="label-s-400">{about}</span>
          </p>
        </div>
      )}
    </div>
  );
};
