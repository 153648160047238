import { useEffect, useRef } from "react";
import { CloseFillIcon, GamesIcons } from "../";
import React from "react";
import { GameSearchResult } from "../../types";

type OverlayLauncherGamesProps = {
  launcherName?: string;
  games: GameSearchResult[];
  onClose: () => void;
};

export const OverlayLauncherGames: React.FC<OverlayLauncherGamesProps> = ({
  launcherName,
  games,
  onClose,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef, onClose]);

  const sortedGames = [...games].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div
      ref={overlayRef}
      className="absolute left-0 top-[120px] z-[100] max-mobile:fixed max-mobile:inset-0 max-mobile:flex max-mobile:items-center max-mobile:justify-center"
    >
      <div
        className="flex max-w-[400px] flex-col gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-1 backdrop-blur-[6.5px] max-mobile:min-w-[80vw]"
        style={{
          background:
            "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
        }}
      >
        <div className="flex items-center justify-between gap-x-4 self-stretch py-3 pl-2">
          <p className="button-label items-start self-stretch">
            Jeux disponible sur {launcherName}
          </p>
          <CloseFillIcon onClick={onClose} />
        </div>
        <div
          className={`styled-scrollbars flex flex-col items-start gap-y-6 self-stretch pb-1 ${
            sortedGames.length > 4 ? "max-h-[20vh] overflow-y-scroll" : ""
          }`}
          style={{ overflowY: sortedGames.length > 4 ? "scroll" : "visible" }}
        >
          {sortedGames.map((game) => (
            <div
              key={game.id}
              className="flex flex-wrap content-center items-center gap-x-[10px] self-stretch px-1"
            >
              <GamesIcons logo={game.logo} className="game-thumbnail-xs" />
              <p className="nav-menu-500">{game.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
