import { RecentlyPlayedCard } from "..";

interface Game {
  id: string;
  name: string;
  logo: string;
  pseudo: string;
  playtime: string;
  lastPlayDate: string;
}

interface RecentlyPlayedProps {
  games: Game[];
}
export const RecentlyPlayed: React.FC<RecentlyPlayedProps> = ({ games }) => {
  return (
    <div className="flex w-full flex-col gap-y-2">
      <p className="h4-mobile flex w-full items-center">Récemment joués</p>
      <div
        className="flex items-start self-stretch rounded-lg p-3"
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), linear-gradient(132deg, rgba(117, 36, 139, 0.66) 40.5%, rgba(98, 79, 117, 0.00) 90.06%)",
        }}
      >
        <div className="flex w-full flex-col items-start gap-y-2 self-stretch">
          {games.slice(0, 3).map((game) => (
            <RecentlyPlayedCard
              key={game.id}
              id={game.id}
              logo={game.logo}
              game={game.name}
              pseudo={game.pseudo}
              playtime={game.playtime}
              lastPlayDate={game.lastPlayDate}
              isClickable={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
