import { useState } from "react";
import {
  AvatarIcon,
  YellowCrownIcon,
  DotsIcon,
  SelectionIndicator,
  BlueCrownIcon,
  OptionsParticipant,
} from "../";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import DefaultAvatar from "../../assets/DefaultAvatar.png";

type ParticipantGroupProps = {
  name: string;
  image: string;
  isSelected?: boolean;
  onToggle?: () => void;
  showOptions: boolean;
  onOptionsClick?: () => void;
  isAdmin?: boolean;
  isModerator?: boolean;
};

export const ParticipantGroup: React.FC<ParticipantGroupProps> = ({
  name,
  image,
  isSelected,
  onToggle,
  showOptions,
  onOptionsClick,
  isAdmin,
  isModerator,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
    if (onOptionsClick) {
      onOptionsClick();
    }
  };

  return (
    <div
      className={`flex h-[64px] cursor-pointer items-center justify-between self-stretch rounded-lg ${
        isSelected ? "outline outline-[2px] outline-[#fef7ffab]" : ""
      }`}
      onClick={onToggle}
    >
      <div className="flex items-center gap-x-2">
        <AvatarIcon
          logo={image || DefaultAvatar}
          className="avatar-m text-center"
        />
        <div
          className={`flex flex-col ${
            isAdmin ? "items-start" : "items-center"
          } justify-center`}
        >
          <p className="nav-menu-500">{name}</p>
          {isAdmin && (
            <div className="flex items-center gap-x-1">
              <YellowCrownIcon />
              <p className="label-s-400">admin</p>
            </div>
          )}
          {isModerator && (
            <div className="flex items-center gap-x-1">
              <BlueCrownIcon />
              <p className="label-s-400">modérateur</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex h-full items-center justify-center">
        {showOptions ? (
          <div className="relative" onClick={handleClick}>
            <DotsIcon />
            <TransitionGroup component={null}>
              {showTooltip && (
                <CSSTransition
                  classNames="tooltip"
                  timeout={300}
                  unmountOnExit
                  key="tooltip"
                >
                  <div className="absolute right-[30px] top-[-2px] z-50">
                    <OptionsParticipant username={name} />
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </div>
        ) : (
          <SelectionIndicator isSelected={isSelected || false} />
        )}
      </div>
    </div>
  );
};
