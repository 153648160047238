import "./components/Function/resizeObserverPolyfill";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./providers/userAuth";
import AppLayout from "./AppLayout";
import { SidebarWidthProvider } from "./components/Sidebar/SidebarWidthContext";
import { ScrollToTop } from "./components";
import { RouteOriginProvider } from "./contexts/RouteOriginContext";

const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <SidebarWidthProvider>
            <RouteOriginProvider>
              <AppLayout />
            </RouteOriginProvider>
          </SidebarWidthProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
