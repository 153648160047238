import { ButtonSimple } from "../";

type BottomConnectGamesProps = {
  navigateTo: () => void;
  addMoreGames: () => void;
  selectedAccountCount: number;
};

export const BottomConnectGames: React.FC<BottomConnectGamesProps> = ({
  navigateTo,
  selectedAccountCount,
  addMoreGames,
}) => {
  return (
    <div
      className="bottom-0 z-10 flex w-full flex-col items-center gap-y-5 border-t border-t-[#C6B6D540] px-5 pb-6 pt-4 backdrop-blur-[7px] max-mobile:fixed min-745:hidden"
      style={{
        background:
          "linear-gradient(92.03deg, rgba(40, 12, 49, 0.8) 8.79%, rgba(33, 16, 51, 0.8) 100%)",
      }}
    >
      <div className="flex flex-col items-center gap-y-2">
        <p className="label-m-400">
          Encore{" "}
          <span className="label-m-700">{selectedAccountCount} comptes</span> à
          connecter
        </p>
        <ButtonSimple
          label="Vers l'accueil"
          plusFonction={false}
          showChevron={true}
          chevronDirection="right"
          onClick={navigateTo}
        />
      </div>
      <p className="link-s" onClick={addMoreGames}>
        Ajoutez d’autres jeux
      </p>
    </div>
  );
};
