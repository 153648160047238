export const CorrectCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="10" fill="#00DD23" />
    <path
      d="M7.5 11.5L10.8158 15L16.5 9"
      stroke="#F8EFFB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CorrectCheck15 = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_851_14764)">
      <rect
        x="9"
        y="9"
        width="15"
        height="15"
        rx="7.5"
        fill="#00DD23"
        shapeRendering="crispEdges"
      />
      <path
        d="M19.1667 13.2998L15.402 17.0645L13.8333 15.4959L12.5 16.8135L14.0686 18.3821L15.3235 19.637L15.402 19.6998L20.5 14.6174L19.1667 13.2998Z"
        fill="#ECF0F1"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_851_14764"
        x="0"
        y="0"
        width="33"
        height="33"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.865153 0 0 0 0 0.138424 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_851_14764"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_851_14764"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CorrectCheck40 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle cx="20" cy="20" r="20" fill="#00DD23" />
    <path
      d="M10 18.8333L17.7368 27L31 13"
      stroke="#F8EFFB"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FalseCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="10" fill="#FE0045" />
    <path
      d="M8 8L16 16M16 8L8 16"
      stroke="#F8EFFB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FalseCheck16 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx="8" cy="8" r="8" fill="#FE0045" />
    <path
      d="M6 6L10 10M10 6L6 10"
      stroke="#F8EFFB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StrokeFalseCheck35 = () => (
  <div className="flex items-center justify-center rounded-full border border-solid border-[#FE0045]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
    >
      <path
        d="M11 11L25 25M25 11L11 25"
        stroke="#FE0045"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export const StrokeCorrectCheck35 = () => (
  <div className="flex items-center justify-center rounded-full border border-solid border-[#00DD23]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
    >
      <path
        d="M10 16.5833L15.5263 23L25 12"
        stroke="#00DD23"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
