import React, { useState, useEffect } from "react";

interface ImageTileProps {
  link: string;
  className?: string;
  isSingle?: boolean;
}

export const ImageTile: React.FC<ImageTileProps> = ({
  link,
  className,
  isSingle = false,
}) => {
  const [isPortrait, setIsPortrait] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = link;
    img.onload = () => {
      setIsPortrait(img.naturalHeight > img.naturalWidth && isSingle);
    };
  }, [link, isSingle]);

  const handleImageClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const handleCloseModal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setShowModal(false);
  };

  const handleModalContentClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const tileClass = `relative overflow-hidden ${className} ${isSingle && "w-full max-h-[600px] flex"}`;
  const imageClass = `h-full w-full ${isSingle ? "object-contain max-h-[600px]" : "absolute inset-0 cursor-pointer object-cover"}`;

  return (
    <div className={tileClass} onClick={handleImageClick}>
      <img src={link} alt="Enlarged" className={imageClass} />
      {!isSingle && <div className="pt-[100%]"></div>}
      {showModal && (
        <div
          className="fixed inset-0 z-[1500] flex items-center justify-center bg-black bg-opacity-75"
          onClick={handleCloseModal}
        >
          <img
            src={link}
            alt="Enlarged"
            className="max-h-[95%] max-w-[95%] border border-white"
            onClick={handleModalContentClick}
          />
        </div>
      )}
    </div>
  );
};
