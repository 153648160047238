import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchUserPage } from "../../helpers/fetchUser";
import {
  BottomBar,
  HeaderUser,
  Loading,
  PrivateIcon,
  SubtitleNavigation,
} from "../../components";
import { User, UserPageType } from "../../types";
import { useAuth } from "../../providers/userAuth";

interface UserLayoutProps {
  children: ReactNode;
}

export const UserLayout = ({ children }: UserLayoutProps) => {
  const { username } = useParams();
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [ownerPage, setOwnerPage] = useState<UserPageType>();

  const { data, isLoading, isError } = useQuery<UserPageType, Error>(
    ["user", username],
    fetchUserPage(username ? username : ""),
  );

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  useEffect(() => {
    setOwnerPage(data);
  }, [data]);

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const hasRecentGames = data?.recentGames && data.recentGames.length > 0;

  const navigationButtons = [
    { title: "Profil", path: `/user/${username}` },
    { title: "Posts", path: `/user/${username}/posts` },
    { title: "Gameplay", path: `/user/${username}/gameplay` },
    hasRecentGames ? { title: "Jeux", path: `/user/${username}/games` } : null,
  ].filter(
    (
      button,
    ): button is { title: string; path: string; hasNewEvents?: boolean } =>
      button !== null,
  );

  const isPrivate = data?.private === 1;
  const isOwnProfile =
    user?.username &&
    encodeUsername(user.username) === encodeUsername(username || "");
  const isFriend = data?.friend === "check";
  const canViewProfile = !isPrivate || isOwnProfile || isFriend;

  return (
    <div className="flex min-h-[100vh] w-full flex-col">
      {data && (
        <HeaderUser
          banner={data.banner}
          logo={data.logo}
          about={data.about}
          friend={data.friend}
          follow={data.follow}
          followersCount={data.followersCount}
          friendsCount={data.commonFriendsCount}
          edit={false}
          friendsLogos={data.commonFriendsLogos}
          userId={data.id}
          isPrivate={isPrivate}
          navigationButtons={navigationButtons}
          username={data.username}
        />
      )}
      {isLoading && <Loading />}
      {isError && <div className="h1-mobile">{isError}</div>}
      {!canViewProfile ? (
        <div
          className="mt-5 flex h-full w-full flex-col items-center gap-y-2 px-5 pb-8 pt-4"
          style={{
            background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
          }}
        >
          <PrivateIcon />
          <p className="h2-mobile text-center">
            Le compte de cet utilisateur est privé
          </p>
          <p className="paragraph-m-400 text-center">
            Vous devez vous suivre mutuellement ou être ami avec{" "}
            {data?.username} pour accéder à son profil.
          </p>
        </div>
      ) : (
        <>
          <div className="flex w-full justify-center max-mobile:hidden">
            <SubtitleNavigation buttons={navigationButtons} />
          </div>
          {children}
        </>
      )}
      <BottomBar />
    </div>
  );
};
