import { useEffect, useRef, useState } from "react";
import { ButtonSimple, CloseFillIcon, TransparentButton } from "../";

type ConfirmBlockUserProps = {
  onClick?: () => void;
};

export const ConfirmBlockUser: React.FC<ConfirmBlockUserProps> = ({
  onClick,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const name = "Exe2Glace";

  const closeModal = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const modalContent = document.getElementById("modal-content");
      if (modalContent && !e.composedPath().includes(modalContent)) {
        closeModal();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isVisible]);

  return isVisible ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-5">
      <div
        id="modal-content"
        ref={overlayRef}
        className="flex w-[500px] flex-col items-center  justify-center gap-y-5 rounded-3xl border-2 border-transparent px-5 pb-8 pt-6 shadow-[0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px]"
        style={{
          background:
            "linear-gradient(293deg, #402C48 0.12%, #9552A9 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #402C48 40%) border-box",
        }}
      >
        <div className="flex flex-col items-start gap-y-5 self-stretch">
          <div className="flex content-start items-center justify-between gap-x-2 self-stretch">
            <p className="h2-mobile self-stretch">
              Souhaites-tu bloquer {name} ?
            </p>
            <div className="cursor-pointer">
              <CloseFillIcon size={12} onClick={closeModal} />
            </div>
          </div>
          <p className="paragraph-m-400 self-stretch text-center">
            Vous ne pourrez plus communiquer entre-vous, ni voir le contenu de
            chacun. Vous pourrez toujours le débloquer ensuite.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-4 self-stretch">
          <ButtonSimple
            label={`Bloquer ${name}`}
            plusFonction={false}
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <TransparentButton label="Annuler" onClick={closeModal} />
        </div>
      </div>
    </div>
  ) : null;
};
