import { useState } from "react";
import {
  ButtonSimple,
  CorrectCheck,
  FalseCheck,
  SimpleInput,
} from "../../../components";
import LogoTP from "../../../assets/LogoTPTextBlanc.png";
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../helpers/Auth";

export const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [isValidLength, setIsValidLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const upperCaseValid = /[A-Z]/.test(password);
    const lowerCaseValid = /[a-z]/.test(password);
    const specialCharValid = /[\^$*.[\]{}()?"!@#%&/,><':;|_~`]/.test(password);

    setIsValidLength(lengthValid);
    setHasUpperCase(upperCaseValid);
    setHasLowerCase(lowerCaseValid);
    setHasSpecialChar(specialCharValid);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleResetPassword = async () => {
    if (!token) {
      setErrorMessage("Token invalide");
      return;
    }
    try {
      const response = await resetPassword(password, token)();
      console.log(response);
      if (response.status === 200) {
        setSuccessMessage("Votre mot de passe a été changé avec succès !");
        setErrorMessage("");
      } else {
        setErrorMessage(
          response.response.data.message || "Une erreur est survenue",
        );
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "Une erreur est survenue lors de la réinitialisation du mot de passe",
      );
    }
  };

  return (
    <div className="flex min-h-screen max-w-[550px] flex-col gap-y-8 self-stretch px-5 pt-[60px] min-745:pt-[15vh]">
      <div className="flex flex-col items-center justify-center self-stretch min-745:hidden">
        <img src={LogoTP} className="w-[150px]" alt="TalkPlay Logo" />
      </div>
      <div className="flex flex-col items-start gap-y-1 self-stretch">
        <p className="h1-mobile self-stretch text-center">
          Changement du mot de passe
        </p>
        <p className="paragraph-m-400 self-stretch text-center">
          Choisis un nouveau mot de passe pour pouvoir te connecter sur TalkPlay
        </p>
      </div>
      {!successMessage ? (
        <>
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Mot de passe</p>
            <SimpleInput
              placeholder="Ton mot de passe"
              iconType="eyes"
              value={password}
              onChange={handlePasswordChange}
              name="password"
            />
            <div className="flex items-center gap-x-2">
              {isValidLength ? <CorrectCheck /> : <FalseCheck />}
              <p className="label-s-400">8 caractères minimum</p>
            </div>
            <div className="flex items-center gap-x-2">
              {hasUpperCase ? <CorrectCheck /> : <FalseCheck />}
              <p className="label-s-400">1 majuscule</p>
            </div>
            <div className="flex items-center gap-x-2">
              {hasLowerCase ? <CorrectCheck /> : <FalseCheck />}
              <p className="label-s-400">1 minuscule</p>
            </div>
            <div className="flex items-center gap-x-2">
              {hasSpecialChar ? <CorrectCheck /> : <FalseCheck />}
              <p className="label-s-400">1 caractère spécial</p>
            </div>
          </div>
          {errorMessage && (
            <p className="nav-menu-700 text-center text-red">{errorMessage}</p>
          )}
          <ButtonSimple
            label="Changer le mot de passe"
            plusFonction={false}
            onClick={handleResetPassword}
          />
        </>
      ) : (
        <div className="flex flex-col items-center gap-y-4">
          <p className="nav-menu-700 text-center text-green">
            {successMessage}
          </p>
          <ButtonSimple
            label="Revenir au menu principal"
            plusFonction={false}
            onClick={() => navigate("/")}
          />
        </div>
      )}
    </div>
  );
};
