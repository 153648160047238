import { BackButton, ButtonSimple, ChevronIcon } from "../../../../components";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/userAuth";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../../helpers/Auth";

export const ChangeAccountPassword = () => {
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (authUser?.email) {
      setEmail(authUser.email);
      setIsEmailValid(true);
    }
  }, [authUser]);

  const handlePasswordReset = async () => {
    if (!email) {
      setErrorMessage("Email non disponible");
      return;
    }

    try {
      const result = await forgotPassword(email)();
      if (result) {
        setSuccessMessage(
          "Un email pour changer votre mot de passe vous a été envoyé !",
        );
        setErrorMessage("");
      } else {
        setErrorMessage("Aucun utilisateur trouvé avec cet email");
      }
    } catch (error) {
      setErrorMessage("Erreur lors de l'envoi de l'email");
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`flex min-h-[calc(100vh-58px)] w-full flex-col gap-y-6 border-l border-l-[rgba(198,182,213,0.25)] px-5 pb-[60px] pt-10 max-mobile:pt-[40px] ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="min-745:hidden">
        <BackButton />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex items-center gap-x-10 self-stretch">
          <ChevronIcon
            orientation="left"
            onClick={navigateBack}
            className="cursor-pointer max-mobile:hidden"
          />
          <p className="h1-mobile">Modifier votre mot de passe</p>
        </div>
        <div className="flex flex-col gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
          <p className="paragraph-m-400">
            Pour modifier votre mot de passe, cliquez sur le bouton ci-dessous.
            Un email contenant les instructions pour réinitialiser votre mot de
            passe vous sera envoyé.
          </p>
          {successMessage ? (
            <div className="mt-4 w-full">
              <p className="nav-menu-700 text-center text-green">
                {successMessage}
              </p>
            </div>
          ) : (
            <>
              {errorMessage && (
                <p className="text-red-500 text-center">{errorMessage}</p>
              )}
              <div className="flex items-end self-stretch">
                <ButtonSimple
                  label="Changer votre mot de passe"
                  onClick={handlePasswordReset}
                  showChevron={true}
                  chevronDirection="right"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
