import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { AddFileChat, SendMessage } from "..";

interface TypingMessageProps {
  content?: string;
  onContentChange?: (content: string) => void;
  onSendMessage?: () => void;
  onFileInputClick?: () => void;
}

export const TypingMessage: React.FC<TypingMessageProps> = ({
  content,
  onContentChange,
  onSendMessage,
  onFileInputClick,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (onSendMessage) {
        onSendMessage();
      }
    }
  };

  return (
    <div
      className="flex w-full items-center border-t border-t-[#C6B6D540] px-5 pb-8 pt-4 backdrop-blur-[7px] max-mobile:fixed max-mobile:bottom-0 max-mobile:z-10 min-745:max-w-[670px]"
      style={{
        background:
          "linear-gradient(92.03deg, rgba(40, 12, 49, 0.8) 8.79%, rgba(33, 16, 51, 0.8) 100%)",
      }}
    >
      <div className="styled-scrollbars flex w-full items-center justify-between gap-x-4 rounded-3xl bg-[rgba(245,235,255,0.10)] pl-2 pr-4 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
        <AddFileChat onClick={onFileInputClick} />
        <TextareaAutosize
          placeholder="Ecrit ton message ici..."
          maxRows={4}
          value={content}
          onChange={(e) => onContentChange && onContentChange(e.target.value)}
          onKeyDown={handleKeyDown}
          className="label-m-400 mb-[1px] flex grow select-text resize-none self-stretch break-words bg-transparent pb-2 pt-2 opacity-50 focus:opacity-100 focus:outline-none"
          style={{ minHeight: "14px", maxHeight: "150px" }}
        />
        <SendMessage onClick={onSendMessage} />
      </div>
    </div>
  );
};
