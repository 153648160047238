import React, { useState, useEffect } from "react";
import {
  CloseFillIcon,
  CorrectCheck,
  EyesHideIcon,
  EyesShowIcon,
  FalseCheck,
  InfoIcon,
  GifOverlay,
} from "..";

type IconType = "none" | "check" | "eyes" | "cross";

type SimpleInputProps = {
  placeholder?: string;
  iconType: IconType;
  isCorrect?: boolean | null;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  maxLength?: number;
  helper?: boolean;
  helperText?: string;
  helperImageUrl?: string;
  hasError?: boolean;
  errorText?: string;
  readOnly?: boolean;
  hoverAction?: () => void;
  inputref?: React.RefObject<HTMLInputElement>;
  infoIconColor?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Add onKeyDown prop
};

export const SimpleInput: React.FC<SimpleInputProps> = ({
  placeholder = "Rechercher sur TalkPlay",
  iconType,
  isCorrect = true,
  value = "",
  onChange,
  name,
  maxLength,
  helper,
  helperText,
  helperImageUrl,
  hasError,
  errorText,
  readOnly = false,
  hoverAction,
  inputref,
  infoIconColor,
  onKeyDown, // Destructure onKeyDown prop
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const clearInput = () => {
    setInputValue("");
    if (onChange) {
      onChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleHover = () => {
    if (hoverAction) {
      hoverAction();
    }
    setShowOverlay(true);
  };

  const handleMouseLeave = () => {
    setShowOverlay(false);
  };
  const handleClick = () => {
    setShowOverlay(true);
  };
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="relative flex flex-col gap-y-2 self-stretch">
      <div className="relative flex h-12 w-full items-center gap-x-4 rounded-full bg-[rgba(245,235,255,0.10)] px-6 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
        <input
          type={iconType === "eyes" && !showPassword ? "password" : "text"}
          placeholder={placeholder}
          className="label-m-400 flex-grow border-none !bg-transparent opacity-50 focus:opacity-100 focus:outline-none"
          value={inputValue}
          onChange={handleInputChange}
          name={name}
          maxLength={maxLength}
          readOnly={readOnly}
          ref={inputref}
          onKeyDown={onKeyDown}
        />
        {iconType === "check" && (
          <div className="absolute right-6">
            {isCorrect ? <CorrectCheck /> : <FalseCheck />}
          </div>
        )}
        {iconType === "eyes" && (
          <div
            className="absolute right-6 cursor-pointer"
            onClick={toggleShowPassword}
          >
            {showPassword ? <EyesShowIcon /> : <EyesHideIcon />}
          </div>
        )}
        {iconType === "cross" && (
          <div className="absolute right-6 cursor-pointer" onClick={clearInput}>
            <CloseFillIcon />
          </div>
        )}
      </div>
      {helper && (
        <div className="relative flex items-center gap-x-2 px-4 opacity-70 hover:z-[100] hover:opacity-100">
          <div
            className="hover:z-[100] hover:opacity-100"
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            <InfoIcon
              size={18}
              color={` ${helperImageUrl && "bg-green"} ${infoIconColor && infoIconColor} `}
            />
            {showOverlay && helperImageUrl && (
              <GifOverlay
                imageUrl={helperImageUrl}
                altText={helperText || ""}
                onClose={handleCloseOverlay}
              />
            )}
          </div>
          <p className="label-s-400 whitespace-nowrap">{helperText}</p>
        </div>
      )}
      {hasError && errorText && (
        <div className="flex items-center gap-x-2 self-stretch px-4 opacity-70">
          <InfoIcon size={18} color="bg-red" />
          <p className="label-s-400 text-red">{errorText}</p>
        </div>
      )}
    </div>
  );
};
