import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../providers/userAuth";
import { fetchFriends, fetchFriendsRequest } from "../../helpers/fetchUser";
import { Friend as FriendType } from "../../types";
import {
  BottomBar,
  FriendCard,
  InputSearch,
  InviteFriends,
  ButtonSimple,
  Loading,
} from "../../components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

export const CombinedFriendsPage = () => {
  const { user } = useAuth();
  const [friendsData, setFriendsData] = useState<FriendType[]>([]);
  const [friendRequests, setFriendRequests] = useState<FriendType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const hasFetchedFriends = useRef(false);
  const isWideScreen = useMediaQuery({ minWidth: 951 });
  const navigate = useNavigate();

  useEffect(() => {
    const getFriends = async () => {
      if (user?.username && !hasFetchedFriends.current) {
        try {
          const [friends, fetchedFriendRequests] = await Promise.all([
            fetchFriends(user.username)(),
            fetchFriendsRequest(),
          ]);
          console.log("Fetched friends:", friends);
          console.log("Fetched friend requests:", fetchedFriendRequests);

          setFriendsData(friends);
          setFriendRequests(fetchedFriendRequests.friendsRequests);
          hasFetchedFriends.current = true;
        } catch (error) {
          console.error("Error fetching friends:", error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      } else if (!user?.username) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    getFriends();
  }, [user]);

  const background = isWideScreen
    ? "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)"
    : "";

  const displayedFriendRequests = isWideScreen
    ? friendRequests
    : friendRequests.slice(0, 3);

  const handleViewMoreFriends = () => {
    navigate("/managefriends/friends");
  };

  const handleViewMoreRequests = () => {
    navigate("/managefriends/friendsrequests");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredFriends = friendsData.filter((friend) =>
    friend.username.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div className="flex min-h-[100svh] w-full flex-row gap-y-8 pt-8 max-950:flex-col max-950:items-center min-951:gap-x-10 max-mobile:pt-[90px] min-745:max-h-[calc(100vh-58px)]">
      <div className="flex w-full items-start max-950:max-w-[600px] min-951:order-1 min-951:min-w-[375px] min-951:pt-5">
        {isLoading && <Loading />}
        {isError && <div className="h1-mobile">{isError}</div>}
        <div
          className="flex w-full rounded-xl px-5 pb-5 max-950:max-w-[600px] min-951:order-1 min-951:min-w-[375px] min-951:pt-5"
          style={{
            background: background,
          }}
        >
          <div className="flex w-full flex-col items-start gap-y-2">
            <div className="flex w-full items-center justify-between">
              <p className="h2-mobile">
                Demandes d’amis ({friendRequests.length})
              </p>
              <p
                className="link-s cursor-pointer min-951:hidden"
                onClick={handleViewMoreRequests}
              >
                Voir plus
              </p>
            </div>
            {displayedFriendRequests.map((friend) => (
              <FriendCard
                key={friend.id}
                logo={friend.logo}
                username={friend.username}
                userId={friend.id}
                friendDemand={true}
                isClickable={true}
              />
            ))}
            {friendRequests.length === 0 && (
              <p className="paragraph-m-400">Aucune demande actuellement</p>
            )}
          </div>
        </div>
      </div>
      <div className="h-full w-full px-5 pb-20 max-950:max-w-[600px]">
        <div className="flex h-full flex-col gap-y-4 min-951:order-2">
          <div className="flex w-full items-center justify-between pt-5">
            <p className="h2-mobile">Vos amis ({friendsData.length})</p>
            {friendsData.length > 0 && (
              <p
                className="link-s cursor-pointer min-950:hidden"
                onClick={handleViewMoreFriends}
              >
                Voir plus
              </p>
            )}
          </div>
          <div className="w-full max-950:hidden">
            <InputSearch
              placeholder="Rechercher un ami"
              showLogoSection={false}
              onChange={handleSearchChange}
              searchQuery={searchQuery}
            />
          </div>
          <div className="styled-scrollbars flex max-h-[calc(100vh-230px)] w-full flex-col overflow-auto">
            {filteredFriends.length > 0 ? (
              filteredFriends.map((friend) => (
                <FriendCard
                  key={friend.id}
                  logo={friend.logo}
                  username={friend.username}
                  userId={friend.id}
                  friendDemand={false}
                  isClickable={true}
                />
              ))
            ) : (
              <div className="flex flex-col items-center gap-y-4">
                <p className="paragraph-m-400 text-center">
                  Vous n'avez aucun ami pour le moment. Invitez des amis pour
                  commencer à discuter!
                </p>
                <InviteFriends />
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};
