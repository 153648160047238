import { BackButton, FriendCard, InputSearch, Loading } from "../../components";
import { fetchFriendsRequest } from "../../helpers/fetchUser";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/userAuth";
import { Friend } from "../../types";

export const RequestFriendsList = () => {
  const auth = useAuth();
  const [friends, setFriends] = useState<Friend[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const fetchFriends = async () => {
      if (!auth.user) {
        setLoading(false);
        return;
      }
      try {
        const { friendsRequests } = await fetchFriendsRequest();

        console.log("Fetched friends response:", friendsRequests);

        if (Array.isArray(friendsRequests)) {
          setFriends(friendsRequests);
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching friend requests:", error);
        setError("Erreur lors de la récupération des demandes d'amis.");
      } finally {
        setLoading(false);
      }
    };

    fetchFriends();
  }, [auth.user]);

  const filteredFriends = friends.filter((friend) =>
    friend.username.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="relative flex min-h-[100svh] w-full max-w-[600px] flex-col gap-y-6 self-stretch px-5 pt-10 min-745:max-h-[calc(100vh-58px)]">
      <div className="absolute left-5 top-[55px]">
        <BackButton />
      </div>
      <p className="h1-mobile self-stretch text-center">
        Demandes ({filteredFriends.length})
      </p>
      <InputSearch
        placeholder="Rechercher un ami"
        showLogoSection={false}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {loading && <Loading />}
      {error && <div className="h1-mobile">{error}</div>}
      {!loading && !error && (
        <div className="flex flex-col items-start gap-y-2 self-stretch">
          {filteredFriends.length > 0 ? (
            filteredFriends.map((friend) => (
              <FriendCard
                key={friend.id}
                logo={friend.logo}
                username={friend.username}
                userId={friend.id}
                friendDemand={true}
                isClickable={true}
              />
            ))
          ) : (
            <p className="paragraph-m-400">Aucune demande actuellement</p>
          )}
        </div>
      )}
    </div>
  );
};
