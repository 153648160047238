import { useEffect, useRef, useState } from "react";
import { FilterButton } from "..";
import "./SubtitleSearch.css";

type SubtitleSearchProps = {
  buttons: { title: string }[];
  className?: string;
  onClick: (title: string) => void;
};

export const SubtitleSearch: React.FC<SubtitleSearchProps> = ({
  buttons,
  className,
  onClick,
}) => {
  const [activeButton, setActiveButton] = useState<string | null>("Tout");
  const [containerWidth, setContainerWidth] = useState("100%");
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = (title: string) => {
    setActiveButton(title);
    onClick(title);
  };

  useEffect(() => {
    if (containerRef.current) {
      const isOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setContainerWidth(isOverflowing ? "100%" : "100%");
    }
  }, [buttons]);

  return (
    <div
      className={` scrollable-container flex w-[430px] ${className} gap-x-2 overflow-x-hidden whitespace-nowrap`}
      ref={containerRef}
      style={{ width: containerWidth }}
    >
      {buttons.map((button, index) => (
        <FilterButton
          key={index}
          label={button.title}
          isActive={activeButton === button.title}
          onClick={() => handleClick(button.title)}
        />
      ))}
    </div>
  );
};
