import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EyesHideWhiteIcon,
  WarningIcon,
  CloseFillIcon,
  DeleteIcon,
} from "../..";
import { hideUser } from "../../../helpers/fetchUser";
import { deletePost } from "../../../helpers/fetchPost";
import React from "react";
import { deleteGameplay } from "../../../helpers/fetchGameplay";

type PostOptionsProps = {
  userId: string;
  name: string;
  postId?: string;
  gameplayId?: string;
  isUserPost: boolean;
  onClose: () => void;
  onDeletePost?: (postId: string) => void;
  onDeleteGameplay?: () => void;
};

export const PostOptions: React.FC<PostOptionsProps> = ({
  userId,
  name,
  postId,
  gameplayId,
  isUserPost,
  onClose,
  onDeletePost,
  onDeleteGameplay,
}) => {
  const [isUserHidden, setIsUserHidden] = useState(false);
  const overlayRef = React.createRef<HTMLDivElement>();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef, onClose]);

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigateReportUser = () => {
    navigate(`/user/${encodeUsername(name)}/report`, {
      state: { userId, name },
    });
  };

  const handleHideUser = async () => {
    try {
      await hideUser(userId);
      setIsUserHidden(true);
      toast.success("Utilisateur masqué avec succès !");
      onClose();
    } catch (error) {
      console.error("Error hiding user:", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (postId) {
        await deletePost(postId);
      } else if (gameplayId) {
        await deleteGameplay(gameplayId);
      }
      onClose();
      if (onDeletePost && postId) onDeletePost(postId);
      if (onDeleteGameplay) onDeleteGameplay();
    } catch (error) {
      console.error("Error deleting post/gameplay:", error);
    }
  };

  const getOptionTitle = () => {
    if (postId) return "Options du post";
    if (gameplayId) return "Options du gameplay";
    return "Options";
  };

  const getRightPosition = () => {
    return gameplayId ? "right-[-40px]" : "right-[-20px]";
  };

  return (
    <div
      ref={overlayRef}
      className={`absolute ${getRightPosition()} top-0 z-[100] flex w-[400px] max-w-[80vw] cursor-default flex-col gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-1 backdrop-blur-[6.5px]`}
      style={{
        background:
          "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
      }}
    >
      <div className="flex items-center justify-between self-stretch py-3 pl-2">
        <p className="button-label items-start self-stretch">
          {getOptionTitle()}
        </p>
        <div className="cursor-pointer">
          <CloseFillIcon onClick={onClose} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch pb-1">
        {isUserPost && (
          <div
            className="flex cursor-pointer flex-wrap content-center items-center gap-x-[10px] self-stretch px-1 text-white hover:underline"
            onClick={handleDelete}
          >
            <DeleteIcon />
            <p className="nav-menu-500">Supprimer</p>
          </div>
        )}
        {!isUserPost && (
          <>
            <div className="flex cursor-pointer flex-wrap content-center items-center gap-x-[10px] self-stretch px-1">
              <WarningIcon />
              <p className="nav-menu-500">Signaler le post</p>
            </div>
            <div
              className="flex cursor-pointer flex-wrap content-center items-center gap-x-[8px] self-stretch pl-[2px] pr-1"
              onClick={handleHideUser}
            >
              <EyesHideWhiteIcon />
              <p className="nav-menu-500">
                {isUserHidden ? (
                  "Utilisateur masqué"
                ) : (
                  <>
                    Masquer <span className="nav-menu-700">{name}</span>
                  </>
                )}
              </p>
            </div>
            <div
              className="flex cursor-pointer flex-wrap content-center items-center gap-x-[10px] self-stretch px-1"
              onClick={navigateReportUser}
            >
              <WarningIcon />
              <p className="nav-menu-500">
                Signaler <span className="nav-menu-700">{name}</span>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
