import axios, { AxiosResponse } from "axios";
import {
  fetchAllLauncherUrl,
  fetchAllPlatformsUrl,
  linkLauncherUrl,
} from "../constants/api";
import {
  AddLauncher,
  CreateLauncherType,
  GameSearchResult,
  Launcher,
  Platform,
} from "../types";
import { convertToWebP } from "../utils/File";

export const createLauncher = async (
  formData: CreateLauncherType,
  files: { logo: File },
): Promise<CreateLauncherType> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formDataWithFiles = new FormData();
  formDataWithFiles.append("name", formData.name);
  formDataWithFiles.append("logo", files.logo);
  if (files.logo) {
    const webPLogo = await convertToWebP(files.logo);
    formDataWithFiles.append("logo", webPLogo);
  }
  const response = await axios.post<CreateLauncherType>(
    fetchAllLauncherUrl,
    formDataWithFiles,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const fetchAllLaunchers = async (): Promise<Launcher[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  try {
    const response = await axios.get<Launcher[]>(fetchAllLauncherUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erreur lors de la récupération des launchers.");
  }
};

export const linkLauncher = async (
  launcherId: string,
  token: string,
): Promise<AddLauncher> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<AddLauncher> = await axios.post(
      linkLauncherUrl,
      { launcherId, token },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la liaison du launcher:", error);
    throw error;
  }
};

export const fetchGamesLauncher = async (
  id: string,
): Promise<GameSearchResult[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<GameSearchResult[]> = await axios.get(
      `${fetchAllLauncherUrl}${id}/games`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des jeux:", error);
    throw error;
  }
};

export const fetchAllPlatorms = async (): Promise<Platform[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  try {
    const response = await axios.get<Platform[]>(fetchAllPlatformsUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erreur lors de la récupération des launchers.");
  }
};
