import { CloseFillIcon } from "..";

type MediaListCardProps = {
  banner: string;
  images?: string[];
  isVideo?: boolean;
  onToggle?: () => void;
  isSelected?: boolean;
  showSelectionIndicator?: boolean;
  onClick?: () => void;
  size?: "s" | "m" | "l";
  onOpenModal?: () => void;
};

export const MediaThumbnail: React.FC<MediaListCardProps> = ({
  banner,
  onToggle,
  isVideo,
  showSelectionIndicator,
  onClick,
  size,
}) => {
  let classname = "string";

  if (size === "s") classname = "media-image-s";
  if (size === "m") classname = "media-image-m";
  if (size === "l") classname = "media-image-l";
  return (
    <>
      {isVideo ? (
        <div className="relative">
          <video
            className={`${classname} mb-1 mr-1 cursor-pointer overflow-hidden`}
            src={banner}
            autoPlay
            loop
            muted
          />
          {showSelectionIndicator && (
            <div className="absolute right-1 top-1 cursor-pointer">
              <CloseFillIcon size={12} onClick={onClick} />
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${classname} relative mb-1 mr-1 cursor-pointer overflow-hidden`}
          onClick={onToggle}
        >
          <div
            className="absolute left-0 top-0 z-[2] box-border h-full w-full overflow-hidden bg-cover bg-center"
            style={{
              backgroundImage: `url(${banner})`,
            }}
          >
            {showSelectionIndicator && (
              <div className="relative">
                <div className="absolute right-1 top-1">
                  <CloseFillIcon size={12} onClick={onClick} />
                </div>
              </div>
            )}
          </div>
          <div className="pt-[100%]"></div>
        </div>
      )}
    </>
  );
};
