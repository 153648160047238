import { AvatarIcon, LastMessageDate, PastilleDiscussion } from "..";

type GroupDiscussionCardProps = {
  id: string;
  logo: string;
  game: string;
  gamelogo: string;
  groupname: string;
  participantCount: number | string;
  messageCount: number;
  lastMessageDate: string;
  isActive?: boolean;
  onClick?: () => void;
};

export const GroupDiscussionCard: React.FC<GroupDiscussionCardProps> = ({
  id,
  logo,
  game,
  gamelogo,
  groupname,
  participantCount,
  messageCount,
  lastMessageDate,
  isActive = false,
  onClick,
}) => {
  return (
    <div
      className={`flex w-full cursor-pointer items-center justify-between gap-x-3 rounded-lg ${
        isActive ? "outline outline-[1px] outline-primary-500" : ""
      } px-3 py-2 hover:bg-[rgba(245,235,255,0.15)]`}
      onClick={onClick}
    >
      <div className="relative flex items-center gap-x-3">
        <AvatarIcon logo={logo} className="avatar-m text-center" />
        {gamelogo && (
          <div className="game-thumbnail-xxs absolute bottom-[-2px] left-[40px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
            <div
              className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
              style={{
                background: `url(${gamelogo}) 50% / cover no-repeat`,
              }}
            ></div>
          </div>
        )}
        <div className="flex flex-col items-start gap-y-0.5">
          <p className="h4-mobile">{groupname}</p>
          {game && <p className="paragraph-s-500 opacity-60">{game}</p>}
          <p className="paragraph-s-500 opacity-60">{`${participantCount} participants`}</p>
        </div>
      </div>
      <div className="flex w-[35px] flex-col items-center gap-y-[10px] self-stretch pt-1">
        {!isActive && messageCount > 0 ? (
          <PastilleDiscussion messageCount={messageCount} />
        ) : null}
        <p className="paragraph-s-500 whitespace-nowrap text-right opacity-60">
          <LastMessageDate timestamp={lastMessageDate} />
        </p>
      </div>
    </div>
  );
};
