import { GamesIcons, LinkIcon, GameCoverColumn } from "../../components";

interface GameInfoStudioProps {
  studioLogo: string;
  studioName: string;
  studioDescription: string;
  studioWebsite: string;
  studioGames: Array<{ id: string; name: string; card: string }>;
}

export const GameInfoStudio: React.FC<GameInfoStudioProps> = ({
  studioLogo,
  studioName,
  studioDescription,
  studioWebsite,
  studioGames = [],
}) => {
  return (
    <div
      className="flex w-full flex-col items-start justify-center gap-y-6 rounded-xl p-5"
      style={{
        background:
          "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
      }}
    >
      <div className="flex items-center gap-x-6 self-stretch">
        <GamesIcons
          logo={studioLogo}
          className="game-thumbnail-m cursor-default"
        />
        <div className="flex flex-col gap-y-2">
          <p className="h2-mobile">{studioName}</p>
          {/* <div className="flex cursor-pointer items-center gap-x-3">
            <LinkIcon />
            <a
              className="link-s"
              href={studioWebsite}
              target="_blank"
              rel="noopener noreferrer"
            >
              Site officiel
            </a>
          </div> */}
        </div>
      </div>
      {/* <p className="paragraph-m-400">{studioDescription}</p> */}
      <div
        className={`flew-wrap flex content-start items-start gap-x-4 self-stretch ${studioGames.length === 0 ? "hidden" : ""}`}
      >
        {studioGames.slice(0, 3).map((game) => (
          <GameCoverColumn
            key={game.id}
            banner={game.card}
            name={game.name}
            gameId={game.id.toString()}
            isClickable={true}
            size="w-[125px] h-[187px]"
          />
        ))}
      </div>
      {/* {studioGames.length > 3 && (
        <p className="link-s cursor-pointer self-stretch text-center">
          Voir plus
        </p>
      )} */}
    </div>
  );
};
