import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

interface FormattedNameRouteProps {
  element: React.ReactNode;
}

export const FormattedNameRoute: React.FC<FormattedNameRouteProps> = ({
  element,
}) => {
  const { name, gameId } = useParams<{ name: string; gameId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (name && name.includes(" ")) {
      const formattedName = name.replace(/\s+/g, "_");
      navigate(`/game/${gameId}/${formattedName}`, { replace: true });
    }
  }, [name, gameId, navigate]);

  return <>{element}</>;
};
