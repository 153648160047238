interface FilterButtonProps {
  isActive: boolean;
  label: string;
  onClick: () => void;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  isActive,
  label,
  onClick,
}) => {
  return (
    <>
      <style>
        {`.button-filter {
            background: linear-gradient(96deg, #fe0045, #280c31) padding-box, 
                        linear-gradient(to right, rgb(198, 182, 213, 0.25), rgb(255, 255, 255, 0)) border-box;
            border: 1px transparent;

        }
        .button-filter-active {
            background: linear-gradient(0deg, rgba(245, 235, 255, 0.20) 0%, rgba(245, 235, 255, 0.20) 100%), rgba(8, 66, 164, 0.20);
            border: 1px solid rgba(248, 239, 251, 0.50);
        }
        `}
      </style>
      <div
        className={`z-0 inline-flex cursor-pointer cursor-pointer items-center justify-center rounded-xl px-[16px] py-[5px] hover:bg-[rgba(245,235,255,0.35)] opacity-${isActive ? 100 : 90} ${
          isActive ? "button-filter" : "button-filter-active"
        }`}
        onClick={onClick}
      >
        <span className={`${isActive ? "label-m-700" : "label-m-400"}`}>
          {label}
        </span>
      </div>
    </>
  );
};
