import axios, { AxiosResponse } from "axios";
import {
  fetchAllStudiosUrl,
  fetchGamesStudiosUrl,
  fetchStudioByIdUrl,
} from "../constants/api";
import { Studio, StudioPage, StudioPanelAdmin } from "../types";
import { StudioGames } from "../types/Studio";
import { convertToWebP } from "../utils/File";

export const fetchOneStudio =
  (studioId: string) => async (): Promise<Studio> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<Studio> = await axios.get(
        fetchStudioByIdUrl + studioId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const studioData: Studio = response.data;
      return studioData;
    } catch (error) {
      console.error("Error fetching studio:", error);
      throw error;
    }
  };

export const fetchAllStudio = async (): Promise<Studio[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response = await axios.get<Studio[]>(fetchAllStudiosUrl, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const fetchStudioPage =
  (studioId: string) => async (): Promise<StudioPage> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<StudioPage> = await axios.get(
        fetchStudioByIdUrl + studioId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const studioPageData: StudioPage = response.data;
      return studioPageData;
    } catch (error) {
      console.error("Error fetching studio:", error);
      throw error;
    }
  };

export const fetchStudioPanelAdmin =
  (studioId: string) => async (): Promise<StudioPanelAdmin> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<StudioPanelAdmin> = await axios.get(
        fetchStudioByIdUrl + studioId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const studioPanelData: StudioPanelAdmin = response.data;
      return studioPanelData;
    } catch (error) {
      console.error("Error fetching studio:", error);
      throw error;
    }
  };

export const fetchStudioGames = async (
  studioId: string,
): Promise<StudioGames[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<StudioGames[]> = await axios.get(
      fetchGamesStudiosUrl + studioId + "/games",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching games for studio:", error);
    throw error;
  }
};

export const createStudio = async (
  newStudioData: Partial<Studio>,
  files: { logo?: File; banner?: File },
): Promise<Studio> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("name", newStudioData.name || "");
  formData.append("date_release", newStudioData.date_release || "");

  if (files.logo) {
    const webPLogo = await convertToWebP(files.logo);
    formData.append("logo", webPLogo);
  }

  if (files.banner) {
    const webPBanner = await convertToWebP(files.banner);
    formData.append("banner", webPBanner);
  }

  try {
    const response: AxiosResponse<Studio> = await axios.post(
      fetchStudioByIdUrl,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error creating studio:", error);
    throw error;
  }
};

export const updateStudio = async (
  studioId: string,
  updatedData: Partial<Studio>,
  files: { logo?: File; banner?: File },
): Promise<Studio> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("name", updatedData.name as string);
  formData.append("date_release", updatedData.date_release as string);

  if (files.logo) {
    const webPLogo = await convertToWebP(files.logo);
    formData.append("logo", webPLogo);
  }

  if (files.banner) {
    const webPBanner = await convertToWebP(files.banner);
    formData.append("banner", webPBanner);
  }

  try {
    const response: AxiosResponse<Studio> = await axios.patch(
      `${fetchStudioByIdUrl}${studioId}`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error updating studio:", error);
    throw error;
  }
};
