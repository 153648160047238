import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

interface VideoTileProps {
  link: string;
  className?: string;
  isSingle: boolean;
  videoId: string;
  activeVideoId: string | null;
  setActiveVideoId: (id: string | null) => void;
}

export const VideoTile: React.FC<VideoTileProps> = ({
  link,
  className,
  isSingle,
  videoId,
  activeVideoId,
  setActiveVideoId,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (inView) {
      setActiveVideoId(videoId);
    }
  }, [inView, videoId, setActiveVideoId]);

  useEffect(() => {
    if (activeVideoId === videoId) {
      const playPromise = videoRef.current?.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Video is playing
          })
          .catch((error) => {
            console.error("Failed to play video:", error);
          });
      }
    } else {
      videoRef.current?.pause();
    }
  }, [activeVideoId, videoId]);

  const videoClass = `relative mb-1 mr-1 w-full cursor-pointer h-auto overflow-hidden ${className}`;

  return (
    <div className={videoClass} ref={ref}>
      <video
        preload="auto"
        className="z-[2] max-h-[500px] w-full object-cover"
        src={link}
        muted
        playsInline
        ref={videoRef}
        controls
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
