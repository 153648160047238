import { createGroup } from "../helpers/Discussion";
import { Player, Game } from "../types/index";
import { toast } from "react-toastify";

export const handleGroupCreation = async (
  groupName: string,
  participants: Player[],
  description: string,
  coverImage: string | undefined,
  selectedGame: Game | undefined,
  clearLocalStorage: () => void,
  navigate: (path: string) => void,
) => {
  try {
    if (!groupName) {
      toast.error("Le nom du groupe est obligatoire", {
        position: "top-center",
      });
      return;
    }

    if (participants.length < 2) {
      toast.error("Sélectionnez au moins 2 participants", {
        position: "top-center",
      });
      return;
    }

    // Convert user IDs to strings
    const userIds = participants.map((p) => p.id.toString());
    const gameId = selectedGame ? selectedGame.id : undefined;

    const requestData = {
      groupName,
      userIds,
      description,
      coverImage: coverImage ?? "",
      gameId,
    };

    console.log("Request payload:", requestData);

    const response = await createGroup(
      groupName,
      userIds,
      description,
      coverImage ?? "",
      gameId,
    );

    console.log("Group created successfully:", response);

    const groupId = response.id;

    clearLocalStorage();
    toast.success("Groupe créé avec succès !", {
      position: "top-center",
    });

    navigate(`/messages/group/${groupId}`);
  } catch (error) {
    console.error("Erreur lors de la création du groupe :", error);
    toast.error("Erreur lors de la création du groupe. Veuillez réessayer.", {
      position: "top-center",
    });
  }
};
