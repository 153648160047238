import { AvatarIcon, GetFileType, PostImage } from "../..";
import { useNavigate } from "react-router-dom";
import { Post } from "../../../types";

interface QuoteCreatePostProps {
  quotePost: Post;
}

interface FileWithLink {
  link: string;
}

export const QuoteCreatePost: React.FC<QuoteCreatePostProps> = ({
  quotePost,
}) => {
  const navigate = useNavigate();

  const filesWithTypes = GetFileType(quotePost?.files as FileWithLink[]);
  const navigateToPost = () => {
    navigate(`/post/${quotePost?.id}`);
  };
  return (
    <div
      className={`mt-1 flex cursor-pointer flex-col items-start gap-y-1 self-stretch rounded-lg bg-[rgba(245,235,255,0.05)] px-[10px] pb-[10px] pt-2 outline outline-1 outline-[rgba(255,255,255,0.40)] hover:bg-[rgba(245,235,255,0.10)]`}
      onClick={navigateToPost}
    >
      <div className="flex items-center gap-x-1 self-stretch">
        <AvatarIcon logo={quotePost?.user.logo} className="avatar-xxs" />
        <p className="label-s-600">
          {quotePost?.user.pseudo || quotePost?.user.username}
        </p>
      </div>
      <p className="label-s-400">{quotePost?.content}</p>
      {quotePost?.files && <PostImage files={filesWithTypes} />}
    </div>
  );
};
