import React from "react";

type ImagesIconProps = {
  onClick?: () => void;
};

export const ImagesIcon: React.FC<ImagesIconProps> = ({ onClick }) => {
  return (
    <div className="group relative cursor-pointer rounded-full p-1.5 transition-colors duration-200 ease-in-out hover:bg-[rgba(255,255,255,0.15)]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        onClick={onClick}
        className="cursor-pointer"
      >
        <g clipPath="url(#clip0_223_2195)">
          <path
            d="M26.6667 24.889V25.7779C26.6667 27.2507 25.4728 28.4445 24 28.4445H2.66667C1.19389 28.4445 0 27.2507 0 25.7779V11.5557C0 10.0829 1.19389 8.889 2.66667 8.889H3.55556V20.4446C3.55556 22.8952 5.54933 24.889 8 24.889H26.6667ZM32 20.4446V6.22233C32 4.74955 30.8061 3.55566 29.3333 3.55566H8C6.52722 3.55566 5.33333 4.74955 5.33333 6.22233V20.4446C5.33333 21.9173 6.52722 23.1112 8 23.1112H29.3333C30.8061 23.1112 32 21.9173 32 20.4446ZM14.2222 8.889C14.2222 10.3618 13.0283 11.5557 11.5556 11.5557C10.0828 11.5557 8.88889 10.3618 8.88889 8.889C8.88889 7.41622 10.0828 6.22233 11.5556 6.22233C13.0283 6.22233 14.2222 7.41622 14.2222 8.889ZM8.88889 16.889L11.9731 13.8048C12.2334 13.5445 12.6555 13.5445 12.9159 13.8048L15.1111 16.0001L22.6397 8.4715C22.9001 8.21116 23.3222 8.21116 23.5826 8.4715L28.4444 13.3334V19.5557H8.88889V16.889Z"
            fill="url(#paint0_linear_223_2195)"
            className="transition-colors duration-200 ease-in-out group-hover:fill-[rgba(255,255,255,0.85)]"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_223_2195"
            x1="16"
            y1="3.55566"
            x2="16"
            y2="28.4445"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8EFFB" />
            <stop offset="1" stopColor="#A66CB8" />
          </linearGradient>
          <clipPath id="clip0_223_2195">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
