import React from "react";
import Trophy from "../../../../assets/trophy.webp";
import TrophyBuilder from "../../../../assets/trophies_builder.webp";
import { PlayerStats } from "../../../../types";
import { CardStat, CocLevelIcon, ImageCard } from "../../..";

interface CocStatsProps {
  playerStats: PlayerStats;
  hasGameAccount: boolean;
}

export const CocStats: React.FC<CocStatsProps> = ({
  playerStats,
  hasGameAccount,
}) => {
  return (
    <div className="flex flex-col gap-y-6">
      {hasGameAccount && playerStats?.clan?.name && (
        <div className="flex items-center justify-center rounded-md bg-[rgba(245,235,255,0.10)] px-4 py-3 shadow-[1px_2px_1px_rgba(0,0,0,0.45)] backdrop-blur-[2px]">
          <p className="h3-mobile">{playerStats?.clan.name}</p>
          {playerStats?.clan.badgeUrls && (
            <ImageCard
              imageUrl={playerStats?.clan.badgeUrls.large ?? ""}
              size={40}
            />
          )}
        </div>
      )}
      {hasGameAccount && playerStats?.trophies && (
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex justify-between gap-x-3">
            <CardStat direction="column">
              <div className="flex items-center justify-center gap-x-2">
                <p className="paragraph-s-700">{playerStats?.trophies}</p>
                <ImageCard imageUrl={Trophy} size={18} />
              </div>
              <p className="label-s-400 opacity-60">Base principale</p>
            </CardStat>
            {playerStats?.builderBaseTrophies && (
              <CardStat direction="column">
                <div className="flex items-center justify-center gap-x-2">
                  <p className="paragraph-s-700">
                    {playerStats?.builderBaseTrophies}
                  </p>
                  <ImageCard imageUrl={TrophyBuilder} size={20} />
                </div>
                <p className="label-s-400 opacity-60">Base ouvrier</p>
              </CardStat>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
