export const DotIcon = () => (
  <svg
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill="#F8EFFB" />
  </svg>
);

export const DotsIcon = () => (
  <svg
    width="30"
    height="12"
    viewBox="0 0 30 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="6" r="2.5" fill="white" />
    <circle cx="15" cy="6" r="2.5" fill="white" />
    <circle cx="26" cy="6" r="2.5" fill="white" />
  </svg>
);

export const DotsFrameIcon = () => (
  <div className="flex w-[30px] items-center justify-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g filter="url(#filter0_d_598_8288)">
        <circle cx="6" cy="5" r="2" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_598_8288"
          x="0"
          y="0"
          width="12"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_598_8290"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_598_8290"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g filter="url(#filter0_d_598_8289)">
        <circle cx="6" cy="5" r="2" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_598_8289"
          x="0"
          y="0"
          width="12"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_598_8290"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_598_8290"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g filter="url(#filter0_d_598_8290)">
        <circle cx="6" cy="5" r="2" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_598_8290"
          x="0"
          y="0"
          width="12"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_598_8290"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_598_8290"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
);

export const GreenDotIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    className={className}
  >
    <circle
      cx="2"
      cy="2"
      r="2"
      fill="var(--complementary-green, #00DD23)"
      filter="url(#green-dot-shadow)"
    />
    <defs>
      <filter id="green-dot-shadow" x="-5" y="-5" width="14" height="14">
        <feDropShadow dx="0" dy="0" stdDeviation="9" floodColor="#00DD23" />
      </filter>
    </defs>
  </svg>
);

export const RedDotIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    className={className}
  >
    <circle cx="2" cy="2" r="2" fill="var(--complementary-red, #FE0045)" />
    <filter id="red-dot-shadow" x="-5" y="-5" width="14" height="14">
      <feDropShadow dx="0" dy="0" stdDeviation="9" floodColor="#A50C0C" />
    </filter>
  </svg>
);

export const DotsIconPost = () => (
  <svg
    width="18"
    height="4"
    viewBox="0 0 18 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill="white" />
    <circle cx="9" cy="2" r="2" fill="white" />
    <circle cx="16" cy="2" r="2" fill="white" />
  </svg>
);

type DotsIconFrameProps = {
  onClick?: () => void;
  shadow?: boolean;
};

export const DotsIconFrame: React.FC<DotsIconFrameProps> = ({
  onClick,
  shadow = true,
}) => {
  return (
    <div
      className={`flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded-[7px] border-[0.3px] border-solid border-white bg-[#ffffff42] px-[4px] ${shadow ? "shadow-[0_3px_5px_rgba(0,0,0)]" : ""} `}
      onClick={onClick}
    >
      <DotsFrameIcon />
    </div>
  );
};

export const DotsIconFrameGameplay: React.FC<DotsIconFrameProps> = ({
  onClick,
}) => {
  return (
    <div
      className={`flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-[rgba(0,0,0)] px-[4px]`}
      onClick={onClick}
    >
      <DotsIcon />
    </div>
  );
};
