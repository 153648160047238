export const MessageFillIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="m-1.5"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.680306C0 0.304583 0.30251 0 0.675676 0H19.3243C19.6975 0 20 0.304584 20 0.680307V15.1668C20 15.5426 19.6975 15.8471 19.3243 15.8471H17.8185C17.4454 15.8471 17.1429 16.1517 17.1429 16.5275V19.3184C17.1429 19.9028 16.4592 20.2152 16.0223 19.8304L11.691 16.0154C11.5679 15.9069 11.4098 15.8471 11.2462 15.8471H0.675675C0.302509 15.8471 0 15.5426 0 15.1668V0.680306ZM1.7806 7.56841C1.7806 6.93116 2.29719 6.41456 2.93444 6.41456H5.24183V8.72225H2.93444C2.29719 8.72225 1.7806 8.20565 1.7806 7.56841ZM10.6266 8.72198C11.2638 8.72198 11.7804 8.20538 11.7804 7.56813C11.7804 6.93088 11.2638 6.41429 10.6266 6.41429H8.31919V8.72198H10.6266ZM6.78037 2.56813C7.41757 2.56813 7.93412 3.08467 7.93412 3.72187V6.02965H5.62663V3.72187C5.62663 3.08468 6.14318 2.56813 6.78037 2.56813ZM5.62709 11.4144C5.62709 12.0516 6.14364 12.5681 6.78083 12.5681C7.41803 12.5681 7.93458 12.0516 7.93458 11.4144V9.10661H5.62709V11.4144ZM17.2976 5.94599C17.2976 6.54305 16.8136 7.02707 16.2165 7.02707C15.6194 7.02707 15.1354 6.54305 15.1354 5.94599C15.1354 5.34893 15.6194 4.86491 16.2165 4.86491C16.8136 4.86491 17.2976 5.34893 17.2976 5.94599ZM14.0537 10.8109C14.6508 10.8109 15.1348 10.3269 15.1348 9.72984C15.1348 9.13278 14.6508 8.64876 14.0537 8.64876C13.4567 8.64876 12.9727 9.13278 12.9727 9.72984C12.9727 10.3269 13.4567 10.8109 14.0537 10.8109Z"
      fill="#F8EFFB"
    />
  </svg>
);

export const MessageStrokeIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.74183 7.91456V9.22225H3.93444C3.57334 9.22225 3.2806 8.92951 3.2806 8.56841C3.2806 8.2073 3.57333 7.91456 3.93444 7.91456H5.74183ZM8.43412 4.72187V6.52965H7.12663V4.72187C7.12663 4.36082 7.41932 4.06813 7.78037 4.06813C8.14143 4.06813 8.43412 4.36082 8.43412 4.72187ZM7.12709 12.4144V10.6066H8.43458V12.4144C8.43458 12.7754 8.14188 13.0681 7.78083 13.0681C7.41978 13.0681 7.12709 12.7754 7.12709 12.4144ZM9.81919 9.22198V7.91429H11.6266C11.9877 7.91429 12.2804 8.20703 12.2804 8.56813C12.2804 8.92924 11.9877 9.22198 11.6266 9.22198H9.81919ZM12.3606 17.3906L12.691 17.0154L12.3606 17.3906L16.6918 21.2056C17.4568 21.8794 18.6429 21.3263 18.6429 20.3184V17.5275C18.6429 17.4246 18.7247 17.3471 18.8185 17.3471H20.3243C20.9768 17.3471 21.5 16.8155 21.5 16.1668V1.68031C21.5 1.03167 20.9768 0.5 20.3243 0.5H1.67568C1.02315 0.5 0.5 1.03167 0.5 1.68031V16.1668C0.5 16.8155 1.02315 17.3471 1.67567 17.3471H12.2462C12.2877 17.3471 12.3284 17.3623 12.3606 17.3906ZM17.7976 6.94599C17.7976 7.26691 17.5374 7.52707 17.2165 7.52707C16.8956 7.52707 16.6354 7.26691 16.6354 6.94599C16.6354 6.62507 16.8956 6.36491 17.2165 6.36491C17.5374 6.36491 17.7976 6.62507 17.7976 6.94599ZM15.6348 10.7298C15.6348 11.0508 15.3747 11.3109 15.0537 11.3109C14.7328 11.3109 14.4727 11.0508 14.4727 10.7298C14.4727 10.4089 14.7328 10.1488 15.0537 10.1488C15.3747 10.1488 15.6348 10.4089 15.6348 10.7298Z"
      stroke="#F8EFFB"
    />
  </svg>
);

export const NewMessageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
  >
    <path
      d="M17.3235 9.76157C17.3235 9.74687 17.3101 9.73531 17.2954 9.7363C11.5877 10.1194 12.5325 6.42773 12.3234 6.42773H1.13448C0.759967 6.42773 0.456364 6.73342 0.456364 7.1105V21.6493C0.456364 22.0264 0.759967 22.3321 1.13448 22.3321H2.64571C3.02022 22.3321 3.32382 22.6378 3.32382 23.0149V25.8158C3.32382 26.4024 4.00995 26.7159 4.44842 26.3297L8.79531 22.501C8.91892 22.3921 9.07757 22.3321 9.2418 22.3321H19.8505C20.225 22.3321 20.5286 22.0264 20.5286 21.6493V14.683L19.9227 14.6015L19.4752 14.5011C18.6941 14.326 18.0332 13.8089 17.6752 13.0929V13.0929V13.0929C17.4442 12.6545 17.3235 12.1663 17.3235 11.6707V9.76157V9.76157Z"
      fill="#F8EFFB"
    />
    <rect
      x="13.4385"
      y="9.73706"
      width="3.89369"
      height="0.689296"
      fill="#F8EFFB"
    />
    <path
      d="M20.6112 0.5H20.3287C19.7436 0.5 19.2693 0.974288 19.2693 1.55935V5.37302H15.385C14.8 5.37302 14.3257 5.84731 14.3257 6.43237V6.71486C14.3257 7.29993 14.8 7.77422 15.385 7.77422H19.2693V11.6585C19.2693 12.2436 19.7436 12.7179 20.3287 12.7179H20.6112C21.1962 12.7179 21.6705 12.2436 21.6705 11.6585V7.77422L25.4842 7.77422C26.0693 7.77422 26.5435 7.29993 26.5435 6.71487V6.43237C26.5435 5.84731 26.0693 5.37302 25.4842 5.37302H21.6705V1.55935C21.6705 0.974288 21.1962 0.5 20.6112 0.5Z"
      fill="#F8EFFB"
    />
  </svg>
);

export const NewMessage20Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M13.1367 7.32553C13.1367 7.31426 13.1264 7.3054 13.1152 7.30616C8.74041 7.59981 9.4646 4.77026 9.30429 4.77026H0.728396C0.441346 4.77026 0.208646 5.00456 0.208646 5.29358V16.4371C0.208646 16.7261 0.441346 16.9604 0.728396 16.9604H1.8867C2.17375 16.9604 2.40645 17.1947 2.40645 17.4837V19.6306C2.40645 20.0801 2.93234 20.3204 3.26841 20.0244L6.60015 17.0898C6.69489 17.0064 6.81649 16.9604 6.94236 16.9604H15.0735C15.3606 16.9604 15.5933 16.7261 15.5933 16.4371V11.0977L15.1289 11.0352L14.7859 10.9582C14.1872 10.824 13.6806 10.4277 13.4062 9.87891V9.87891V9.87891C13.2292 9.54282 13.1367 9.16868 13.1367 8.78882V7.32553V7.32553Z"
      fill="#F8EFFB"
    />
    <rect
      x="10.1589"
      y="7.30664"
      width="2.98438"
      height="0.52832"
      fill="#F8EFFB"
    />
    <path
      d="M15.6565 0.226807H15.4399C14.9915 0.226807 14.628 0.590331 14.628 1.03876V3.9618H11.6508C11.2024 3.9618 10.8389 4.32532 10.8389 4.77375V4.99028C10.8389 5.43871 11.2024 5.80223 11.6508 5.80223H14.628V8.7794C14.628 9.22783 14.9915 9.59136 15.4399 9.59136H15.6565C16.1049 9.59136 16.4684 9.22783 16.4684 8.7794V5.80223L19.3915 5.80223C19.8399 5.80223 20.2034 5.43871 20.2034 4.99028V4.77376C20.2034 4.32532 19.8399 3.9618 19.3915 3.9618H16.4684V1.03876C16.4684 0.590331 16.1049 0.226807 15.6565 0.226807Z"
      fill="#F8EFFB"
    />
  </svg>
);

export const MessageFriendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M15.4515 7.13879C15.4515 6.84977 15.2188 6.61548 14.9318 6.61548H0.586673C0.299623 6.61548 0.0669222 6.84977 0.0669222 7.13879V18.2823C0.0669222 18.5713 0.299623 18.8056 0.586673 18.8056H1.74498C2.03203 18.8056 2.26472 19.0399 2.26472 19.3289V21.4758C2.26472 21.9253 2.79062 22.1656 3.12669 21.8696L6.45842 18.935C6.55317 18.8516 6.67476 18.8056 6.80064 18.8056H14.9318C15.2188 18.8056 15.4515 18.5713 15.4515 18.2823V7.13879Z"
      fill="#F8EFFB"
    />
    <path
      d="M15.4531 1.25H15.2366C14.3739 1.25 13.6746 1.94931 13.6746 2.81196V4.98499H11.4475C10.5848 4.98499 9.8855 5.6843 9.8855 6.54695V6.76347C9.8855 7.62611 10.5848 8.32542 11.4475 8.32542H13.6746V10.5526C13.6746 11.4152 14.3739 12.1145 15.2366 12.1145H15.4531C16.3157 12.1145 17.0151 11.4152 17.0151 10.5526V8.32542L19.1881 8.32543C20.0507 8.32543 20.75 7.62611 20.75 6.76347V6.54695C20.75 5.68431 20.0507 4.98499 19.1881 4.98499H17.0151V2.81196C17.0151 1.94931 16.3157 1.25 15.4531 1.25Z"
      fill="#F8EFFB"
      stroke="#1B042C"
      strokeWidth="1.5"
    />
  </svg>
);
