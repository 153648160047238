import {
  HeaderProfile,
  SubtitleNavigation,
  EditRoundedIcon,
  EditIconFrame,
  DotsIconFrame,
  DescriptionUser,
  SecondaryButton,
  UserOptions,
} from "../../";
import { useParams } from "react-router";
import { useAuth } from "../../../providers/userAuth";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import React, { useState } from "react";
import { LogoType } from "../../../types";

interface HeaderUserProps {
  banner?: string;
  logo: string;
  about: string;
  followersCount: number;
  friendsCount: number;
  friendsLogos: LogoType[];
  edit?: boolean;
  userId: string;
  follow: boolean;
  friend: string;
  isPrivate?: boolean;
  navigationButtons: { title: string; path: string }[];
  username: string;
}

export const HeaderUser: React.FC<HeaderUserProps> = ({
  banner,
  logo,
  about,
  followersCount: initialFollowersCount,
  friendsCount,
  friendsLogos,
  edit = false,
  userId,
  follow: initialFollow,
  friend,
  isPrivate = false,
  navigationButtons,
  username,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const isUserPage = username === user?.username;
  const isDesktop = useMediaQuery({ minWidth: 745 });
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [followersCount, setFollowersCount] = useState(initialFollowersCount);
  const [follow, setFollow] = useState(initialFollow);

  const handleFollowChange = (newFollowStatus: boolean) => {
    setFollow(newFollowStatus);
    setFollowersCount((prevCount) =>
      newFollowStatus ? prevCount + 1 : prevCount - 1,
    );
  };

  const backgroundStyle = banner
    ? isDesktop
      ? {
          backgroundImage: `linear-gradient(111deg, rgba(20, 0, 41, 0.91) 18.07%, rgba(13, 0, 27, 0.71) 41.51%, rgba(0, 0, 0, 0.17) 64.39%, rgba(20, 0, 41, 0.59) 78.87%), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }
      : {
          backgroundImage: `linear-gradient(0deg, #1A0920 10.91%, rgba(0, 0, 0, 0.11) 81.91%), url(${banner}) `,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }
    : isDesktop
      ? {
          background:
            "linear-gradient(0deg, #280C31 7.83%, rgba(8, 0, 16, 0.39) 52.26%, rgba(0, 0, 0, 0.00) 66.65%), linear-gradient(285deg, rgba(14, 65, 168, 0.70) 10.65%, rgba(132, 35, 129, 0.70) 26.88%, rgba(250, 5, 90, 0.70) 60.48%, rgba(211, 19, 132, 0.70) 82.82%, rgba(152, 40, 197, 0.70) 103.6%)",
        }
      : {
          background:
            "linear-gradient(0deg, #1A0920 7.83%, rgba(0, 0, 0, 0.00) 66.65%), linear-gradient(285deg, rgba(14, 65, 168, 0.70) 10.65%, rgba(132, 35, 129, 0.70) 26.88%, rgba(250, 5, 90, 0.70) 60.48%, rgba(211, 19, 132, 0.70) 82.82%, rgba(152, 40, 197, 0.70) 103.6%",
        };

  return (
    <div
      style={backgroundStyle}
      className={`relative flex w-full flex-col items-center justify-center px-5 min-745:min-h-[200px] min-851:pl-8 ${
        edit ? "max-mobile:pt-[96px]" : "max-mobile:pt-[150px]"
      }`}
    >
      {isUserPage && !edit ? (
        <div
          className="absolute right-5 top-[60px] cursor-pointer min-745:top-[24px]"
          onClick={() => navigate(`/settings/editprofil`)}
        >
          <EditIconFrame />
        </div>
      ) : (
        <div
          className="absolute right-5 top-[60px] min-1157:hidden min-745:top-[24px]"
          onClick={() => setShowUserOptions(true)}
        >
          {/* <DotsIconFrame /> */}
          {showUserOptions && (
            <div className="relative">
              <UserOptions
                userId={userId}
                name={username ?? ""}
                onClose={() => setShowUserOptions(false)}
              />
            </div>
          )}
        </div>
      )}
      {edit && (
        <div className="flex cursor-pointer items-center justify-center gap-x-2 self-stretch min-745:absolute min-745:right-10 min-745:top-10">
          <EditRoundedIcon />
          <p className="link-s">Ajoute une cover à ton profil</p>
        </div>
      )}

      <div className="flex w-full flex-col justify-center gap-y-4 max-tablet:max-w-[610px]">
        <HeaderProfile
          username={username ?? ""}
          logo={logo ?? ""}
          followersCount={followersCount}
          edit={edit}
          about={about}
          friendsCount={friendsCount}
          friendsLogos={friendsLogos}
          friend={friend}
          follow={follow}
          userId={userId}
          onFollowChange={handleFollowChange}
        />

        <div className="min-745:hidden">
          <DescriptionUser edit={edit} about={about} />
        </div>
        <div
          className={`flex flex-col justify-center min-745:hidden ${
            isUserPage && "hidden"
          } ${edit ? "" : "gap-y-2"}`}
        >
          {!edit && !isUserPage && (
            <div className="flex items-center gap-x-2">
              <SecondaryButton
                userId={userId}
                actionType="follow"
                initialFollow={follow}
                onFollowChange={handleFollowChange}
              />
              <SecondaryButton
                userId={userId}
                actionType="friendRequest"
                transparent={true}
                initialFriendStatus={friend}
              />
            </div>
          )}
        </div>
      </div>
      {!isPrivate && (
        <div className="w-full min-745:hidden max-tablet:max-w-[610px]">
          <SubtitleNavigation buttons={navigationButtons} padding="pt-4 pb-2" />
        </div>
      )}
    </div>
  );
};
