import { useNavigate } from "react-router-dom";
import {
  BackButton,
  ChevronIcon,
  SettingFrame,
  TransparentButton,
} from "../../../../components";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/userAuth";
import { User } from "../../../../types";
import { useMediaQuery } from "react-responsive";

export const AccountSettings = () => {
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const { user: authUser } = useAuth();
  const username = authUser?.username;
  const [userData, setUserData] = useState<User>();

  useEffect(() => {
    setUserData(authUser);
  }, [authUser]);

  const navigateChangeMail = () => {
    navigate(`/settings/account/mail`);
  };

  const navigateChangePseudo = () => {
    navigate(`/settings/account/pseudo`);
  };

  const navigatePasswordPage = () => {
    navigate(`/settings/account/password`);
  };

  return (
    <div
      className={`flex min-h-[calc(100vh-58px)] w-full flex-col gap-y-6 border-l border-l-[rgba(198,182,213,0.25)] px-5 pb-[60px] pt-10 max-mobile:pt-[40px] ${isWideScreen ? "" : "absolute inset-0 min-h-screen"}`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="min-745:hidden">
        <BackButton />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex items-center gap-x-10 self-stretch">
          <p className="h1-mobile">Accès au compte</p>
        </div>
        <div className="flex flex-col gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
          {/* <div
            className="flex cursor-pointer items-center justify-between self-stretch"
            onClick={navigateChangeMail}
          >
            <div className="flex gap-x-2 self-stretch">
              <SettingFrame noBackground={true} settingIconName="Mail" />
              <p className="nav-menu-500">Change ton email</p>
            </div>
            <ChevronIcon orientation="right" />
          </div> */}
          <div
            className="flex cursor-pointer items-center justify-between self-stretch"
            onClick={navigateChangePseudo}
          >
            <div className="flex gap-x-2 self-stretch">
              <SettingFrame noBackground={true} settingIconName="Edit" />
              <p className="nav-menu-500">Change ton pseudo</p>
            </div>
            <ChevronIcon orientation="right" />
          </div>
          <div
            className="flex cursor-pointer items-center justify-between self-stretch"
            onClick={navigatePasswordPage}
          >
            <div className="flex gap-x-2 self-stretch">
              <SettingFrame noBackground={true} settingIconName="Password" />
              <p className="nav-menu-500">Changer de mot de passe</p>
            </div>
            <ChevronIcon orientation="right" />
          </div>
        </div>
      </div>
    </div>
  );
};
