interface ImageCardProps {
  imageUrl: string;
  size: number;
  children?: React.ReactNode;
}

export const ImageCard: React.FC<ImageCardProps> = ({
  imageUrl,
  size,
  children,
}) => {
  return (
    <div
      className="box-border bg-center"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: `${size}px`,
        height: `${size}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
};
