import { DotsIconFrame, ImagesIcon, BackButton, DisplayedUsers } from "../..";
import { useNavigate, useParams } from "react-router-dom";
import { Participant } from "../../../types/Group";

type HeaderGroupDiscussionProps = {
  groupName: string;
  gameName?: string;
  banner?: string;
  participants?: Participant[];
  showParticipants?: boolean;
  showImagesIcon?: boolean;
};

export const HeaderGroupDiscussion: React.FC<HeaderGroupDiscussionProps> = ({
  groupName,
  gameName,
  banner,
  participants,
  showParticipants = true,
  showImagesIcon = true,
}) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const handleParticipantsClick = () => {
    navigate(`/messages/group/${id}/participants`);
  };

  const handleSettingsGroup = () => {
    navigate(`/messages/group/${id}/settings`, {
      state: { groupName, banner, participants },
    });
  };

  const navigateMediaPage = () => {
    navigate(`/messages/group/${id}/medias`);
  };

  const navigateMenuFromParticipantGroup = () => {
    navigate(-1);
  };

  const defaultBackground =
    "linear-gradient(0deg, #280C31 7.83%, rgba(8, 0, 16, 0.39) 52.26%, rgba(0, 0, 0, 0.00) 66.65%), linear-gradient(285deg, rgba(14, 65, 168, 0.70) 10.65%, rgba(132, 35, 129, 0.70) 26.88%, rgba(250, 5, 90, 0.70) 60.48%, rgba(211, 19, 132, 0.70) 82.82%, rgba(152, 40, 197, 0.70) 103.6%)";

  return (
    <div
      className={`relative flex w-full flex-col items-center justify-end bg-cover bg-center bg-no-repeat max-mobile:pt-[109px] ${
        !showParticipants && "h-[168px]"
      } h-[210px]`}
      style={{
        background: banner
          ? `linear-gradient(0deg, #280C31 10.91%, rgba(27, 8, 33, 0.06) 53.51%, rgba(9, 3, 12, 0.10) 77.2%, rgba(0, 0, 0, 0.11) 81.91%), url(${banner}) lightgray 50% / cover no-repeat`
          : defaultBackground,
      }}
    >
      {!showParticipants && (
        <div className="absolute left-[20px] top-[40px] ">
          <BackButton />
        </div>
      )}
      <div className="flex w-[90%] items-end justify-between pb-4">
        <div className="flex flex-col items-start gap-y-2">
          <div className="flex flex-col items-start">
            <p
              className="h1-mobile"
              style={{ textShadow: "0px 2px 5px rgba(0, 0, 0, 0.45)" }}
            >
              {groupName}
            </p>
            <p
              className="label-m-400"
              style={{ textShadow: "0px 2px 5px rgba(0, 0, 0, 0.45)" }}
            >
              {gameName}
            </p>
          </div>
          {showParticipants && (
            <DisplayedUsers
              participants={participants}
              showParticipants={showParticipants}
              displayType="participants"
            />
          )}
        </div>
        {showParticipants && (
          <div className="flex flex-col items-center justify-center self-stretch">
            <DotsIconFrame onClick={handleSettingsGroup} />
            {/* {showImagesIcon && <ImagesIcon onClick={navigateMediaPage} />} */}
          </div>
        )}
        {!showParticipants && (
          <div className="flex flex-col items-center justify-center self-stretch">
            <DotsIconFrame onClick={navigateMenuFromParticipantGroup} />
          </div>
        )}
      </div>
      <span
        className="h-[1px] w-full"
        style={{
          background:
            "linear-gradient(90deg, rgba(248, 239, 251, 0.00) 0%, #F8EFFB 47.5%, rgba(248, 239, 251, 0.00) 100%)",
        }}
      />
      {showParticipants && (
        <div className="absolute left-[20px] top-[40px] min-745:hidden">
          <BackButton path="/messages" />
        </div>
      )}
    </div>
  );
};
