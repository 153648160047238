import React, { forwardRef, useState, useEffect, useRef } from "react";
import { ChevronIcon, SearchFillIcon } from "..";
import Logo from "../../assets/logoTP.png";
import { Game } from "../../types";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

interface InputSearchProps {
  showLogoSection: boolean;
  placeholder: string;
  size?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  selectedGame?: Game | null;
  games?: Game[];
  onGameSelect?: (game: Game | null) => void;
  onDropdownChange?: (isOpen: boolean) => void;
  searchQuery?: string;
  value?: string;
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (
    {
      showLogoSection,
      placeholder,
      size,
      onChange,
      onClick,
      selectedGame,
      games = [],
      onGameSelect,
      onDropdownChange,
      searchQuery,
      value,
    },
    ref,
  ) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [gameSearchQuery, setGameSearchQuery] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setShowDropdown(false);
          setGameSearchQuery("");
          if (onDropdownChange) {
            onDropdownChange(false);
          }
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onDropdownChange]);

    const handleGameSelect = (game: Game | null) => {
      if (onGameSelect) {
        onGameSelect(game);
      }
      setGameSearchQuery("");
      setShowDropdown(false);
      if (onDropdownChange) {
        onDropdownChange(false);
      }
    };

    const handleInputChange = (value: string) => {
      if (showDropdown) {
        setGameSearchQuery(value);
      }
      if (onChange) {
        onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const handleDropdownToggle = () => {
      const newState = !showDropdown;
      setShowDropdown(newState);
      if (onDropdownChange) {
        onDropdownChange(newState);
      }
    };

    const filteredGames = games
      .filter((game) =>
        game.name.toLowerCase().includes(gameSearchQuery.toLowerCase()),
      )
      .slice(0, 4);

    return (
      <div
        ref={dropdownRef}
        className={`relative z-[5] flex ${size ? `${size}` : "h-12"} shadow-[inset 0 1px 2px rgba(0,0,0,0.25)] w-full items-center gap-x-4 border border-[0.5px] border-[rgba(198,182,213,0.15)] bg-[rgba(245,235,255,0.10)] px-[13px] backdrop-blur-[10.5px] hover:px-3 ${showDropdown ? "rounded-b-none rounded-t-lg" : "rounded-2xl"} ${isFocused ? "ring-1 ring-inset ring-[rgb(59,130,246)] hover:!px-[13px]" : "hover:border-2 hover:border-[rgba(198,182,213,0.35)]"}`}
        onClick={onClick}
      >
        <div className="flex flex-1 items-center gap-x-2">
          <SearchFillIcon isFocused={isFocused} />
          {!showDropdown && (
            <Textbox
              attributesWrapper={{}}
              attributesInput={{
                id: "input-search",
                name: "input-search",
                type: "text",
                placeholder: placeholder,
              }}
              value={value || searchQuery || ""}
              onChange={(res: string) => handleInputChange(res)}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              validationOption={{
                name: "search",
                check: false,
                required: false,
              }}
              classNameInput="label-m-400 flex-grow !border-none !bg-transparent !outline-none focus:opacity-100"
              classNameWrapper="w-full !rounded-md outline-none"
              classNameContainer="label-m-400 bg-transparent !p-0 !rounded-md"
            />
          )}
          {showDropdown && (
            <Textbox
              attributesWrapper={{}}
              attributesInput={{
                id: "game-search",
                name: "game-search",
                type: "text",
                placeholder: "Rechercher un jeu",
              }}
              value={gameSearchQuery || (selectedGame ? selectedGame.name : "")}
              onChange={(res: string) => setGameSearchQuery(res)}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              validationOption={{
                name: "game-search",
                check: false,
                required: false,
              }}
              classNameInput="label-m-400 flex-grow !border-none !bg-transparent !outline-none focus:opacity-100"
              classNameWrapper="w-full !rounded-md outline-none"
              classNameContainer="label-m-400 bg-transparent !p-0 !rounded-md"
            />
          )}
        </div>
        {showLogoSection && (
          <div
            className="flex cursor-pointer items-center gap-x-1"
            onClick={handleDropdownToggle}
          >
            {selectedGame ? (
              <img
                src={selectedGame.logo}
                alt={selectedGame.name}
                className="game-thumbnail-xxs"
              />
            ) : (
              <img
                src={Logo}
                alt="Logo TalkPlay"
                className="game-thumbnail-xxs"
              />
            )}
            <ChevronIcon orientation={showDropdown ? "up" : "down"} />
          </div>
        )}
        {showDropdown && (
          <div className="absolute right-[-0.5px] top-full mt-0 w-[calc(100%+1px)] rounded-b-md">
            <ul className="rounded-b-md bg-[rgba(245,235,255,0.10)]">
              {filteredGames.map((game) => (
                <li
                  key={game.id}
                  className={`paragraph-m-400 cursor-pointer border-[0.5px] border-solid border-[#ffffff33] bg-[#3a2144] px-4 py-2 hover:bg-[#57296C]`}
                  onClick={() => handleGameSelect(game)}
                >
                  <div className="flex items-center gap-2">
                    <img src={game.logo} alt={game.name} className="h-5 w-5" />
                    {game.name}
                  </div>
                </li>
              ))}
              <li
                className="paragraph-m-400 cursor-pointer rounded-bl-lg rounded-br-lg border-[0.5px] border-solid border-[#ffffff33] bg-[#3a2144] px-4 py-2 hover:bg-[#57296C]"
                onClick={() => handleGameSelect(null)}
              >
                <div className="flex items-center gap-2">
                  <img src={Logo} alt="Logo TalkPlay" className="h-5 w-5" />
                  Aucun jeu sélectionné
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  },
);
