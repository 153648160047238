import { useState } from "react";
import { EditRounded25Icon, TooltipNewMessage } from "../";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import DefaultAvatar from "../../assets/DefaultAvatar.png";

type AvatarIconProps = {
  logo?: string;
  className: string;
  notificationCount?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const AvatarIcon: React.FC<AvatarIconProps> = ({
  logo,
  className = "",
  notificationCount,
  onClick,
}) => {
  const displayNotificationCount =
    notificationCount ?? 0 > 9 ? "9+" : notificationCount ?? 0;

  return (
    <div
      className={`${className} flex items-start justify-end`}
      style={{
        background: `url(${logo || DefaultAvatar}) lightgray 50% / cover no-repeat`,
      }}
      onClick={onClick}
    >
      {notificationCount && (
        <div className="flex h-[15px] w-[15px] items-center justify-center rounded-full border-[0.5px] border-solid border-[primary-100] bg-red pt-[2px]">
          <span className="notification-label">{displayNotificationCount}</span>
        </div>
      )}
    </div>
  );
};

export const AvatarEditIcon: React.FC<AvatarIconProps> = ({
  logo,
  className = "",
}) => {
  return (
    <div
      className={`${className} flex items-start justify-end`}
      style={{ background: `url(${logo}) lightgray 50% / cover no-repeat` }}
    >
      <EditRounded25Icon />
    </div>
  );
};

export const YellowCrownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_535_576)">
      <g filter="url(#filter0_di_535_576)">
        <path
          d="M12 6.375C12.4326 6.375 12.8555 6.24671 13.2153 6.00634C13.575 5.76598 13.8554 5.42433 14.0209 5.02462C14.1865 4.62491 14.2298 4.18507 14.1454 3.76074C14.061 3.33641 13.8527 2.94663 13.5467 2.6407C13.2408 2.33478 12.851 2.12644 12.4267 2.04203C12.0024 1.95763 11.5625 2.00095 11.1628 2.16651C10.7631 2.33208 10.4215 2.61246 10.1811 2.97219C9.94075 3.33192 9.81245 3.75485 9.81245 4.1875C9.81245 4.76766 10.0429 5.32406 10.4532 5.7343C10.8634 6.14453 11.4198 6.375 12 6.375ZM21.7214 9.19375L20.6078 8.57188C20.3171 8.37656 19.9472 8.47578 19.7371 8.75469C19.5277 9.03495 19.2435 9.25045 18.9171 9.37642C18.5908 9.50239 18.2355 9.53372 17.8921 9.4668C16.9882 9.29922 16.375 8.44258 16.375 7.52344C16.375 7.38461 16.3198 7.25148 16.2216 7.15331C16.1235 7.05515 15.9903 7 15.8515 7H14.3371C14.1027 7 13.8835 7.15625 13.8347 7.38711C13.7461 7.80807 13.5154 8.18575 13.1813 8.45679C12.8473 8.72784 12.4301 8.87577 12 8.87577C11.5698 8.87577 11.1527 8.72784 10.8186 8.45679C10.4845 8.18575 10.2538 7.80807 10.1652 7.38711C10.1164 7.15586 9.89878 7 9.66284 7H8.14839C8.00956 7 7.87643 7.05515 7.77826 7.15331C7.6801 7.25148 7.62495 7.38461 7.62495 7.52344C7.62495 8.52695 6.88276 9.42774 5.88003 9.4957C5.56846 9.51767 5.25638 9.46063 4.97273 9.32986C4.68907 9.1991 4.44302 8.99884 4.25737 8.74766C4.05112 8.4707 3.68198 8.37852 3.39448 8.57031L2.27847 9.19531C2.15167 9.27979 2.0601 9.40767 2.02097 9.55492C1.98183 9.70217 1.99784 9.85864 2.06597 9.99492L6.07183 18.25H17.9281L21.9339 9.99336C22.0021 9.85708 22.0181 9.70061 21.9789 9.55336C21.9398 9.40611 21.8482 9.27823 21.7214 9.19375Z"
          fill="black"
        />
        <path
          d="M12 6.375C12.4326 6.375 12.8555 6.24671 13.2153 6.00634C13.575 5.76598 13.8554 5.42433 14.0209 5.02462C14.1865 4.62491 14.2298 4.18507 14.1454 3.76074C14.061 3.33641 13.8527 2.94663 13.5467 2.6407C13.2408 2.33478 12.851 2.12644 12.4267 2.04203C12.0024 1.95763 11.5625 2.00095 11.1628 2.16651C10.7631 2.33208 10.4215 2.61246 10.1811 2.97219C9.94075 3.33192 9.81245 3.75485 9.81245 4.1875C9.81245 4.76766 10.0429 5.32406 10.4532 5.7343C10.8634 6.14453 11.4198 6.375 12 6.375ZM21.7214 9.19375L20.6078 8.57188C20.3171 8.37656 19.9472 8.47578 19.7371 8.75469C19.5277 9.03495 19.2435 9.25045 18.9171 9.37642C18.5908 9.50239 18.2355 9.53372 17.8921 9.4668C16.9882 9.29922 16.375 8.44258 16.375 7.52344C16.375 7.38461 16.3198 7.25148 16.2216 7.15331C16.1235 7.05515 15.9903 7 15.8515 7H14.3371C14.1027 7 13.8835 7.15625 13.8347 7.38711C13.7461 7.80807 13.5154 8.18575 13.1813 8.45679C12.8473 8.72784 12.4301 8.87577 12 8.87577C11.5698 8.87577 11.1527 8.72784 10.8186 8.45679C10.4845 8.18575 10.2538 7.80807 10.1652 7.38711C10.1164 7.15586 9.89878 7 9.66284 7H8.14839C8.00956 7 7.87643 7.05515 7.77826 7.15331C7.6801 7.25148 7.62495 7.38461 7.62495 7.52344C7.62495 8.52695 6.88276 9.42774 5.88003 9.4957C5.56846 9.51767 5.25638 9.46063 4.97273 9.32986C4.68907 9.1991 4.44302 8.99884 4.25737 8.74766C4.05112 8.4707 3.68198 8.37852 3.39448 8.57031L2.27847 9.19531C2.15167 9.27979 2.0601 9.40767 2.02097 9.55492C1.98183 9.70217 1.99784 9.85864 2.06597 9.99492L6.07183 18.25H17.9281L21.9339 9.99336C22.0021 9.85708 22.0181 9.70061 21.9789 9.55336C21.9398 9.40611 21.8482 9.27823 21.7214 9.19375Z"
          fill="url(#paint0_linear_535_576)"
        />
      </g>
      <g filter="url(#filter1_di_535_576)">
        <path
          d="M5.125 19.5H18.875C19.0408 19.5 19.1997 19.5658 19.3169 19.6831C19.4342 19.8003 19.5 19.9592 19.5 20.125V21.375C19.5 21.5408 19.4342 21.6997 19.3169 21.8169C19.1997 21.9342 19.0408 22 18.875 22H5.125C4.95924 22 4.80027 21.9342 4.68306 21.8169C4.56585 21.6997 4.5 21.5408 4.5 21.375V20.125C4.5 19.9592 4.56585 19.8003 4.68306 19.6831C4.80027 19.5658 4.95924 19.5 5.125 19.5Z"
          fill="#6B340C"
        />
        <path
          d="M5.125 19.65H18.875C19.001 19.65 19.1218 19.7 19.2109 19.7891C19.3 19.8782 19.35 19.999 19.35 20.125V21.375C19.35 21.501 19.3 21.6218 19.2109 21.7109C19.1218 21.8 19.001 21.85 18.875 21.85H5.125C4.99902 21.85 4.8782 21.8 4.78912 21.7109C4.70004 21.6218 4.65 21.501 4.65 21.375V20.125C4.65 19.999 4.70004 19.8782 4.78912 19.7891C4.8782 19.7 4.99902 19.65 5.125 19.65Z"
          stroke="url(#paint1_linear_535_576)"
          stroke-width="0.3"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_di_535_576"
        x="-2"
        y="2"
        width="28"
        height="24.25"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_535_576"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_535_576"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_535_576"
        />
      </filter>
      <filter
        id="filter1_di_535_576"
        x="4"
        y="19"
        width="17"
        height="4.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.123324 0 0 0 0 0.123324 0 0 0 0 0.123324 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_535_576"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_535_576"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.2" dy="0.2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_535_576"
        />
      </filter>
      <linearGradient
        id="paint0_linear_535_576"
        x1="13.6666"
        y1="10.8021"
        x2="8.26842"
        y2="3.36093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D27E00" />
        <stop offset="1" stop-color="#E7B400" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_535_576"
        x1="6.27083"
        y1="19.983"
        x2="7.34126"
        y2="23.4321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C6B6D5" stop-opacity="0.25" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_535_576">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BlueCrownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_535_345)">
      <g filter="url(#filter0_di_535_345)">
        <path
          d="M12 6.375C12.4326 6.375 12.8555 6.24671 13.2153 6.00634C13.575 5.76598 13.8554 5.42433 14.0209 5.02462C14.1865 4.62491 14.2298 4.18507 14.1454 3.76074C14.061 3.33641 13.8527 2.94663 13.5467 2.6407C13.2408 2.33478 12.851 2.12644 12.4267 2.04203C12.0024 1.95763 11.5625 2.00095 11.1628 2.16651C10.7631 2.33208 10.4215 2.61246 10.1811 2.97219C9.94075 3.33192 9.81245 3.75485 9.81245 4.1875C9.81245 4.76766 10.0429 5.32406 10.4532 5.7343C10.8634 6.14453 11.4198 6.375 12 6.375ZM21.7214 9.19375L20.6078 8.57188C20.3171 8.37656 19.9472 8.47578 19.7371 8.75469C19.5277 9.03495 19.2435 9.25045 18.9171 9.37642C18.5908 9.50239 18.2355 9.53372 17.8921 9.4668C16.9882 9.29922 16.375 8.44258 16.375 7.52344C16.375 7.38461 16.3198 7.25148 16.2216 7.15331C16.1235 7.05515 15.9903 7 15.8515 7H14.3371C14.1027 7 13.8835 7.15625 13.8347 7.38711C13.7461 7.80807 13.5154 8.18575 13.1813 8.45679C12.8473 8.72784 12.4301 8.87577 12 8.87577C11.5698 8.87577 11.1527 8.72784 10.8186 8.45679C10.4845 8.18575 10.2538 7.80807 10.1652 7.38711C10.1164 7.15586 9.89878 7 9.66284 7H8.14839C8.00956 7 7.87643 7.05515 7.77826 7.15331C7.6801 7.25148 7.62495 7.38461 7.62495 7.52344C7.62495 8.52695 6.88276 9.42774 5.88003 9.4957C5.56846 9.51767 5.25638 9.46063 4.97273 9.32986C4.68907 9.1991 4.44302 8.99884 4.25737 8.74766C4.05112 8.4707 3.68198 8.37852 3.39448 8.57031L2.27847 9.19531C2.15167 9.27979 2.0601 9.40767 2.02097 9.55492C1.98183 9.70217 1.99784 9.85864 2.06597 9.99492L6.07183 18.25H17.9281L21.9339 9.99336C22.0021 9.85708 22.0181 9.70061 21.9789 9.55336C21.9398 9.40611 21.8482 9.27823 21.7214 9.19375Z"
          fill="black"
        />
        <path
          d="M12 6.375C12.4326 6.375 12.8555 6.24671 13.2153 6.00634C13.575 5.76598 13.8554 5.42433 14.0209 5.02462C14.1865 4.62491 14.2298 4.18507 14.1454 3.76074C14.061 3.33641 13.8527 2.94663 13.5467 2.6407C13.2408 2.33478 12.851 2.12644 12.4267 2.04203C12.0024 1.95763 11.5625 2.00095 11.1628 2.16651C10.7631 2.33208 10.4215 2.61246 10.1811 2.97219C9.94075 3.33192 9.81245 3.75485 9.81245 4.1875C9.81245 4.76766 10.0429 5.32406 10.4532 5.7343C10.8634 6.14453 11.4198 6.375 12 6.375ZM21.7214 9.19375L20.6078 8.57188C20.3171 8.37656 19.9472 8.47578 19.7371 8.75469C19.5277 9.03495 19.2435 9.25045 18.9171 9.37642C18.5908 9.50239 18.2355 9.53372 17.8921 9.4668C16.9882 9.29922 16.375 8.44258 16.375 7.52344C16.375 7.38461 16.3198 7.25148 16.2216 7.15331C16.1235 7.05515 15.9903 7 15.8515 7H14.3371C14.1027 7 13.8835 7.15625 13.8347 7.38711C13.7461 7.80807 13.5154 8.18575 13.1813 8.45679C12.8473 8.72784 12.4301 8.87577 12 8.87577C11.5698 8.87577 11.1527 8.72784 10.8186 8.45679C10.4845 8.18575 10.2538 7.80807 10.1652 7.38711C10.1164 7.15586 9.89878 7 9.66284 7H8.14839C8.00956 7 7.87643 7.05515 7.77826 7.15331C7.6801 7.25148 7.62495 7.38461 7.62495 7.52344C7.62495 8.52695 6.88276 9.42774 5.88003 9.4957C5.56846 9.51767 5.25638 9.46063 4.97273 9.32986C4.68907 9.1991 4.44302 8.99884 4.25737 8.74766C4.05112 8.4707 3.68198 8.37852 3.39448 8.57031L2.27847 9.19531C2.15167 9.27979 2.0601 9.40767 2.02097 9.55492C1.98183 9.70217 1.99784 9.85864 2.06597 9.99492L6.07183 18.25H17.9281L21.9339 9.99336C22.0021 9.85708 22.0181 9.70061 21.9789 9.55336C21.9398 9.40611 21.8482 9.27823 21.7214 9.19375Z"
          fill="url(#paint0_linear_535_345)"
        />
      </g>
      <g filter="url(#filter1_di_535_345)">
        <path
          d="M5.125 19.5H18.875C19.0408 19.5 19.1997 19.5658 19.3169 19.6831C19.4342 19.8003 19.5 19.9592 19.5 20.125V21.375C19.5 21.5408 19.4342 21.6997 19.3169 21.8169C19.1997 21.9342 19.0408 22 18.875 22H5.125C4.95924 22 4.80027 21.9342 4.68306 21.8169C4.56585 21.6997 4.5 21.5408 4.5 21.375V20.125C4.5 19.9592 4.56585 19.8003 4.68306 19.6831C4.80027 19.5658 4.95924 19.5 5.125 19.5Z"
          fill="#0842A4"
        />
        <path
          d="M5.125 19.65H18.875C19.001 19.65 19.1218 19.7 19.2109 19.7891C19.3 19.8782 19.35 19.999 19.35 20.125V21.375C19.35 21.501 19.3 21.6218 19.2109 21.7109C19.1218 21.8 19.001 21.85 18.875 21.85H5.125C4.99902 21.85 4.8782 21.8 4.78912 21.7109C4.70004 21.6218 4.65 21.501 4.65 21.375V20.125C4.65 19.999 4.70004 19.8782 4.78912 19.7891C4.8782 19.7 4.99902 19.65 5.125 19.65Z"
          stroke="url(#paint1_linear_535_345)"
          strokeWidth="0.3"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_di_535_345"
        x="-2"
        y="2"
        width="28"
        height="24.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_535_345"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_535_345"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_535_345"
        />
      </filter>
      <filter
        id="filter1_di_535_345"
        x="4"
        y="19"
        width="17"
        height="4.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.123324 0 0 0 0 0.123324 0 0 0 0 0.123324 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_535_345"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_535_345"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.2" dy="0.2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_535_345"
        />
      </filter>
      <linearGradient
        id="paint0_linear_535_345"
        x1="13.6666"
        y1="10.8021"
        x2="8.26842"
        y2="3.36093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0842A4" />
        <stop offset="1" stop-color="#9EB9E7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_535_345"
        x1="6.27083"
        y1="19.983"
        x2="7.34126"
        y2="23.4321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C6B6D5" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_535_345">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const NewDiscussion = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
  >
    <path
      d="M6 10C7.93438 10 9.5 8.43438 9.5 6.5C9.5 4.56562 7.93438 3 6 3C4.06562 3 2.5 4.56562 2.5 6.5C2.5 8.43438 4.06562 10 6 10ZM8.4 11H8.14062C7.49063 11.3125 6.76875 11.5 6 11.5C5.23125 11.5 4.5125 11.3125 3.85938 11H3.6C1.6125 11 0 12.6125 0 14.6V15.5C0 16.3281 0.671875 17 1.5 17H10.5C11.3281 17 12 16.3281 12 15.5V14.6C12 12.6125 10.3875 11 8.4 11ZM15 10C16.6562 10 18 8.65625 18 7C18 5.34375 16.6562 4 15 4C13.3438 4 12 5.34375 12 7C12 8.65625 13.3438 10 15 10ZM16.5 11H16.3813C15.9469 11.15 15.4875 11.25 15 11.25C14.5125 11.25 14.0531 11.15 13.6187 11H13.5C12.8625 11 12.275 11.1844 11.7594 11.4812C12.5219 12.3031 13 13.3938 13 14.6V15.8C13 15.8688 12.9844 15.9344 12.9812 16H18.5C19.3281 16 20 15.3281 20 14.5C20 12.5656 18.4344 11 16.5 11Z"
      fill="#F8EFFB"
    />
    <path
      d="M19.1156 0.5H18.9306C18.2714 0.5 17.737 1.03441 17.737 1.69364V3.69075H15.6936C15.0344 3.69075 14.5 4.22516 14.5 4.88439V5.06936C14.5 5.72859 15.0344 6.26301 15.6936 6.26301H17.737V8.30636C17.737 8.96559 18.2714 9.5 18.9306 9.5H19.1156C19.7748 9.5 20.3092 8.96559 20.3092 8.30636V6.26301L22.3064 6.26301C22.9656 6.26301 23.5 5.72859 23.5 5.06936V4.88439C23.5 4.22516 22.9656 3.69075 22.3064 3.69075H20.3092V1.69364C20.3092 1.03441 19.7748 0.5 19.1156 0.5Z"
      fill="#F8EFFB"
      stroke="#534361"
    />
  </svg>
);

type NewDiscussionFrameProps = {
  onClick?: () => void;
};

export const NewDiscussionFrame: React.FC<NewDiscussionFrameProps> = ({
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`relative ${showTooltip ? "" : "cursor-pointer"} z-20 flex h-[34px] w-[34px] items-center justify-center rounded-[7px] border-[0.3px] border-solid border-white bg-[rgba(255,255,255,0.25)] pb-1 pl-1 backdrop-blur-[2px]`}
      onClick={handleClick}
    >
      <NewDiscussion />
      <TransitionGroup component={null}>
        {showTooltip && (
          <CSSTransition
            classNames="tooltip"
            timeout={300}
            unmountOnExit
            key="tooltip"
          >
            <div className="absolute right-[0px] top-[42px] z-50">
              <TooltipNewMessage desktop={true} />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export const SelectPlayer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M15.6 6.79961H14V5.19961C14 4.97961 13.82 4.79961 13.6 4.79961H12.8C12.58 4.79961 12.4 4.97961 12.4 5.19961V6.79961H10.8C10.58 6.79961 10.4 6.97961 10.4 7.19961V7.99961C10.4 8.21961 10.58 8.39961 10.8 8.39961H12.4V9.99961C12.4 10.2196 12.58 10.3996 12.8 10.3996H13.6C13.82 10.3996 14 10.2196 14 9.99961V8.39961H15.6C15.82 8.39961 16 8.21961 16 7.99961V7.19961C16 6.97961 15.82 6.79961 15.6 6.79961ZM5.6 7.99961C7.3675 7.99961 8.8 6.56711 8.8 4.79961C8.8 3.03211 7.3675 1.59961 5.6 1.59961C3.8325 1.59961 2.4 3.03211 2.4 4.79961C2.4 6.56711 3.8325 7.99961 5.6 7.99961ZM7.84 8.79961H7.4225C6.8675 9.05461 6.25 9.19961 5.6 9.19961C4.95 9.19961 4.335 9.05461 3.7775 8.79961H3.36C1.505 8.79961 0 10.3046 0 12.1596V13.1996C0 13.8621 0.5375 14.3996 1.2 14.3996H10C10.6625 14.3996 11.2 13.8621 11.2 13.1996V12.1596C11.2 10.3046 9.695 8.79961 7.84 8.79961Z"
      fill="white"
    />
  </svg>
);

export const SelectPlayerFrame: React.FC<NewDiscussionFrameProps> = ({
  onClick,
}) => {
  return (
    <div
      className={`relative z-20 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-[7px] border-[0.3px] border-solid border-white bg-[rgba(255,255,255,0.25)] pl-1 backdrop-blur-[2px]`}
      onClick={onClick}
    >
      <SelectPlayer />
    </div>
  );
};
