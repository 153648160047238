import React from "react";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

interface CustomTextboxProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  validationOption?: {
    name: string;
    check: boolean;
    required: boolean;
  };
  maxLength?: number;
}

export const CustomTextbox: React.FC<CustomTextboxProps> = ({
  placeholder,
  value,
  onChange,
  onBlur,
  validationOption,
  maxLength = 0,
}) => {
  const charactersRemaining = maxLength ? maxLength - value.length : null;

  return (
    <div className="w-full">
      <Textbox
        attributesWrapper={{}}
        attributesInput={{
          id: "custom-textbox",
          name: "custom-textbox",
          type: "text",
          placeholder: placeholder,
          maxLength: maxLength,
        }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        validationOption={validationOption}
        classNameInput="w-full !bg-[#3a2144] !p-2 outline-none !rounded-md focus-within:!border-[rgb(59,130,246)]"
        classNameWrapper="w-full !rounded-md outline-none"
        classNameContainer="label-m-400 bg-[#3a2144] !p-0 !rounded-md shadow-sm"
      />
      {charactersRemaining !== null && (
        <p className="label-s-400 mt-1 text-gray-400">
          {charactersRemaining} caractères restants
        </p>
      )}
    </div>
  );
};
