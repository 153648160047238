import { useState, useEffect } from "react";
import {
  AvatarEditIcon,
  AvatarIcon,
  BackButton,
  DescriptionUser,
  DisplayedUsers,
  DotsIconFrame,
  SecondaryButton,
  UserOptions,
} from "../..";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../providers/userAuth";
import { LogoType } from "../../../types";

interface HeaderProfileProps {
  username: string;
  logo: string;
  followersCount: number;
  edit?: boolean;
  about: string;
  friendsCount: number;
  friendsLogos: LogoType[];
  follow: boolean;
  friend: string;
  userId: string;
  onFollowChange: (newFollowStatus: boolean) => void;
}

export const HeaderProfile: React.FC<HeaderProfileProps> = ({
  username,
  logo,
  followersCount,
  edit = false,
  about,
  friendsCount,
  friendsLogos,
  follow,
  friend,
  userId,
  onFollowChange,
}) => {
  const { user } = useAuth();
  const isUserPage = username === user?.username;
  const [canGoBack, setCanGoBack] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.key !== "default") {
      setCanGoBack(true);
    }
  }, [location]);

  const abonneText =
    followersCount === 0 || followersCount === 1 ? "abonné" : "abonnés";

  return (
    <div className="flex w-full max-pc:flex-col max-pc:gap-y-4 min-computer:justify-between">
      <div className="flex items-start gap-x-2 min-745:gap-x-8">
        <div className="flex h-full pt-2">
          {edit ? (
            <AvatarEditIcon logo={logo} className="avatar-l" />
          ) : (
            <AvatarIcon logo={logo} className="avatar-l" />
          )}
        </div>
        <div className="flex flex-col items-start justify-center gap-y-2 self-stretch">
          <div className="flex flex-col">
            <h1 className="h1-mobile self-stretch">{username}</h1>
            <div className="flex items-center gap-x-6 self-stretch">
              <p className="label-m-400">
                {followersCount} {abonneText}
              </p>
            </div>
          </div>
          <div className="max-mobile:hidden">
            <DescriptionUser edit={edit} about={about} />
          </div>
        </div>
      </div>
      {!isUserPage && (
        <div className="flex h-[33px] gap-y-2 self-stretch max-mobile:hidden max-pc:items-center max-pc:justify-between min-computer:flex-col min-computer:items-end min-computer:justify-between">
          <div className="relative max-pc:hidden">
            {/* <DotsIconFrame onClick={() => setShowUserOptions(true)} /> */}
            {showUserOptions && (
              <UserOptions
                userId={userId}
                name={username}
                onClose={() => setShowUserOptions(false)}
              />
            )}
          </div>
          <div className="flex items-center gap-x-2">
            <SecondaryButton
              userId={userId}
              actionType="follow"
              initialFollow={follow}
              onFollowChange={onFollowChange}
            />
            <SecondaryButton
              userId={userId}
              actionType="friendRequest"
              transparent={true}
              initialFriendStatus={friend}
            />
          </div>
          <DisplayedUsers
            friends={friendsCount}
            friendsLogos={friendsLogos}
            showParticipants={true}
            displayType="friends"
          />
        </div>
      )}
      {canGoBack && (
        <div className="absolute left-[20px] top-[65px] min-745:hidden">
          <BackButton />
        </div>
      )}
    </div>
  );
};
