import { useNavigate } from "react-router-dom";
import {
  TransparentSecondaryButton,
  CloseFillIcon,
  EditIcon,
  SettingIcon14,
} from "..";
import { useEffect, useRef } from "react";

interface OverlayOptionUserProps {
  onClose: () => void;
}

export const OverlayOptionUser: React.FC<OverlayOptionUserProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const navigateEditProfil = () => {
    onClose();
    navigate(`/settings/editprofil/`);
  };

  const navigateSettingsPage = () => {
    onClose();
    navigate(`/settings/`);
  };

  const navigateConnectGames = () => {
    onClose();
    navigate(`/connectgames/`);
  };

  return (
    <div
      ref={overlayRef}
      className="relative z-[100] flex max-h-[70vh] w-[450px] flex-col gap-y-10 rounded-lg border-[2px] border-[rgba(198,182,213,0.5)] pb-8 pl-6 pr-4 pt-4 shadow-[0_2px_34px_rgba(0,0,0,0.45)] backdrop-blur-[6.5px]"
      style={{
        background:
          "linear-gradient(0deg, rgba(20, 0, 41, 0.85) 61.19%, rgba(40, 12, 49, 0.85) 100%)",
      }}
    >
      <div className="flex w-full items-center justify-between">
        <p className="h1-mobile">Options du joueur</p>
        <CloseFillIcon onClick={onClose} />
      </div>
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center gap-x-2" onClick={navigateEditProfil}>
          <div className="w-[14px] cursor-pointer">
            <EditIcon />
          </div>
          <p className="nav-menu-500 cursor-pointer hover:underline">
            Modifier le profil
          </p>
        </div>
        <div
          className="flex items-center gap-x-2"
          onClick={navigateSettingsPage}
        >
          <div className="w-[14px] cursor-pointer">
            <SettingIcon14 />
          </div>
          <p className="nav-menu-500 cursor-pointer hover:underline">
            Paramètres de ton compte
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col gap-y-4">
        <p className="paragraph-s-500">
          Enrichis ton expérience TalkPlay en ajoutant plus de jeux de à ton
          compte
        </p>
        <TransparentSecondaryButton
          label="Ajouter des jeux"
          noBg={true}
          size="h-12"
          onClick={navigateConnectGames}
        />
      </div>
    </div>
  );
};
