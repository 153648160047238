import ClashLevel from "../../assets/CoCLevel.webp";
import CRLevel from "../../assets/CRLevel.png";

interface CocLevelIconProps {
  level: number;
  size?: number;
}

export const CocLevelIcon: React.FC<CocLevelIconProps> = ({
  level,
  size = 32,
}) => {
  return (
    <div
      className="box-border flex items-center justify-center bg-center"
      style={{
        background: `url(${ClashLevel}) center no-repeat`,
        backgroundSize: "cover",
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <p
        className="label-s-400"
        style={{
          textShadow: "2px 4px 8px rgba(0, 0, 0, 0.90)",
        }}
      >
        {level}
      </p>
    </div>
  );
};

export const CRLevelIcon: React.FC<CocLevelIconProps> = ({
  level,
  size = 32,
}) => {
  return (
    <div
      className="box-border flex items-center justify-center bg-center"
      style={{
        background: `url(${CRLevel}) center no-repeat`,
        backgroundSize: "cover",
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <p
        className="label-s-400"
        style={{
          textShadow: "2px 4px 8px rgba(0, 0, 0, 0.90)",
        }}
      >
        {level}
      </p>
    </div>
  );
};
