import { LogoType } from "../../types";
import { Participant } from "../../types/Group";

type DisplayedUsersProps = {
  participants?: Participant[];
  friends?: number;
  friendsLogos?: LogoType[];
  showParticipants: boolean;
  handleParticipantsClick?: () => void;
  displayType: "participants" | "friends";
};

export const DisplayedUsers: React.FC<DisplayedUsersProps> = ({
  participants,
  friends,
  friendsLogos,
  showParticipants,
  handleParticipantsClick,
  displayType,
}) => {
  const displayedParticipants = showParticipants
    ? participants?.slice(0, 4) ?? []
    : [];
  const additionalCount =
    (participants?.length ?? 0) - displayedParticipants.length;

  if (displayType === "friends") {
    if (!friendsLogos || friendsLogos.length === 0) {
      return null;
    }
    const displayedFriendsLogos = friendsLogos.slice(0, 4);
    return (
      <div className="flex items-center justify-end gap-x-2 py-2">
        <div className="flex items-center gap-x-[6px] whitespace-nowrap">
          <p className="digit-500">{friends}</p>
          <p className="label-m-400 pt-1">
            {friends === 1 ? "Ami en commun" : "Amis en communs"}
          </p>
        </div>
        <div
          className="flex items-center pl-[15px]"
          onClick={handleParticipantsClick}
        >
          {displayedFriendsLogos.map((logo, index) => (
            <div key={index} className="avatar-xs -ml-[15px]">
              <img
                src={logo.logo}
                alt={`Friend ${index + 1}`}
                className="image-avatar border border-[#C6B6D5]"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex items-center pl-[15px]"
      onClick={handleParticipantsClick}
    >
      {displayedParticipants.map((participant, index) => (
        <div key={index} className="avatar-s -ml-[15px]">
          <img
            src={participant.logo}
            alt={participant.username}
            className="image-avatar border border-[#C6B6D5]"
          />
        </div>
      ))}
      {additionalCount > 0 && (
        <div className="label-s-600 avatar-s -ml-[15px] flex h-[46px] w-[46px] items-center justify-center border border-[#C6B6D5] bg-primary-500">
          +{additionalCount}
        </div>
      )}
    </div>
  );
};
