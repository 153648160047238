export const HomeFillIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="m-1.5"
  >
    <path
      d="M10.4044 5.71389L4.57926 12.4228C4.18799 12.8734 3.96896 13.4731 3.96896 14.0958V21H7.967V14.9998C7.967 14.6655 8.05099 14.3376 8.20973 14.0523L8.24144 13.9953C8.54196 13.4551 9.07675 13.1257 9.65308 13.1257H12.4848C13.0611 13.1257 13.5959 13.4551 13.8964 13.9953L13.9281 14.0523C14.0869 14.3376 14.1709 14.6655 14.1709 14.9998V21H18.1689V14.0958C18.1689 13.4731 17.9499 12.8734 17.5586 12.4228L11.7335 5.71389C11.361 5.28495 10.7768 5.28495 10.4044 5.71389Z"
      fill="#F8EFFB"
    />
    <path
      d="M1 11.8815C4.21999 7.97626 6.0253 5.78675 9.24529 1.88151C10.2144 0.706166 11.7856 0.706164 12.7547 1.88151L21 11.8815M4.57926 12.4228L10.4044 5.71389C10.7768 5.28495 11.361 5.28495 11.7335 5.71389L17.5586 12.4228C17.9499 12.8734 18.1689 13.4731 18.1689 14.0958V21H14.1709V14.9998C14.1709 14.6655 14.0869 14.3376 13.9281 14.0523L13.8964 13.9953C13.5959 13.4551 13.0611 13.1257 12.4848 13.1257H9.65308C9.07675 13.1257 8.54196 13.4551 8.24144 13.9953L8.20973 14.0523C8.05099 14.3376 7.967 14.6655 7.967 14.9998V21H3.96896V14.0958C3.96896 13.4731 4.18799 12.8734 4.57926 12.4228Z"
      stroke="#F5EBFF"
      strokeWidth="1.14286"
      strokeLinecap="round"
    />
  </svg>
);

export const HomeStrokeIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11.8815C4.21999 7.97626 6.0253 5.78675 9.24529 1.88151C10.2144 0.706166 11.7856 0.706164 12.7547 1.88151L21 11.8815M4.57926 12.4228L10.4044 5.71389C10.7768 5.28495 11.361 5.28495 11.7335 5.71389L17.5586 12.4228C17.9499 12.8734 18.1689 13.4731 18.1689 14.0958V21H14.1709V14.9998C14.1709 14.6655 14.0869 14.3376 13.9281 14.0523L13.8964 13.9953C13.5959 13.4551 13.0611 13.1257 12.4848 13.1257H9.65308C9.07675 13.1257 8.54196 13.4551 8.24144 13.9953L8.20973 14.0523C8.05099 14.3376 7.967 14.6655 7.967 14.9998V21H3.96896V14.0958C3.96896 13.4731 4.18799 12.8734 4.57926 12.4228Z"
      stroke="#F8EFFB"
      strokeLinecap="round"
    />
  </svg>
);
