// src/helpers/Group.ts
import axios, { AxiosResponse } from "axios";
import { Group, GroupInPage } from "../types/Group";
import { apiUrl, deleteGroupUrl } from "../constants/api";

export const fetchGroups = async (): Promise<Group[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response: AxiosResponse<Group[]> = await axios.get(
      `${apiUrl}groups`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    const groupsData: Group[] = response.data;
    return groupsData;
  } catch (error) {
    console.error("Error fetching groups:", error);
    throw error;
  }
};

export const fetchGroupMessages = async (
  groupId: string,
): Promise<GroupInPage> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    throw new Error("Auth token not found");
  }

  const response = await axios.get<GroupInPage>(`${apiUrl}group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const markGroupAsSeen = async (groupId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    throw new Error("Auth token not found");
  }

  await axios.post(
    `${apiUrl}group/markAsSeen/${groupId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};

export const sendMessageWithVideo = async (
  groupId: string,
  message: string,
  video: File,
): Promise<void> => {
  const authToken = localStorage.getItem("token");
  try {
    await axios.post(
      `${apiUrl}group/message/file`,
      { groupId, content: message, files: video },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const deleteGroup = async (groupId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }
  try {
    await axios.delete(deleteGroupUrl + groupId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error("Error deleting discussion:", error);
    throw error;
  }
};
