import axios, { AxiosResponse } from "axios";
import {
  forgotPasswordUrl,
  loginUrl,
  preRegisterUrl,
  registerUrl,
  resetPasswordUrl,
} from "../constants/api";

export const login =
  (login: string, password: string) => async (): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        loginUrl,
        {
          login: login,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const loginData: any = response.data;
      return loginData;
    } catch (error) {
      return error;
    }
  };

export const register =
  (username: string, email: string, password: string) =>
  async (): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        registerUrl,
        {
          username: username,
          password: password,
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Erreur lors de l'appel API d'enregistrement", error);
      throw error;
    }
  };

export const preregister = (email: string) => async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(
      preRegisterUrl,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const preregisterData: any = response.data;
    return preregisterData;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = (email: string) => async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(forgotPasswordUrl, {
      email: email,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword =
  (password: string, token: string) => async (): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.post(resetPasswordUrl, {
        password: password,
        hash: token,
      });

      return response;
    } catch (error) {
      return error;
    }
  };
