import { Route, Routes } from "react-router-dom";
import { FormattedNameRoute, VideoProvider } from "../components";
import {
  HomePage,
  SignIn,
  UserInformation,
  ConnectGames,
  ClashOfClansAuth,
  ClashRoyaleAuth,
  BrawlStarsAuth,
  RiotAuth,
  PostPage,
  ReportPost,
  GameplayPage,
  CreatePost,
  CreateGameplay,
  GamePage,
  GameInfoPage,
  SearchPage,
  CommunitiesPage,
  CombinedFriendsPage,
  FriendsList,
  RequestFriendsList,
  UserPage,
  ReportUser,
  PagesContainer,
  ChooseGame,
  UserPostsPage,
  UserGamesPage,
  PagesContainerSettings,
  PrivacyPolicy,
  TermsOfService,
  CommunityGuidelines,
  UserGameplayPage,
  LostPassword,
  ChangePassword,
} from "../pages";

export const RoutesWithVideoProvider = () => (
  <VideoProvider>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/signin" element={<SignIn />} />
      {/* <Route
                  path="/confirmationregister"
                  element={<PreRegisterConfirmation />}
                /> */}
      {/* <Route path="/changepassword" element={<ChangePassword />} />
                <Route
                  path="/changepasswordconfirmation"
                  element={<ChangePasswordConfirmation />}
                />
                <Route path="/lostpassword" element={<LostPassword />} />
                <Route
                  path="/lostpassword/confirmation"
                  element={<LostPasswordConfirmation />}
                />
                <Route
                  path="/mailconfirmation"
                  element={<MailConfirmation />}
                /> */}
      <Route path="/inscription" element={<UserInformation />} />
      <Route path="/connectgames" element={<ConnectGames />} />
      <Route path="/connectgames/clashofclans" element={<ClashOfClansAuth />} />
      <Route path="/connectgames/clashroyale" element={<ClashRoyaleAuth />} />
      <Route path="/connectgames/brawlstars" element={<BrawlStarsAuth />} />
      <Route path="/connectgames/riot" element={<RiotAuth />} />
      <Route path="/connectgames/:token" element={<ConnectGames />} />
      <Route path="/post/:postId" element={<PostPage />} />
      <Route path="/post/:postId/report" element={<ReportPost />} />
      <Route path="/gameplay/:gameplayId" element={<GameplayPage />} />
      <Route path="/createpost" element={<CreatePost />} />
      <Route path="/creategameplay" element={<CreateGameplay />} />
      <Route
        path="/game/:gameId/:name"
        element={<FormattedNameRoute element={<GamePage />} />}
      />
      <Route
        path="/game/:gameId/:name/info"
        element={<FormattedNameRoute element={<GameInfoPage />} />}
      />
      {/* <Route path="/updategame" element={<UpdateGame />} />
                <Route path="/creategame" element={<CreateGame />} />
                <Route path="/createstudio" element={<CreateStudio />} />
                <Route path="/updatestudio" element={<UpdateStudio />} />
                <Route path="/createlauncher" element={<CreateLauncher />} /> */}
      <Route path="/search" element={<SearchPage />} />
      <Route path="/communities" element={<CommunitiesPage />} />
      <Route path="/managefriends" element={<CombinedFriendsPage />} />
      <Route path="/managefriends/friends" element={<FriendsList />} />
      <Route
        path="/managefriends/friendsrequests"
        element={<RequestFriendsList />}
      />
      <Route path="/user/:username" element={<UserPage />} />
      {/* <Route path="/notifications" element={<Notifications />} /> */}
      <Route path="/user/:username/report" element={<ReportUser />} />
      <Route path="/messages/*" element={<PagesContainer />} />
      <Route path="/choosegame" element={<ChooseGame />} />
      {/* <Route
                  path="/message/group/:groupId/medias"
                  element={<Medias />}
                />
                <Route
                  path="/message/group/:groupId/search"
                  element={<SearchInConversation />}
                />
                <Route
                  path="/message/group/:groupId/modifygroup"
                  element={<CreateGroup edit={true} />}
                />
                <Route
                  path="/message/user/:messageId/medias"
                  element={<Medias />}
                />
                <Route
                  path="/message/user/:messageId/search"
                  element={<SearchInConversation />}
                /> */}
      <Route path="/user/:username/posts" element={<UserPostsPage />} />
      <Route path="/user/:username/games" element={<UserGamesPage />} />
      <Route path="/user/:username/gameplay" element={<UserGameplayPage />} />
      <Route path="/settings/*" element={<PagesContainerSettings />} />
      <Route path="/legal/privacy" element={<PrivacyPolicy />} />
      <Route path="/legal/terms" element={<TermsOfService />} />
      <Route path="/legal/guidelines" element={<CommunityGuidelines />} />
      <Route path="/forgot-password" element={<LostPassword />} />
      <Route path="/reset-password/:token" element={<ChangePassword />} />
    </Routes>
  </VideoProvider>
);
