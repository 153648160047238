import { useSidebarWidth } from "./SidebarWidthContext";
import Image from "../../assets/SidebarImage1.png";
import { LogoBeta } from "../Icons/LogoBeta";

type SidebarInscriptionProps = {
  chooseGame?: boolean;
};

export const SidebarInscription: React.FC<SidebarInscriptionProps> = ({
  chooseGame = false,
}) => {
  const { sidebarRef } = useSidebarWidth();

  return (
    <div
      ref={sidebarRef}
      className="sticky hidden h-auto min-h-screen min-w-[350px] flex-col justify-between self-stretch bg-[#D5D7E7] pt-[50px] min-745:flex"
    >
      <div className="flex flex-col items-start gap-y-6 self-stretch pl-[56px] pr-5">
        <div className="h-[40px] w-[150px]">
          <LogoBeta textColor="black" clickable={false} />
        </div>

        <p className="sidebar-title self-stretch">
          Connect games,
          <br /> Find any player,
          <br /> Share gameplay.
        </p>
      </div>
      <img src={Image} alt="Illustration" className="max-h-[500px] w-[100%]" />
    </div>
  );
};
