import { BackButton, ButtonSimple, InfoIcon } from "../";

type BackAndButtonNextProps = {
  selectedPlayersCount?: number;
  navigateTo: () => void;
  label: string;
  isPlayer?: boolean;
  infoChoice?: boolean;
  back?: boolean;
  widget?: boolean;
  showSelectedCountText?: boolean;
};

export const BackAndButtonNext: React.FC<BackAndButtonNextProps> = ({
  selectedPlayersCount,
  navigateTo,
  label,
  isPlayer,
  infoChoice,
  back,
  widget,
  showSelectedCountText = false,
}) => {
  return (
    <div
      className={`bottom-0 left-0 z-10 flex w-full flex-col items-center gap-y-5 border-t border-t-[#C6B6D540] px-5 pb-8 pt-4 backdrop-blur-[7px] max-mobile:fixed ${
        widget ? "" : "min-745:hidden"
      } `}
      style={{
        background:
          "linear-gradient(92.03deg, rgba(40, 12, 49, 0.8) 8.79%, rgba(33, 16, 51, 0.8) 100%)",
      }}
    >
      {!back && showSelectedCountText && (
        <>
          {isPlayer ? (
            <>
              {selectedPlayersCount && selectedPlayersCount > 0 ? (
                <p className="label-m-400">
                  {selectedPlayersCount} joueur
                  {selectedPlayersCount !== 1 ? "s" : ""} pour intégrer le
                  groupe sélectionné
                </p>
              ) : (
                <p className="label-m-400">
                  Aucun joueur sélectionné pour intégrer le groupe
                </p>
              )}
            </>
          ) : (
            <>
              {selectedPlayersCount && selectedPlayersCount > 0 ? (
                <p className="label-m-400 text-center">
                  {selectedPlayersCount} jeu
                  {selectedPlayersCount !== 1 ? "x" : ""} sélectionné
                  {selectedPlayersCount !== 1 ? "s" : ""}
                </p>
              ) : (
                <p className="label-m-400 text-center">Aucun jeu sélectionné</p>
              )}
            </>
          )}
        </>
      )}

      <div
        className={`${back ? "justify-between" : "justify-end"} flex items-center  self-stretch`}
      >
        {back && (
          <button className="text-primary-100">
            <BackButton />
          </button>
        )}
        <ButtonSimple
          label={label}
          onClick={navigateTo}
          showChevron={true}
          chevronDirection="right"
        />
      </div>
      {infoChoice == true && (
        <div className="flex items-center justify-center gap-x-1 self-stretch">
          <InfoIcon size={18} />
          <p className="label-s-400 opacity-70">
            Tu pourras toujours ajouter d’autres jeux plus tard
          </p>
        </div>
      )}
    </div>
  );
};
