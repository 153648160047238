import { DiscordIcon } from "..";

export const DiscordButton = () => {
  const handleClick = () => {
    window.open(
      "https://discord.gg/bfYqH8jB8W",
      "_blank",
      "noopener,noreferrer",
    );
  };

  return (
    <div
      className="flex cursor-pointer items-center justify-center gap-x-[10px] self-stretch rounded-[12px] border border-transparent px-4 py-[10px] backdrop-blur-[2px]"
      style={{
        background:
          "linear-gradient(290deg, #6441a5, #6441a5) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.8), #6441a5 40%) border-box",
      }}
      onClick={handleClick}
    >
      <DiscordIcon />
      <p className="button-label">Rejoins le Discord de TalkPlay</p>
    </div>
  );
};
