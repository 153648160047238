import React, { useState } from "react";
import { BackButton, ButtonSimple, SimpleInput } from "../../../components";
import { SpinnerLoading } from "../../../components/Icons/Spinner/SpinnerLoading";
import { addRiotGame } from "../../../helpers/fetchGame";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

export const RiotAuth = () => {
  const location = useLocation();
  const { launcherId } = location.state || {};
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleConnectAccount = async () => {
    setIsLoading(true);
    try {
      await addRiotGame(launcherId, name, tag);
      toast.success("Compte Riot lié avec succès !");
      navigate("/connectgames");
    } catch (error) {
      console.error("Failed to connect account:", error);
      toast.error("Échec de la connexion du compte.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-start gap-y-6 self-stretch px-8 pb-10 pt-6 max-mobile:pt-20 ">
      <BackButton />
      <div className="flex flex-col gap-y-4 self-stretch max-1157:items-center min-745:gap-y-8 min-745:pt-[10vh]">
        <div className="flex gap-x-2">
          <p className="h1-mobile">Connexion Riot</p>
        </div>
        <p className="paragraph-m-400 max-1157:max-w-[400px] ">
          Renseigne ton pseudo et ton tagline pour lier ton compte à TalkPlay
        </p>
        <div className="flex w-full max-1157:flex-col max-1157:gap-y-4 min-851:gap-x-16">
          <div className="flex w-full flex-col items-center gap-y-4 self-stretch min-745:gap-y-8">
            <div className="flex w-full max-w-[400px] flex-col items-start gap-y-4 rounded-lg border border-[rgba(198,182,213,0.15)] bg-[rgba(151,46,182,0.29)] p-4">
              <p className="nav-menu-700">Pseudo</p>
              <SimpleInput
                placeholder="Pseudo"
                value={name}
                onChange={(e) => setName(e.target.value)}
                iconType={"none"}
              />
              <p className="nav-menu-700">Tag</p>
              <SimpleInput
                placeholder="EUW"
                value={tag}
                helper={true}
                helperText="Exemple: EUW"
                onChange={(e) => setTag(e.target.value)}
                iconType={"none"}
                infoIconColor="bg-white"
              />
              <ButtonSimple
                label="Connecter ce compte"
                onClick={handleConnectAccount}
              />
            </div>
            {isLoading && (
              <div className="flex w-full flex-col items-center gap-y-4">
                <SpinnerLoading />
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
