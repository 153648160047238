(function () {
  const resizeObserverLoopErr = "ResizeObserver loop limit exceeded";

  const origConsoleError = console.error;

  console.error = function (...args) {
    if (args[0] === resizeObserverLoopErr) {
      return;
    }
    // origConsoleError.apply(console, args);
  };
})();
