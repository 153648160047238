type TransparentButtonProps = {
  label: string;
  className?: string;
  onClick?: () => void;
  size?: string;
  noBg?: boolean;
};

export const TransparentButton: React.FC<TransparentButtonProps> = ({
  label,
  className = "",
  onClick,
  size = "h-12",
}) => {
  return (
    <div
      className={`${className} flex ${size} cursor-pointer items-center justify-center self-stretch rounded-[12px] border border-solid border-primary-100 bg-transparent px-4 py-2.5 backdrop-blur-[2px] hover:bg-[rgba(245,235,255,0.20)]`}
      onClick={onClick}
    >
      <p className="hover:text-primary-200 button-label">{label}</p>
    </div>
  );
};

export const TransparentSecondaryButton: React.FC<TransparentButtonProps> = ({
  label,
  className = "",
  onClick,
  size = "h-[34px]",
  noBg = false,
}) => {
  return (
    <div
      className={`${className} flex ${size} cursor-pointer items-center justify-center self-stretch whitespace-nowrap rounded-[20px] border-[0.4px] border-solid border-primary-100 ${noBg ? "" : "bg-[rgba(245,235,255,0.20)]"} px-3 py-2 backdrop-blur-[4px] hover:bg-[rgba(245,235,255,0.35)]`}
      onClick={onClick}
    >
      <p className="hover:text-primary-200 button-label">{label}</p>
    </div>
  );
};
