import {
  BackButton,
  SimpleInput,
  ButtonSimple,
  ChevronIcon,
  ConfirmationModal,
} from "../../../../components";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../../../providers/userAuth";
import { User, UserExist } from "../../../../types";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import {
  fetchUsernameExist,
  fetchTimeLeftUpdateUsername,
  updateUsername,
} from "../../../../helpers/fetchUser";

export const PseudoChange = () => {
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const username = authUser?.username;
  const [userData, setUserData] = useState<User>();
  const [currentUsername, setCurrentUsername] = useState<string>(
    username || "",
  );
  const [newUsername, setNewUsername] = useState<string>("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<
    boolean | null
  >(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const [isTimeLeftLoading, setIsTimeLeftLoading] = useState<boolean>(true);
  const [isTimeRestricted, setIsTimeRestricted] = useState<boolean>(false);

  useEffect(() => {
    const fetchTimeLeft = async () => {
      try {
        const timeLeftData = await fetchTimeLeftUpdateUsername();
        const isRestricted =
          timeLeftData.days > 0 ||
          timeLeftData.hours > 0 ||
          timeLeftData.minutes > 0;
        setIsTimeRestricted(isRestricted);

        if (isRestricted) {
          setTimeLeft(formatTimeLeft(timeLeftData));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du temps restant", error);
        setTimeLeft(null);
      } finally {
        setIsTimeLeftLoading(false);
      }
    };

    fetchTimeLeft();
  }, []);

  const formatTimeLeft = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    return `${days} jours, ${hours} heures, ${minutes} minutes`;
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const handleNewUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedUsername = e.target.value;
    setNewUsername(updatedUsername);
    if (updatedUsername.length < 3) {
      setIsUsernameAvailable(false);
    } else {
      debouncedCheckUsername(updatedUsername);
    }
  };

  const debouncedCheckUsername = useCallback(
    debounce(async (username: string) => {
      if (username.length < 3) {
        setIsUsernameAvailable(false);
        return;
      }

      try {
        const userExistData: UserExist = await fetchUsernameExist(username)();
        setIsUsernameAvailable(!userExistData.exists);
      } catch (error) {
        console.error("Erreur lors de la vérification du pseudo", error);
        setIsUsernameAvailable(false);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    if (newUsername && newUsername.length >= 3) {
      debouncedCheckUsername(newUsername);
    }
    return () => debouncedCheckUsername.cancel();
  }, [newUsername, debouncedCheckUsername]);

  const handleSaveClick = () => {
    if (isUsernameAvailable) {
      setShowModal(true);
    }
  };

  const handleConfirm = async () => {
    try {
      const response = await updateUsername(newUsername);
      console.log("Pseudo updated successfully", response);
      setCurrentUsername(newUsername);
      const timeLeftData = await fetchTimeLeftUpdateUsername();
      setTimeLeft(formatTimeLeft(timeLeftData));
    } catch (error) {
      console.error("Erreur lors du changement de pseudo", error);
    } finally {
      setShowModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div
      className={`flex min-h-[calc(100vh-58px)] w-full flex-col gap-y-6 border-l border-l-[rgba(198,182,213,0.25)] px-5 pb-[60px] pt-10 max-mobile:pt-[40px] ${
        isWideScreen ? "" : "absolute inset-0 min-h-screen"
      }`}
      style={{
        background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
      }}
    >
      <div className="min-745:hidden">
        <BackButton />
      </div>
      <div className="flex flex-col items-start gap-y-6 self-stretch">
        <div className="flex items-center gap-x-10 self-stretch">
          <ChevronIcon
            orientation="left"
            onClick={navigateBack}
            className="cursor-pointer max-mobile:hidden"
          />
          <p className="h1-mobile">Modifier votre pseudo</p>
        </div>
        {isTimeRestricted &&
          (isTimeLeftLoading ? (
            <p className="nav-menu-700">Chargement du temps restant...</p>
          ) : (
            <p className="nav-menu-700">{`Temps restant avant de pouvoir changer de pseudo à nouveau : ${timeLeft}`}</p>
          ))}
        <div className="flex flex-col gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Pseudo actuel</p>
            <SimpleInput
              placeholder="Ton pseudo actuel"
              iconType="none"
              value={currentUsername}
              readOnly={true}
            />
          </div>
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Nouveau pseudo</p>
            <SimpleInput
              placeholder="Ton nouveau pseudo"
              iconType="check"
              value={newUsername}
              onChange={handleNewUsernameChange}
              isCorrect={newUsername.length >= 3 ? isUsernameAvailable : false}
              helper={true}
              helperText={
                newUsername.length < 3
                  ? "Le pseudo doit contenir au moins 3 caractères."
                  : isUsernameAvailable === null
                    ? "Vérification de la disponibilité..."
                    : isUsernameAvailable
                      ? "Pseudo disponible"
                      : "Pseudo indisponible"
              }
            />
          </div>
          <div className="flex items-end self-stretch">
            <ButtonSimple
              label="Changer"
              onClick={handleSaveClick}
              showChevron={true}
              chevronDirection="right"
              disabled={!isUsernameAvailable || isTimeRestricted}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <ConfirmationModal
          title="Confirmer le changement de pseudo"
          message={`Êtes-vous sûr de vouloir changer votre pseudo ? Vous ne pourrez changer votre pseudo qu'une fois par mois.`}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};
